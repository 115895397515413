import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { InputRow } from './InputRow'
const ProfileEdit: React.FunctionComponent<any> = props => {
  let { profileBasic, personalInfo, bussinessAccount, companyInfo, notChangableInfo } = props

  return (
    <>
      <form onSubmit={props.profileChange}>
        <h1 className={'pg-text-lg pg-py-5 pg-text-gray-700'}>{t`Personal Account`}</h1>

        <div className={'pg-py-3 pg-bg-gray-400 pg-text-gray-700 pg-px-1'}>{t`BASICS`}</div>
        {notChangableInfo &&
          notChangableInfo.map((each: any, index: number) => {
            return (
              <InputRow
                label={each.label}
                value={each.value}
                editable={false}
                key={index}
                border={true}
                onEdit={props.onEdit}
                name={each.name}
                {...(each.optionsArray && { optionsArray: each.optionsArray })}
              />
            )
          })}
        {profileBasic &&
          profileBasic.map((each: any, index: number) => {
            return (
              <InputRow
                label={each.label}
                value={each.value}
                editable={true}
                key={index}
                border={true}
                onEdit={props.onEdit}
                name={each.name}
                {...(each.optionsArray && { optionsArray: each.optionsArray })}
              />
            )
          })}
        <div className={'pg-py-3 pg-bg-gray-400 pg-text-gray-700 pg-px-1'}>{t`PERSONAL`}</div>
        {personalInfo &&
          personalInfo.map((each: any, index: number) => {
            return (
              <InputRow
                label={each.label}
                editable={true}
                value={each.value}
                key={index}
                onEdit={props.onEdit}
                border={true}
                name={each.name}
                {...(each.optionsArray && { optionsArray: each.optionsArray })}
              />
            )
          })}
        {bussinessAccount && <div className={'pg-py-3 pg-bg-gray-400 pg-text-gray-700 pg-px-1'}>{t`Company Info`}</div>}
        {bussinessAccount &&
          companyInfo &&
          companyInfo.map((each: any, index: number) => {
            return (
              <InputRow
                label={each.label}
                editable={true}
                key={index}
                value={each.value}
                onEdit={props.onEdit}
                border={true}
                name={each.name}
                {...(each.optionsArray && { optionsArray: each.optionsArray })}
              />
            )
          })}
      </form>
    </>
  )
}
const mapStateToProps = (state: any) => ({ editableForm: state.account.editableForm })

export default connect(mapStateToProps, null)(ProfileEdit)
