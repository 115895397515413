import * as React from 'react'

// ui-elements
import { Icon } from './Icon'
import { element } from 'prop-types';

export default interface Iprops {
  className?: string
  icon: string
  iconAlt?: string
  iconWidth?: number 
  iconHeight?: number
  label?: string | boolean
  element?: any
  noPointer?: boolean
  linkStyle?: object
  to?: string
  onClick?: any
}

export const IconLink: React.FunctionComponent<Iprops> = ({ className = 'iconLink', icon, iconAlt,element, iconWidth,noPointer, iconHeight, label, linkStyle, onClick }: Iprops) => {
  return (
    <div className={className} {...onClick && { onClick: label ? () => onClick(label) : onClick() }} style={{cursor: noPointer ?'auto':'pointer'}}>
      <Icon src={icon} alt={iconAlt} width={iconWidth} height={iconHeight}/>
      <span style={linkStyle} >{label}</span>
      {element &&  <span style={linkStyle}>{element}</span>}
    </div>
  )
}
