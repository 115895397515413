import React from 'react'
import { connect } from 'react-redux'
import Backend from 'react-dnd-html5-backend'

import Dropdown from '../ui-elements/Dropdown/Dropdown'
import { EnhanceDropdown as enhancer } from '../ui-elements/Dropdown/EnhanceDropdown'

import styles from './Table.module.scss'
//interface
import { TableRow } from './TableBody/TableRow'
import { Iprops } from './Table.interface'
import { Item } from './Table.interface'
import { TableHeader } from './TableHeader/TableHeader'
import { DndProvider, useDrop } from 'react-dnd'

const EnhancedDropdown = enhancer(Dropdown)

export const Table: React.FunctionComponent<Iprops> = ({
  tableData,
  hovered,
  dropdown,
  setHoverState,
  moveDocuments,
  onCheckAll,
  width,
  className,
  renameText,
  showAll,
  editableId,
  checkAll,
  isMoveModal,
  onSort,
  setEditStatus,
  tabletView,
  onSelect,
  dropDownData,
  handleNavigate,
  optionSelected,
  checkbox,
  openModal,
  modalSelection,
  onCheck,
  invoiceClassName,
  handleOnRowClick,
  labelShow,
  smPadding = false,
  onOpenCFModal,
  handleChange,
  selectionNumber,
  documentsLength,
  selection,
  moveRow,
  expandableRow,
  hasDnd,
  sortable,
  getInvoice,
  hasIcon,
  noBorder = false,
  header,
  invoice,
  selectableRows = true,
  hasHeader = true
}) => {
  const dropDownStyle = {
    position: 'absolute',
    width: 138
  }
  return (

      <table className={noBorder ? [styles.table, className, styles.noBorder].join(' ') : [styles.table, className].join(' ')}>
        {hasHeader && (
          <TableHeader
            titles={header}
            dropdown={dropdown}
            sortable={sortable}
            documentsLength={documentsLength}
            selectionNumber={selectionNumber}
            labelShow={labelShow}
            editableId={editableId}
            selectableRows={selectableRows}
            selection={selection}
            showAll={showAll}
            onSort={onSort}
            tabletView={tabletView}
            onOpenCFModal={onOpenCFModal}
            {...(onCheckAll && { checkAll: checkAll, onCheckAll: onCheckAll })}
          />
        )}

        <tbody>
          {tableData &&
            tableData.map((item: Item, index: number) => {
              return (
                  <TableRow
                    showAll={showAll}
                    moveRow={moveRow}
                    item={item}
                    moveDocuments={moveDocuments}
                    expandableRow={expandableRow}
                    key={index}
                    setEditStatus={setEditStatus}
                    id={item.id}
                    hovered={hovered}
                    setHoverState={setHoverState}
                    labelShow={labelShow}
                    getInvoice={getInvoice}
                    modalSelection={modalSelection}
                    renameText={renameText}
                    editableId={editableId}
                    index={index}
                    invoice={invoice}
                    indexNumber={index}
                    selectableRows={selectableRows}
                    hasIcon={hasIcon}
                    isMoveModal={isMoveModal}
                    selection={selection}
                    handleChange={handleChange}
                    checkbox={checkbox}
                    handleNavigate={handleNavigate}
                    onCheck={onCheck}
                    hasDnd={hasDnd}
                    smPadding={smPadding}
                    invoiceClassName={invoiceClassName}
                    width={width}
                    openModal={openModal}
                    handleOnRowClick={handleOnRowClick}
                  >
                    {!expandableRow && dropdown && (
                      <td className={[styles.show, styles.left].join(' ')}>
                        <EnhancedDropdown
                          width={dropDownStyle.width}
                          optionSelected={optionSelected}
                          onSelect={onSelect}
                          fileType={item.discriminator}
                          position={dropDownStyle.position}
                          data={dropDownData && dropDownData}
                          id={item.id}
                        />
                      </td>
                    )}
                  </TableRow>
              )
            })}
        </tbody>
      </table>
  )
}
