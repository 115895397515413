import * as React from 'react'
import { t } from 'ttag'
import { connect } from 'react-redux'
// ui-elements
import { Hr } from '../../ui-elements/Hr/Hr'
import { Button } from '../../ui-elements/Button/Button'
import { IconLink } from '../../ui-elements/Icon/IconLink'

// icons
import refreshIcon from '../../../images/Icons/commonIcons/refresh.svg'
import deleteIcon from '../../../images/Icons/sidebarIcons/delete.1.svg'

// internal components & styles
import FileFiltering from './FileFiltering/FileFiltering'
import { UpgradeAccount } from './UpgradeAccount/UpgradeAccount'
import { Nav } from './Nav'
import '../Sidebar.scss'
import { restoreFiles, deleteDocument, setDocuments } from '../../../services/internal/store/actions'

export interface Iprops {
  onItemClick?: (e: any) => void
  restoreFiles: (e: any) => void
  deleteDocument: (e: any) => any
  documents: any
  buttonLoading?: boolean
  selection: any
  setDocuments?: any
}
// () => deleteDocument({ folderId: selection ,documents})
class TrashSideBar extends React.Component<Iprops, any> {
  state = {
    delete: false,
    restore: false
  }
  removeDocument = (mode: string) => {
    let tableData = this.props.documents
    let { selection, documents } = this.props
    this.setState({ [mode]: true })
    try {
      let result =
        mode == 'delete'
          ? this.props.deleteDocument({ folderId: selection, documents })
          : this.props.restoreFiles({ documentIds: selection, documents })
      tableData = tableData.filter((x: any, index: number) => {
        return x.id !== result.payload.folderId[index]
      })
      tableData = tableData.reduce(function(prev: any, value: any) {
        var isDuplicate = false

        for (var i = 0; i < selection.length; i++) {
          if (value.id == selection[i]) {
            isDuplicate = true
            break
          }
        }

        if (!isDuplicate) {
          prev.push(value)
        }

        return prev
      }, [])
      this.props.setDocuments(tableData)
      this.setState({ showRemove: false })
      // this.setState({ [mode]: false })
    } catch (error) {}
  }

  render() {
    let { onItemClick, restoreFiles, deleteDocument, documents, selection } = this.props
    return (
      <div className="sidebar-menu">
        <Button
          className={[selection && selection.length > 0 ? 'pg-btnSuccess' : 'pg-btnDisabled', 'pg-btnLg']}
          style={{ marginBottom: '15px' }}
          loading={this.state.restore && this.props.buttonLoading}
        >
          <IconLink onClick={() => this.removeDocument('restore')} icon={refreshIcon} iconAlt="upload icon" label={t`Restore File`} />
        </Button>
        <Button
          className={[selection && selection.length > 0 ? 'pg-btnDanger' : 'pg-btnDisabled', 'pg-btnLg']}
          style={{ marginBottom: '15px' }}
          loading={this.state.delete && this.props.buttonLoading}
        >
          <IconLink icon={deleteIcon} iconAlt="upload icon" label={t`Erase File`} onClick={() => this.removeDocument('delete')} />
        </Button>
        <FileFiltering forFM={true} onItemClick={onItemClick} />
        <UpgradeAccount />
        <Hr />
        <Nav />
      </div>
    )
  }
}
const mapStateToProps = (state: any) => ({
  select: state,
  documents: state.document.documents,
  buttonLoading: state.loading.buttonLoading,
  selection: state.selection.selection,
  toggle: state.selection.toggle
})
const mapDispatchToProps = (dispatch: any) => {
  return {
    restoreFiles: (value: any) => dispatch(restoreFiles(value)),
    deleteDocument: (value: any) => dispatch(deleteDocument(value)),
    setDocuments: (value: any) => dispatch(setDocuments(value))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TrashSideBar)
