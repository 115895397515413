import * as React from 'react'

// ui-elements
import { TextInput } from '../Input/Input'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { IconLink } from '../Icon/IconLink'

// icons
import searchIcon from '../../../images/Icons/commonIcons/search.svg'
import settingIcon from '../../../images/Icons/commonIcons/setting.svg'

// styles
import styles from './SearchInput.module.scss'
import { SuggestionBox } from './SuggestionBox';

// interface
export default interface Iprops {
  withSetting?: boolean
  value?: any
  placeHolder: string
  handleInputChange: (e: any) => void
  handleKeyDown?: any
}

export const SearchInput = ({ withSetting = false, placeHolder, handleInputChange,handleKeyDown,value }: Iprops) => {
  return (
    <div className={'pg-relative pg-flex pg-justify-center pg-items-center'}>
      <TextInput placeholder={placeHolder} onChange={(e: any) => handleInputChange(e.target.value)} onKeyDown={handleKeyDown} value={value}/>
      <div className={styles.btnWrapper}>
        <Button style={{ backgroundColor: '#fff' }}>
          <Icon src={searchIcon} />
        </Button>
      </div>
      {/* <IconLink icon={settingIcon} className={withSetting ? `pg-flex pg-p-2 pg-bg-gray-350 pg-border pg-rounded pg-border-gray-400 pg-rounded-r-none pg-justify-center` : `${styles.hide}`} /> */}
    </div>
  )
}
