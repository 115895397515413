import * as React from 'react'

import { TextInput } from '../../Input/Input'
import { Button } from '../../Button/Button'

import styles from '../CreateFolderModal/CreateFolder.module.scss'
import { changeProfile } from '../../../../services/internal/store/actions'
import { connect } from 'react-redux'
import { ItemInterface } from '../../../../services/internal/store/reducers/documentReducer'

import { Select } from '../../Select/Select'
import { t } from 'ttag'
import { IconLink } from '../../Icon/IconLink'
import error from '../../../../images/Icons/commonIcons/error.svg'
import { FMModal } from '../FMModal/FMModal'
import { InputBox } from './InputBox/InputBox'

interface Iprops {
  value?: string
  showModal?: boolean
  item: ItemInterface
  tableData?: any
  formDescription?: string
  title?: string
  validationErr?: any
  input?: any
  updateTable?: (table: any) => void
  handleSubmit?: (e: any) => void
  changeHandler?: (e: any) => void
  renameFolder: (e: any) => void
  handleClose?: () => void
}

class EditModal extends React.Component<any, any> {
  textInput: any

  constructor(props: any) {
    super(props)
    this.textInput = React.createRef()
    this.focus = this.focus.bind(this)
  }

  focus() {
    this.textInput.current.focus()
  }
  componentDidMount() {
    this.props.input &&
      this.props.input.map((each: any) => {
        this.setState({ [each.name]: each.value })
      })
    // this.props.input && this.focus()
  }

  render() {
    let { handleClose, showModal, validationErr } = this.props

    return (
      <FMModal show={showModal} width={440} title={this.props.title} formDescription={this.props.formDescription} handleClose={handleClose}>
        <form onSubmit={this.props.profileChange} className={'pg-w-full'}>
          {validationErr && validationErr !== true && (
            <div className={'wrongVerify warn pg-mb-2'}>
              <IconLink icon={error} label={`${validationErr}`} />
            </div>
          )}
          {this.props.input &&
            this.props.input.map((each: any, index: number) => (
              <InputBox
                index={index}
                wrapperRef={this.textInput}
                onChange={this.props.updateChange}
                name={each.name}
                type={each.type}
                optionsArray={each.name == 'city' ? this.props.cities : each.optionsArray}
                value={each.value}
              />
            ))}
          <div className={`${styles.submitButton} pg-mt-5`}>
            <Button className={['pg-btnPrimary', 'pg-btnSm']}>{t`Edit`}</Button>
          </div>
        </form>
      </FMModal>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeProfile: (value: any) => dispatch(changeProfile(value))
  }
}

const mapStateToProps = (state: any) => ({
  info: state.account.info,
  cities: state.account.cities,
  validationErr: state.account.validationErr
})

export default connect(mapStateToProps, mapDispatchToProps)(EditModal)
