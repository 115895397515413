import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// services
import { goToNextStep, goToPreviousStep, selectVsCard } from '../../../services/internal/store/actions'

import { formatPrice } from '../../../services/internal/utils/formatPrice'
import { switchTimeLang } from '../../../services/internal/utils/switchTimeLang'
import OrderCard from '../OldOrder/Steps/Card/Card'
import MultiplePlan from '../MultipleControl/MultiplePlan'

// styles
import styles from '../OldOrder/Order.module.scss'

export interface Iprops {
  vmProducts?: any
  os?: string
  serviceDuration?: any
  timed?: boolean
  period?: any
  vsCard?: any
  id?: number
  hasActiveFooter?: boolean
  selectVsCard?: any
  selectedPrice?: number
  activePlanNum?: number
  vpsCardClick?: any
  osName?: string
  vpsName?: string
  vmArray?: any
  vpsPeriod?: any
  planId?: any
  purchaseServer?: any
  time2?: any
}

const findVpsNumber = ({ core, ram, disk, vmProducts2 }: any) => {
  let name
  vmProducts2.map((each: any) => {
    if (core == each.core && ram == each.ram && disk == each.disk) name = each.name
  })
  return name
}

const sliceProducts = ({ os, osName, purchaseServer, vmProducts2, vmArray, vpsName, serviceDuration }: any) => {
  let osDisplayName: any
  if (os && os.length > 1) osDisplayName = os[1]
  if (osName) osDisplayName = osName
  if (os == 'Ubuntu Desktop 16.04' || osDisplayName == 'ubuntu16.ds' || os == 'Windows Server 2012' || osDisplayName == 'win2012') {
    vmProducts2 = vmProducts2.slice(2)
  }
  let vpsNumber = 1
  if (!purchaseServer) {
    if (vmArray) vpsName = findVpsNumber({ core: vmArray.cpuN, ram: vmArray.ramN, disk: vmArray.diskN, vmProducts2 })
    if (vpsName) vpsNumber = parseInt(vpsName.replace(/^[^0-9]+/, ''), 10)
    if (vmProducts2 && vmProducts2[0] && vmProducts2[0].name == 'VPS1') {
      vmProducts2 = vmProducts2.slice(vpsNumber - 1)
    }
  }

  let index2: any = []
  vmProducts2.map((each: any, index: any) => {
    switch (serviceDuration) {
      case 'MONTH':
        if (!each.hasMonth) {
          index2.push(index)
        }
        break
      case 'TMONTH':
        if (!each.hasTMonth) {
          index2.push(index)
        }
        break
      case 'SMONTH':
        if (!each.hasSMonth) index2.push(index)
        break
      case 'YEAR':
        if (!each.hasYear) index2.push(index)
        break
    }
  })
  return [{ vmProducts2, index2 }]
}

const VpsCard: React.FunctionComponent<Iprops> = ({
  os,
  time2,
  osName,
  planId,
  vmProducts,
  serviceDuration,
  selectedPrice,
  vmArray,
  vpsPeriod,
  vpsCardClick,
  vsCard,
  hasActiveFooter = true,
  timed,
  vpsName,
  purchaseServer,
  selectVsCard
}) => {
  const serviceDurationIndex = switchTimeLang(serviceDuration, 'index')
  let { vmProducts2, index2 } = sliceProducts({ os, osName, purchaseServer, vmProducts2: vmProducts, vmArray, vpsName, serviceDuration })[0]
  // console.log(index2)
  return vmProducts2.map((product: any, index: number) => {
    let multiplePlanFooter = (
      <MultiplePlan
        key={index}
        planId={planId}
        className={'pg-mx-2'}
        selectedPrice={selectedPrice}
        vpsPeriod={vpsPeriod}
        time={time2 ? time2 : vsCard.time}
        active={product.name == vsCard.name}
        prices={product.jsonPrices3}
        plan={product}
        chooseTime={vpsCardClick}
      />
    )
    const vpsInfos = [
      { label: t`Processor`, name: 'cpu' },
      { label: t`Memory`, name: 'ram' },
      { label: t`Disk space`, name: 'disk' }
    ]
    if (!index2.includes(index))
      return (
        <OrderCard
          key={index}
          selected={vsCard && vsCard.name == product.name}
          footerData={
            timed ? (
              multiplePlanFooter
            ) : (
              <div className={'pg-w-full pg-flex'}>
                <span className={'pg-w-full pg-flex pg-justify-center'}>{formatPrice(product.jsonPrices3[serviceDurationIndex])}</span>
              </div>
            )
          }
          className={styles.choosePlanCard}
          withoutHover={false}
          timed={true}
          hasActiveFooter={hasActiveFooter}
          active={false}
          data={timed ? [product, vmProducts2[index]] : [product, vmProducts2[index]]}
          onClickCard={timed ? selectVsCard : selectVsCard}
        >
          <div className={styles.planInfo}>
            <span className={styles.name}>{product.name}</span>
            <p className={styles.trafic}>{product.unlimited == 'unlimited' ? t`Unlimited traffic` : ''}</p>
            <ul className={styles.softwareInfo}>
              {vpsInfos.map((vpsInfo: any, index: number) => (
                <li className={styles.row} key={index}>
                  <span className={localStorage.getItem('__language') ? 'fa' : ''}>{vpsInfo.label}: </span>
                  <span>{product[vpsInfo.name]}</span>
                </li>
              ))}
            </ul>
          </div>
        </OrderCard>
      )
  })
}

const mapStateToProps = (state: any) => ({
  currentStep: state.vm.currentStep,
  vmProducts: state.vm.vmProducts2,
  vsCard: state.vm.vsCard,
  os: state.vm.os,
  vpsName:
    state.vm.vmBillsNamed && state.vm.vmBillsNamed[0] && state.vm.vmBillsNamed[0].lastInvoice && state.vm.vmBillsNamed[0].lastInvoice.product.name,
  serviceDuration: state.vm.serviceDuration,
  vmBills: state.vm.vmBills,
  vmArray: state.vm.vmArrayNamed && state.vm.vmArrayNamed[0],
  vpsPeriod:
    state.vm.vmBillsNamed && state.vm.vmBillsNamed[0] && state.vm.vmBillsNamed[0].lastInvoice && state.vm.vmBillsNamed[0].lastInvoice.recurringPeriod
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    goToNextStep: (stepNumber: number) => dispatch(goToNextStep(stepNumber)),
    selectVsCard: (data: number) => dispatch(selectVsCard(data)),
    goToPreviousStep: (stepNumber: number) => dispatch(goToPreviousStep(stepNumber))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VpsCard)
