import * as React from 'react'
import image from '../../../images/Icons/typeIcons/icon-image.svg'

export default interface Iprops {
  src?: any
  alt?: any
  className?: string
  width?: number
  height?: number
  style?: object
  mimetype?: string
  id?: any
}

export const Image = ({ src, alt, className = 'icon', width, id,height, style, mimetype }: any) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      width={width}
      id={id}
      height={height}
      style={style}
      onError={(e: any) => {
        e.target.alt ='img not found' ;
        e.target.src = image
      }}
    />
  )
}