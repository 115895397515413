import * as React from 'react'

import DropdownItem from './DropdownItem'

import styles from './Dropdown.module.scss'
import buttonStyles from '../Button/Button.module.scss'

import { Icon } from '../Icon/Icon'

import bigger from '../../../images/Icons/commonIcons/bigger.1.svg'
import biggerGray from '../../../images/Icons/commonIcons/bigger-gray.svg'
import more from '../../../images/Icons/commonIcons/more1.svg'

interface Iprops {
  isOpen: boolean
  onToggle?: () => void
  data?: object[]
  isSelected?: number
  onSelect?: () => void
  width?: number | string
  noButton?: boolean
  position?: any
  id?: number
  selectable?: boolean
  fileType?: string
  buttonDropDown?: boolean
  marginLeft?: number
  customButton?: any
  vmDropdown?: any
  bordered?: boolean
  button?: any
  disabled?: boolean
  insideData?: any
  dropdownStyle?: any
  hardClose?: boolean
}

export const Dropdown: React.FunctionComponent<Iprops> = ({
  data,
  isOpen,
  onToggle,
  isSelected,
  hardClose,
  insideData,
  onSelect,
  vmDropdown,
  width,
  customButton,
  noButton,
  fileType,
  selectable,
  id,
  bordered,
  children,
  marginLeft,
  disabled,
  dropdownStyle,
  buttonDropDown,
  button,
  position = 'absolute'
}) => {
  return (
    <div className={`${styles.dropdownBox} ${dropdownStyle}`}>
      {children
        ? children
        : !noButton && (
            <button onClick={onToggle} className={styles.dpButton}>
              <Icon src={more} className={'iconq'} style={{ width: '50px !important' }} />
            </button>
          )}
      {children
        ? children
        : buttonDropDown && (
            <span onClick={onToggle} className={buttonStyles.caretSpan}>
              <Icon src={disabled ? biggerGray : bigger} />
            </span>
          )}
      {button && (
        <div onClick={onToggle} className={styles.dpButton}>
          {button}
        </div>
      )}
      {customButton && (
        <div onClick={onToggle} >
          {customButton}
        </div>
      )}
      {isOpen && !vmDropdown && (
        <ul className={position ? [styles[position], styles.dropdown].join(' ') : styles.dropdown} style={{ width: width, marginLeft: marginLeft }}>
          {data &&
            data.map((item: any, i: number) => {
              if ((fileType === 'D' && item.label !== 'Download File') || fileType === 'F' || !fileType)
                return (
                  <DropdownItem
                    label={item.label}
                    index={i}
                    selectable={selectable}
                    key={i}
                    id={id ? id : item.id}
                    bordered={bordered}
                    isSelected={isSelected}
                    onSelect={onSelect}
                    onClick={item.onClick}
                    {...(item.description && { description: item.description })}
                  />
                )
            })}
        </ul>
      )}
      {isOpen && vmDropdown && !hardClose && (
        <ul className={position ? [styles[position], styles.dropdown].join(' ') : styles.dropdown} style={{ width: width, marginLeft: marginLeft }}>
          {insideData}
        </ul>
      )}
    </div>
  )
}

export default Dropdown
