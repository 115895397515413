import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'
// ui-elements
import { DropDownButton } from '../../ui-elements/Button/DropDownButton'
import { Button } from '../../ui-elements/Button/Button'
import { Hr } from '../../ui-elements/Hr/Hr'
import { IconLink } from '../../ui-elements/Icon/IconLink'

// icons
import upFromUrlIcon from '../../../images/Icons/sidebarIcons/upfromurl.svg'
import downloadIcon from '../../../images/Icons/commonIcons/download.svg'
import downloadGrayIcon from '../../../images/Icons/commonIcons/download-gray.svg'
import uploadIcon from '../../../images/Icons/commonIcons/upload.svg'

// internal components & styles
import { UpgradeAccount } from './UpgradeAccount/UpgradeAccount'
import FileFiltering from './FileFiltering/FileFiltering'
import ActionNav from './ActionNav'
import { Nav } from './Nav'
import '../Sidebar.scss'

import {
  removeFolder,
  generateDownloadLink,
  downloadDirectory,
  signout,
  getTrashDocuments,
  getSharedDocuments,
  setDocuments,
  setTempDocuments,
  setRouter,
  setPreviewImage,
  setToggle,
  restoreFiles,
  removeMessages,
  uploadDocument,
  urlUpload,
  removeSelection,
  uploadServer,
  setUploader,
  setUploadModal,
  setUploadFiles,
  setUploadProgress,
  setModalView,
  setModalState,
  setModalSelections
} from '../../../services/internal/store/actions'
import CFModal from '../../../components/ui-elements/Modal/CreateFolderModal/CreateFolder'
import MoveFile from '../../../components/ui-elements/Modal/MoveFileModal.tsx/MoveFile'
import UrlUploadmodal from '../../../components/ui-elements/Modal/urlUpload/urlUploadModal'
import toast from '../../../components/ui-elements/Toast/Toast'
import { IRemoveFolderInput, IGenerateLinkInput, IDownloadDirectoryInput } from '../../../services/internal/repositories/documents'
import { ToastUndo } from '../../ui-elements/Toast/ToastUndo/ToastUndo'
import UploadingModal from '../../ui-elements/Modal/UploadingModal/UploadingModal'
import UploadButton from '../../UploadButton/UploadButton'
import FMModal from './FMModal/FMModal'

export interface Iprops {
  onItemClick?: any
  selection?: number[]
  toggle?: any
  onDrop?: any
  uploader?: any
  getUserInfo?: any
  userInfo?: any
}

class FM extends React.Component<any, any> {
  uploader: any
  constructor(props: any) {
    super(props)
  }
  state = {
    isOpenMenu: false,
    isOpenSignout: false,
    isOpenStatus: false,
    showcFmodal: false,
    showModal: false,
    prevProps: '',
    prevState: '',
    modal: '',
    countDown: 10,
    toRemove: [],
    removeItem: [],
    removedIndex: 0,
    uploadModal: false
  }

  toRemoveFromList = (type: string) => {
    let removeItem = this.props.document.documents.filter((obj: any, index: number) => {
      return this.props.selection.selection.includes(obj.id)
    })

    let removedIndex = this.props.document.documents.indexOf(removeItem[0])
    this.setState({
      toRemove: [...this.state.toRemove, this.props.selection.selection],
      removeItem,
      removedIndex
    })

    let documents = this.props.document.documents.filter((i: any, index: number) => !this.props.selection.includes(i.id))
    this.props.setDocuments(documents)
    let deleteMsg = t`File is removed.`
    let recoverMsg = t`File is recovered.`

    toast.success(<ToastUndo undo={this.undo} id={this.props.selection} msg={type == `Restore File` ? recoverMsg : deleteMsg} />, {
      toRemove: [],
      onClose:
        type == t`Delete`
          ? this.onRemoveDocument
          : type == t`Restore File`
          ? this.props.restoreFiles({ documentIds: this.props.selection.selection })
          : ''
    })
  }
  changeSize = (size: string) => {
    this.props.setPreviewImage(size)
    let urlSize = this.props.history.length > 0 && this.props.history.location.pathname.split('/')[4]
    let url
    const sizes = ['small', 'medium', 'large']
    // console.log(size)
    if (!sizes.includes(urlSize)) url = this.props.history.location.pathname.replace('image/', `image/${size}/`)
    else if (size) url = this.props.history.location.pathname.replace(urlSize, size)
    else url = this.props.history.location.pathname.replace(`/${urlSize}`, size)
    this.props.history.push(url)
  }

  onRemoveDocument = () => {
    let tableData = this.props.document.documents

    try {
      let result = this.props.removeFolder({ folderId: this.props.selection.selection })
      let selection = this.props.selection.selection
      tableData = tableData.filter((x: any, index: number) => {
        return x.id !== result.payload.folderId[index]
      })
      tableData = tableData.reduce(function(prev: any, value: any) {
        var isDuplicate = false
        for (var i = 0; i < selection.length; i++) {
          if (value.id == selection[i]) {
            isDuplicate = true
            break
          }
        }

        if (!isDuplicate) {
          prev.push(value)
        }

        return prev
      }, [])
      this.props.setDocuments(tableData)
      this.setState({ showRemove: false })
    } catch (error) {}
  }
  onItemClick = (e: any, file: any) => {
    let selection = this.props.selection.selection
    if (!e.target) {
      switch (e) {
        case t`Restore File`:
          this.toRemoveFromList(e)
          break
        case t`New Folder`:
          this.setState({ modal: 'createFolder', showModal: true })
          break
        case t`Transfer`:
          if (selection && selection.length > 0) {
            this.setState({ modal: 'moveFile', showModal: true })
          } else {
            toast.error(t`Please choose a file`)
          }
          break
        case t`Delete`:
          if (selection && selection.length > 0) {
            this.onRemoveDocument()
          } else {
            toast.error(t`Please choose a file`)
          }
          break
        case t`Erase File`:
          this.toRemoveFromList(e)
          break
        case t`File upload from URL`:
          this.setState({ modal: 'urlUpload', showModal: true })
        case t`Large`:
          this.changeSize('large')
          break
        case t`Main Size`:
          this.changeSize('')
          break
        case t`Medium`:
          this.changeSize('medium')
          break
        case t`Small`:
          this.changeSize('small')
          break
        case t`Download File`:
          let { uuid, name } = this.props.item
          let result = this.props.generateDownloadLink({ uuid, name })
          setTimeout(() => {
            if (result && this.props.downloadToken && this.props.downloadToken.length > 0)
              window.location.href = `${process.env.REACT_APP_URL}/dl/${this.props.downloadToken}/${this.props.item.uuid}/${this.props.item.name}`
          }, 1000)
          break
        default:
          break
      }
    } else if (e.target.value) {
      switch (e.target.value) {
        case t`Deleted files`:
          if (this.props.history && this.props.history.location.pathname !== '/nwfm/trash') {
            this.props.history.push(`/nwfm/trash`)
            this.props.getTrashDocuments()
          } else {
            this.props.history.push(`/nwfm`)
          }

          break
        case t`Sharing`:
          if (this.props.history && this.props.history.location.pathname !== '/nwfm/shared') {
            this.props.history.push(`/nwfm/shared`)
            this.props.getSharedDocuments()
          } else {
            this.props.history.push(`/nwfm`)
          }

          break
      }
    } else if (e.target.innerText) {
      switch (e.target.value) {
        case t`copy`:
          break
      }
    } else if (e.target.files) {
    }
  }
  handleClose = () => {
    this.setState({ showModal: false, modalView: '' })
    this.props.setModalSelections(1)
  }

  undo = (ids: any) => {
    let documents = this.props.document.documents

    //console.log(ids)
    this.setState({
      toRemove: this.state.toRemove.filter((v: any, index: number) => v[index] !== ids[index])
    })
    this.props.removeSelection()
    this.state.removeItem.map((each: any, index) => {
      documents.splice(this.state.removedIndex + index, 0, each)
    })

    this.props.setDocuments(documents)
  }
  onDrop = (acceptedFiles: any) => {
    let fd = new FormData()

    acceptedFiles.map((file: any) => {
      fd.append('File[]', file)
    })
    this.props.uploadDocument(fd)
  }
  setUploadModal = () => {
    this.setState({ modal: 'uploadModal', showModal: true })
  }
  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.handleUploadClose()
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }
  handleUploadClose = () => {
    this.props.setModalState(false)
    this.props.setModalView(``)
    this.props.setUploadFiles([])
  }
  downloadDirectory = (selection: any, downloadType: any) => {
   let result = this.props.downloadDirectory({ documentIds: selection, downloadType: downloadType })
    // setTimeout(() => {
    //   if ( this.props.downloadToken && this.props.downloadToken.length > 0)
    //     window.location.href = `${process.env.REACT_APP_URL}/dl/${this.props.downloadToken}/${this.props.item.uuid}/${this.props.item.name}`
    // }, 2000)
  }
  render() {
    let { selection, userInfo } = this.props
    selection = selection.selection
    let data = [
      { label: t`Download Format` + ` zip`, onClick: () => this.downloadDirectory(selection, 'zip') },
      { label: t`Download Format` + ` iso`, onClick: () => this.downloadDirectory(selection, 'iso') },
      { label: t`Download Format` + ` tar`, onClick: () => this.downloadDirectory(selection, 'tar') }
    ]

    let modal
    switch (this.state.modal) {
      case 'createFolder':
        modal = <CFModal handleCFClose={this.handleClose} showModal={this.state.showModal} />
        break
      case 'moveFile':
        modal = <MoveFile handleClose={this.handleClose} showModal={this.state.showModal} />
        break
      case 'urlUpload':
        modal = <UrlUploadmodal showModal={this.state.showModal} handleCFClose={this.handleClose} />
        break
      case 'uploadModal':
        modal = <UploadingModal handleClose={this.handleClose} showModal={this.state.showModal} />
        break
      default:
        break
    }
    let usedPercent = (userInfo && userInfo.plan && +(userInfo.quota / +JSON.parse(userInfo.plan.jsonInfo).quota).toFixed(2)) * 100

    return (
      <div className="sidebar-menu">
        <div>
          <Button
            className={[this.props.isUploadDisabled ? 'pg-btnDisabled' : 'pg-btnPrimary', 'pg-btnLg']}
            name={t`File upload`}
            onClick={this.onItemClick}
            style={{ marginBottom: '15px', cursor: 'pointer' }}
            id={'upload'}
            disabled={this.props.isUploadDisabled}
          >
            <IconLink icon={uploadIcon} iconAlt="upload icon" label={t`File upload`} onClick={this.onItemClick} />
            <div className={'pg-absolute pg-top-0 pg-w-full pg-opacity-0'}>
              <UploadButton setUploadModal={() => this.props.setModalView(`uploadModal`)} handleUploadClose={this.handleUploadClose} />
            </div>
          </Button>
        </div>
        <DropDownButton
          data={data}
          className={[selection && selection.length > 0 ? 'pg-btnSuccess' : 'pg-btnDisabled', 'pg-btnLg']}
          disabled={!selection || selection.length == 0}
          style={{ marginBottom: '15px' }}
        >
          <IconLink icon={selection && selection.length > 0 ? downloadIcon : downloadGrayIcon} iconAlt="download icon" label={t`Download Format`} />
        </DropDownButton>
        <IconLink
          icon={upFromUrlIcon}
          onClick={() => this.props.setModalView(`urlUpload`)}
          className="pg-flex pg-items-center pg-p-2 pg-font-vMedium pg-border-0 pg-cursor-pointer
           pg-text-right pg-rounded-br-sm iconLink upFromUrl"
          iconAlt="upload icon"
          label={t`File upload from URL`}
        />
        <ActionNav onItemClick={this.onItemClick} moveable={this.props.selection.selection.length > 0} />
        <Hr />
        <FileFiltering forFM={true} onItemClick={this.onItemClick} />
        <UpgradeAccount percent={usedPercent ? usedPercent : 0} />
        <Hr />
        <Nav />
        <FMModal handleUploadClose={this.handleUploadClose} />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  document: state.document,
  selection: state.selection,
  item: state.sidebar.item,
  userInfo: state.account.info,
  open: state.sidebar.open,
  history: state.history.history,
  messages: state.messages,
  downloadToken: state.sidebar.downloadToken,
  downloadLoading: state.loading.downloadLoading,
  uploader: state.document.uploader,
  parentId: state.document.parentId,
  modalView: state.modal.modalView,
  cancelToken: state.account.cancelToken
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeFolder: (value: IRemoveFolderInput) => dispatch(removeFolder(value)),
    generateDownloadLink: (value: IGenerateLinkInput) => dispatch(generateDownloadLink(value)),
    downloadDirectory: (value: IDownloadDirectoryInput) => dispatch(downloadDirectory(value)),
    signout: () => dispatch(signout()),
    getTrashDocuments: () => dispatch(getTrashDocuments()),
    getSharedDocuments: () => dispatch(getSharedDocuments()),
    setDocuments: (value: any) => dispatch(setDocuments(value)),
    setTempDocuments: (value: any) => dispatch(setTempDocuments(value)),
    setRouter: (value: any) => dispatch(setRouter(value)),
    setPreviewImage: (value: any) => dispatch(setPreviewImage(value)),
    setToggle: (value: any) => dispatch(setToggle(value)),
    restoreFiles: (value: any) => dispatch(restoreFiles(value)),
    removeMessages: () => dispatch(removeMessages()),
    uploadDocument: (value: any) => dispatch(uploadDocument(value)),
    urlUpload: (value: any) => dispatch(urlUpload(value)),
    removeSelection: () => dispatch(removeSelection()),
    uploadServer: (value: any) => dispatch(uploadServer(value)),
    setUploader: (value: any) => dispatch(setUploader(value)),
    setUploadFiles: (value: any) => dispatch(setUploadFiles(value)),
    setModalView: (value: any) => dispatch(setModalView(value)),
    setModalState: (value: any) => dispatch(setModalState(value)),
    setUploadProgress: (value: any) => dispatch(setUploadProgress(value)),
    setModalSelections: (value: Array<number>) => dispatch(setModalSelections(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FM)
