import * as React from 'react'
import { t } from 'ttag'

import { Prompt } from '../../Prompt/Prompt'
import { TextInput } from '../../../Input/Input'
import { ColorfulBox } from '../../../ColorfulBox/ColorfulBox'

export const UpgradeVm: React.FunctionComponent<any> = ({ showModal, handleClose, upgradeName, upgradeVm, error, errorMsg, handleChange }) => {
  return (
    <Prompt showModal={showModal} handleClose={handleClose} width={480} title={t`Upgrade`} notClickable={!upgradeName} onClick={upgradeVm}>
      <div className={'pg-my-2'}>
        <ColorfulBox className={['ylw', 'lg']} withClose={false} direction={'rtl'}>
          <div className={'fa'}>
            {t`کاربر گرامی، برای اعمال ارتقای سخت‌افزاری نیاز به حذف و ایجاد مجدد سرور شما وجود دارد. با این کار کلیه اطلاعات و تنظیمات سرور شما از
          بین خواهد رفت. برای اعمال ارتقا نام سرور خود را در کادر زیر وارد نمایید`}
          </div>
        </ColorfulBox>
      </div>
      <TextInput
        errIcon={false}
        withIcon={error}
        error={error}
        value={upgradeName}
        name={'upgradeName'}
        message={errorMsg}
        onChange={handleChange}
        style={{ width: '100%' }}
      />
    </Prompt>
  )
}
