import React from 'react'

export default interface Iprops {
  optionsArray: any
  value: string
  onChange: any
  disabled?: boolean
  name: string | undefined
}

export const Select: React.FunctionComponent<Iprops> = ({ optionsArray,disabled, value, onChange, name }) => {
  const options = optionsArray.map((elem: any, index: number) => (
    <option key={index} value={elem.value}>
      {elem.label}
    </option>
  ))
  return (
    <select value={value} onChange={onChange} name={name} disabled={disabled} className={`pg-w-full  pg-h-35p pg-my-2 pg-border-gray-400 pg-border ${disabled? 'pg-bg-gray-300 pg-text-white':'pg-bg-white'} `}>
      {options}
    </select>
  )
}
