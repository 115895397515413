import React, { Component } from 'react'
import { DragSource } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import Dropdown from '../../ui-elements/Dropdown/Dropdown'
import { Icon } from '../../ui-elements/Icon/Icon'
import { Checkbox } from '../../ui-elements/Checkbox/Checkbox'
import { navigateObject } from '../../Content/Content'
import { EnhanceDropdown as enhancer } from '../../ui-elements/Dropdown/EnhanceDropdown'
import Card from '../../ui-elements/Card/Card'

//interface
import { ITableItem } from '../../Content/ContentBody'
//styles
import styles from './Card.module.scss'

const EnhancedDropdown = enhancer(Dropdown)

interface Iprops {
  item: ITableItem
  checkbox?: boolean
  dropdown?: boolean
  id?: number
  dropDownData?: any
  uuid?: string
  checked?: boolean
  mainItem?: any
  dragSource?: any
  canDrop?: any
  onCheckAll?: () => void
  onCheck?: (id: number, e?: any) => void
  handleNavigate?: (e: navigateObject) => void
}

class FMCard extends React.Component<any, any> {
  componentDidMount() {
    if (this.props.connectDragPreview) {
      this.props.connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      })
    }
  }
  render() {
    let {
      item,
      selection,
      isDragging,
      checkbox,
      moveDocuments,
      handleNavigate,
      dropdown,
      onCheck,
      onCheckAll,
      checked,
      dragSource,
      canDrop,
      dropDownData
    } = this.props

    let imgSrc = `${process.env.REACT_APP_URL}/preview/${item['uuid']}/medium/${item['name']}`

    return dragSource(
      <div>
        <Card item={item} moveDocuments={moveDocuments} selection={selection} isDragging={isDragging}>
          <div
            {...(handleNavigate && { onDoubleClick: e => handleNavigate({ e: e, name: item.mainName, uuid: item.uuid, item: item }) })}
            {...(onCheck && { onClick: e => onCheck && item.id && onCheck(item.id) })}
            {...(!selection.includes(item && item.id) && { onDragStart: () => onCheck && item.id && onCheck(item.id) })}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <div className={styles.type}>
              {item && item.type && item.type == 'image' ? (
                <Icon src={imgSrc} className={'imageIcon icon'} />
              ) : (
                <Icon mimetype={window.location.pathname.includes('trash') ? 'icon' : item.type} />
              )}
            </div>
            <div className={styles.info}>
              <span className={styles.name}>{item['name']}</span>
              <span className={styles.date}>
                {item.created_at} {item.size && item.size !== '---' && `,${item.size}`}
              </span>
            </div>
          </div>
          {checkbox && (
            <Checkbox
              className={styles.checkbox}
              onChange={() => (onCheckAll ? onCheckAll() : onCheck && item.id && onCheck(item.id))}
              checked={checked}
            />
          )}

          {dropdown && (
            <div className={styles.dropdown}>
              <EnhancedDropdown data={dropDownData} id={item.id} />
            </div>
          )}
        </Card>
      </div>
    )
  }
}
const card = {
  beginDrag({ position }: any) {
    return { position }
  }
}

const collect = (connect: any, monitor: any) => ({
  dragSource: connect.dragSource(),
  dragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview()
})
export const TRDND = 'card'
export default DragSource(TRDND, card, collect)(FMCard)
