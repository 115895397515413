import * as React from 'react'
import { Progressbar } from '../Progressbar/Progressbar'

export default interface Iprops {
  brush?: any
  className?: string
}

export const VmBrush: React.FunctionComponent<Iprops> = ({ brush }) => {
  // brush= {attempts: 1, pending: 0, startedTimes: [1584512981620], strokes: ["POWER"]}
  const strokes = brush && brush.strokes
  let step = brush && brush.startedTimes.length - 1
  let active = strokes && strokes[step]
  let length = strokes && strokes.length 

  return (
    <div className={'pg-flex pg-justify-center pg-flex-col pg-items-center pg-w-full'}>
      <span className={'pg-text-gray-700 pg-text-xs'}>
        {+step }/{length} {active}
      </span>
      <div className="pg-py-1 pg-w-full">
        <Progressbar value={(step / length) * 100} height={8} />
      </div>
    </div>
  )
}
