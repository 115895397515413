import { actionTypes } from '../actions/types'
import { t } from 'ttag'

export interface PayloadInterface {
  data?: object[]
}
export interface StateInterface {
  item?: any
  image?: string
  open?: boolean
  downloadToken?: string
  editableId?: number
  renameText?: string
  profileTab?: String
  previewState?: boolean
  isDirectory?: boolean
}
export const initialState: StateInterface = {
  item: [],

  downloadToken: '',
  editableId: 0,
  renameText: '',
  isDirectory:false,
  profileTab: t`Plan`,
  open: false,
  previewState: false
}

const selectReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SIDEBAR_ITEM:
      return {
        ...state,
        item: action.payload
      }
    case actionTypes.SET_PREVIEW_STATE:
      return {
        ...state,
        previewState: action.payload
      }
    case actionTypes.SET_SIDEBAR_STATE:
      return {
        ...state,
        open: action.payload
      }

    case actionTypes.SET_DOWNLOAD_TOKEN:
      return {
        ...state,
        downloadToken: action.payload,
        isDirectory: action.value
      }
    case actionTypes.SET_EDIT_STATUS:
      return {
        ...state,
        editableId: action.payload
      }
    case actionTypes.UPDATE_RENAME_TEXT:
      return {
        ...state,
        renameText: action.payload
      }
    case actionTypes.SET_PROFILE_TAB:
      return {
        ...state,
        profileTab: action.payload
      }
    default:
      return state
  }
}

export default selectReducer
