import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { Authentication } from '../Authentication'

// ui-elements
import { TextInput } from '../../../components/ui-elements/Input/Input'
import { Button } from '../../../components/ui-elements/Button/Button'
import { IconLink } from '../../../components/ui-elements/Icon/IconLink'

// services
import { PayloadInterface } from '../../../services/internal/store/reducers/authReducer'
import { setToken, login, removeMessages, setUserInfo, setLoadingState, setEmail, setLockedAccount } from '../../../services/internal/store/actions'

// icons & styles
import lock from '../../../images/Icons/typeIcons/login/lock.svg'
import styles from '../Authentication.module.scss'
import error from '../../../images/Icons/commonIcons/error.svg'
import { getLocale } from '../../../translate/translate'
import { ColorfulBox } from '../../../components/ui-elements/ColorfulBox/ColorfulBox'

class LockedAccount extends React.Component<any, any> {
  state = {
    email: '',
    password: '',
    loading: false,
    token: '',
    msg: false
  }

  handleChange =  (e: any) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
   componentWillUnmount() {
     this.props.removeMessages()
    this.props.setLockedAccount(false)
  }
  onBlur =  () => {
     this.props.removeMessages()
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({ token: nextProps.auth.token })
  }

  handleSubmit =  (e?: any) => {
    const { history } = this.props

    if (e) e.preventDefault()
     this.props.removeMessages()
    try {
      this.props.setLoadingState(true)
      let result = setTimeout(() => {
        this.props.login({ email: this.state.email, password: this.state.password })
      }, 6000)
      //console.log(result)
    } catch (error) {
      //console.log('E: ', error)
    }
  }
  _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      console.log('do validate')
    }
  }
  componentDidMount() {}

  render() {
    if (this.state.token) {
      return <Redirect to="/nwfm" />
    } else if (!this.state.token) {
      window.document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
      // localStorage.clear()
      let lockedAccount = false
      return (
        <Authentication>
          <form className={styles.login} onSubmit={this.handleSubmit}>
            <span className={styles.title}>{t`Login to account`}</span>
            <p className={styles.description}>{t`Please login to use the services`}</p>

            <ColorfulBox className={['ylw', 'sm']} fontSize={10}>
              <p>
                {t`Your account is locked or not activated yet. Please use the activation link which is sent to your email or send a request to`}
                <span className={'pg-underline pg-cursor-pointer'} >
                  <Link to={'/reactivate'}>{t`receive the activation link`}</Link>
                </span>
                &nbsp;
                {t`again`}
              </p>
            </ColorfulBox>

            <div className={styles.row}>
              <div />
              <div className={styles.switch}>
                <Link to={'/nwlogin'}>
                  <span className={styles.link}>{t`ReLogin`}</span>
                </Link>
              </div>
            </div>

            <Link to={'/nwforgetpassword'} className={[styles.forgetPassword, styles.noMargin].join(' ')}>
              <IconLink icon={lock} label={t`I forgot my password!!`} />
            </Link>
          </form> 
        </Authentication>
      )
    }
  }
}

const mapStateToProps = (state: any) => ({
  isLoading: state.loading.isLoading,
  auth: state.auth,
  messages: state.messages,
  lockedAccount: state.account.lockedAccount
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (payload: PayloadInterface) => dispatch(login(payload)),
    setToken: (token: string) => dispatch(setToken(token)),
    setUserInfo: (payload: PayloadInterface) => dispatch(setUserInfo(payload)),
    removeMessages: (payload: PayloadInterface) => dispatch(removeMessages(payload)),
    setLoadingState: (payload: boolean) => dispatch(setLoadingState(payload)),
    setEmail: (payload: boolean) => dispatch(setEmail(payload)),
    setLockedAccount: (payload: boolean) => dispatch(setLockedAccount(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockedAccount)
