import * as React from 'react'
import { connect } from 'react-redux'

import pgLoading from '../../images/loading/loading.gif'
import { Icon } from '../ui-elements/Icon/Icon'

export const Loading: React.FunctionComponent<any> = props => {
  return (
    <div className={'pg-w-full pg-h-full pg-flex pg-mt-10 pg-justify-center'}>
      <Icon src={pgLoading} width={50} />
    </div>
  )
}
