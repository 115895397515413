import { strToObj } from './stringToObject'
import { formatBytes } from './formatBytes'

export const renderVmBill = (result: any) => {
  let array: any = []
  result.map((each: any) => {
    let activePlanName = each.lastInvoice && each.lastInvoice.product && each.lastInvoice.product.name
    let isExpired = each.lastInvoice && new Date(each.lastInvoice.to) < new Date()
    let isPaid = each.lastInvoice && each.lastInvoice.paid
    let vmId = each.vm && each.vm.id
    let isOverdue = each.lastInvoice && new Date(each.lastInvoice.dueDate) < new Date()
    // const isActiveOk = idleDate ? new Date(idleDate).getTime() + (24 * 60 * 60 * 1000) < new Date().getTime() : false
    array.push({
      os: each.vmOS && each.vmOS.displayName,
      vmId: vmId,
      notDeletable: !each.vm && (!isPaid || isExpired),
      osName: each.os,
      activePlanNum: activePlanName && activePlanName.match(/\d+/) ? activePlanName.match(/\d+/)[0] - 1 : activePlanName ? activePlanName : '',
      totalPrice: each.lastInvoice && each.lastInvoice.totalPrice,
      state: each.vm && each.vm.state ? each.vm.state : 'defined',
      // state:'PROBLEM',
      ip:
        each.vm && each.vm.nics && each.vm.nics[0] && each.vm.nics[0].ipAddresses && each.vm.nics[0].ipAddresses[0].ip4
          ? each.vm.nics[0].ipAddresses[0].ip4
          : '',
      title: each.name,
      hasChanges:
        (each.vm && each.vm.cpuCores != each.cpuCores) ||
        (each.vm && each.vm.ram != each.ram * 1024) ||
        (each.vm && each.vm.primaryDisk != each.primaryDisk),
      // || (each.ram != each.vm && each.vm.ram) || (each.primaryDisk != each.vm && each.vm.disk)),
      cpuN: each.cpuCores,
      cpu: `${each.cpuCores + 'core'}`,
      ramN: each.ram + 'GB',
      diskN: each.primaryDisk + 'GB',
      ram: each.ram + 'GB',
      disk: each.primaryDisk + ' GB',
      vmCreatedBefore: each.vmCreatedBefore,
      id: each.id,
      expired: !each.lastInvoice || isExpired,
      recurringTime: each.lastInvoice && each.lastInvoice.recurringPeriod,
      expiredNew: !each.vm && each.lastInvoice && (each.lastInvoice.to || each.lastInvoice.from) && new Date(each.lastInvoice.dueDate) < new Date(),
      vpsId: each.lastInvoice && each.lastInvoice.product && each.lastInvoice.product.id,
      brush: each.vm && each.vm.brush,
      retry: !each.vm && each.vmCreatedBefore,
      create:
        (!each.vm && !each.vmCreatedBefore) ||
        (each.lastInvoice && new Date(each.lastInvoice.to)! < new Date()) ||
        (!each.vm && each.vmCreatedBefore),
      paymentId: each.lastInvoice && each.lastInvoice.id,
      vpsName: each.lastInvoice && each.lastInvoice.product && each.lastInvoice.product.name,
      payment:
        (each.lastInvoice && each.lastInvoice.invoiceStatus && each.lastInvoice.invoiceStatus == 'DRAFT' && each.vm && !each.vm.nics) ||
        (each.lastInvoice && each.lastInvoice.invoiceStatus && each.lastInvoice.invoiceStatus == 'DRAFT' && !each.cm),
      renewPay:
        each.lastInvoice &&
        each.lastInvoice.invoiceStatus &&
        each.lastInvoice.invoiceStatus == 'DRAFT' &&
        each.vm &&
        each.vm.nics &&
        each.vm.nics[0].ipAddresses[0].ip4
          ? true
          : false,
      // expiredVm: each.vm && new Date(each.lastInvoice.dueDate) < new Date(),
      invoiceItem: each.lastInvoice,
      renewable:
        (each.vm && isPaid && each.lastInvoice && each.lastInvoice.to && new Date(each.lastInvoice.to) < new Date()) ||
        (each.vm && !isPaid && each.lastInvoice && (each.lastInvoice.to || each.lastInvoice.from) && new Date(each.lastInvoice.dueDate) < new Date()),
      shouldUpgrade: !(
        each.vm &&
        (each.vm.cpuCores != each.cpuCores || (each.vm && each.vm.ram / 1024 != each.ram) || (each.primaryDisk != each.vm && each.vm.primaryDisk))
      ),
      isExpired: isExpired,
      paymentOverDue:
        ((each.lastInvoice && each.lastInvoice.invoiceStatus && each.lastInvoice.invoiceStatus == 'DRAFT' && each.vm && !each.vm.nics) ||
          (each.lastInvoice && each.lastInvoice.invoiceStatus && each.lastInvoice.invoiceStatus == 'DRAFT' && !each.cm)) &&
        each.lastInvoice &&
        new Date(each.lastInvoice.dueDate) < new Date(),
      paymentCreatedBefore:
        ((each.lastInvoice && each.lastInvoice.invoiceStatus && each.lastInvoice.invoiceStatus == 'DRAFT' && each.vm && !each.vm.nics) ||
          (each.lastInvoice.invoiceStatus == 'DRAFT' && !each.cm)) &&
        each.vmCreatedBefore,
      isOverdue: each.lastInvoice && new Date(each.lastInvoice.dueDate) < new Date(),
      deleteVmBill: !each.vm && (!isPaid || isExpired),
      renewInvoicedelete:
        ((isPaid && isExpired) ||
          (!isPaid && each.lastInvoice.to && each.lastInvoice.from && each.lastInvoice && new Date(each.lastInvoice.dueDate) < new Date())) &&
        !each.vm &&
        (!isPaid || isExpired),
      renewInvoice:
        (isPaid && isExpired) ||
        (!isPaid && each.lastInvoice.to && each.lastInvoice.from && each.lastInvoice && new Date(each.lastInvoice.dueDate) < new Date()),

      dateTo:
        localStorage.getItem('__language') == 'fa'
          ? each.lastInvoice.persianTo
          : new Date(new Date(each.lastInvoice.to).toUTCString()).toLocaleString().split(',')[0],
      retriable: each.lastInvoice && isPaid && new Date(each.lastInvoice.to) > new Date() && !each.vm && each.vmCreatedBefore,
      vmBillStatus: each.vmBillStatus,
      idle: each.lastInvoice && isPaid && !isExpired && each.lastInvoice.recurringPeriod == 'DAY' && each.vmBillStatus != 'REMOVED',
      idleDate: each.idleDate && Math.round((new Date(each.idleDate).getTime() + 24 * 60 * 60 * 1000 - new Date().getTime()) / 3600000),
      cancelUpgrade:
        !each.lastInvoice.paid && !isOverdue && !(!each.vm && (!each.lastInvoice.paid || isExpired)) && each.lastInvoice.cancelInvoice && true,
      deleteNotCreated:
        isOverdue &&
        !each.vm && (!each.lastInvoice.paid || isExpired) &&
        !((each.lastInvoice.paid && isExpired) || (!each.lastInvoice.paid && (each.lastInvoice.to || each.lastInvoice.from) && isOverdue))
    })
  })
  // ${isActiveOk && idleDate ? '' : ' : ' + Math.round((new Date(idleDate).getTime() + (24 * 60 * 60 * 1000)
  // - new Date().getTime()) / (3600000)) + ' ' + remaindHoursLocale}
  array.map((each: any, index: number) => {
    let keys = Object.keys(each)

    let filtered = keys.filter(function(key) {
      return each[key] == true
    })
    each['buttonState'] = filtered
  })
  array.sort((a: any, b: any) => {
    if (a.invoiceItem) return b.invoiceItem.createdAt - a.invoiceItem.createdAt
  })
  array.sort((a: any, b: any) => {
    return a.state.length - b.state.length
  })
  return array
}
export const renderProducts = (vmProducts: any) => {
  let array: any = []

  vmProducts.map((product: any) => {
    let featureInfo = strToObj(product.featureInfo)
    let jsonPrices = product.jsonPrices
      .map((each: any) => each.valuePrice)
      .sort(function(a: any, b: any) {
        return a - b
      })
    let hasMonth = false,
      hasTMonth = false,
      hasSMonth = false,
      hasYear = false,
      hasTYear = false
    let array9 = [hasMonth, hasTMonth, hasTMonth, hasYear]

    let jsonPrices2 = product.jsonPrices.map((each: any) => {
      switch (each.recuringPeriod) {
        case 'MONTH':
          hasMonth = true
          array9[0] = each.valuePrice
          break
        case 'SMONTH':
          hasSMonth = true
          array9[2] = each.valuePrice
          break
        case 'YEAR':
          hasYear = true
          array9[3] = each.valuePrice
          break
        case 'TYEAR':
          hasTYear = true
          array9[4] = each.valuePrice
          break
        case 'TMONTH':
          hasTMonth = true
          array9[1] = each.valuePrice
          break
      }
      let obj = { time: each.recuringPeriod, prices: each.valuePrice }

      return obj
    })
    let array2 = [hasMonth, hasTMonth, hasTMonth, hasYear, hasTYear]

    let jsonInfo = strToObj(product.jsonInfo)
    let core = jsonInfo.cpu + 'Cores'
    let cpu = Object.keys(featureInfo)[0].split('CPU: ')[1]
    let ram = Object.keys(featureInfo)[1].split('RAM: ')[1]
    let disk = Object.keys(featureInfo)[2].split('دیسک: ')[1]
    let unlimited = Object.keys(featureInfo)[3].split('ترافیک:')[1]
    if (unlimited.includes('unlimited')) unlimited = 'نامحدود'
    array.push({
      name: product.name,
      os: product.os,
      id: product.id,
      time: jsonInfo.plan_type,
      cpu: `${cpu} ( ${core} )`,
      core: jsonInfo.cpu,
      hasMonth,
      hasTMonth,
      hasSMonth,
      hasYear,
      ram: ram,
      disk: disk,
      jsonPrices3: array9,
      unlimited: unlimited,
      prices: jsonPrices,

      jsonPrices2: jsonPrices2
    })
  })
  return array
}
