import * as React from 'react'
import { t } from 'ttag'

// ui-elements
import { IconLink } from '../ui-elements/Icon/IconLink'

// styles & icons
import profile from '../../images/Icons/profileIcons/profile.svg'
import signOut from '../../images/Icons/commonIcons/signout.svg'
import styles from './Signout.module.scss'
import { connect } from 'react-redux'
import { signout, getVmBills, setExpirationNotification } from '../../services/internal/store/actions'
import { Link } from 'react-router-dom'
import { NavbarDropDown } from '../Navbar/DropDown/NavbarDropDown'
import { Icon } from '../ui-elements/Icon/Icon'
import { render } from 'react-dom'
import moment from 'moment'
import { translateName } from '../Plans/Plan'

export interface Iprops {
  username: string
  open?: boolean
  signout?: any
}

class Status extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      expiredCount: 0,
      availableCount: 0,
      info: {}
    }
  }

  componentDidMount() {
    //  this.props.getVmBills()
    // this.props.getUserInfo()
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    let vmBills = nextProps.vmBills
    if (vmBills && vmBills.length > 0) {
      let expiredCount = 0
      let availableCount = 0
      //console.log(vmBills)
      vmBills.map((each: any) => {
        if (each.lastInvoice && moment(each.lastInvoice.to).diff(moment(), 'days') < 7) {
          this.props.setExpirationNotification(true)
          expiredCount++
        }
        if (each.vm && each.vm.state == 'ON') availableCount++
      })

      this.setState({ expiredCount, availableCount })
    }
    if (nextProps.account && nextProps.account.username) {
      this.setState({ info: nextProps.account })
    }
  }
  render() {
    let { account, icons } = this.props
    let isFree = this.state.info && this.state.info.plan && this.state.info.plan.name == 'FREE'
    let ExpirationDate = this.state.info.lastPaidInvoice && this.state.info.lastPaidInvoice.persianTo
    return (
      <li className={'pg-p-0'}>
        <div className={'pg-flex pg-pointer-auto'}>
          <div className={'pg-p-3 pg-w-full pg-justify-center pg-flex pg-flex-col'}>
            <span className={'pg-text-center pg-mt-5'}>
              <Icon src={icons[0]} width={50} />
            </span>
            <span className={'pg-text-center pg-text-xs '}>{t`File Storage`}</span>
            <span className={'pg-text-blue-400 pg-text-center pg-mt-5 '}>
              {t`Plan`} {translateName(account.plan && account.plan.name)}
            </span>

            {/* <span className={'pg-text-gray-600 pg-text-xs pg-text-center pg-mt-2'}>
              {ExpirationDate && !isFree ? t`Credit:` + ExpirationDate : <Link to={'/nwaccount/plans/upgrade'}>{t`Upgrade the account`}</Link>}
            </span> */}
	</div>
          <div className={'pg-border-r pg-p-3 pg-text-center  pg-flex pg-flex-col pg-w-full pg-justify-center'}>
            <span className={'pg-text-center'}>
              <Icon src={icons[1]} width={50} />
            </span>
            <span className={'pg-text-xs '}> {t`VM`}</span>
            <span className={'pg-text-blue-400 pg-mt-5'}>
              {this.state.availableCount} {t`Online vms`}
            </span>
            <span className={'pg-text-gray-600 pg-text-xs pg-text-center pg-mt-2'}>
              {t`Near to expiration` + this.state.expiredCount && this.state.expiredCount !== 0}
            </span>
          </div>
        </div>
      </li>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    signout: () => dispatch(signout()),
    getVmBills: () => dispatch(getVmBills()),
    setExpirationNotification: (value: boolean) => dispatch(setExpirationNotification(value))
  }
}
const mapStateToProps = (state: any) => ({
  account: state.account.info,
  vmBills: state.vm.vmBills
})
export default connect(mapStateToProps, mapDispatchToProps)(Status)
