import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// icons & styles

import styles from '../TableOrder.module.scss'
import style2 from '../../OldOrder/Order.module.scss'

import { Icon } from '../../../ui-elements/Icon/Icon'
import threeMonthIcon from '../../../../images/Icons/vmIcons/plansIcon/3-month.svg'
import sixMonthIcon from '../../../../images/Icons/vmIcons/plansIcon/6-month.svg'
import monthlyIcon from '../../../../images/Icons/vmIcons/plansIcon/monthly.svg'
import longIcon from '../../../../images/Icons/vmIcons/plansIcon/long.svg'
import hpcIcon from '../../../../images/Icons/vmIcons/plansIcon/hpc.svg'

import { switchTimeLang } from '../../../../services/internal/utils/switchTimeLang'
export interface Iprops {
  osName?: string
  os?: string
  steps?: string[]
  setServiceDuration?: any
  onSelectVersion?: any
  serviceDuration?: any
}

const DurationDropDown: React.FunctionComponent<Iprops> = ({ setServiceDuration, serviceDuration, steps }) => {
  let times = [
    { name: t`monthly`, icon: monthlyIcon },
    { name: t`three month`, icon: threeMonthIcon, displayName: t`3 month` },
    { name: t`six month`, icon: sixMonthIcon, displayName: t`6 month` },
    { name: t`One Year`, icon: longIcon },
    { name: t`Two Year`, icon: longIcon },
    // { name: t`HPC`, icon: hpcIcon }
  ]
  let icon = times.map((each: any, index: any) => {
    if (switchTimeLang(each.name, 'fa') == serviceDuration) return <Icon src={each.icon} width={23} key={index} />
  })
  return (
    <div className={`pg-flex pg-items-center pg-shadow-sm ${[styles.selectos, styles.allBoxes].join(' ')}`} style={{ height: '40px' }}>
      {times &&
        times.map((time: any, index: any) => {
          if (time.name !== t`three month` && time.name !== t`six month`) {
            let style
            if (switchTimeLang(time.name, 'fa') == serviceDuration) style = true

            return (
              <div
                key={index}
                className={`${style && style2.activeBackground}  pg-border-l pg-flex pg-items-center pg-cursor-pointer pg-justify-center `}
                style={{ height: '40px' }}
              >
                <div className={''}>
                  <span className={'pg-mx-1'} onClick={() => setServiceDuration(time.name)}>
                    {time.displayName ? time.displayName : time.name}
                  </span>
                </div>
              </div>
            )
          }
        })}
      <div className={'pg-flex pg-justify-center'} style={{ width: ' 40px' }}>
        <span>{icon}</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  os: state.vm.os,
  oses: state.vm.oses,
  serviceDuration: state.vm.serviceDuration
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    // setServiceDuration: (stepNumber: number) => dispatch(setServiceDuration(stepNumber))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DurationDropDown)
