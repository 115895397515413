import * as React from 'react'

// styles
import styles from './Tooltip.module.scss'

export default interface Iprops {
  children?: any
  width?: number
  text?: any
  position?: string
  height?: number
  lineHeight?: string
  paddingX?: any
}

export const Tooltip: React.FunctionComponent<Iprops> = props => {
  return (
    <div
      className={[
        styles.tooltip,
        props.position === 'top' && styles.top,
        props.position === 'left' && styles.left,
        props.position === 'right' && styles.right
      ].join(' ')}
    >
      <div className={styles.hasTooltip}>{props.children}</div>
      <div
        style={{ width: props.width, height: props.height, lineHeight: props.lineHeight, paddingLeft: props.paddingX, paddingRight: props.paddingX }}
        className={[styles.text, props.position && styles[props.position]].join(' ')}
      >
        {props.text}
      </div>
    </div>
  )
}
