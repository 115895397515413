import * as React from 'react'
import { connect } from 'react-redux'

// ui-elements
import { DownloadBar } from './DownloadBar'

// internal components & styles
import '../../Sidebar.scss'

export interface Iprops {
  onItemClick?: (e: any) => void
  item?: any
  image?: string
  downloadLoading?: boolean
}

const PreviewSideBar: React.FunctionComponent<Iprops> = ({ item, downloadLoading }) => {
  return (
    <div className="sidebar-menu">
      <DownloadBar item={item} downloadLoading={downloadLoading} />
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  item: state.sidebar.item,
  downloadLoading: state.loading.downloadLoading
})

export default connect(mapStateToProps)(PreviewSideBar)
