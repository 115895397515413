import { RestInterface } from '../../external/rest/rest'
import { throwError } from '../utils/sentry'

export interface VmInterface {}

class Vm implements VmInterface {
  private _rest: RestInterface
  constructor(rest: RestInterface) {
    this._rest = rest
  }

  async getOses() {
    const url = '/cws/rest/vmOSs'

    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async getVmproducts() {
    const url = '/cws/rest/products/user/products?category=CWS&calculationType=FIXED'
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async networks() {
    const url = '/cws/rest/networks'
    const headers = { Accept: '*/*' }
    try {
      return await this._rest.get({ url, headers })
    } catch (error) {
      throw error
    }
  }
  async promo(body: any, promo: any) {
    const url = `/cws/rest/vmBills/promoApplicabale?promo=${promo}`

    try {
      return await this._rest.post({ url, body })
    } catch (error) {
      throw error
    }
  }
  async setIdle(id: any, vmBillStatus: any, body: any) {
    const url = `/cws/rest/vmBills/${id}/${vmBillStatus}`

    try {
      return await this._rest.put({ url, body })
    } catch (error) {
      throw error
    }
  }
  async getVmBills(name?: string) {
    let url
    if (!name) url = '/cws/rest/vmBills?where=[{"plan.category":{"$eq":"CWS"}}]&sort:name'
    else url = `/cws/rest/vmBills?where={"name":{"$eq":${name}}}&sort=+name`
    try {
      return await this._rest.get({ url, headers: { Accept: '*/*' } })
    } catch (error) {
      throw error
    }
  }
  async renewal(id: number) {
    const url = `/cws/rest/vmBills/${id}/renewal`
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async renewalDelete(id: number) {
    const url = `/cws/rest/vmBills/${id}/renewal`
    try {
      return await this._rest.delete({ url })
    } catch (error) {
      throw error
    }
  }
  async createInvocie(body: any) {
    const url = `/cws/rest/vmBills/createInvoice`
    try {
      return await this._rest.post({ url, body, headers: { Accept: '*/*' } })
    } catch (error) {
      throw error
    }
  }
  async deleteOs(id: any) {
    const url = `/cws/rest/vmBills/${id}`
    try {
      return await this._rest.delete({ url })
    } catch (error) {
      throw error
    }
  }
  async updateVm(id: any) {
    const url = `/cws/rest/vmBills/${id}/changeVmConf`
    try {
      return await this._rest.put({ url })
    } catch (error) {
      throw error
    }
    // سرور daily-test خاموش و سپس تعلیق می شود
    // توجه داشته باشید تا ۲۴ ساعت سرور قابل فعال سازی نیست. آیا مطمئن هستید؟
  }
  async retryVm(id: number) {
    let url = `/cws/rest/vmBills/retry?invoiceId=${id}`
    try {
      return await this._rest.post({ url, headers: { Accept: '*/*' } })
    } catch (error) {
      throw error
    }
  }
  async deleteVm(id: any) {
    const url = `/cws/rest/vms/${id}`
    try {
      return await this._rest.delete({ url, headers: { Accept: '*/*' } })
    } catch (error) {
      throw error
    }
  }

  async power(id: any, powerAction: any) {
    const url = `cws/rest/vmBills/${id}/power`
    let body = { id: id, powerAction: powerAction }
    try {
      return await this._rest.put({ url, body })
    } catch (error) {
      throw error
    }
  }
  async activeIdle(id: any, vmbillStatus: any) {
    const url = `cws/rest/vmBills/${id}/${vmbillStatus}`
    try {
      return await this._rest.put({ url })
    } catch (error) {
      throw error
    }
  }
  async cancelChanges(id: any) {
    const url = `cws/rest/vmBills/${id}/cancelChanges`
    try {
      return await this._rest.put({ url })
    } catch (error) {
      throw error
    }
  }
  async calculateLeftCredit(id: any) {
    const url = `/cws/rest/vmBills/${id}/calculateLeftCredit`
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async changeVmBillPlan(id: any, plan: any, period: any) {
    // ttp://us.cdn.persiangig.com/cws/rest/vmBills/63602537/changeVmBillPlan?plan=1051090000&period=TMONTH
    const url = `/cws/rest/vmBills/${id}/changeVmBillPlan?plan=${plan}&period=${period}`
    // let headers = {
    //   'cache-control': 'no-cache',
    //   'Content-Length': '',
    //   'Accept-Encoding': 'gzip, deflate',
    //   Cookie: 'JSESSIONID=06BBC4E7FDF2032B6CAB6BC4D64A3A2C; token="X/m+jdsaotpScj/9AjByBQ=="',
    //   Host: 'us.cdn.persiangig.com',
    //   'Postman-Token': '070d15b4-cdc3-44f9-9de6-db4263c8cabd,ca2eb53c-52b0-4ea3-bc97-d557e6fd24ef',
    //   'Cache-Control': 'no-cache',
    //   'User-Agent': 'PostmanRuntime/7.15.2',
    //   token: 'X/m+jdsaotpScj/9AjByBQ==',
    //   Accept: '*/*'
    // }
    try {
      return await this._rest.put({ url })
    } catch (error) {
      throw error
    }
  }
  async changeVmInfo(id: any, body: any) {
    const url = `/cws/rest/vmBills/${id}`
    try {
      return await this._rest.put({ url, body })
    } catch (error) {
      throw error
    }
  }
}

export default Vm
