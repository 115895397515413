import { actionTypes } from './types'
import { actionBuilder } from './actionBuilder'

export const setSidebarItems = actionBuilder(actionTypes.SET_SIDEBAR_ITEM, 'payload')
export const setPreviewState = actionBuilder(actionTypes.SET_PREVIEW_STATE, 'payload')
export const setDownloadToken = actionBuilder(actionTypes.SET_DOWNLOAD_TOKEN, 'payload','value')
export const setEditStatus = actionBuilder(actionTypes.SET_EDIT_STATUS, 'payload')
export const setRenameText = actionBuilder(actionTypes.UPDATE_RENAME_TEXT, 'payload')
export const setProfileTab = actionBuilder(actionTypes.SET_PROFILE_TAB, 'payload')
export const setSidebarState = actionBuilder(actionTypes.SET_SIDEBAR_STATE, 'payload')


