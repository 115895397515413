import React, { useState } from 'react'
import { Hr } from '../../ui-elements/Hr/Hr'
import { Icon } from '../../ui-elements/Icon/Icon'
import zoomin from '../../../images/Icons/zoom/multimedia.svg'
import zoomOut from '../../../images/Icons/zoom/zoom.svg'
import { TimePickerComponent } from './TimePicker'
export const ViewBar: React.FunctionComponent<any> = ({ view, changeView }) => {
  const viewArray = [
    { label: 'CPU', color: 'pg-border-blue-400', bg: 'pg-bg-blue-400  pg-text-white' },
    { label: 'RAM', color: 'pg-border-yellow-400', bg: 'pg-bg-yellow-400 pg-text-white' },
    { label: 'DISK', color: 'pg-border-green-400', bg: 'pg-bg-green-400 pg-text-white' },
    { label: 'NET', color: 'pg-border-purple-400', bg: 'pg-bg-purple-400 pg-text-white' }
  ]
  return (
    <div className={'pg-flex pg-flex-col pg-justify-center'}>
      <div className={'pg-bg-white pg-shadow  pg-p-3 pg-mt-4 pg-mr-4'}>
        <div className={`pg-flex pg-justify-center pg-text-gray-700 pg-mb-2 `}>View</div>
        {viewArray.map((viewElement: any) => (
          <span
            onClick={() => changeView(viewElement.label)}
            className={`pg-flex pg-justify-center pg-items-center pg-py-2  pg-leading-normal pg-px-2 pg-border pg-cursor-pointer pg-rounded-sm ${
              viewElement.color
            } pg-mt-2 ${view == viewElement.label && viewElement.bg}`}
          >
            {viewElement.label}
          </span>
        ))}
      </div>
      <div className={'pg-my-2 pg-mr-4'}>
        <Hr />
      </div>
      <div className={'pg-flex pg-mr-4 pg-justify-between'}>
        <span className={'pg-bg-white pg-shadow  pg-p-3'}>
          <Icon src={zoomin} width={20} />
        </span>
        <span className={'pg-bg-white pg-shadow  pg-p-3'}>
          <Icon src={zoomOut} width={20} />
        </span>
      </div>
      <div className={'pg-my-2 pg-mr-4'}>
        <span className={'pg-text-xs  pg-text-gray-700'}>from:</span>
        <div className={'pg-bg-white '}>
          <TimePickerComponent />
        </div>
        <span className={'pg-text-xs  pg-text-gray-700'}>to:</span>
        <div className={'pg-bg-white '}>
          <TimePickerComponent />
        </div>
      </div>
    </div>
  )
}
