import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import styles from '../FMModal/FMmodal.module.scss'

import { Progressbar } from '../../Progressbar/Progressbar'
import { StatusButton } from './StatusButton'
import toast from '../../Toast/Toast'
import image from '../../../../images/Icons/typeIcons/icon-image.svg'
import { uploadDocument } from '../../../../services/internal/store/actions'
import { Image } from '../../Image/Image'
import { useState } from 'react'
import { UploadRow } from './UploadRow'
interface Iprops {
  uploadFiles?: any
  showModal?: boolean
  uploadProgress?: any
  cancelToken?: any
  ipCountry?: string
  handleClose?: any
}

const toastList = new Set()
const MAX_TOAST = 1

const UploadingModal: React.FunctionComponent<Iprops> = ({ cancelToken, uploadFiles, handleClose, showModal, uploadProgress, ipCountry }) => {
  if (ipCountry && ipCountry.length > 0 && ipCountry !== 'IR' && toastList.size < MAX_TOAST && window.location.pathname.includes('nwfm')) {
    const id = toast.warning(
      'اگر به فیلتر شکن (vpn) وصل هستید ممکن است هنگام بارگزاری فایل دچار مشکل شوید، در صورت بروز مشکل فیلتر شکن (vpn) قطع نموده و دوباره امتحان کنید.'
    )
    toastList.add(id)
  }

  // const [canceled, setCanceled] = useState(false)

  return (
    <div className={showModal ? [styles.uploadingModal, styles.displayBlock].join(' ') : [styles.uploadingModal, styles.displayNone].join(' ')}>
      <div className={styles.header}>
        <span> {t`Uploading`}</span>
        <span onClick={handleClose} className={'pg-cursor-pointer'}>
          {t`close`}
        </span>
      </div>
      <div className={styles.body}>
        <ul className={styles.overFlow}>
          {uploadFiles.map((uploadFile: any, index: number) => {
            return <UploadRow key={index} uploadFile={uploadFile} uploadProgress={uploadProgress} index={index} cancelToken={cancelToken} />
          })}
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  uploadFiles: state.document.uploadFiles,
  ipCountry: state.account.ipCountry,
  uploadProgress: state.document,
  cancelToken: state.account.cancelToken
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    uploadDocument: (value: any) => dispatch(uploadDocument(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadingModal)
