import { actionTypes } from '../actions/types'
import { boolean } from '@storybook/addon-knobs';

// Interfaces
export interface StateInterface {
  token: string
  username: string | null
  registered?: boolean
  passwordSuccess: boolean
}

export interface PayloadInterface {
  token?: string
  username?: string
  email?: string
  passwordSuccess?: boolean
  registered?: boolean
}

export interface ActionInterface {
  type: string
  payload: PayloadInterface
}

export const initialState: StateInterface = {
  token: '',
  passwordSuccess: false,
  registered:false,
  username: localStorage.getItem('user') || '{}'
}

const authReducer = (state: StateInterface = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload.token
      }
      case actionTypes.SET_REGISTER_STATE:
          return {
            ...state,
            registered: action.payload
          }
    case actionTypes.SET_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordSuccess: action.payload
      }

    case actionTypes.SET_USER_CREDENTIALS:
      return {
        ...state,
        username: action.payload.username
      }
    case actionTypes.SIGNOUT:
      return {
        ...state,
        token: '',
        username: ''
      }
    default:
      return state
  }
}

export default authReducer
