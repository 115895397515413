import React from 'react'
import { connect } from 'react-redux'

import { Icon } from '../../../ui-elements/Icon/Icon'

// icons & styles
import centOs from '../../../../images/Icons/vmIcons/centos.svg'
import debianOs from '../../../../images/Icons/vmIcons/debian.svg'
import ubuntuOs from '../../../../images/Icons/vmIcons/ubuntu.svg'
import windowsOs from '../../../../images/Icons/vmIcons/windows.svg'
import centOsWhite from '../../../../images/Icons/vmIcons/centosWhite.svg'
import debianOsWhite from '../../../../images/Icons/vmIcons/debianWhite.svg'
import ubuntuOsWhite from '../../../../images/Icons/vmIcons/ubuntuWhite.svg'
import windowsOsWhite from '../../../../images/Icons/vmIcons/windowsWhite.svg'

import styles from '../TableOrder.module.scss'

export interface Iprops {
  osName?: string
  os?: any
  osMainName?: any
  selectOs?: any
  onSelectVersion?: any
  osArray?: any
}

const OsDropDown: React.FunctionComponent<Iprops> = ({ os, osArray, selectOs, osMainName }) => {
  return (
    <div>
      {osArray.map((each: any,i:number) => (
        <div dir={'ltr'} key={i}>
          {osMainName == each.name &&
            each.version.map((version: any, index: any) => {
              return (
                <div
                  key={index}
                  className={`${styles.value} ${version == os[0] && styles.active}`}
                  onClick={() => selectOs(each.mainName[index], version, each.name)}
                >
                  {version}
                </div>
              )
            })}
        </div>
      ))}
    </div>
  )
}

export const GetImgByOsName2 = (osName: string, isWhite?: boolean) => {
  if (!isWhite) {
    switch (osName) {
      case 'Debian':
        return <Icon src={debianOs} />
      case 'CentOS':
        return <Icon src={centOs} />
      case 'Ubuntu':
        return <Icon src={ubuntuOs} />
      case 'Windows':
        return <Icon src={windowsOs} />
      default:
        return <Icon src={centOs} />
    }
  } else if (isWhite) {
    switch (osName) {
      case 'Debian':
        return <Icon src={debianOsWhite} />
      case 'CentOS':
        return <Icon src={centOsWhite} />
      case 'Ubuntu':
        return <Icon src={ubuntuOsWhite} />
      case 'Windows':
        return <Icon src={windowsOsWhite} />
      default:
        return <Icon src={centOsWhite} />
    }
  }
}

const mapStateToProps = (state: any) => ({
  os: state.vm.os,
  oses: state.vm.oses
})

export default connect(mapStateToProps, null)(OsDropDown)
