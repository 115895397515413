import { actionTypes } from '../actions/types'
import { switchTimeLang } from '../../utils/switchTimeLang'
import { t } from 'ttag'

// Interfaces
interface StateInterface {
  os: Array<any>
  currentStep: number
  oses: Array<any>
  vmProducts: Array<any>
  serviceDuration: string
  vsCard: any
  vmBills: Array<any>
  vmName: string
  finalPrice?: any
  firstStep?: any
  vsCardMain: any
  steps: Array<any>
  vmId: number
  leftCredit?: string
  vpsPeriod?: string
  vmBillsNamed?: any
  vsCardDay?: any
  vmArrayNamed?: any
  vmArray?: Array<any>
  vmProducts2?: any
  uploadFiles?: any
  vmSteps?: any
  vmBillError?: boolean
}

export const initialState: StateInterface = {
  os: ['CentOS 7.3 (64bit)', 'rhel7.0', 'CentOS'],
  vpsPeriod: '',
  leftCredit: '',
  vmName: '',
  serviceDuration: 'YEAR',
  currentStep: 0,
  vmId: 0,
  oses: [],
  uploadFiles: [],
  firstStep: false,
  vmProducts: [],
  vsCardDay:[],
  vmProducts2: [],
  vsCard: [],
  vsCardMain: [],
  finalPrice: 0,
  vmBillsNamed: [],
  vmArrayNamed: [],
  vmSteps: [t`Select operating system`, t`Select service Duration`, t`Select Custom Settings`, t`Network card info`, t` Submit`],
  vmBills: [],
  vmArray: [],
  vmBillError: false,
  steps: [false, false, false, false, false]
}

const vmReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_VM_BILLS:
      return {
        ...state,
        vmBillError: false,
        vmBills: action.payload,
        vmArray: action.array
      }
    case actionTypes.SET_FINAL_PRICE:
      return {
        ...state,
        finalPrice: action.payload
      }
    case actionTypes.DONE_FIRST_STEP:
      return {
        ...state,
        firstStep: action.payload
      }
    case actionTypes.SET_VM_BILL_ERROR:
      return {
        ...state,
        vmBillError: action.payload
      }
    case actionTypes.SET_VM_BILLS_NAMED:
      return {
        ...state,
        vmBillError: false,
        vmBillsNamed: action.payload,
        vmArrayNamed: action.array
      }

    case actionTypes.SET_OSES:
      return {
        ...state,
        oses: action.payload
      }
    case actionTypes.SET_VM_PRODUCTS:
      if (!state.firstStep) {
        return {
          ...state,
          vmProducts: action.payload,
          vmProducts2: action.array,
          vsCard: action.array[0],
          vsCardMain: action.array[0]
        }
      } else {
        return {
          ...state,
          vmProducts: action.payload,
          vmProducts2: action.array
        }
      }
    case actionTypes.SET_UPLOAD_FILES:
      return {
        ...state,
        uploadFiles: action.payload
      }
    case actionTypes.SELECT_VS_CARD_DAY:
      return {
        ...state,
        vsCardDay: action.payload[0]
      }
    case actionTypes.SET_SERVICE_DURATION:
      if (state.serviceDuration == 'DAY') {
        return {
          ...state,
          serviceDuration: switchTimeLang(action.payload, 'fa'),
          vsCard: [],
          vsCardMain: [],
          finalPrice: 0
        }
      } else
        return {
          ...state,
          serviceDuration: switchTimeLang(action.payload, 'fa')
        }

    // return {
    //   ...state,
    //   serviceDuration: switchTimeLang(action.payload, 'fa'),
    //   steps: [true, true, false, false, false]
    // }
    case actionTypes.SELECT_OS:
      if ((action.id == 'Windows Server 2012' || action.id == 'Windows Server 2016' || action.id == 'Windows Server 2019' || action.id == 'Ubuntu Desktop 16.04') && state.serviceDuration !== 'DAY') {
        //  console.log('jj')
        return {
          ...state,
          vsCard: {},
          vsCardMain: {},
          finalPrice: 0,
          os: [action.id, action.payload, action.name],
          steps: action.payload ? [true, false, false, false, false] : [false, false, false, false, false]
        }
      } else
        return {
          ...state,
          os: [action.id, action.payload, action.name],
          steps: action.payload ? [true, false, false, false, false] : [false, false, false, false, false]
        }
    case actionTypes.SET_NAME:
      return {
        ...state,
        vmName: action.payload,
        steps: [true, true, true, true, true]
      }
    case actionTypes.SELECT_VS_CARD:
      // console.log('hi')
      return {
        ...state,
        vsCard: action.payload[0],
        vsCardMain: action.payload[1],
        steps: [true, true, true, true, false]
      }
    case actionTypes.STEP_FORWARD:
      return {
        ...state,
        currentStep: state.currentStep + 1
      }

    case actionTypes.SET_VPS_PERIOD:
      return {
        ...state,
        vpsPeriod: action.payload
      }

    case actionTypes.STEP_BACKWARD:
      return {
        ...state,
        currentStep: state.currentStep - 1
      }
    case actionTypes.SELECT_STEP:
      return {
        ...state,
        currentStep: action.payload
      }
    case actionTypes.SET_NETWORK:
      return {
        ...state,
        network: state.payload
      }

    case actionTypes.SET_VM_ID:
      return {
        ...state,
        vmId: action.payload
      }

    case actionTypes.SET_LEFT_CREDIT:
      return {
        ...state,
        leftCredit: action.payload
      }
    case actionTypes.SELECT_VM:
      return {
        ...state,
        selectVm: action.payload
      }

    default:
      return state
  }
}

export default vmReducer
