import * as React from 'react'
import { connect } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { t } from 'ttag'

// components
import ProfileEdit from '../../../components/Profile/ProfileEdit'
import EditModal from '../../../components/ui-elements/Modal/EditModal/EditModal'
import { Loading } from '../../../components/loading/Loading'

// services
import { getUserInfo, getProducts, changeProfile, setCities, setValidationErr } from '../../../services/internal/store/actions'

//utils
import { validateInput } from '../../../services/internal/utils/validateInput'

//external data
import iranGeography from '../../../services/internal/utils/iranProvinces.json'

import { industries, industriesEn } from '../../../services/internal/utils/industriesName'

const staff = ['0 - 10', '10 - 50', '50 - 100', 'بیشتر از 100']

export interface Iprops {
  getUserInfo: any
  info: any
  getProducts: any
  changeProfile: any
  loading: boolean
  setCities: any
  cities: any
  setValidationErr?: any
}

export interface Istate {
  selected: string
}

class Profile extends React.Component<Iprops, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      province: '',
      industries: localStorage.getItem('__language') == 'fa' ? industries : industriesEn,
      staff: staff,
      profile: {},
      changedValues: [],
      provinces: [],
      cities: [],
      input: [],
      modalView: '',
      showModal: false
    }
  }

  componentDidMount = () => {
    document.title = t`Account`
    if (this.props.info.length < 1) this.props.getUserInfo()
    this.props.getProducts()
    this.props.getUserInfo()
    let provinces = iranGeography.map((province: any) => province.name)
    this.setState({ provinces })
  }

  closeModal = () => {
    this.props.setValidationErr('')
    if (this.state.modalView) this.setState({ showModal: false, modalView: '' })
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.info && nextProps.info.profile) {
      let info = nextProps.info
      if (this.props.cities.length < 2) {
        this.findCities(nextProps.info.profile.province ? this.findProvinceName(info.profile.province) : 'تهران')
      }
      this.setState({
        displayName: info.displayName,
        id: info.id,
        email: info.email,
        mobileNumber: info.mobileNumber,
        name: info.name,
        family: info.family,
        nationalId: info.profile.nationalId,
        province: info.profile.province,
        city: info.profile.city,
        postalCode: info.profile.postalCode,
        planId: info.plan.id,

        fax: info.profile.fax,
        customerType: info.profile.customerType,
        address: info.profile.address,
        profile: nextProps.info.profile,
        company: nextProps.info.profile.company,
        businessCode: nextProps.info.profile.businessCode,
        registerNo: nextProps.info.profile.registerNo,
        industryType: nextProps.info.profile.industryType,
        companyNationalCode: nextProps.info.profile.companyNationalCode,
        companyStaffCount: nextProps.info.profile.companyStaffCount,
        customerTypeFa: info.profile.customerType !== 'business' ? t`Person` : t`Business`
      })
    } else if (nextProps.info) {
      let info = nextProps.info

      this.setState({
        displayName: info.displayName ? info.displayName : '-',
        mobileNumber: info.mobileNumber ? info.mobileNumber : '-',
        name: info.name ? info.name : '-',
        family: info.family ? info.family : '-',
        id: info.id,
        profile: info.profile
          ? info.profile
          : {
              customerType: ''
            },
        email: info.email,
        nationalId: '---',
        province: '---',
        city: '---',
        postalCode: '---',
        fax: '---',
        customerType: t`Person`,
        customerTypeFa: t`Person`,
        address: '---',
        company: '---',
        businessCode: '---',
        registerNo: '---',
        industryType: '---',
        companyNationalCode: '---',
        companyStaffCount: '---'
      })
    }
  }

  renderSelectionArray = (array: any, isIndex?: any) => {
    let arrayNamed: any = []
    array.map((each: any, index: number) => arrayNamed.push({ label: each, value: isIndex ? index : each, code: index }))
    return arrayNamed
  }
  findProvinceNumber = (provinceName: string) => iranGeography.findIndex(k => k.name == provinceName)
  findProvinceName = (provinceNumber: string) => iranGeography && iranGeography[+provinceNumber] && iranGeography[+provinceNumber].name
  findCities = (provinceName: any) => {
    let cities: any = []
    iranGeography.map((province: any) => {
      if (province.name == provinceName) {
        province.cities.map((city: any) => cities.push(city.name))
      }
    })
    this.setState({ cities })
    if (cities[0] !== this.props.cities[0] && this.props.cities[0].label !== this.renderSelectionArray(cities)[0].label)
      this.props.setCities(this.renderSelectionArray(cities))
    return cities[0]
  }

  onEdit = (modalView: string, label: string) => {
    this.setState({ showModal: true, modalView: 'edit' })
    if (modalView == 'fullName') {
      this.setState({
        input: [
          { name: 'name', value: this.state.name },
          { name: 'family', value: this.state.family }
        ],
        modalType: t`Name`
      })
    } else if (modalView == 'location') {
      this.setState({
        input: [
          {
            name: 'province',
            value: this.state.province,
            selectable: true,
            optionsArray: this.renderSelectionArray(this.state.provinces),
            type: 'select'
          },
          { name: 'city', value: this.state.city, selectable: true, optionsArray: this.renderSelectionArray(this.state.cities), type: 'select' }
        ],
        modalType: t`Location`
      })
    } else if (modalView == 'customerTypeFa') {
      this.setState({
        input: [
          { name: 'customerType', value: t`Person`, type: 'radio' },
          { name: 'customerType', value: t`Business`, type: 'radio' }
        ],
        modalType: t`customerType`
      })
    } else if (modalView == 'industryType') {
      this.setInput('industryType', this.state.industryType, true, this.renderSelectionArray(this.state.industries))
      this.setState({ modalType: t`industryType` })
    } else if (modalView == 'companyStaffCount') {
      this.setInput('companyStaffCount', this.state.companyStaffCount, true, this.renderSelectionArray(this.state.staff, true))
      this.setState({ modalType: t`companyStaffCount` })
    } else {
      this.setState({ input: [{ name: modalView, value: this.state[modalView] }], modalType: label })
    }
  }

  setInput = (name: string, value: any, selectable?: boolean, optionsArray?: any) => {
    this.setState({
      input: [
        {
          name,
          value,
          selectable,
          type: selectable ? 'select' : 'text',
          optionsArray
        }
      ]
    })
  }

  updateChange = (e: any) => {
    let changedValues = this.state.changedValues.concat(e.target.name)
    this.props.setValidationErr('')
    if (e.target.name === 'province') {
      this.findCities(e.target.value)
      if (e.target.value !== this.findProvinceName(this.state.province)) {
        this.state.changedValues.concat('city')
        let cityName = this.findCities(e.target.value)
        this.setState({ cityName })
      }
      this.state.changedValues.concat('provinceNumber')
      this.setState({
        province: e.target.value,
        provinceNumber: e.target.value,
        input: [
          {
            name: 'province',
            value: e.target.value,
            defaultValue: this.findProvinceName(e.target.value),
            selectable: true,
            type: 'select',
            optionsArray: this.renderSelectionArray(this.state.provinces)
          },
          { name: 'city', type: 'select', value: this.state.city, selectable: true, optionsArray: this.renderSelectionArray(this.props.cities) }
        ],
        changedValues
      })
    } else if (e.target.name === 'city') {
      this.setState({
        city: e.target.value,
        input: [
          {
            name: 'province',
            value: this.state.provinc,
            selectable: true,
            type: 'select',
            optionsArray: this.renderSelectionArray(this.state.provinces)
          },
          { name: 'city', value: e.target.value, selectable: true, optionsArray: this.renderSelectionArray(this.state.cities), type: 'select' }
        ],
        changedValues
      })
    } else if (e.target.name === 'name') {
      this.setState({ [e.target.name]: e.target.value, changedValues })
      this.setState({
        input: [
          { name: 'name', value: e.target.value },
          { name: 'family', value: this.state.family }
        ]
      })
    } else if (e.target.name === 'family') {
      this.setState({ [e.target.name]: e.target.value, changedValues })
      this.setState({
        input: [
          { name: 'name', value: this.state.name },
          { name: 'family', value: e.target.value }
        ]
      })
    } else if (e.target.name == 'customerType') {
      this.setState({
        customerType: e.target.value == 'حقیقی' ? 'individual' : 'business',
        changedValues
      })
    } else if (e.target.name == 'companyStaffCount') {
      this.setInput('companyStaffCount', e.target.value, true, this.renderSelectionArray(this.state.staff, true))
      this.setState({
        companyStaffCount: e.target.value,
        changedValues
      })
    } else if (e.target.name == 'industryType') {
      this.setInput('industryType', e.target.value, true, this.renderSelectionArray(this.state.industries))
      this.setState({
        industryType: e.target.value,
        changedValues
      })
    } else {
      this.setState({
        input: [{ name: [e.target.name], value: e.target.value }]
      })
      this.setState({ [e.target.name]: e.target.value, changedValues })
    }
  }

  profileChange = (e: any) => {
    if (e) e.preventDefault()
    let body: any = {}
    let profiles = [
      'nationalId',
      'province',
      'address',
      'city',
      'postalCode',
      'company',
      'customerType',
      'businessCode',
      'registerNo',
      'companyNationalCode',
      'industryType',
      'companyStaffCount'
    ]
    let valid, city, province, seperateChnage
    this.state.changedValues.map((each: any, index: number) => {
      valid = validateInput(each, this.state[this.state.changedValues[index]])
      this.props.setValidationErr(valid)
      if (!profiles.includes(each)) {
        body[each] = this.state[this.state.changedValues[index]]
      } else {
        let profile = this.state.profile
        if ((!profile && each == 'province') || each == 'city') {
          profile = { province: '', city: '' }
          seperateChnage = true
        }
        profile[each] = this.state[each]
        if (each == 'province') {
          // console.log(this.state.provinceNumber)
          profile['province'] = this.findProvinceNumber(this.state.provinceNumber)

          if (this.state.cityName) {
            profile['city'] = this.state.cityName
          }
        }
        if (each == 'industryType') {
          let industries = this.state.industries
          profile['industryType'] = industries.findIndex((k: any) => k == this.state.industryType)
        }

        body['id'] = this.state.id
        body['profile'] = profile
        city = profile.city
        province = profile.province
      }
    })
    if (valid == true) {
      this.props.changeProfile(body)
      this.setState({ modalView: '', changedValues: [] })
    }
  }

  render() {
    let profileBasic: any, personalInfo: any, companyInfo: any, notChangableInfo: any
    if (this.props.info) {
      profileBasic = [
        { label: t`Display Name`, value: this.state.displayName, name: 'displayName' },
        { label: t`Telephone`, value: this.state.mobileNumber, name: `mobileNumber` },
        { label: t`Customer Type`, value: this.state.customerTypeFa, name: `customerTypeFa` }
      ]
      personalInfo = [
        { label: t`Name`, value: this.state.name + ` ` + this.state.family, name: `fullName` },
        { label: t`National Id`, value: this.state.nationalId, name: `nationalId` },
        {
          label: t`Location`,
          value: this.findProvinceName(this.state.province) + ` - ` + this.state.city,
          name: `location`
        },
        { label: t`Adress`, value: this.state.address, name: 'address' },
        { label: t`Postal Code`, value: this.state.postalCode, name: 'postalCode' },
        { label: t`Fax`, value: this.state.fax, name: 'fax' }
      ]
      ;(companyInfo = [
        { label: t`Company Name`, value: this.state.company, name: 'company' },
        { label: t`Business Code`, value: this.state.businessCode, name: 'businessCode' },
        { label: t`Register Number`, value: this.state.registerNo, name: 'registerNo' },
        { label: t`Company National Code'`, value: this.state.companyNationalCode, name: 'companyNationalCode' },
        {
          label: t`Industry Type`,
          value: typeof +this.state.industryType == 'number' ? this.state.industries[this.state.industryType] : this.state.industryType,
          name: 'industryType'
        },
        { label: t`Company Staff Count`, value: staff[this.state.companyStaffCount], name: 'companyStaffCount' }
      ]),
        (notChangableInfo = [{ label: t`Email`, value: this.state.email, name: 'email' }])
    }
    return (
      <>
        {this.props.loading ? (
          <Loading />
        ) : (
          <>
            <Prompt
              when={this.state.changedValues.length > 0}
              message={
                t`You have some unsaved work in part:(` + this.state.changedValues.map((each: any) => each) + t`). Are you sure you want to leave?`
              }
            />
            <ProfileEdit
              profileChange={this.profileChange}
              onEdit={this.onEdit}
              profileBasic={profileBasic}
              notChangableInfo={notChangableInfo}
              personalInfo={personalInfo}
              companyInfo={companyInfo}
              bussinessAccount={this.state.customerType == 'business'}
              updateChange={this.updateChange}
            />
          </>
        )}

        <EditModal
          handleClose={this.closeModal}
          showModal={this.state.showModal && this.state.modalView == 'edit'}
          profileChange={this.profileChange}
          updateChange={this.updateChange}
          input={this.state.input}
          title={`${t`Edit`} ${this.state.modalType}`}
        />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserInfo: () => dispatch(getUserInfo()),
    getProducts: () => dispatch(getProducts()),
    changeProfile: (value: any) => dispatch(changeProfile(value)),
    setCities: (value: any) => dispatch(setCities(value)),
    setValidationErr: (value: any) => dispatch(setValidationErr(value))
  }
}
const mapStateToProps = (state: any) => ({
  info: state.account.info,
  loading: state.loading.isLoading,
  cities: state.account.cities
})
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
