import * as React from 'react'
import { classNames } from 'react-select/lib/utils'

export default interface Iprops {
  backgroundColor?: string
  width?: string
  height?: string
  className?: any
}

export const Hr = ({ backgroundColor = '#f2f2f2', width = '100%', height = '2px',className }: Iprops) => (
  <div className={`hr ${className}` } style={{ backgroundColor: backgroundColor, width: width, height: height }} />
)
