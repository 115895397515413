import * as React from 'react'
import { connect } from 'react-redux'
import { InputRow } from '../Profile/InputRow'
import { Progressbar } from '../ui-elements/Progressbar/Progressbar'
import { formatBytes } from '../../services/internal/utils/formatBytes'
import { translateName } from './Plan'
import { Button } from '../ui-elements/Button/Button'
import { Link } from 'react-router-dom'
import { t } from 'ttag'
import { changePlan } from '../../services/internal/store/actions'

export interface Iprops {
  info?: any
  changePlan?: any
}

const renderColor = (percent: any) => {
  let color
  if (percent < 70) color = 'green'
  else if (percent > 70 && percent < 85) color = 'yellow'
  else color = 'red'
  return color
}

const Plans: React.FunctionComponent<Iprops> = ({ info, changePlan }) => {
  let faname, percent, faTime, expireDate, name: any, time: any, id:any
  if (info && info.plan && JSON.parse(info.plan.jsonInfo)) {
    //console.log(JSON.parse(info.plan.jsonInfo).name)
    name = info.plan.name
    time = JSON.parse(info.plan.jsonInfo).plan_type
    id = info.plan.id
    console.log(name, id, time)
    if (info.plan.featureInfo)
      faname =
        localStorage.getItem('__language') == 'en'
          ? JSON.parse(info.plan.featureInfo).en_description
          : JSON.parse(info.plan.featureInfo).fa_description
    else faname = translateName(info.plan.name)

    faTime =
      localStorage.getItem('__language') == 'en'
        ? JSON.parse(info.plan.jsonInfo).plan_type
        : JSON.parse(info.plan.jsonInfo).plan_type === 'MONTH'
        ? t`Monthly`
        : t`yearly`

    percent = info && info.plan && +(info.quota / JSON.parse(info.plan.jsonInfo).quota).toFixed(2)
  }
  // console.log(percent)
  if (info && info.lastPaidInvoice) expireDate = info.lastPaidInvoice.persianTo

  return (
    <div className={'pg-w-1/2 lgDesktop-max:pg-w-3/4 laptop:pg-w-3/4 tablet:pg-w-3/4  mobile-max:pg-w-full'}>
      <h1 className={'pg-text-lg pg-py-5 pg-text-gray-700'}>{t`Active Plan`}</h1>
      {/* <InputRow label={'Plan Name'} value={`${faname} - ${faTime}`} border={true} editable={false} /> */}
      <div className={`pg-flex pg-text-gray-700 pg-w-full pg-flex-col  pg-border-b-2`}>
        <div className={`pg-flex pg-justify-between pg-items-center`}>
          <div>
            {faname} {faname !== 'رایگان' && '-' + faTime}
          </div>

          <Link to={'/nwaccount/plans/upgrade'}>
            <Button className={['pg-btnPrimary', 'pg-btnMd', 'pg-my-4']} style={{ height: '35px' }}>{t`Upgrade`}</Button>
          </Link>
        </div>
      </div>
      <div className={`pg-flex pg-text-gray-700 pg-w-full pg-flex-col pg-py-4 pg-border-b-2`}>
        <div className={`pg-flex pg-justify-between pg-my-2`}>
          <div>{t`Personal Space`} </div>
          <div>
            {formatBytes({ bytes: info.quota, lang: localStorage.getItem('__language') })} {t`of`}{' '}
            {formatBytes({ bytes: info.plan && JSON.parse(info.plan.jsonInfo).quota, lang: localStorage.getItem('__language') })}
          </div>
        </div>
        <Progressbar value={percent ? percent * 100 : 0.9} height={28} width={'100%'} color={renderColor(percent)} />
      </div>
      {expireDate && faname !== 'رایگان' && (
        <InputRow
          label={t`Expiration Date` + ': '+ expireDate}
          bigger={true}
          value={
            <span>
              {/* {expireDate}{' '} */}
              <Button
               className={['pg-btnPrimary', 'pg-btnMd', 'pg-my-4']} style={{ height: '35px' }}
                onClick={() => changePlan(id,`{ plan_type: ${time}, managed: false }`, true)}
                // style={{ textDecoration: 'underline', color: '#338dfa', cursor: 'pointer' }}
              >{t`Extend FM Plan`}</Button>
            </span>
          }
          border={true}
          editable={false}
        />
      )}
      <div className={'pg-flex pg-justify-end'} />
    </div>
  )
}
const mapStateToProps = (state: any) => ({ info: state.account.info })
const mapDispatchToProps = (dispatch: any) => {
  return {
    // changePlan: (id: number, additionalInfo: string, applyNow: boolean) => dispatch(changePlan(id, additionalInfo, applyNow))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Plans)
