import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { Authentication } from '../Authentication'

// ui-elements
import { TextInput } from '../../../components/ui-elements/Input/Input'
import { Button } from '../../../components/ui-elements/Button/Button'
import { IconLink } from '../../../components/ui-elements/Icon/IconLink'

// services
import { PayloadInterface } from '../../../services/internal/store/reducers/authReducer'
import { setToken, login, removeMessages, setUserInfo, setLoadingState, setEmail } from '../../../services/internal/store/actions'

// icons & styles
import lock from '../../../images/Icons/typeIcons/login/lock.svg'
import styles from '../Authentication.module.scss'
import error from '../../../images/Icons/commonIcons/error.svg'
import { getLocale } from '../../../translate/translate'
import panel from '../../../images/panel.svg'
import support from '../../../images/support.svg'
import traffic from '../../../images/traffic.svg'
import upgrade from '../../../images/upgrade.png'

class Login extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      email: '',
      password: '',
      loading: false,
      token: props.token ? props.token : '',
      msg: false
    }
  }
  handleChange = async (e: any) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  componentWillUnmount() {
    this.props.removeMessages()
  }
  onBlur = () => {
    this.props.removeMessages()
  }

  // UNSAFE_componentWillReceiveProps(nextProps: any) {
  //   this.setState({ token: nextProps.auth.token })
  // }

  handleSubmit = (e?: any) => {
    const { history } = this.props

    if (e) e.preventDefault()
    this.props.removeMessages()
    try {
      this.props.setLoadingState(true)
      let result = setTimeout(() => {
        // this.props.setEmail(this.state.email)
        this.props.login({ email: this.state.email, password: this.state.password })
      }, 6000)
      //console.log(result)
    } catch (error) {
      //console.log('E: ', error)
    }
  }
  _handleKeyDown = (e: any) => {}
  componentDidMount() {}
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({ token: nextProps.auth.token })
    if (nextProps.location !== this.props.location) {
      this.setState({
        prevPath: this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname
      })
    }
  }
  render() {
    let prevPath = this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname
    if (!prevPath) prevPath = localStorage.getItem('prevPath')
    if (this.state.token) {
      return <Redirect to={prevPath ? prevPath : '/nwfm'} />
    } else if (this.props.lockedAccount) {
      return <Redirect to="/locked" />
    } else if (!this.state.token) {
      window.document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
      // localStorage.setItem('csrf_token', '')
      return (
        <Authentication>
          <form className={styles.login} onSubmit={this.handleSubmit}>
            <span className={styles.title}>{t`Login to account`}</span>
            <p className={styles.description}>{t`Please login to use the services`}</p>
            <TextInput
              onKeyDown={this._handleKeyDown}
              placeholder={t`Username or email`}
              autoComplete={'username'}
              fontEn={true}
              name={'email'}
              dir={'ltr'}
              onChange={this.handleChange}
              onBlur={this.onBlur}
            />
            <TextInput
              onKeyDown={this._handleKeyDown}
              placeholder={t`Password`}
              name={'password'}
              type={'password'}
              dir={'ltr'}
              onChange={this.handleChange}
              autoComplete={'current-password'}
            />
            <div className={styles.row}>
              <div className={styles.switch}>
                {/* {t`Not a member?`}
                <Link to={'/nwregister'}>
                  <span className={styles.link}>{t`Register`}</span>
                </Link> */}
              </div>
              <Link to={'/nwforgetpassword'} className={styles.forgetPassword}>
                <IconLink icon={lock} label={t`I forgot my password!!`}/>
              </Link>
	      <Button loading={this.props.isLoading && !this.state.loading} className={['pg-btnSuccess', 'pg-btnSm']}>
		{t`Login`}
              </Button>
	      <span className="pg-btn pg-btnPrimary pg-btnSm"> 
	      	<a href="https://www.persiangig.com/auth/register">
                	{t`Register`}
	     	</a> 
	      </span>
            </div>
            {this.props.messages.errors.length > 0 && (
              <div className={[styles.wrongVerify, styles.warn].join(' ')}>
                <IconLink icon={error} label={!this.props.lockedAccount && `${this.props.messages.errors}`} />
              </div>
            )}
		<p className="pg-pt-10 pg-text-right">
		پلن های متنوع پرشین گیگ را متناسب با نیازهایتان انتخاب کنید.
		</p>
		<p className="pg-text-right pg-text-blue-400 pg-font-vBold pg-py-4">
			<a href="https://www.persiangig.com/pricing" target="_blank">مشاهده پلن ها</a>
		</p>
		<div className="flex-center pg-flex-col pg-text-xs">
			<div className="pg-flex pg-justify-between pg-w-full pg-pb-4">
				<div className="pg-w-50% pg-text-right">
					<img src={panel} width="20px" />
					<span> پنل اختصاصی </span>
				</div>
                                <div className="pg-w-50% pg-text-right">
                                        <img src={support} width="20px" />
                                        <span>   پشتیبانی 724 رایگان    </span>
                                </div>
			</div>
                        <div className="pg-flex pg-justify-between pg-w-full">
                               <div className="pg-w-50% pg-text-right">
                                        <img src={upgrade} width="20px" />
                                        <span> قابلیت ارتقا پذیری منابع </span>
                               </div>
                               <div className="pg-w-50% pg-text-right">
                                        <img src={traffic} width="20px" />
                                        <span> ترافیک نامحدود حجمی </span>
                               </div>
                        </div>

		</div>

          </form>
        </Authentication>
      )
    }
  }
}

const mapStateToProps = (state: any) => ({
  isLoading: state.loading.isLoading,
  auth: state.auth,
  messages: state.messages,
  lockedAccount: state.account.lockedAccount
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (payload: PayloadInterface) => dispatch(login(payload)),
    setEmail: (payload: any) => dispatch(setEmail(payload)),
    setToken: (token: string) => dispatch(setToken(token)),
    setUserInfo: (payload: PayloadInterface) => dispatch(setUserInfo(payload)),
    removeMessages: (payload: PayloadInterface) => dispatch(removeMessages(payload)),
    setLoadingState: (payload: boolean) => dispatch(setLoadingState(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
