import * as React from 'react'
import { t } from 'ttag'
import loading from '../../images/loading/tail-spin.svg'

import { Icon } from '../ui-elements/Icon/Icon'

export const PaymentLoading: React.FunctionComponent<any> = props => {
  return (
    <div className={'pg-flex pg-flex-col pg-justify-center'}>
      <h1 className={'pg-text-md pg-font-bold '}>{t`Page is directing to bank page`}</h1>
      <div className={'pg-flex pg-w-full pg-justify-center pg-my-4'}>
        <Icon src={loading} width={50} />
      </div>
      <span className={'pg-text-sm pg-w-full pg-text-center pg-text-gray-600'}>{t`Please wait ...`}</span>
    </div>
  )
}
