import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { t } from 'ttag'

// ui-elements
import { Footer } from '../../Footer'
import { renderOsIcon } from '../OsIcon/OsIcon'
import CardFooter from '../FooterState/CardFooter'
import { IconLink } from '../../ui-elements/Icon/IconLink'

// icons
import ramIcon from '../../../images/Icons/vmIcons/ram.svg'
import cpuIcon from '../../../images/Icons/vmIcons/cpu.svg'
import diskIcon from '../../../images/Icons/vmIcons/disk.svg'

import { deleteOs, retryVm, deleteAndCreateInvoice, renewalVm, renewalDelete, calculateLeftCredit } from '../../../services/internal/store/actions'

// styles & internal-component
import styles from './Card.module.scss'

// import moment from 'moment'
export interface Iprops {
  on?: boolean
  off?: boolean
  purchase?: boolean
  invoice?: boolean
  payment?: boolean
  create?: boolean
  turningOff?: boolean
  defined?: boolean
  status?: string
  os?: string
  cpu?: string
  disk?: string
  ram?: string
  showDetails?: boolean
  Extended?: boolean
  fullScreen?: boolean
  fullCard?: boolean
  children?: any
  retry: (value: any) => void
  title?: string
  retriable?: boolean
  brush?: boolean
  problem?: boolean
  onClick?: (value: any) => void
}

class Card extends React.Component<any, any> {
  render() {
    let {
      state,
      title,
      invoice,
      payment,
      create,
      status,
      os,
      cpu,
      idleDate,
      id,
      ip,
      disk,
      ram,
      showDetails,
      fullScreen,
      renewInvoice,
      renewable,
      retriable,
      dateTo
    } = this.props && this.props.vm

    localStorage.getItem('__language') == 'en' && moment().locale('fa')

    let { fullCard, handleCardClick, children, gridFullCard, loading } = this.props
    let on = state == 'ON'
    let off = state == 'OFF'
    let problem = state == 'PROBLEM'

    let cardObj = {
      os: os,
      name: title,
      cpu: cpu,
      disk: disk,
      id: id,
      ram: ram,
      on: on,
      off: off,
      status: status
    }

    let clickable = !create && !retriable && !payment && state != 'PAUSED' && state != 'PROBLEM'
    let isOff = off || invoice || payment || create || retriable
    let twoButtonStyle = payment || invoice || renewInvoice || retriable
    let oneButtonStyle = create || renewable

    let osIcon = renderOsIcon(os)
    return (
      <div
        {...(clickable && {
          onClick: () => handleCardClick && handleCardClick(cardObj)
        })}
        className={`${!loading ? styles.item : ''} ${
          fullCard ? (gridFullCard ? [styles.shadow, 'pg-mb-4'].join(' ') : styles.height) : styles.border
        }  `}
        style={{ height: fullScreen ? 'auto' : '100%' }}
      >
        <div className={styles.top}>
          <div className={[styles.right, clickable && 'pg-cursor-pointer'].join(' ')}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subTitle} style={{ height: '10px' }}>
              {ip && ip}
            </div>
          </div>
          <div className={'pg-flex pg-flex-col'}>
            <div className={styles.state}>
              <span className={styles.status}>{state ? state : 'DEFINED'}</span>
              <span className={`${styles.lamp} ${on ? styles.on : isOff ? styles.off : styles.problem}`}></span>
            </div>
            {dateTo && renewInvoice && (
              <div className={'pg-text-9p pg-text-gray-700'}>
                <span className={'fa'}>{t`Expired at :`}</span> {moment(dateTo).format('YYYY/MM/DD')}
              </div>
            )}
          </div>
        </div>
        <div className={showDetails ? 'hide' : fullCard ? styles.showFull : styles.show}>
          <div className={styles.center}>
            <div className={styles.left}>
              <IconLink icon={osIcon} iconAlt="linux icon" label={os} />
              <IconLink icon={cpuIcon} iconAlt="cpu icon" label={cpu} />
            </div>
            <div className={styles.right}>
              <IconLink icon={diskIcon} iconAlt="disk icon" label={disk} />
              <IconLink icon={ramIcon} iconAlt="ram icon" label={ram} />
            </div>
          </div>
          <Footer className={`${styles.footer}  ${twoButtonStyle ? styles.twoBtn : oneButtonStyle ? styles.oneBtn : ''}`}>
            <CardFooter
              {...this.props.vm}
              on={on}
              ip={ip}
              id={id}
              off={off}
              
              title={title}
              state={state}
              problem={problem}
              fullCard={this.props.fullCard}
              openModal={this.props.openModal}
              openCardModal={this.props.openCardModal}
              gridFullCard={this.props.gridFullCard}
            />
          </Footer>
        </div>
        {children}
      </div>
    )
  }
}
const mapStateToProps = (state: any) => ({
  vmBills: state.vm.vmBills
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteOs: (value: any) => dispatch(deleteOs(value)),
    calculateLeftCredit: (value: any) => dispatch(calculateLeftCredit(value)),
    retry: (value: any) => dispatch(retryVm(value)),
    renewal: (value: any) => dispatch(renewalVm(value)),
    renewalDelete: (value: any) => dispatch(renewalDelete(value)),
    deleteAndCreateInvoice: (value: any) => dispatch(deleteAndCreateInvoice(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Card)
