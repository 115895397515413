import { RestInterface } from '../../external/rest/rest'
import { throwError } from '../utils/sentry'

export interface HostsInterface {}

class Hosts implements HostsInterface {
  private _rest: RestInterface
  date: number
  constructor(rest: RestInterface) {
    this._rest = rest
    let date = new Date()
    let timestamp = date.getTime()
    this.date = timestamp
  }
  async deleteBill(id: any, billType: any) {
    const url = `/cfs/rest/${billType}/${id}`
    try {
      return await this._rest.delete({ url, headers: { Accept: '*/*', 'Content-Type': ' application/x-www-form-urlencoded' } })
    } catch (error) {
      throw error
    }
  }
  async isDomainAvailable(domainName: any,suffix:any) {
    const url = `/cfs/rest/domains/isAvailable?domainName=${domainName}${suffix}&dojo.preventCache=${this.date}`
    try {
      return await this._rest.get({ url, headers: { Accept: '*/*', 'Content-Type': ' application/x-www-form-urlencoded' } })
    } catch (error) {
      throw error
    }
  }

  async getHosts() {
    const url = `/cfs/rest/hosts?dojo.preventCache=${this.date}`

    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async getDomains() {
    const url = `/cfs/rest/domains?dojo.preventCache=${this.date}`

    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async autoRenewal(id: any, autoRenewal: boolean) {
    const url = `/cfs/rest/hosts/${id}`
    let body = { id: id, autoRenewal: autoRenewal }
    try {
      return await this._rest.put({ url, body })
    } catch (error) {
      throw error
    }
  }
  async checkDomain(domainName: any, autoRenewal: boolean) {
    const url = ` cfs/rest/domains/isAvailable?domainName=${domainName}.com&dojo.preventCache=${this.date}`
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async orderHost(body: any) {
    const url = `/cfs/rest/hosts`
    try {
      return await this._rest.post({ url,body })
    } catch (error) {
      throw error
    }
  }
  async createHostBill(body: any) {
    const url = `/cfs/rest/hosts/`
    try {
      return await this._rest.post({ url, body })
    } catch (error) {
      throw error
    }
  }
}

export default Hosts
