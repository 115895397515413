import { actionTypes } from './types'
import { actionBuilder } from './actionBuilder'

export const setLoadingState = actionBuilder(actionTypes.SET_LOADING_STATE, 'isLoading')
export const setModalLoadingState = actionBuilder(actionTypes.SET_MODAL_LOADING_STATE, 'modalLoading')
export const setButtonLoadingState = actionBuilder(actionTypes.SET_BUTTON_LOADING_STATE, 'buttonLoading')

export const setDownloadLoadingState = actionBuilder(actionTypes.SET_DOWNLOAD_LOADING, 'downloadLoading')
export const setInvoiceLoadingState = actionBuilder(actionTypes.SET_INVOICE_LOADING, 'invoiceLoading','index')
export const setDocumentLoadingState = actionBuilder(actionTypes.SET_DOCUMENTS_LOADING, 'payload')
export const setLoadingId = actionBuilder(actionTypes.SET_VM_LOADING_ID, 'payload')
export const setLoadingInvoiceState = actionBuilder(actionTypes.SET_LOADING_INVOICE_STATE, 'payload')
