import * as React from 'react'
import { t } from 'ttag'

// ui-elements
import { IconLink } from '../../ui-elements/Icon/IconLink'

// icons
import newFolderIcon from '../../../images/Icons/sidebarIcons/newfolder.svg'
import deleteIcon from '../../../images/Icons/sidebarIcons/delete.svg'
import moveIcon from '../../../images/Icons/sidebarIcons/move.svg'
import { connect } from 'react-redux'
import { setModalView, removeFolder, setDocuments, deleteDocument } from '../../../services/internal/store/actions'

interface Iprops {
  onItemClick?: any
  setModalView: (e: any) => void
  hide?: boolean
  selection?: any
  documents?: any
  moveable?: boolean
}
import toast from '../../../components/ui-elements/Toast/Toast'
import { UndoTimed } from '../../../components/UndoTimed/UndoTimed'
import { IRemoveFolderInput } from '../../../services/internal/repositories/documents'

const ActionNav: React.FunctionComponent<any> = props => {
  const altIcon = 'Icon'

  return (
    <div className="actionNav">
      <IconLink
        icon={newFolderIcon}
        className="pg-p-2 iconLink sidebarHover"
        iconAlt={`new-folder ${altIcon}`}
        label={t`New Folder`}
        onClick={() => props.setModalView(`New Folder`)}
      />
      {props.hide ? (
        ''
      ) : (
        <>
          <IconLink
            icon={moveIcon}
            className="pg-p-2 iconLink sidebarHover"
            iconAlt={`move ${altIcon}`}
            label={t`Transfer`}
            onClick={props.moveable ? () => props.setModalView(`moveFile`) : () => toast.error(t`Please choose a file`)}
          />
          <UndoTimed
            documents={props.documents}
            selection={props.selection}
            removeFolder={props.removeFolder}
            dragNdropState={props.dragNdropState}
            setDocuments={props.setDocuments}
            button={<IconLink icon={deleteIcon} className="pg-p-2 pg-mb-1 iconLink sidebarHover" iconAlt={`delete ${altIcon}`} label={t`Delete`}  />}
          />
        </>
      )}
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  documents: state.document.documents,
  dragNdropState:state.document.dragNdropState,
  selection: state.selection.selection
})
const mapDispatchToProps = (dispatch: any) => {
  return {
    setModalView: (value: any) => dispatch(setModalView(value)),
    removeFolder: (value: IRemoveFolderInput) => dispatch(removeFolder(value)),
    setDocuments: (value: any) => dispatch(setDocuments(value)),
    deleteDocument: (value: any) => dispatch(deleteDocument(value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionNav)
