import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'ttag'

// ui-elements
import { Button } from '../../../ui-elements/Button/Button'
import { IconLink } from '../../../ui-elements/Icon/IconLink'

// internal-component

// icons & styles
import arrowLeftIcon from '../../../../images/Icons/arrowIcons/left-arrow-white.svg'
import arrowGrayRightIcon from '../../../../images/Icons/arrowIcons/right-arrow-black.svg'
import invoiceIcon from '../../../../images/Icons/commonIcons/invoice.svg'
import styles from './Footer.module.scss'

export default interface Iprops {
  nextStep?: any
  previousStep?: any
  finalStep?: boolean
  hide?: boolean
  currentStep?: number
  homePage?: string
  firstStep?: boolean
  history?: any
  createInvoice?: any
  steps?: any
  enableButton?: any
  disableButton?: any
  buttonLoading?: any
  hasLink?: boolean
  handlePreviousStep?: (e: any) => void
  handleNextStep?: (e: any) => void
}

export const Footer: React.FunctionComponent<Iprops> = props => {
  // console.log(props.disableButton)
  return (
    <footer className={styles.footer}>
      <div className={styles.btnWrapper}>
        <div className={styles.right}>
          {props.hasLink
            ? props.currentStep !== 0 && (
                <Link to={props.previousStep}>
                  <div onClick={(e: any) => props.handlePreviousStep && props.handlePreviousStep(e)} className={'pg-mx-2'}>
                    <Button
                      className={['pg-btnDefault', 'pg-pY-2', 'pg-px-4']}
                      style={{ boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)', border: 'solid 1px #979797', height: '35px' }}
                    >
                      <IconLink
                        icon={arrowGrayRightIcon}
                        linkStyle={{ marginLeft: '10px', marginRight: '10px' }}
                        iconWidth={13}
                        label={t`Previous`}
                      />
                    </Button>
                  </div>
                </Link>
              )
            : props.currentStep !== 0 && (
                <div onClick={(e: any) => props.handlePreviousStep && props.handlePreviousStep(e)} className={'pg-mx-2'}>
                  <Button
                    className={['pg-btnDefault', 'pg-pY-2', 'pg-px-4']}
                    style={{ boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)', border: 'solid 1px #979797', height: '35px' }}
                  >
                    <IconLink icon={arrowGrayRightIcon} linkStyle={{ marginLeft: '10px', marginRight: '10px' }} iconWidth={13} label={t`Previous`} />
                  </Button>
                </div>
              )}
          <Link to={`/${props.homePage}`}>
            <Button className={['pg-btnDefault', 'pg-btnSm']}>{t`Cancel`}</Button>
          </Link>
        </div>
        {props.hasLink ? (
           <div className={`${styles.left}  ${(props.disableButton || !props.enableButton) && 'pg-pointer-events-none'} `}>
           <Link to={props.nextStep}>
             <div
               className={props.finalStep ? styles.largBtn : styles.wrapper}
               onClick={props.finalStep ? props.createInvoice : (e: any) => props.handleNextStep && props.handleNextStep(props.nextStep)}
             >
               <Button
                 className={
                   props.disableButton
                     ? ['pg-btnDisabled', 'pg-btnSm', 'pg-pointer-events-none']
                     : props.enableButton
                     ? ['pg-btnPrimary', 'pg-btnSm']
                     : ['pg-btnDisabled', 'pg-btnSm', 'pg-pointer-events-none']
                 }
                 loading={props.buttonLoading}
                 disabled={props.disableButton}
                 style={props.finalStep ? { width: '159px', direction: 'rtl' } : {}}
               >
                 {props.finalStep ? (
                   <IconLink icon={invoiceIcon} label={t`Issuance`} />
                 ) : (
                   <IconLink icon={arrowLeftIcon} iconWidth={13} label={t`Next`} />
                 )}
               </Button>
             </div>
           </Link>
         </div>

        ) : (
          <div className={`${styles.left} pg-pointer-events-none`}>
            <div
              className={props.finalStep ? styles.largBtn : styles.wrapper}
              onClick={props.finalStep ? props.createInvoice : (e: any) => props.handleNextStep && props.handleNextStep(props.nextStep)}
            >
              <Button
                className={
                  props.disableButton
                    ? ['pg-btnDisabled', 'pg-btnSm', 'pg-pointer-events-none']
                    : props.enableButton
                    ? ['pg-btnPrimary', 'pg-btnSm']
                    : ['pg-btnDisabled', 'pg-btnSm', 'pg-pointer-events-none']
                }
                loading={props.buttonLoading}
                disabled={props.disableButton && !props.enableButton}
                style={props.finalStep ? { width: '159px', direction: 'rtl' } : {}}
              >
                {props.finalStep ? (
                  <IconLink icon={invoiceIcon} label={t`Issuance`} />
                ) : (
                  <IconLink icon={arrowLeftIcon} iconWidth={13} label={t`Next`} />
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    </footer>
  )
}
