import * as React from 'react'
import { t } from 'ttag'

// ui-elements
import { IconLink } from '../../ui-elements/Icon/IconLink'

// styles & icons
import profile from '../../../images/Icons/profileIcons/profile.svg'
import signOut from '../../../images/Icons/commonIcons/signout.svg'
import styles from './NavbarProfileList.module.scss'
import { connect } from 'react-redux'
import { signout } from '../../../services/internal/store/actions'
import { Link } from 'react-router-dom'

export interface Iprops {
  username?: string
  open?: boolean
  signout?: any
}

const NavbarProfileList: React.FunctionComponent<Iprops> = ({ signout }) => {
  return (
    <>
      <li className={`pg-p-2 ${styles.item}`}>
        <Link to={'/nwaccount/profile'}>
          <IconLink icon={profile} iconAlt="profile" label={t`Account`} linkStyle={{ marginRight: '5px', fontSize: '12px' }} />
        </Link>
      </li>
      <li className={`pg-p-2 pg-text-xs ${styles.item}`} onClick={()=>signout()}>
        <IconLink icon={signOut} iconAlt="logout" label={t`Exit`} />
      </li>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    signout: () => dispatch(signout())
  }
}
export default connect(
  null,
  mapDispatchToProps
)(NavbarProfileList)
