import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// ui-elements
import Toggle from '../../../ui-elements/Toggle/Toggle'

// styles
import styles from './FileFiltering.module.scss'
import { setToggle } from '../../../../services/internal/store/actions'

export interface Iprops {
  forFM?: boolean
  onItemClick?: any
  setToggle?: any
  forVM?: boolean
  history?: any
  router?: any
  selection?: any
}

const FileFiltering = ({ forFM, forVM, setToggle, selection ,history}: Iprops) => {
  //console.log(selection.toggle[0], selection.toggle[1])
  return (
    <div className={styles.fileFiltering}>
      <div
        className={forFM ? `pg-mr-11p pg-h-22p pg-leading-snug pg-mt-21p ${styles.option}` : `hide`}
        onClick={() => setToggle({ type: !selection.toggle[0] ? t`Sharing` : `` ,history})}
      >
        <Toggle checked={selection.toggle[0]} name={t`Sharing`} />
        <span className={`pg-mr-10p pg-text-gray-800 pg-align-middle ${styles.text}`}>{t`Sharing`}</span>
      </div>
      <div className={forFM ? `pg-mr-11p pg-h-22p pg-leading-snug pg-mt-21p ${styles.option}` : `hide`} onClick={() => setToggle({type: !selection.toggle[1] ?  t`Trash` :``,history})}>
        <Toggle checked={selection.toggle[1]} name={t`Deleted files`} />
        <span className={`pg-mr-10p pg-text-gray-800 pg-align-middle ${styles.text}`}>{t`Deleted files`}</span>
      </div>
      <div className={forVM ? `pg-mr-11p pg-h-22p pg-leading-snug pg-mt-21p ${styles.option}` : `hide`}>
        <Toggle checked={false} />
        <span className={`pg-mr-10p pg-text-gray-800 pg-align-middle ${styles.text}`}>{t`Show active servers`}</span>
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({ router: state.router, selection: state.selection,history:state.history.history })
const mapDispatchToProps = (dispatch: any) => {
  return {
    setToggle: (value: any) => dispatch(setToggle(value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileFiltering)
