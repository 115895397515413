import React from 'react'

import styles from './Rangebar.module.scss'

export default interface Iprops {
  value?: any
  width?: number
  direction?: any
  step?: number
  min?: number
  max?: number
  name?:string
  updateRange?: (e: string,name?:string) => void
}

export const RangeBar: React.FunctionComponent<Iprops> = ({ value, width, updateRange, direction, step,name ,min,max}) => (

  <div className={styles.styled_range} style={{ direction: direction, width: width }}>

    <input type="range" className={styles.track_range} step={step} style={{ width: width }} value={value} min={min} max={max}/>
    <input
      type="range"
      className={styles.thumb_range}
      style={{ width: width }}
      defaultValue={value}
      value={value}
      min={min} max={max}
      step={step}
      {...(updateRange && { onChange: e => updateRange(e.target.value,name) })}
    />
  </div>
)
