import { put } from 'redux-saga/effects'
import * as actions from '../actions'

import { bottle } from '../../../index'
import { renderVmBill, renderProducts } from '../../utils/vms'
import { splitInfo } from '../../utils/account'
import { t } from 'ttag'

const vm = bottle.container.Vm
const catchError = bottle.container.CatchError
export function* getOses() {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield vm.getOses()

    yield put(actions.setOses(result))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* getVmproducts() {
  try {
    let result = yield vm.getVmproducts()
    let array = renderProducts(result)
    yield put(actions.setVmproducts(result, array))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}
export function* getVmBills(action?: any) {
  try {
    yield put(actions.setVMBillError(false))
    let result = yield vm.getVmBills(action.payload ? action.payload : '')
    let array = renderVmBill(result)
    let splitArray = splitInfo(array)
    yield put(actions.setvmBills(result.length == 0 ? ['empty'] : result, array))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setVMBillError(true))
    // yield put(err && err.errors && actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}

export function* getVmBillsNamed(action?: any) {
  try {
    yield put(actions.setVMBillError(false))
    let result = yield vm.getVmBills(action.payload)
    let array = renderVmBill(result)
    yield put(actions.setvmBillsNamed(result.length > 0 ? result : 'empty', array))
  } catch (err) {
    catchError.throwError(err)
    yield put(err && err.errors && actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setVMBillError(true))
  }
}
export function* getNetwork() {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield vm.networks()
    yield put(actions.setNetwork(result))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* setPromo(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield vm.promo(action.payload.body.vmInfo, action.payload.promo)
    yield put(actions.setNetwork(result))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* createInvoice(body: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setButtonLoadingState(true))
    let result = yield vm.createInvocie(body.payload)
    yield put(actions.setLoadingState(false))
    window.location.pathname = '/nwvms'
    yield put(actions.setMessage('صورت حسب سرور ایجاد شد'))
    yield put(actions.setButtonLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setButtonLoadingState(false))
  }
}
export function* deleteAndCreateInvoice(payload: any) {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield vm.deleteOs(payload.payload.id)
    yield put(actions.setMessage(result))
    let result2 = yield vm.createInvocie(payload.payload.payload)
    yield put(actions.setLoadingState(false))
    window.location.pathname = '/nwvms'
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* deleteOs(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload.id))
    let result = yield vm.deleteOs(action.payload.id)
    yield put(actions.setvmBills(action.payload.vmArray))
    yield put(actions.setMessage('صورت حساب درحال حذف شدن است'))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  }
}

export function* deleteVm(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload.id))
    let result = yield vm.deleteVm(action.payload)
    yield put(actions.setMessage('سرور حذف شد'))
    window.location.pathname = '/nwvms'
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  }
}

export function* deleteUpgradeVm(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload))
    let result, result2, result3
    let first = yield vm.power(action.payload.id, 'poweroff')
    if (first) result = yield vm.deleteVm(action.payload.tmpId)
    if (result) result2 = yield vm.updateVm(action.payload.id)
    if (result2) result3 = yield vm.retryVm(action.payload.id)
    yield put(actions.setMessage('سرور حذف و دوباره ایجاد شد'))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  }
}

export function* power(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload.id))
    action.payload.powerAction == 'poweroff' || action.payload.powerAction == 'shutdown'
      ? yield put(actions.setMessage('سرور درحال خاموش شدن'))
      : yield put(actions.setMessage('سرور درحال روشن شدن'))
    let result = yield vm.power(action.payload.id, action.payload.powerAction)
    yield put(actions.setLoadingId(null))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingId(null))
    yield put(actions.setLoadingState(false))
  }
}
export function* activeIdle(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload.id))

    let result = yield vm.activeIdle(action.payload.id, action.payload.vmBillStatus)
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  }
}
export function* retryVm(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload))
    yield put(actions.setMessage(t`please wait`))
    let result = yield vm.retryVm(action.payload)
    setTimeout(yield put(actions.setLoadingState(false)), 5000)
    yield put(actions.setLoadingId(null))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  }
}
export function* renewalVm(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload.id))
    let result = yield vm.renewal(action.payload.id)
    yield put(actions.setMessage('صورتحساب صادر شد'))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  }
}
export function* renewalDelete(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload))
    let result = yield vm.renewalDelete(action.payload)
    yield put(actions.setMessage('صورتحساب لغو شد'))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  }
}
export function* changeVmBillPlan(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload.id))
    let result = yield vm.changeVmBillPlan(action.payload.id, action.payload.plan, action.payload.period)
    yield put(actions.setMessage('صورتحساب صادر شد'))

    let pathname = window.location.pathname.split('/setting')[0]
    window.location.replace(pathname)

    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  }
}
export function* calculateLeftCredit(action: any) {
  try {
    let result = yield vm.calculateLeftCredit(action.payload.id)
    let value = result.value
    yield put(actions.setLeftCredit(result.value))
  } catch (err) {
    catchError.throwError(err)
    // yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}
export function* cancelChanges(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingId(action.payload.id))
    let result = yield vm.cancelChanges(action.payload.id)
    yield put(actions.setMessage('صورتحساب لفو شد'))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setLoadingId(null))
  }
}
export function* updateVm(action: any) {
  try {
    yield vm.updateVm(action.payload.id)
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}
export function* changeVmInfo(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield vm.changeVmInfo(action.payload.id,action.payload.body)
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))

  }
}

