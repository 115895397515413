import React from 'react'

// ui-elements
import { EnhanceDropdown as enhancer } from '../../../../ui-elements/Dropdown/EnhanceDropdown'
import Dropdown from '../../../../ui-elements/Dropdown/Dropdown'

// styles
import styles from './Card.module.scss'

export interface Iprops {
  icon?: string
  footerData?: any
  children: any
  className?: string
  selected?: boolean | ''
  withoutHover?: boolean
  active?: boolean
  version?: string[]
  activeOs?: any
  data?: any
  hasActiveFooter?: any
  isOsCard?: boolean
  timed?: boolean
  onClickCard?: (e: any) => void
}

const Card: React.FunctionComponent<Iprops> = props => {
  let button = <div className={props.selected ? `pg-text-center ${styles.selected}` : `pg-text-center`}> {props.activeOs}</div>
  const EnhancedDropdown = enhancer(Dropdown)
  let style = [`${styles.item}`, props.className]

  if (!props.selected) {
    if (props.withoutHover || props.active) {
      style.push(`${styles.withoutHover}`, `${styles.active}`)
    }
  } else {
    style.push(`${styles.selected}`)
  }

  return (
    <div
      className={style.join(' ')}
      {...(props.onClickCard &&
        !props.version && { onClick: () => props.onClickCard && props.onClickCard(props.data ? props.data : props.footerData) })}
    >
      <div className={props.timed ? styles.top2 : styles.top}>{props.children}</div>
      <footer className={[styles.footer, props.isOsCard ? styles.borderFull : '', props.hasActiveFooter === false ? styles.height : styles.active].join(' ')}>
        {props.footerData && props.footerData}
        {props.version && (
          <div className={'pg-w-full'}>
            <EnhancedDropdown data={props.version} noButton={true} button={button} width={'100%'} marginLeft={10} />
          </div>
        )}
      </footer>
    </div>
  )
}

export default Card
