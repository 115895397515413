import * as React from 'react'

// ui-elements
import { Icon } from '../Icon/Icon'

// icons
import errorIcon from '../../../images/Icons/commonIcons/error.svg'

// styles
import styles from './Input.module.scss'

export default interface Iprops {
  placeholder?: string
  onChange?: (e: any) => void
  onBlur?: () => void
  ref?:any
  label?: string
  disabled?: boolean
  message?: string
  error?: boolean
  onErr?: boolean
  success?: boolean
  withIcon?: boolean
  icon?: string
  style?: object
  name?: string
  type?: string
  value?: string | number
  onClick?: any
  wrapperRef?: any
  className?: string
  onKeyDown?: any
  errIcon?: boolean
  fontEn?: boolean
  noLabel?: boolean
  noMargin?: boolean
  autoComplete?: any
  dir?: any
}

export const TextInput = ({
  placeholder,
  onChange,
  ref,
  dir,
  wrapperRef,
  name,
  errIcon,
  label,
  noLabel,
  value,
  type = 'text',
  disabled = false,
  message,
  onBlur,
  error = false,
  noMargin,
  fontEn = false,
  success,
  autoComplete,
  withIcon,
  onErr = false,
  icon,
  onKeyDown,
  onClick,
  className,
  style
}: Iprops) => {
  return (
    <div
      className={
        error
          ? `${styles.inputWrapper} ${styles.error} ${className}`
          : success
          ? `${styles.inputWrapper} ${styles.success} ${className}`
          : disabled
          ? `${styles.inputWrapper} ${styles.disabled} ${className}`
          : `${styles.inputWrapper} ${className}`
      }
      style={style}
    >
      {!noLabel && <label className={styles.label}>{label}</label>}
      <input
        autoComplete={autoComplete ? autoComplete : name}
        type={type}
        dir={dir}
        onKeyDown={onKeyDown}
        ref={wrapperRef}
        className={
          onErr
            ? [styles.textInput, styles.onErr, 'pg-margin-0', fontEn && styles.textInputEn].join(' ')
            : withIcon
            ? [styles.textInput, fontEn && styles.textInputEn, noMargin && styles.noMargin].join(' ')
            : [styles.textInput, styles.reset, fontEn && styles.textInputEn, noMargin && styles.noMargin].join(' ')
        }
        placeholder={placeholder}
        style={{ margin: '0!important' }}
        onClick={onClick}
        value={value}
        name={name}
        disabled={disabled}
        onChange={(e: any) => onChange && onChange(e)}
        onBlur={() => onBlur && onBlur()}
      />
      {!errIcon && (
        <>
          <Icon className={withIcon ? styles.withIcon : `hide`} src={icon} />
          <span className={error ? styles.shoowErorrMsg : `hide`}>
            <Icon src={errorIcon} />
            <span>{message}</span>
          </span>
        </>
      )}
    </div>
  )
}
