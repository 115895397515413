import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { FMModal } from '../FMModal/FMModal'
import { ContentBody } from '../../../Content/ContentBody'
import { Button } from '../../Button/Button'
import { navigateObject } from '../../../Content/Content'
import { Breadcrumb } from '../../../Breadcrumb/Breadcrumb'
import { Icon } from '../../Icon/Icon'

//styles and icons
import loadingIcon from '../../../../images/loading/tail-spin.svg'
import styles from './MoveFile.module.scss'

//services
import { moveDocuments, getModalDocuments, setParentId, setModalSelections, setHistory } from '../../../../services/internal/store/actions'
import { IGetDocumentsInput, IMoveDocumentsInput } from '../../../../services/internal/repositories/documents'

export interface Iprops {
  showModal?: boolean
  document?: any
  modalSelection?: number
  selection?: Array<number>
  setModalSelections: any
  parentId?: number
  setHistory?: any
  loading?: boolean
  getModalDocuments: (getInput: IGetDocumentsInput) => void
  handleClose: () => void
  moveDocuments: (moveInput: any) => void
  setParentId: (parentId: number) => void
}

export interface Istate {
  name: string
  description: string
  showToast: boolean
  message: string
  fileId: number
  tableData: any
  lastChild: boolean
  history: any
  id: any
}

class MoveFile extends React.Component<Iprops, Istate> {
  constructor(props: Iprops) {
    super(props)
    this.state = {
      name: '',
      description: '',
      showToast: false,
      message: '',
      fileId: 0,
      tableData: [],
      history: [],
      lastChild: false,
      id: 1
    }
  }

  componentDidMount() {
    if (!window.location.pathname.split('nwfm')[1]) this.onGetDocument(false, '/')
    else this.onGetDocument(true, window.location.pathname.split('nwfm')[1])
    this.setState({ tableData: this.props.document.documents })
    this.props.setHistory([{ title: `Main Folder`, link: '/', active: false, onClick: this.onGetDocument }])

    if (this.props.modalSelection !== 1) this.props.setModalSelections(1)
  }

  onGetDocument = (isChildren?: boolean, path?: any, id?: number) => {
    if (path !== '/') {
      if (id) this.props.setParentId(id)
      this.props.getModalDocuments({ isChildren: true, path, modal: true, id })
    } else {
      this.props.setModalSelections(1)
      this.props.setParentId(1)
      this.props.getModalDocuments({ modal: true, id })
    }
    this.setState({
      history: {
        title: name,
        link: path,
        active: false,
        onClick: this.onGetDocument,
        parent: true,
        id: id
      }
    })
  }
  /**
   * gets data and makes an obj
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.document.modal_documents.length > 0) this.setState({ tableData: nextProps.document.modal_documents })
    if (nextProps.document.lastChild) {
      if (this.props.modalSelection !== this.state.id) this.props.setModalSelections(this.state.id)
    }
  }

  handleNavigate = ({ e, name, id }: navigateObject) => {
    this.setState({ id })
    let history = this.state.history
    let parent = 1
    let path
    let item
    if (e.target.tagName != 'INPUT') {
      if (id && this.props.modalSelection !== id) this.props.setModalSelections(id)
      let discriminator = this.props.document.modal_documents.filter((obj: any) => {
        return obj.name == name
      })
      console.log(discriminator)
      if (discriminator[0] || !this.state.lastChild) {
        if (discriminator[0].parent) parent = discriminator[0].parent.id
        path = discriminator[0].fullPath
        item = discriminator[0]
        discriminator = discriminator[0].discriminator
      }
      console.log(discriminator)
      if (discriminator === 'D') {
        this.onGetDocument(true, path, id)
      }
    }
  }

  moveDocument = () => {
    if (this.state.id && this.props.selection) {
      this.props.moveDocuments({ targetId: this.state.id, documentIds: this.props.selection })
      this.props.handleClose()
    }
  }

  render() {
    const { showModal, handleClose, loading, modalSelection } = this.props
    let MainTitleArray: any = [{ urlPath: 'nwfm', title: t`Main Folder` }]
    let style = localStorage.getItem('__language') == 'en' ? { marginRight: 5 } : { marginLeft: 5 }

    return (
      <FMModal
        show={showModal}
        handleClose={handleClose}
        flexDirection={'column'}
        width={640}
        title={t`Transfer`}
        formDescription={t`Move 1 item to ...`}
      >
        <Breadcrumb history={this.state.history} modal={true} parentId={this.props.parentId} MainTitleArray={MainTitleArray} />
        <div className={[styles.move, modalSelection && styles.selected].join(' ')}>
          {!loading ? (
            <ContentBody
              view={'grid'}
              tableData={this.state.tableData}
              isMoveModal={true}
              dropdown={false}
              checkbox={false}
              modalSelection={modalSelection}
              hasHeader={false}
              handleNavigate={this.handleNavigate}
            />
          ) : (
            <div className={styles.loading}>
              {loading ? (
                <>
                  <Icon src={loadingIcon} style={{ padding: '4px' }} /> {t`Loading ...`}
                </>
              ) : (
                'No Data Was Found'
              )}
            </div>
          )}
        </div>

        <div className={styles.submitButton}>
          <Button
            className={[
              this.props.modalSelection !== 0 && !this.props.modalSelection ? 'pg-btnPrimary  pg-btnPrimaryOutline' : 'pg-btnPrimary',
              'pg-btnSm'
            ]}
            style={style}
            disabled={this.props.modalSelection !== 0 && !this.props.modalSelection}
            onClick={() => this.moveDocument()}
          >
            {t`Transfer`}
          </Button>
          <Button className={['pg-btnDefault', 'pg-btnSm']} onClick={handleClose}>
            {t`Cancel`}
          </Button>
        </div>
      </FMModal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  document: state.document,
  loading: state.loading.modalLoading,
  modalSelection: state.selection.modalSelect,
  selection: state.selection.selection,
  parentId: state.document.parentId
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    getModalDocuments: (value: IGetDocumentsInput) => dispatch(getModalDocuments(value)),
    moveDocuments: (value: any) => dispatch(moveDocuments(value)),
    setHistory: (value: any) => dispatch(setHistory(value)),
    setModalSelections: (value: Array<number>) => dispatch(setModalSelections(value)),
    setParentId: (value: number) => dispatch(setParentId(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveFile)
