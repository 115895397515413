import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export function useOutsideCloser(ref: any, toggle: any, isOpen: boolean, closable?: boolean) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target) && isOpen) {
      toggle(false)
    }

    if (ref.current && !ref.current.contains(event.target) && closable) {
      toggle()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

/**
 * Component that alerts if you click outside of it
 */
export function OutsideCloser(props: any) {
  const wrapperRef = useRef(null)
  useOutsideCloser(wrapperRef, props.toggle, props.toggleState, props.closable)
  return (
    <div ref={wrapperRef} className={'pg-flex'}>
      {props.children}
    </div>
  )
}

export default OutsideCloser
