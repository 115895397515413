import * as React from 'react'
import { Modal } from '../Modal'
import { TextInput } from '../../Input/Input'
import { Button } from '../../Button/Button'

import styles from './FMmodal.module.scss'
import OutsideCloser from '../../../Navbar/DropDown/OutsideCloser'

export default interface Iprops {
  show?: boolean
  title?: string
  width?: number
  formDescription?: string
  children?: any
  buttonCloser?: boolean
  flexDirection?: string
  handleClose?: () => void
}

export const FMModal: React.FunctionComponent<Iprops> = ({
  show,
  title,
  formDescription,
  width,
  handleClose,
  children,
  flexDirection,
  buttonCloser
}) => {
  return (
    <Modal show={show} title={title} width={width} handleClose={handleClose}>
      {buttonCloser ? (
        <div className={styles.uploadModal}>
          {formDescription && (
            <div className={'fa pg-mt-3'}>
              <span>{formDescription}</span>
            </div>
          )}
          <div className={flexDirection && flexDirection === 'column' ? styles.column : styles.row}>{children}</div>
        </div>
      ) : (
        <OutsideCloser toggle={handleClose} toggleState={show} closable={true} id={'modal'}>
          <div className={styles.uploadModal}>
            {formDescription && (
              <div className={'fa pg-mt-3'}>
                <span>{formDescription}</span>
              </div>
            )}
            <div className={flexDirection && flexDirection === 'column' ? styles.column : styles.row}>{children}</div>
          </div>
        </OutsideCloser>
      )}
    </Modal>
  )
}
