import { formatPrice } from './formatPrice'
import { t } from 'ttag'
import { translate } from './translate'
import { formatBytes } from './formatBytes'
import { formatTime } from './formatTime'

export const renderInvoice = (invoice: any,product:any) => {

  let vmBill = invoice && invoice[0] && invoice[0].vmBill
  let id = invoice ? (invoice.id ? invoice.id : invoice[0].id) : ''
  let price = invoice && invoice.invoiceItems && invoice.invoiceItems.price
  let invoice2 = invoice
  let domainName = invoice && invoice[0] && invoice[0].host && invoice[0].host.domain && invoice[0].host.domain.domainName
  if (!domainName) domainName = '.com'

  let name = invoice && invoice[0] && invoice[0].invoice && invoice[0].invoice.product && invoice[0].invoice.product.name
  let array: any = []
  if (product != 'cfs') invoice = invoice[0].invoice
  let invoiceItems = invoice && invoice.invoiceItems
  let isDay = invoice.recurringPeriod === 'DAY'
  if (invoiceItems) {
    if (invoiceItems.length > 0) {
      if (invoiceItems[1].price || invoiceItems[1].price === 0 > invoiceItems[0].price || invoiceItems[0].price === 0) price = invoiceItems[1].price
    } else price = 0
    let invoiceTable: any = []
    let tax = invoiceItems
      .filter((item: any) => {
        return item.priceComponent.priceType == 'SURCHARGE' && item.priceComponent.title == 'TAX'
      })
      .map((item: any, index: any) => (item.price * 2) / 3)
    let others = invoiceItems
      .filter((item: any) => {
        return item.priceComponent.priceType == 'SURCHARGE' && item.priceComponent.title == 'TAX'
      })
      .map((item: any, index: any) => item.price / 3)
    let totFixedDiscount = invoiceItems
      .filter((item: any) => {
        let { priceType, title, valueApplication } = item.priceComponent
        return priceType == 'DISCOUNT' && title != 'GIFT' && valueApplication == 'FIXED'
      })
      .reduce(
        (a: any, b: any) => {
          return { price: a.price + b.price }
        },
        { price: 0 }
      ).price

    let totGift = invoiceItems
      .filter((item: any) => {
        let { priceType, title, valueApplication } = item.priceComponent
        return priceType == 'DISCOUNT' && title == 'GIFT' && valueApplication == 'FIXED'
      })
      .reduce(
        (a: any, b: any) => {
          return { price: a.price + b.price }
        },
        { price: 0 }
      ).price
    let totSurchargeNotTax = invoiceItems
      .filter((item: any) => {
        let { priceType, title } = item.priceComponent
        return priceType == 'SURCHARGE' && title != 'TAX'
      })
      .reduce(
        (a: any, b: any) => {
          return { price: a.price + b.price }
        },
        { price: 0 }
      ).price
    let totPercentDiscount = 0
    let invoiceDiscountPercent = invoiceItems.filter(
      (item: any) => item.priceComponent.valueApplication == 'PERCENT' && item.priceComponent.priceType == 'DISCOUNT'
    )
    if (invoiceDiscountPercent && invoiceDiscountPercent.length > 0) {
      if (invoiceDiscountPercent.length == 1) {
        totPercentDiscount = invoiceDiscountPercent[0].price
      } else {
        let sumDiscount = invoiceDiscountPercent.reduce((a: any, b: any) => a.price + b.price)
        totPercentDiscount = sumDiscount
      }
    }
    let basePrice = 0
    if (invoiceItems.length > 0) {
      basePrice = invoiceItems[0].price
    }
    invoiceItems.map((each: any) => {
      if (each.priceComponent && each.priceComponent.priceType == 'BASE') basePrice = each.price
    })
    let totalAmountWithoutSurchage = basePrice - totPercentDiscount - totFixedDiscount - totGift + totSurchargeNotTax
    invoiceItems.map((each: any, index: number) => {
      let unit =
        each.priceComponent.title == 'CPU' ? ' Core' : each.priceComponent.title == 'RAM' ? ' GB' : each.priceComponent.title == 'DISK' ? ' GB' : ''
      if (each.priceComponent.title !== 'TAX' && each.price)
        invoiceTable.push({
          index: each.priceComponent.title === 'BASE' ? 1 : invoiceTable.length + 1,
          price:
            product != 'cfs'
              ? each.priceComponent.title === 'BASE'
                ? name
                : translate(`${each.priceComponent.title}`)
              : translate(`${each.priceComponent.title}`),
          amount: product != 'cfs' ? (each.quantity ? each.quantity + unit : '-') : '-',
          rate: isDay ? formatPrice(each.price / each.quantity) : formatPrice(each.price),
          normalPrice: formatPrice(each.price)
        })
    })
    if (invoiceItems.length > 0) {
      invoiceTable.push(
        {
          index: ' ',
          price: ' ',
          amount: ' ',
          rate: t`Total - tax`,
          normalPrice: formatPrice(totalAmountWithoutSurchage)
        },
        {
          index: ' ',
          price: ' ',
          amount: ' ',
          rate: t`6 percent tax`,
          normalPrice: formatPrice(tax)
        },
        {
          index: ' ',
          price: ' ',
          amount: ' ',
          rate: t`3 percent others`,
          normalPrice: formatPrice(others)
        },
        {
          index: '  ',
          price: '  ',
          amount: '  ',
          rate: t`Total Price`,
          normalPrice: formatPrice(invoice.payablePrice)
        }
      )
    } else
      invoiceTable.push({
        index: ' ',
        price: ' ',
        amount: ' ',
        rate: t`Total - tax`,
        normalPrice: formatPrice(totalAmountWithoutSurchage)
      })

    let title = invoice.product.name
    console.log(product, invoice2)
    if (product == 'hosting') title = invoice.product.name + t` Domain of ` + domainName
    else if (product == 'domain') title = t` Domain of ` + invoice2 && invoice2[0] && invoice2[0].domain.domainName
    else if (product == 'cws') title = vmBill.name
    title = array = {
      name: invoice.product.name,
      totalAmountWithoutSurchage,
      title,
      refCode: invoice.refCode,
      id: id ? id : invoice.id,
      quota: formatBytes({ bytes: JSON.parse(invoice.product.jsonInfo).quota }),
      persianTo: formatTime(invoice.to, 'jYYYY/jM/jD درساعت HH:MM:SS'),
      persianFrom: formatTime(invoice.from, 'jYYYY/jM/jD درساعت HH:MM:SS'),
      payablePrice: invoice.payablePrice,
      totalPrice: invoice.totalPrice,
      persianCreatedAt: formatTime(invoice.createdAt, 'jYYYY/jM/jD درساعت HH:MM:SS'),
      persianApprovedAt: formatTime(invoice.approvedAt, 'jYYYY/jM/jD درساعت HH:MM:SS'),
      rate: price,
      paid: invoice.paid,
      off: invoiceItems.length > 2,
      invoiceTable
    }
  }
  invoiceItems
    .filter((item: any) => {
      return item.priceComponent.priceType == 'SURCHARGE' && item.priceComponent.title == 'TAX'
    })
    .map((item: any, index: any) => {
      array['tax'] = (item.price * 2) / 3
      array['others'] = item.price / 3
    })
  return array
}
