import { put, call } from 'redux-saga/effects'
import * as actions from '../actions'

import { bottle } from '../../../index'
import { AnyAction } from 'redux'
import { getErrorMessage } from '../../utils/getErrorMessage'

const auth = bottle.container.Auth
const storage = bottle.container.Storage
const catchError = bottle.container.CatchError
export function* login(action: AnyAction) {
  try {
    yield put(actions.setLoadingState(true))
    const { token, user } = yield auth.login(action.payload.email, action.payload.password)
    if (token) {
      localStorage.setItem('csrf_token', token)
      yield put(actions.setToken({ token }))
      yield put(actions.setUserCredentials({ username: user.username }))
      yield storage.setItem('user', user.username)
      let expireDate = new Date(new Date().setTime(new Date().getTime() + 2 * 24 * 60 * 60 * 1000))
      document.cookie = `token="${localStorage.getItem('csrf_token')}";Domain=.persiangig.com;expires=${expireDate};path=/;SameSite=None; Secure`
      yield put(actions.setLoadingState(false))
    }
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setLoadingState(false))
    let msg = err && err.errors && err.errors[0].msg ? err && err.errors && err.errors[0].msg : err

    let errorCode = err && err.errors && err.errors && err.errors[0].code
    if (err && err.message) {
      msg = err && err.message
      errorCode = 2
    }

    msg = getErrorMessage(errorCode, msg)
    if (errorCode === 5002) {
      yield put(actions.setEmail(action.payload.email))
      yield put(actions.setLockedAccount(true))
    }
    yield put(actions.setError(msg))
    // yield put(actions.setLoadingState(false))
  }
}
export function* reactivate(action: AnyAction) {
  try {
    yield put(actions.setLoadingState(true))
    const result = yield auth.reactivate(action.email)
    if (result == 200) {
      yield put(actions.setMessage('لینک فعال سازی به ایمیل شما فرستاده شد.'))
    }
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    //console.log(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* forgetPassword(action: AnyAction) {
  try {
    yield put(actions.setLoadingState(true))
    const result = yield auth.forgetPassword(action.email.email)
    if (result == 200) {
      yield put(actions.setMessage('لینک بازیابی کلمه عبور به ایمیل شما فرستاده شد'))
    }
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    //console.log(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* register(action: AnyAction) {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield auth.register(action.email, action.username, action.password, action.reCaptcha)
    yield put(actions.setMessage('ثبت نام با موفقیت انجام شد'))
    yield put(actions.setRegisterState(true))
    window.location.pathname = '/nwregister/success'
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* signout() {
  try {
    yield auth.signout()
    yield put(actions.setUserCredentials({ username: '' }))
    yield storage.removeItem('csrf_token')
    window.location.replace('/nwlogin')
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setUserCredentials({ username: '' }))
    yield storage.removeItem('csrf_token')
    window.location.replace('/nwlogin')
    yield put(actions.setLoadingState(false))
  }
  location.reload()
}

export function* getUserInfo() {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield auth.getUserInfo()
    yield put(actions.setUserInfo(result))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* changePassword(action: AnyAction) {
  try {
    yield put(actions.setLoadingState(true))
    yield auth.changePassword(action.currentPassword, action.newPassword)
    yield put(actions.setChangePasswordSuccess(true))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* getProducts() {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield auth.getProducts()
    yield put(actions.setProducts(result))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
