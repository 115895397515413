import React, { Component } from 'react'

import Invoice from '../../Invoice/Invoice'
import { TrContainter } from './TrContainter'

import styles from '../Table.module.scss'
import { Route } from 'react-router-dom'
import { Draggable } from 'react-beautiful-dnd'

export class TableRow extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      hovered: false,
      expanded: false,
      loading: false
    }
  }

  setHovered = () => {
    this.setState({ hovered: true, hoverable: true })
  }

  setUnHovered = () => {
    this.setState({ hovered: false, hoverable: false })
  }

  renderInvoice = (e: any) => {
    this.setState({ expanded: !this.state.expanded, loading: !this.state.loading, id: e })
    this.props.handleOnRowClick(e, this.props.index)
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.invoice && nextProps.invoice.length > 0) {
      this.setState({ loading: false, name: nextProps.invoice.name })
    }
  }

  render() {
    const { item, invoiceClassName, isMoveModal, width, index, expandableRow, handleOnRowClick, selectableRows } = this.props
    const { expanded } = this.state
    return (
          <>
            <TrContainter
              expandableRow={expandableRow}
              item={item}
              hoverable={this.state.hoverable}
              isMoveModal={isMoveModal}
              expanded={expanded}
              setHovered={this.setHovered}
              setUnHovered={this.setUnHovered}
              renderInvoice={this.renderInvoice}
              handleOnRowClick={handleOnRowClick}
              selectableRow={selectableRows}
              width={width}
              moveRow={this.props.moveRow}
              moveDocuments={this.props.moveDocuments}
              {...this.props}
            />
            {expandableRow && expanded ? (
              <Invoice
                index={index}
                id={this.state.id}
                invoiceObj={this.props.invoice[+this.props.indexNumber] ? this.props.invoice[+this.props.indexNumber] : []}
                className={styles.show}
                invoiceClassName={invoiceClassName}
              />
            ) : (
              <tr />
            )}
          </>
 
    )
  }
}
