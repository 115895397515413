import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { t } from 'ttag'

import hpcIcon from '../../../../images/Icons/vmIcons/plansIcon/hpc.svg'
import longIcon from '../../../../images/Icons/vmIcons/plansIcon/long.svg'
import bigger2 from '../../../../images/Icons/commonIcons/bigger.black.svg'
import monthlyIcon from '../../../../images/Icons/vmIcons/plansIcon/monthly.svg'
import sixMonthIcon from '../../../../images/Icons/vmIcons/plansIcon/6-month.svg'
import threeMonthIcon from '../../../../images/Icons/vmIcons/plansIcon/3-month.svg'

//offline data
import oses from '../OfflineData/Oses.json'
import products from '../OfflineData/products.json'

import TableOrder from './TableOrder'
import SelectOs from '../SelectBoxes/SelectOs'
import SelectMonth from '../SelectBoxes/SelectMonth'
import { Footer } from '../../OldOrder/Footer/Footer'
import { Icon } from '../../../ui-elements/Icon/Icon'
import { Button } from '../../../ui-elements/Button/Button'
import ChooseNetworkCard from '../../OldOrder/Steps/ChooseNetworkCard'

import { renderProducts } from '../../../../services/internal/utils/vms'

// styles
import style from '../TableOrder.module.scss'

//actions
import {
  getOses,
  selectOs,
  goToNextStep,
  getVmproducts,
  createInvoice,
  goToPreviousStep,
  selectVsCard,
  setPromo,
  setName,
  setServiceDuration,
  setVmproducts,
  setOses,
  setFinalPrice,
  doneFirstStep
} from '../../../../services/internal/store/actions'

class FirstPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      oses: props.oses ? props.oses : [],
      expanded: false,
      activeRow: '',
      index: 0,
      finalInvoice: [],
      disableButton: true,
      stepsPathName: ['choose-plan', 'final-step'],
      stepsMainName: [t`Select a Plan`, t` Submit`]
    }
  }

  componentDidMount() {
    if (window.location.pathname.includes('nwvms') && !this.props.finalPrice) {
      this.props.getOses()
      this.props.getVmproducts()
    } else {
      let array = renderProducts(products)
      this.props.setOses(oses)
      this.props.setVmproducts(products, array)
    }
    window.location.pathname.includes('nwvms')
      ? this.props.history.push(`/nwvms/order/choose-plan`)
      : this.props.history.push(`/nwlandings/vm-campaign/choose-plan`)
  }

  goToNextStep = () => {
    this.props.doneFirstStep(true)
    window.location.pathname.includes('nwvms')
      ? this.props.history.push(`/nwvms/order/final-step`)
      : this.props.history.push(`/nwlandings/vm-campaign/choose-plan`)
    this.props.goToNextStep()
  }

  saveData = () => {
    let { cpu, ram, disk } = this.props.vsCardMain
    ram = parseFloat(ram)
    // console.log('%%',ram)
    let finalInvoice = {
      name: `${this.props.vmName}`,
      nicInfos: [{ ipCount: 1, network: this.props.networkName }],
      os: `${this.props.os[1]}`,
      plan: {
        additionalInfo: JSON.stringify({ cpu: cpu, ram: ram, disk: disk, managed: false }),
        id: this.props.vsCardMain.id,
        jsonInfo: JSON.stringify({ plan_type: this.props.serviceDuration })
      }
    }
    localStorage.setItem('invoice', JSON.stringify(finalInvoice))
  }

  render() {
    let steps = this.state.stepsMainName

    return (
      <React.Fragment>
        <div className={`  pg-pt-2 pg-mt-2 `}>
          <div className={'pg-flex  pg-mb-4 pg-w-full laptop-max:pg-flex-col'}>
            <div className={'pg-ml-2 pg-px-6 pg-bg-white pg-pt-2 pg-w-full  pg-shadow-sm laptop-max:pg-ml-0 laptop-max:pg-mb-2 '}>
              <SelectOs steps={steps} />
            </div>
            <div className={'pg-mr-2 pg-px-6 pg-w-full pg-bg-white pg-pt-2  pg-shadow-sm laptop-max:pg-mr-0'}>
              <SelectMonth steps={steps} />
            </div>
          </div>
          <div className={`pg-bg-white pg-px-8 pg-pt-8  pg-shadow-sm pg-w-full ${style.tableScroll}`}>
            <TableOrder />
          </div>
        </div>
        <div className={'pg-mb-4 pg-bg-white pg-px-6 pg-mt-2 pg-py-2   pg-shadow-sm pg-rounded-sm'}>
          <div onClick={() => this.setState({ expanded: !this.state.expanded })}>
            <span>{t`Network Details`}</span>
            <span className={'pg-mx-4'}>
              <Icon className={this.state.expanded ? style.collapseIcon : style.expandIcon} src={bigger2} />
            </span>
          </div>
          {this.state.expanded && (
            <div className={'pg-mb-4 pg-p-6'}>
              <ChooseNetworkCard steps={steps} noStep={true} />
            </div>
          )}
        </div>

        {window.location.pathname.includes('landings') ? (
          <div onClick={this.saveData} className={'pg-w-full'}>
            <Link to={'/nwvms/order/final-step'}>
              <Button
                className={ !this.props.finalPrice ? ['pg-btnDisabled', 'pg-pointer-events-none'] : ['pg-btnPrimary']}
                disabled={ !this.props.finalPrice}
                style={{ width: '100%', height: '35px' }}
              >
                ثبت سفارش
              </Button>
            </Link>
          </div>
        ) : (
          <Footer
            homePage={'nwvms'}
            hasLink={true}
            handleNextStep={this.goToNextStep}
            nextStep={'/nwvms/order/final-step'}
            finalStep={this.props.currentStep == 4}
            currentStep={this.props.currentStep}
            enableButton={this.props.serviceDuration==='DAY' || this.props.finalPrice}
            disableButton={ !this.props.finalPrice}
            steps={this.props.steps}
          />
        )}
      </React.Fragment>
    )
  }
}

export const GetDurationIcon = (duration: string, isWhite?: boolean) => {
  switch (duration) {
    case 'MONTH':
      return <Icon width={25} src={monthlyIcon} />
    case 'YEAR':
      return <Icon width={25} src={longIcon} />
    case 'TMONTH':
      return <Icon width={25} src={threeMonthIcon} />
    case 'SMONTH':
      return <Icon width={25} src={sixMonthIcon} />
    case 'DAY':
      return <Icon width={25} src={hpcIcon} />
    default:
      return <Icon width={25} src={monthlyIcon} />
  }
}
const mapStateToProps = (state: any) => ({
  os: state.vm.os,
  oses: state.vm.oses,
  currentStep: state.vm.currentStep,
  vmName: state.vm.vmName,
  finalPrice: state.vm.finalPrice,
  vmProducts: state.vm.vmProducts2,
  vsCardMain: state.vm.vsCardMain,
  serviceDuration: state.vm.serviceDuration,
  steps: state.vm.steps,
  networkName: state.account.networkName
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOses: () => dispatch(getOses()),
    setOses: (value: any) => dispatch(setOses(value)),
    doneFirstStep: (value: any) => dispatch(doneFirstStep(value)),
    setVmproducts: (value1: any, value2: any) => dispatch(setVmproducts(value1, value2)),
    setPromo: (value: any) => dispatch(setPromo(value)),
    setFinalPrice: (value: any) => dispatch(setFinalPrice(value)),
    setName: (value: any) => dispatch(setName(value)),
    selectVsCard: (value: any) => dispatch(selectVsCard(value)),
    setServiceDuration: (stepNumber: number) => dispatch(setServiceDuration(stepNumber)),
    getVmproducts: () => dispatch(getVmproducts()),
    selectOs: (osId: string, id: any, name: any) => dispatch(selectOs(osId, id, name)),
    goToNextStep: () => dispatch(goToNextStep()),
    goToPreviousStep: () => dispatch(goToPreviousStep()),
    createInvoice: (value: string) => dispatch(createInvoice(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstPage)
