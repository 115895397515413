import React from 'react'

import { DropTarget } from 'react-dnd'

import styles from './Breadcrumb.module.scss'
interface Iprops {
  title?: any
}

const DropppableTitle: React.FunctionComponent<any> = ({ title, dropTarget, isOver }) => {
  return (<span className={isOver ? styles.isOver : ''}>{title}</span>)
}
const collect = (connect: any, monitor: any) => ({
  dropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
  isOver: monitor.isOver()
})
const cardTarget = {
  canDrop({ item }: any, monitor: any) {
    return monitor
  },

  drop({ item, selection, moveDocuments, documents }: any, monitor: any) {}
}

export const TRDND = 'card'
export default DropTarget(TRDND, cardTarget, collect)(DropppableTitle)
