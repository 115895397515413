import React from 'react'
import { t } from 'ttag'

// ui-elements
import { Button } from '../ui-elements/Button/Button'
import { IconLink } from '../ui-elements/Icon/IconLink'
import { formatPrice } from '../../services/internal/utils/formatPrice'
import PayModal from '../ui-elements/Modal/PayModal/PayModal'

// icons & styles
import addCreditIcon from '../../images/Icons/commonIcons/addCredit.svg'

import styles from './Wallet.module.scss'


export const Wallet: React.FunctionComponent<any> = ({ info, openWalletModal, showModal, onCloseModal }) => {
  let balance = info && info.balance
  return (
   <div>
    <div className={styles.giftCard}>
      <div className={styles.addCredit}>
        <span className={styles.faModeTxt}>
          {`میزان اعتبار جاری شما`} <span className={styles.price}>{formatPrice(balance)} </span>می‌باشد.
        </span>
        <span className={styles.enModeTxt}>
          {t`Your Current Credit Score is:`} <span className={styles.price}>۳۴۰,۰۰۰ R</span>
        </span>
        <Button className={['pg-btnDefault', 'pg-btnLg']} onClick={openWalletModal}>
          <IconLink icon={addCreditIcon} label={t`Add Credit`} />
        </Button>
      </div>
      <PayModal showModal={showModal} handleCFClose={onCloseModal} walletPage={true}  payment={false} />
    </div>
   </div>
  )
}
