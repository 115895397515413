import React from 'react'
import { saveLocale, getLocale } from '../../../translate/translate'

// styles
import styles from './LangSwitcher.module.scss'

let lng = getLocale() 
const setLocale = (locale: string) => () => {
  saveLocale(locale)
  window.location.reload()
}

export const LangSwitcher = (language?: any) => {
  if (!lng) {
    saveLocale(language.language)
    // window.location.reload()
  }
  return (
    <div className={styles.langSwitch}>
      <div className={lng == 'en' ? `${styles.item} ${styles.active}` : styles.item} onClick={setLocale('en')}>
        EN
      </div>
      <div className={lng == 'fa' ? `${styles.item} ${styles.active}` : styles.item} onClick={setLocale('fa')}>
        FA
      </div>
    </div>
  )
}
