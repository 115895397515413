import React from 'react'

import styles from './Skeleton.module.scss'

export const VMGridSkeleton: React.FunctionComponent<any> = ({ rowsNumber = 5 ,fullCard}) => {
  return (
    <>
      {Array.from(Array(rowsNumber), (e, i) => (
        <div className={fullCard ? [styles.card,styles.fullCard].join(' '):styles.card} key={i}>
          <div className={'pg-flex pg-justify-between'}>
            <div className={styles.loadingLineSm} />
            <div className={styles.loadingVLineSm} />
          </div>
          <div className={styles.loadingLineM} />
          <div className={'pg-flex'} dir={'rtl'}>
            <div className={'pg-felx pg-w-1/2'}>
              <div className={styles.loadingLineSm} />
              <div className={styles.loadingLineSm} />
            </div>
            <div className={'pg-felx pg-w-1/2'} style={{ direction: 'ltr' }}>
              <div className={styles.loadingLineSm} />
              <div className={styles.loadingLineSm} />
            </div>
          </div>
          <div className={styles.btn} />
        </div>
      ))}
    </>
  )
}
