import React, { useState, useRef } from 'react'
import { t } from 'ttag'
import { XYCoord } from 'dnd-core'
import { useDrag, useDrop, DragPreviewImage } from 'react-dnd'

import { TableItem } from './TableItem'
import { Icon, renderIconSrc } from '../../ui-elements/Icon/Icon'

import styles from '../Table.module.scss'
import bigger from '../../../images/Icons/commonIcons/bigger.black.svg'

export const TrContainter: React.FunctionComponent<any> = ({
  item,
  expanded,
  setHovered,
  setUnHovered,
  renderInvoice,
  children,
  hoverable,
  selection,
  isMoveModal,
  width,
  handleNavigate,
  setEditStatus,
  checkbox,
  showAll,
  handleChange,
  hasDnd,
  onCheck,
  hasIcon,
  editableId,
  moveRow,
  renameText,
  labelShow,
  openModal,
  modalSelection,
  expandableRow,
  handleOnRowClick,
  id,
  selectableRows,
  smPadding = false
}) => {
  const hidden = ['type', 'id', 'fullPath', 'discriminator', 'uuid', 'item', 'genericType', 'prices', 'unlimited', 'core', 'mainName']

  return (
    <tr
      key={item.id}
      className={
        isMoveModal && modalSelection === item.id
          ? [styles.activeRow ? [styles.draggingRow].join(' ') : ''].join(' ')
          : expanded
          ? 'pg-bg-white pg-border-2 pg-border-b-0 pg-border-blue-400'
          : ''
      }
      onMouseEnter={setHovered}
      onMouseLeave={setUnHovered}
      // onDragStart={()=>handleOnRowClick(item.id)}
      {...(handleOnRowClick && { onClick: () => (expandableRow ? renderInvoice(item.id) : handleOnRowClick(item.id)) })}
    >
      {/* {hasDnd && <DragPreviewImage connect={preview} src={baseSrc ? baseSrc : renderIconSrc(item.type)} />} */}
      {Object.keys(item).map((k, i) => {
        if (!hidden.includes(k)) {
          
          return (
            <TableItem
              name={k}
              selectableRows={selectableRows}
              key={i}
              style={{ width: width }}
              editableId={editableId}
              renameText={renameText}
              id={item.id}
              hovered={hoverable}
              hasIcon={hasIcon}
              handleChange={handleChange}
              uuid={item.uuid}
              handleNavigate={k === 'name' && handleNavigate}
              openModal={openModal}
              setEditStatus={setEditStatus}
              label={item[k]}
              itemName={item.mainName}
              item={item}
              selection={selection}
              onCheck={onCheck}
              shareButton={k == 'size'}
              checked={typeof item.id != 'undefined' && selection && selection.includes(item.id)}
              className={
                showAll
                  ? ['show']
                  : k == 'index'
                  ? ['pg-text-center']
                  : k === 'name' || (labelShow && labelShow.includes(k))
                  ? smPadding
                    ? ['smPadding', 'show']
                    : ['show']
                  : smPadding
                  ? ['smPadding']
                  : ''
              }
              checkbox={checkbox === false ? checkbox : k === 'name' ? true : selectableRows && i == 0 ? true : false}
              mimetype={k === 'name' ? item.type : ''}
            />
          )
        }
      })}

      {children}
      {expandableRow && (
        <td className={[styles.show, styles.left].join(' ')} data-name={id} onClick={() => renderInvoice(item.id)}>
          <div className={'pg-px-8'}>
            {t`Details`} <Icon className={expanded ? styles.collapseIcon : styles.expandIcon} src={bigger} />
          </div>
        </td>
      )}
    </tr>
  )
}
