import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'
// components
import Grid from '../Grid/Grid'
import { Table } from '../Table/Table'
import { NoData } from '../NoData/NoData'
import { TableSkeleton } from '../SkeletonLoading/TableSkeleton'
import { GridSkeleton } from '../SkeletonLoading/GridSkeleton'
import useWindowDimensions from '../WindowDimensions/WindowDimensions'

// services
import { formatDate } from '../../services/internal/utils/formatDates'
import { formatBytes } from '../../services/internal/utils/formatBytes'
import { formatType } from '../../services/internal/utils/formatTypes'
import { setTempDocuments, setEditStatus, moveDocuments } from '../../services/internal/store/actions'

//interface
import { ItemInterface } from '../../services/internal/store/reducers/documentReducer'
import { navigateObject } from './Content'
import { Tooltip } from '../ui-elements/Tooltip/Tooltip'
import { trimLongStr } from '../../services/internal/utils/trimLongStr'
import { IMoveDocumentsInput } from '../../services/internal/repositories/documents'
import TableDnd from '../ui-elements/DndTable/Table'
import CardDragLayer from '../ui-elements/DndTable/DragComponents/CardDragLayer'
import { renderIconSrc } from '../ui-elements/Icon/Icon'

export interface Iprops {
  moveDocuments?: any
  moveRow?: any
  setDragnDropState?: any
  view: string
  width?: number
  dropdown?: boolean
  dropDownData?: any
  tableData: ITableItem[]
  onDragStart?: any
  checkbox?: boolean
  preLoading?: boolean
  isMoveModal?: boolean
  hasHeader?: boolean
  username?: string
  loading?: boolean
  loadingStyle?: string
  setDocuments?: any
  smPadding?: boolean
  openModal?: any
  handleNavigate?: ({ e, name, id, uuid, item }: navigateObject) => void
  onCheck?: (id: number, e?: any) => void
  onCheckAll?: () => void
  onOpenCFModal?: () => void
  onSort?: (sortBy: string, type?: string | undefined) => void
  setTempDocuments?: (e: any) => void
  handleChange?: any
  selection?: any
  documentLoading?: any
  selectionNumber?: any
  documentsLength?: any
  modalSelection?: any
  editableId?: any
  renameText?: any
  handleOnRowClick?: any
  onDragEnd?: any
  invoice?: any
}

export interface ITableItem {
  id?: number
  type?: string
  name?: any
  mainName?: any
  discriminator?: string
  fullPath?: string
  created_at?: string
  owner?: any
  size?: string
  uuid?: string
  item?: any
  genericType?: any
  smPadding?: boolean
}
const setLength = (width: number) => {
  if ((width > 767 && width < 869) || width > 1200) return 40
  else return 20
}
const makeArray = (array: any, width: number, username?: string, isCard?: boolean) => {
  let tableData: ITableItem[] = []
  let lang = localStorage.getItem('__language')
  if (array[0] == 'empty') return array
  array.length > 0 &&
    array.map((each: ItemInterface) => {
      let displayName
      if (username && each.owner && each.owner.displayName && each.owner.displayName)
        if (each.owner.displayName === username) displayName = t`Me`
        else displayName = each.owner.displayName
      tableData.push({
        id: each.id,
        type:
          each.genericType && formatType(each.genericType, each.discriminator == 'D')
            ? formatType(each.genericType, each.discriminator == 'D')
            : each.mimeType,
        genericType: each.genericType ? each.genericType : each.mimeType,
        mainName: each.name,
        name:
          each.name && !isCard && each.name.length > setLength(width) ? (
            <Tooltip text={each.name} height={27} paddingX={'2%'} position={'top'}>
              {trimLongStr({ str: each.name })}
            </Tooltip>
          ) : (
            each.name
          ),
        discriminator: each.discriminator,
        fullPath: each.fullPath,
        created_at: formatDate(each.createdAt),
        owner: displayName,
        size: each.size ? formatBytes({ bytes: each.size, lang }) : '---',
        uuid: each.uuid,
        item: each
      })
    })
  return tableData
}

const makeSimpleArray = (array: any) => {
  let tableData: ITableItem[] = []

  array.length > 0 &&
    array.map((each: ItemInterface) => {
      if (each.discriminator === 'D') {
        tableData.push({
          id: each.id,
          mainName: each.name,
          type: each.genericType && formatType(each.genericType, each.discriminator == 'D'),
          name: each.name,
          discriminator: each.discriminator,
          fullPath: each.fullPath
        })
      }
    })
  return tableData
}

export const ContentBody: React.FunctionComponent<Iprops> = ({
  view,
  dropDownData,
  handleNavigate,
  username,
  tableData,
  selection,
  onCheck,
  modalSelection,
  isMoveModal,
  onSort,
  onOpenCFModal,
  loading,
  loadingStyle,
  preLoading,
  editableId,
  renameText,
  selectionNumber,
  setDragnDropState,
  documentsLength,
  moveRow,
  onDragEnd,
  onDragStart,
  setTempDocuments,
  openModal,
  handleOnRowClick,
  invoice,
  documentLoading,
  handleChange,
  ...rest
}) => {
  const header = [t`Name`, t`Date`, t`Owner`, t`Content`]
  const { width } = useWindowDimensions()

  tableData = isMoveModal ? makeSimpleArray(tableData) : makeArray(tableData, width, username, view === 'tableData')

  if (!isMoveModal) {
    if (loading || documentLoading || (preLoading && tableData[0] == 'empty') || preLoading == undefined)
      return (
        <div className={'pg-mt-12'}>{width < 768 || view === 'tableData' ? <GridSkeleton rowsNumber={10} /> : <TableSkeleton rowsNumber={10} />}</div>
      )
    else if ((!loading && tableData.length < 1) || (!preLoading && tableData[0] == 'empty')) return <NoData subMsg={t`Upload your first file`} />
  }

  let previews: any = []
  tableData.map((data: any) => {
    selection &&
      selection.map((select: any) => {
        if (data.id === select) {
          let imgSrc = data && data.genericType == 'image' && `${process.env.REACT_APP_URL}/preview/${data.uuid}/medium/${data.name}`
          if (!imgSrc) imgSrc = renderIconSrc(data.type)
          previews.push(imgSrc)
        }
      })
  })

  return (
    <div className={'pg-pb-10'}>
      <CardDragLayer selection={previews} ids={selection} isGrid={view === 'tableData'} width={width} />
      {view === 'tableData' ? (
        <Grid
          sortable={true}
          dropDownData={dropDownData}
          onSort={onSort}
          onCheck={onCheck}
          checkbox={true}
          tableData={tableData}
          handleNavigate={handleNavigate}
          {...rest}
        />
      ) : width < 768 ? (
        <Grid
          sortable={true}
          onCheck={onCheck}
          moveDocuments={moveDocuments}
          dropDownData={dropDownData}
          onSort={onSort}
          checkbox={true}
          tableData={tableData}
          handleNavigate={handleNavigate}
          {...rest}
        />
      ) : isMoveModal ? (
        <Table
          dropdown={true}
          openModal={openModal}
          tabletView={width && width < 768 ? true : false}
          onOpenCFModal={onOpenCFModal}
          header={header}
          hasIcon={true}
          // snapshot={snapshot}
          moveDocuments={moveDocuments}
          moveRow={moveRow}
          handleOnRowClick={handleOnRowClick}
          hasDnd={!isMoveModal && true}
          onCheck={onCheck}
          dropDownData={dropDownData}
          onSort={onSort}
          documentsLength={documentsLength}
          selectionNumber={selectionNumber}
          sortable={true}
          editableId={editableId}
          renameText={renameText}
          selection={selection}
          isMoveModal={isMoveModal}
          handleChange={handleChange}
          handleNavigate={handleNavigate}
          tableData={tableData}
          modalSelection={modalSelection}
          {...rest}
        />
      ) : (
        <TableDnd
          dropdown={true}
          openModal={openModal}
          tabletView={width && width < 768 ? true : false}
          onOpenCFModal={onOpenCFModal}
          header={header}
          hasIcon={true}
          setDragnDropState={setDragnDropState}
          moveDocuments={moveDocuments}
          moveRow={moveRow}
          handleOnRowClick={handleOnRowClick}
          hasDnd={!isMoveModal && true}
          onCheck={onCheck}
          dropDownData={dropDownData}
          onSort={onSort}
          documentsLength={documentsLength}
          selectionNumber={selectionNumber}
          sortable={true}
          editableId={editableId}
          renameText={renameText}
          selection={selection}
          isMoveModal={isMoveModal}
          handleChange={handleChange}
          handleNavigate={handleNavigate}
          tableData={tableData}
          modalSelection={modalSelection}
          {...rest}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  username: state.auth.username,
  selection: state.selection.selection,
  loading: state.loading.isLoading,
  selectionNumber: state.selection.selection,
  documentLoading: state.loading.documentLoading,
  documentsLength: state.document.documents.length
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setTempDocuments: (value: any) => dispatch(setTempDocuments(value)),
    setEditStatus: (value: any) => dispatch(setEditStatus(value)),
    moveDocuments: (value: IMoveDocumentsInput) => dispatch(moveDocuments(value))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContentBody)
