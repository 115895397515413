import * as React from 'react'
import { t } from 'ttag'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { Control } from '../../Control/Control'

// icons & style
import plugIcon from '../../../images/Icons/vmIcons/plug.svg'
import idleIcon from '../../../images/Icons/vmIcons/clock.svg'
import renewIcon from '../../../images/Icons/vmIcons/renew.svg'
import rocketIcon from '../../../images/Icons/vmIcons/rocket.svg'
import remoteIcon from '../../../images/Icons/vmIcons/remote.svg'
import settingIcon from '../../../images/Icons/vmIcons/setting.svg'
import upgradeIcon from '../../../images/Icons/vmIcons/upgrade.svg'
import powerGreenIcon from '../../../images/Icons/vmIcons/power-green.svg'
import powerWhiteIcon from '../../../images/Icons/vmIcons/power-white.svg'

import { power, renewalVm } from '../../../services/internal/store/actions'

import styles from './Control.module.scss'
import { Tooltip } from '../../ui-elements/Tooltip/Tooltip'

export interface MCprops {
  width?: number
  Extended?: boolean
  onClick?: any
  shouldUpgrade?: any
  title?: any
  tmpId?: number
  problem?: any
  id?: number
  power?: any
  idle?: boolean
  renewal?: any
  openCardModal?: any
  isIdleDisabled?: boolean
  isUpgradeDisabled?: boolean
  vmBillStatus?: any
  isExtendDisabled?: boolean
  idleDate?: any
  state?: any
}

const MultipleControl: React.FunctionComponent<MCprops> = ({
  isUpgradeDisabled,
  idleDate,
  idle,
  isExtendDisabled,
  state,
  title,
  id,
  problem,
  power,
  renewal,
  tmpId,
  Extended,
  shouldUpgrade,
  openCardModal,
  isIdleDisabled
}) => {
  const settingUrl = `/nwvms/${title}/settings`

  if (problem) {
    isUpgradeDisabled = true
    shouldUpgrade = false
    isExtendDisabled = true
  }

  return (
    <div className={`${styles.multipleControl} pg-mt-1`} style={{ width: '100%' }} onClick={e => e.stopPropagation()}>
      <Link to={`${settingUrl}/remote`}>
        <Control icon={remoteIcon} text={t`Remote connection`} />
      </Link>
      <Link to={`${settingUrl}/network`}>
        <Control icon={settingIcon} text={t`Setting`} id={tmpId ? tmpId : id} />
      </Link>
      <div className={isUpgradeDisabled ? 'pg-pointer-events-none' : ''}>
        {isUpgradeDisabled ? (
          <Control
            icon={upgradeIcon}
            text={t`Upgrade`}
            id={tmpId ? tmpId : id}
            disabled={isUpgradeDisabled}
            className={isUpgradeDisabled ? 'pg-btnDisabled disabled' : ''}
          />
        ) : (
          <Link to={`${settingUrl}/upgrade`}>
            <Control
              icon={upgradeIcon}
              text={t`Upgrade`}
              id={tmpId ? tmpId : id}
              disabled={isUpgradeDisabled}
              className={isUpgradeDisabled ? 'pg-btnDisabled disabled' : ''}
            />
          </Link>
        )}
      </div>
      {shouldUpgrade && (
        <Control
          icon={rocketIcon}
          text={t`Apply upgrade`}
          onClick={() => openCardModal({ vmId: tmpId, modalView: 'upgradePrompt', vmName: title })}
          id={tmpId ? tmpId : id}
        />
      )}
      {isExtendDisabled ? (
        <Control icon={renewIcon} text={t`Extend server`} disabled={isExtendDisabled} id={tmpId ? tmpId : id} />
      ) : (
        <Control icon={renewIcon} text={t`Extend server`} onClick={() => renewal({ id: tmpId })} id={tmpId ? tmpId : id} />
      )}
      {idle && isIdleDisabled && !idleDate ? (
        <Control disabled={isIdleDisabled} icon={idleIcon} text={t`Idle`} width={25} id={tmpId ? tmpId : id} />
      ) : idleDate ? (
        <Tooltip text={`${idleDate} ساعت تا فعال‌سازی`} width={86} height={27} position={'bottom'} lineHeight={'11px'}>
          <Control text={idleDate} disabled={isIdleDisabled} icon={idleIcon} width={25} id={tmpId ? tmpId : id} />
        </Tooltip>
      ) : (
        idle && (
          <Control
            icon={idleIcon}
            text={t`Idle`}
            width={25}
            onClick={() => openCardModal({ vmId: tmpId, modalView: 'idlePrompt' })}
            id={tmpId ? tmpId : id}
          />
        )
      )}

      {state == 'ON' && (
        <Control
          icon={plugIcon}
          disabled={problem}
          text={t`Shut Down`}
          onClick={() => power({ powerAction: 'shutdown', id: tmpId })}
          id={tmpId ? tmpId : id}
        />
      )}
      {state == 'ON' ? (
        <Control
          className="off"
          icon={powerWhiteIcon}
          disabled={problem}
          text={t`Turn off`}
          onClick={() => power({ powerAction: 'poweroff', id: tmpId })}
          id={tmpId ? tmpId : id}
        />
      ) : (
        <Control
          icon={powerGreenIcon}
          disabled={problem}
          onClick={() => power({ powerAction: 'poweron', id: tmpId })}
          id={tmpId ? tmpId : id}
          text={t`Turn on`}
        />
      )}

      {Extended ? (
        <Control icon={plugIcon} disabled={problem} text={t`Extend Server`} onClick={() => renewal({ id: tmpId })} id={tmpId ? tmpId : id} />
      ) : null}
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  isUpgradeDisabled: state.account.isUpgradeDisabled.status,
  isExtendDisabled: state.account.isExtendDisabled.status,
  isIdleDisabled: state.account.isIdleDisabled,
  id: state.vm.vmId
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    renewal: (value: any) => dispatch(renewalVm(value)),
    power: (value: any) => dispatch(power(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleControl)
