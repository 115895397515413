import React from 'react'
import { Image as ImageCard } from '../ui-elements/Image/Image'

import styles from './PreviewImage.module.scss'

export class ProgressiveImage extends React.Component<any, any> {
  state = {
    currentImage: this.props.preview,
    loading: true
  }
  loadingImage: any

  componentDidMount() {
    this.fetchImage(this.props.image)
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.image !== this.props.image) {
      this.setState({ currentImage: nextProps.preview, loading: true }, () => {
        this.fetchImage(nextProps.image)
      })
    }
  }

  componentWillUnmount() {
    if (this.loadingImage) {
      this.loadingImage.onload = null
    }
  }

  fetchImage = (src: any) => {
    const image = new Image()
    image.onload = () => this.setState({ currentImage: this.loadingImage.src, loading: false })
    image.src = src
    this.loadingImage = image
  }

  style = (loading: any) => {
    return {
      transition: '0.5s filter linear',
      filter: `${loading ? 'blur(50px)' : ''}`
    }
  }

  render() {
    const { currentImage, loading } = this.state
    const { alt, id } = this.props
    return <ImageCard style={this.style(loading)} src={currentImage} alt={alt} id={id} className={styles.previewImage} />
  }
}
