import React, { useState } from 'react'
import { t } from 'ttag'

export const NoData: React.FunctionComponent<any> = ({subMsg}) => {
  return (
    <table className={`pg-flex pg-flex-col`}>
      <tbody className={`pg-flex pg-flex-col`}>
        <tr className={'pg-border-t  pg-p-8 pg-border-gray-400 '} />
        <tr className={'pg-border-t  pg-p-8 pg-border-gray-400 '} />
        <tr className={'pg-border-t pg-border-b pg-p-2 pg-border-gray-400 pg-text-center '}>
          <td className={'pg-flex pg-flex-col pg-justify-center'}>
            <span>{t`No Data Was Found`}</span>
            <span className={'pg-text-xs pg-text-gray-600'}>{subMsg}</span>
          </td>
        </tr>
        <tr className={'pg-border-b  pg-p-8 pg-border-gray-400 '} />
        <tr className={'pg-border-b  pg-p-8 pg-border-gray-400 '} />
        <tr className={'pg-border-b  pg-p-8 pg-border-gray-400 '} />
        <tr className={'pg-border-b  pg-p-8 pg-border-gray-400 '} />
        <tr className={'pg-border-b  pg-p-8 pg-border-gray-400 '} />
      </tbody>
    </table>
  )
}
