import * as React from 'react'
import { t } from 'ttag'
import { connect } from 'react-redux'

import { Button } from '../../ui-elements/Button/Button'

import { switchTimeLang } from '../../../services/internal/utils/switchTimeLang'

import styles from './Control.module.scss'

export interface MPprops {
  activePlanNum?: number
  active?: boolean
  chooseTime?: any
  vpsPeriod?: string
  plan?: any
  prices?: any
  planId?: number
  vmBills?: any
  selectedPrice?: any
  className?: any
  period?: any
  time2?: any
  time?: any
}

// TODO implement plan logic
const plans = [t`1 month`, t`3 month`, t`6 month`, t`one year`, t`two year`]
const MultiplePlan: React.FunctionComponent<MPprops> = ({ planId, time, active, chooseTime, plan, className, vpsPeriod, prices, selectedPrice }) => {
  return (
    <div className={`${styles.multiplePlan} ${styles.control} ${className}`}>
      {plans.map((item, i) => {
        if (item !== t`3 month` && item !== t`6 month`) {
          let active2 = false
          let price = prices[i]
          //if btn active
          if (active && switchTimeLang(time, 'index') == switchTimeLang(item, 'index')) active2 = true
          let disabled = price <= selectedPrice
          //if btn disabled
          if (switchTimeLang(item, 'index') == switchTimeLang(vpsPeriod, 'index') && plan && plan.id == planId) {
            disabled = true
          }
          if (!price) disabled = true
          return (
            <Button
              key={i}
              onClick={() => chooseTime(item, plan, time)}
              className={
                disabled
                  ? ['pg-btnDisabled', 'pg-px-6']
                  : item && active2
                  ? ['pg-btnSuccess', 'pg-px-6']
                  : !active
                  ? ['pg-btnSecondary', 'pg-px-6']
                  : ['pg-px-6']
              }
            >
              {item}
            </Button>
          )
        }
      })}
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  vmBills: state.vm.vmBills,
  period: state.vm.vpsPeriod
})

export default connect(mapStateToProps, null)(MultiplePlan)
