import * as React from 'react'
import { t } from 'ttag'

import { Image } from '../../Image/Image'
import toast from '../../Toast/Toast'

import styles from '../FMModal/FMmodal.module.scss'

import image from '../../../../images/Icons/typeIcons/icon-image.svg'
import { Progressbar } from '../../Progressbar/Progressbar'
import { StatusButton } from './StatusButton'
import { useState } from 'react'

export const UploadRow: React.FunctionComponent<any> = ({ uploadProgress, index, uploadFile, cancelToken }) => {
  const [status, setStatus] = useState(t`uploading`)

  function cancelUpload() {
    cancelToken(toast.errorTop(t`Your upload has been canceled`))
    setStatus(t`cancel`)
  }

  let percent = uploadProgress && uploadProgress.uploadProgress[index] && uploadProgress.uploadProgress[index].percentCompleted
  // if (percent == 100 && status !== t`Done`) setStatus(t`Done`)
  let imgSrc = uploadFile && URL.createObjectURL(uploadFile) ? uploadFile && URL.createObjectURL(uploadFile) : image

  return (
    <li className={styles.file}>
      <Image src={imgSrc} className={styles.thumbnail} width={15} />
      <input value={uploadFile && uploadFile['name']} readOnly className={styles.name} />
      {uploadFile && uploadFile.exist ? (
        'exist'
      ) : (
        <div className={styles.progressBar}>
          <div style={{ fontSize: '10px' }} className={'pg-text-gray-700'}>
            {status === t`Done` ? status : percent ? percent==100 ? t`Done`: `${percent}%`: '...'}
          </div>
          <Progressbar height={'5px'} width={'60px'} value={percent} />
        </div>
      )}
      <div className={'pg-mx-2'} onClick={() => status == t`uploading` && cancelToken && cancelUpload()}>
        <StatusButton status={percent == 100 ? t`Done` : status} />
      </div>
    </li>
  )
}
