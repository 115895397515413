import { put } from 'redux-saga/effects'
import * as actions from '../actions'

import { bottle } from '../../../index'
import { t } from 'ttag'

const documents = bottle.container.documents
const catchError = bottle.container.CatchError
export function* setToggle(action: any) {
  let toggleType = action.payload.type
  let history = action.payload.history
  let off = action.payload.off
  try {
    yield put(actions.setLoadingState(true))
    if (toggleType == t`Trash`) {
      if (history && history.location.pathname !== '/nwfm/trash') {
        history.push(`/nwfm/trash`)
        yield put(actions.setToggle([false, true]))
        yield put(actions.getTrashDocuments())
      } else if (history && history.location.pathname == '/nwfm/trash') history.push(`/nwfm`)
    } else if (toggleType == t`Sharing`) {
      if (history && history.location.pathname !== '/nwfm/shared') {
        history.push(`/nwfm/shared`)
        yield put(actions.setToggle([true, false]))
        yield put(actions.getSharedDocuments())
      } else if (history && history.location.pathname == '/nwfm/shared') history.push(`/nwfm`)
    }
    // if (off) yield put(actions.getDocuments())
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* setImagePreview(action: any) {
  try {
    yield put(actions.setLoadingState(false))
    let urlSize = window.location.pathname.split('/')[4]
    let url
    const sizes = ['small', 'medium', 'large']
    if (!sizes.includes(urlSize)) url = action.history.location.pathname.replace('image/', `image/${action.payload}/`)
    else if (action.payload) url = action.history.location.pathname.replace(urlSize, action.payload)
    else url = action.history.location.pathname.replace(`/${urlSize}`, action.payload)
    action.history.push(url)
  } catch {}
  //  this.props.setPreviewImage(size)
  // let urlSize = this.props.history.length > 0 && this.props.history.location.pathname.split('/')[4]
  // let url
  // const sizes = ['small', 'medium', 'large']
  // if (!sizes.includes(urlSize)) url = this.props.history.location.pathname.replace('image/', `image/${size}/`)
  // else if (size) url = this.props.history.location.pathname.replace(urlSize, size)
  // else url = this.props.history.location.pathname.replace(`/${urlSize}`, size)
  // this.props.history.push(url)
}
