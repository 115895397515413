import * as React from 'react'

// ui-elements

// styles & icons
import styles from './NavbarDropDown.module.scss'
import { Icon } from '../../ui-elements/Icon/Icon'

export default interface Iprops {
  username?: string
  open?: boolean
  signout?: any
  toggle?: () => void
  profileImg?: string
  alt?: string
  iconSrc?: any
  iconLink?: any
  header?: any
  children?: any
  width?: any
  onHover?: any
}

export const NavbarDropDown: React.FunctionComponent<Iprops> = ({ width,onHover, iconSrc, iconLink, header, toggle, open, children }) => {
  if (!width) width = '200px'
  let style = {
    width
  }
  return (
    <div
      {...(toggle && {
        onClick: e => {
          e.preventDefault()
          toggle()
        },
       
      })}
      {...(toggle &&
        onHover && {
          onMouseEnter: (e: any) => {
            e.preventDefault()
            toggle()
          },
        })}
      className="pg-flex pg-items-center pg-cursor-pointer"
    >
      {iconLink ? iconLink : <Icon src={iconSrc} alt="Avatar Image" className="pg-rounded-full tablet-max:pg-w-34p tablet-max:pg-h-34p" />}

      <ul className={open ? `${styles.signout} ${styles.open}` : `${styles.signout}`} style={style} >
        <li className={'pg-p-2'}>{header}</li>
        
        {children}
      </ul>
    </div>
  )
}
