import * as React from 'react'
import { connect } from 'react-redux'

import CFModal from '../../../ui-elements/Modal/CreateFolderModal/CreateFolder'
import MoveFile from '../../../ui-elements/Modal/MoveFileModal.tsx/MoveFile'
import UrlUploadmodal from '../../../ui-elements/Modal/urlUpload/urlUploadModal'
import UploadingModal from '../../../ui-elements/Modal/UploadingModal/UploadingModal'
import { setModalState } from '../../../../services/internal/store/actions'

const FMModal: React.FunctionComponent<any> = props => {
  let modal = <div />

  switch (props.modalView) {
    case 'New Folder':
      modal = <CFModal handleCFClose={() => props.setModalState(false)} showModal={props.showModal} />
      break
    case 'moveFile':
      modal = <MoveFile handleClose={() => props.setModalState(false)} showModal={props.showModal} />
      break
    case 'urlUpload':
      modal = <UrlUploadmodal showModal={props.showModal} handleCFClose={() => props.setModalState(false)} />
      break
    case 'uploadModal':
      modal = <UploadingModal handleClose={props.handleUploadClose} showModal={props.showModal} />
      break
    default:
      break
  }
  return modal
}
const mapStateToProps = (state: any) => ({
  showModal: state.modal.modalState,
  modalView: state.modal.modalView
})
const mapDispatchToProps = (dispatch: any) => {
  return {
    setModalState: (value: any) => dispatch(setModalState(value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FMModal)
