import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

// styles & internal-component
import VpsCards from '../VpsCards/VpsCards'
import Card from '../../../components/VMContent/Cards/Card'
import { Icon } from '../../../components/ui-elements/Icon/Icon'
import VMModalRenderer from '../ModalRendere.tsx/VMModalRenderer'
import VMNetwork from '../../../components/VMContent/VMModes/VMNetwork'
import { VMRemote } from '../../../components/VMContent/VMModes/VMRemote'
import { VMNotFound } from '../../../components/VMContent/NotFound/VMNotFound'
import { VmBrushDescripted } from '../../../components/VMContent/VMModes/VMBrush'
import { withLoadingOverLay as enhancer } from '../../../components/LoadingOverlay/LoadingOverlay'

import loading from '../../../images/loading/loading.gif'

//syles
import styles from './VMCard.module.scss'

import { getVmproducts, setvmBills, setInvoiceItem, getVmBillsNamed } from '../../../services/internal/store/actions'

export interface Iprops {
  children?: any
  title?: string
  vmProducts?: any
}

class VMCard extends React.Component<any, any> {
  // refresh each count seconds
  timeout: any = 0
  count: number = 5000

  constructor(props: any) {
    super(props)
    let vmPathName = this.props.location.pathname.split('/nwvms/')[1]
    if (this.props.location.pathname.includes('/setting')) vmPathName = vmPathName.split('/setting')[0]
    this.state = {
      modalView: '',
      vmPathName: vmPathName,
      errorMsg: '',
      vmName: '',
      focus:true,
      deleteOS: true,
      error: false
    }
    // call and get vm own info from url pathname
    this.props.getVmBills(vmPathName)
    window.addEventListener('visibilitychange', this.onFocus)
    this.props.getVmProducts()
  }
  //clears interval and vmbills array on unmount
  componentWillUnmount() {
    clearInterval(this.timeout)
    this.props.setvmBills([])
    window.removeEventListener('visibilitychange', this.onFocus)
  }
  onFocus = (e: any) => {
    if (document.visibilityState === 'hidden') this.setState({ focus: false })
    else {
      this.setState({ focus: true })
      this.callVmBill()
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.vmBillsNamed.length !== nextProps.vmBillsNamed.length) {
      clearTimeout(this.timeout)
      if (nextProps.vmBillsNamed && this.state.focus) {
        this.callVmBill()
      }
    }
    if (nextProps.vmBillError) {
      this.props.getVmBills(this.state.vmPathName)
    }
  }

  callVmBill() {
    this.timeout = setTimeout(() => this.props.getVmBills(this.state.vmPathName), 5000)
  }

  // handle input change changes
  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value, error: false })
  }

  //modal for payment
  openPayModal = (invoice: any, modalView: string) => {
    this.props.setInvoiceItem(invoice)
    if (invoice.payablePrice < this.props.userInfo.balance) this.setState({ payment: 'done' })
    this.setState({ showModal: true, modalView: 'pay' })
  }

  // handle modal close
  handleClose = () => {
    this.setState({ showModal: false, modalView: '', name: '', upgradeName: '' })
  }

  openCardModal = ({ vmId, modalView, vmName, deleteOs }: any) => {
    this.setState({ showModal: true, vmId, modalView })
    //check if name correct
    if (vmName) {
      this.setState({ checkvmName: vmName })
    }
    // check delete type
    if (deleteOs) this.setState({ deleteOs })
  }

  render() {
    let { vmArray, vmBillsNamed } = this.props

    let hasVmArray = vmArray && vmArray[0]
    let vpsId = hasVmArray && this.props.vmArray[0].activePlanNum
    let price = hasVmArray && this.props.vmArray[0].totalPrice
    let payable = hasVmArray && this.props.vmArray[0].payment
    let brush = vmBillsNamed && vmBillsNamed[0] && vmBillsNamed[0].vm && vmBillsNamed[0].vm.brush
    let hasVm = vmArray && vmArray.length !== 0
    let isLoading = vmArray && vmArray.length == 0 && vmBillsNamed !== 'empty' && !vmArray

    return (
      <div className={styles.item}>
        {hasVm &&
          vmArray.map((vm: any, index: number) => {
            return (
              <div key={index}>
                <Card key={index} openModal={this.openPayModal} openCardModal={this.openCardModal} fullCard={true} vm={vm} />
                {!brush ? (
                  <Switch>
                    {!payable && (
                      <Route
                        path="/nwvms/:name/settings/upgrade"
                        render={() => (
                          <VpsCards
                            hasActiveFooter={false}
                            id={vm.id}
                            osName={vm && vm.osName}
                            planId={vm && vm.vpsId}
                            recurringTime={vm && vm.recurringTime}
                            activePlanNum={vpsId}
                            price={price}
                          />
                        )}
                      />
                    )}
                    <Route
                      path="/nwvms/:name/settings/remote"
                      render={() => (
                        <VMRemote ip={vmArray && vmArray[0] && vmArray[0].ip} windows={vmArray && vmArray[0] && vmArray[0].osName == 'win2012'} />
                      )}
                    />
                    <Route path="/nwvms/:name/settings/network" render={() => <VMNetwork openCardModal={this.openCardModal} />} />
                  </Switch>
                ) : (
                  brush && <VmBrushDescripted steps={brush.strokes} startedTimes={brush.startedTimes} />
                )}
              </div>
            )
          })}

        {vmBillsNamed == 'empty' && <VMNotFound vmPathName={this.state.vmPathName} />}

        {isLoading && (
          <div className={'pg-w-full pg-h-full pg-flex pg-my-10 pg-justify-center'}>
            <Icon src={loading} width={50} />
          </div>
        )}

        {this.state.showModal && (
          <VMModalRenderer
            location={this.props.location}
            modalView={this.state.modalView}
            VMGrid={false}
            isdeleteOs={this.state.deleteOs}
            vmId={this.state.vmId}
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            checkvmName={this.state.checkvmName}
          />
        )}
      </div>
    )
  }
}
const mapStateToProps = (state: any) => ({
  vmArray: state.vm.vmArrayNamed,
  vmBillsNamed: state.vm.vmBillsNamed,
  loading: state.loading.isLoading,
  userInfo: state.account.info
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setInvoiceItem: (value: any) => dispatch(setInvoiceItem(value)),
    getVmBills: (value: any) => dispatch(getVmBillsNamed(value)),
    setvmBills: (value: any) => dispatch(setvmBills(value)),
    getVmProducts: () => dispatch(getVmproducts())
  }
}
// loading overlay on vmcard
const LoadingOverLay = enhancer(VMCard)

export default connect(mapStateToProps, mapDispatchToProps)(LoadingOverLay)
