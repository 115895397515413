import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { t } from 'ttag'

// ui-elements
import NotFound from '../../NotFound/NotFound'
import TableRouter from '../TableOrder/TableRouter'
import VMGrid from '../../../containers/VM/VMGrid/VMGrid'
import VMCards from '../../../containers/VM/VMCards/VMCards'

export default interface Iprops {
  isDisabled?: boolean
  isUpgradeDisabled?: boolean
}

export const VMContentBody: React.FunctionComponent<Iprops> = ({ isDisabled }) => {
  useEffect(() => {
    document.title = t`Virtual Server`
  }, [])

  return (
    <React.Fragment>
      <Switch>
        {/* order page */}
        {!isDisabled ? (
          <Route path={'/nwvms/order'} render={(props: any) => <TableRouter {...props} />} />
        ) : (
          <Route path="/nwvms/order" component={NotFound} />
        )}
        {/* each card */}
        <Route path="/nwvms/:name" component={VMCards} />
        {/* vm grid view */}
        <Route exact path="/nwvms" component={VMGrid} />
      </Switch>
    </React.Fragment>
  )
}
