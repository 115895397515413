import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// styles
import styles from  '../../OldOrder/Order.module.scss'
import { setServiceDuration } from '../../../../services/internal/store/actions'

import { StepDescription } from '../../OldOrder/Steps/Details/StepDescription'
import DurationDropDown from '../DropDown/DurationDropDown'

export interface Iprops {
  isOpen?: any
  durationData?: boolean
  serviceDuration?: any
  expanded?: any
}

class SelectMonth extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      oses: props.oses ? props.oses : [],
      expanded: false,
      isOsOpen: false,
      osMainName: 'CentOS',
      isMonthOpen: true,
      index: props.vsCardMain && props.vsCardMain.name === 'VPS2' ? 1 : '',
      finalInvoice: [],
      disableButton: true,
      stepsPathName: ['choose-plan', 'final-step'],
      stepsMainName: [t`Select a Plan`, t` Submit`]
    }
  }

  setOsClose = () => {
    this.setState({ isOsOpen: false })
  }

  chooseOs = () => {
    this.setState({ dropdown: true })
  }

  onOsImgClick = (osMainName: any, osArray: any) => {
    osArray.map((each: any) => {
      if (each.name == osMainName) this.props.selectOs(each.mainName[0], each.version[0], each.name)
    })
    this.setState({ osMainName })
  }

  selectMonth = (time: any) => {
    this.props.setServiceDuration(time)
  }

  render() {
    let { setServiceDuration } = this.props

    return (
      <div className={'pg-w-full pg-flex pg-items-center pg-px-2  pg-py-2 pg-justify-between lgDesktop-max:pg-flex-col'}>
        <StepDescription
          stepNumber={1}
          title={[t`Second step`, `:`, this.state.stepsMainName[2]].join(' ')}
          subTitle={t`Select the length of time you want the service to be activated for you.`}
        />

        <div className={`pg-flex pg-items-center pg-my-4 ${[styles.allBoxes].join(' ')}`} onClick={this.chooseOs}>
          <DurationDropDown setServiceDuration={setServiceDuration} />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setServiceDuration: (stepNumber: number) => dispatch(setServiceDuration(stepNumber))
  }
}

const mapStateToProps = (state: any) => ({
  os: state.vm.os,
  oses: state.vm.oses,
  vsCardMain: state.vm.vsCardMain
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectMonth)
