import React from 'react'
import centosIcon from '../../../images/Icons/vmIcons/centos.svg'
import debianIcon from '../../../images/Icons/vmIcons/debian.svg'
import ubuntuIcon from '../../../images/Icons/vmIcons/ubuntu.svg'
import windowsIcon from '../../../images/Icons/vmIcons/windows.svg'

export const renderOsIcon = (os: any) => {
  let icon
  switch (os) {
    case 'CentOS 7.3 (64bit)':
    case 'Fedora 8.2 (64bit)':
    case 'CentOS 6.9 (64bit)':
    case 'CentOS 8.1 (64bit)':
      icon = centosIcon
      break
    case 'Ubuntu 14.04 (64bit)':
    case 'Ubuntu 16.04 (64bit)':
    case 'Ubuntu 18.04 (64bit)':
    case 'Ubuntu Desktop 16.04':
      icon = ubuntuIcon
      break
    case 'Windows Server 2012':
      icon = windowsIcon
      break
    case 'Debian 8 (64bit)':
    case 'Debian 10.3 (64bit)':
      icon = debianIcon
      break
    default:
      icon = centosIcon
      break
  }
  return icon
}
