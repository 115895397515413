import React from 'react'
import { t } from 'ttag'

// ui-elements
import { BreadcrumbMP } from '../../Breadcrumb/BreadCrumbMP'
import { BreadCrumbAuto } from '../../Breadcrumb/BreadCrumbAuto'
import { ColorfulBox } from '../../ui-elements/ColorfulBox/ColorfulBox'

//styles
import styles from './VMContent.module.scss'

export default interface Iprops {
  handleSearchInput?: (e: string) => void
  history: any
  className?: string
}

export const VMContentHeader: React.FunctionComponent<Iprops> = ({ className }) => {
  let MainTitleArray: any = [{ urlPath: 'nwvms', title: t`Main Page` }]
  let isSetting =
    !window.location.pathname.includes('order') &&
    window.location.pathname.split('/nwvms/')[1] &&
    window.location.pathname.split('/nwvms/')[1].split('/setting')
  let isOrder = window.location.pathname.includes('order')
  
  return (
    <div className={`${styles.header} ${className}`}>
      {isSetting ? (
        <BreadcrumbMP title={window.location.pathname.split('/nwvms/')[1].split('/setting')[0]} />
      ) : !isOrder ? (
        <BreadCrumbAuto MainTitleArray={MainTitleArray} />
      ) : (
        ''
      )}
      <ColorfulBox className={['red', 'sm']} width={326} message={t`For upgrade you need to turn off your vm`} hide={true} />
    </div>
  )
}
