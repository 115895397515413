import * as React from 'react'
import { Link } from 'react-router-dom'
import { t } from 'ttag'

// ui-elements
import { Button } from '../../ui-elements/Button/Button'
import { IconLink } from '../../ui-elements/Icon/IconLink'

// icons
import purchaseIcon from '../../../images/Icons/commonIcons/plus-white.svg'

// internal components & styles
import '../Sidebar.scss'
import { Nav } from './Nav'
import { Tooltip } from '../../ui-elements/Tooltip/Tooltip'

export default interface Iprops {
  onItemClick?: (e: any) => void
  isVmDisabled?: boolean
}

export const HostingSidebar: React.FunctionComponent<Iprops> = (props: any) => {
  
  return (
    <div className="sidebar-menu">
      {props.isVmDisabled ? (
        <Link to="/nwhosting/order/submit-domain">
          <Tooltip text={t`buying is disabled`} width={130} height={37} position={'bottom'}>
            <Button className={['pg-btnDisabled', 'pg-btnLg']} style={{ marginBottom: '15px' }}>
              <IconLink icon={purchaseIcon} iconAlt="upload icon" label={t`Buy new server`} />
            </Button>
          </Tooltip>
        </Link>
      ) : (
        <Link to="/nwhosting/order/submit-domain">
          <Button className={['pg-btnPrimary', 'pg-btnLg']} style={{ marginBottom: '15px' }}>
            <IconLink icon={purchaseIcon} iconAlt="upload icon" label={t`Buy new Host`} />
          </Button>
        </Link>
      )}
      <Nav />
    </div>
  )
}
