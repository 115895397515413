import * as React from 'react'

import { Icon } from '../../Icon/Icon'
import Cancel from '../../../../images/Icons/statusbuttonsIcons/icon-upload-cancel.svg'
import Done from '../../../../images/Icons/statusbuttonsIcons/icon-upload-done.svg'
import Err from '../../../../images/Icons/statusbuttonsIcons/icon-upload-error.svg'
import { t } from 'ttag'
interface Iprops {
  status?: string
}
export const StatusButton: React.FunctionComponent<Iprops> = ({ status }) => {
  let icon
  switch (status) {
    case t`uploading`:
      icon = Cancel
      break
    case t`Done`:
      icon = Done
      break
    case 'err':
    case t`cancel`:
      icon = Err
      break
  }
  return <Icon src={icon} width={48} />
}
