import * as React from 'react'
import folder from '../../../images/Icons/typeIcons/icon-folder.svg'
import excel from '../../../images/Icons/typeIcons/icon-excel.svg'
import image from '../../../images/Icons/typeIcons/icon-image.svg'
import music from '../../../images/Icons/typeIcons/icon-music.svg'
import zip from '../../../images/Icons/typeIcons/icon-zip.svg'
import video from '../../../images/Icons/typeIcons/icon-video.svg'
import pdf from '../../../images/Icons/typeIcons/icon-pdf.svg'
import icon from '../../../images/Icons/typeIcons/icon.svg'
import code from '../../../images/Icons/typeIcons/icon-code.svg'
import { disabled } from 'glamor';

export default interface Iprops {
  src?: string
  alt?: string
  className?: string
  width?: number
  height?: number
  disabled?: boolean
  id?: string
  style?: object
  mimetype?: string
}

export const Icon = ({ src, alt, className = 'icon', width, height, style, mimetype, id }: Iprops) => {
  return (
    <img
      src={mimetype ? renderIconSrc(mimetype) : src}
      alt={alt}
      id={id}
      className={className}
      width={width}
      height={height}
      style={style}
      onError={(e: any) => {
        e.target.alt = 'img not found'
        e.target.src = image
      }}
    />
  )
}

export const renderIconSrc = (mimetype?: string) => {
  let src
  // console.log(mimetype)
  switch (mimetype) {
    case 'folder':
      src = folder
      break
    case 'excel':
      src = excel
      break
    case 'image':
      src = image
      break
    case 'audio':
      src = music
      break
    case 'video':
      src = video
      break
    case 'archive':
      src = zip
      break
    case 'pdf':
      src = pdf
      break
    case 'pdf':
      src = pdf
      break
    case 'code':
      src = code
      break

    default:
      src = image
      break
  }
  // src =src + '1'
  return src
}

export const toObjectUrl = async (url: string) => {
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    return URL.createObjectURL(blob)
  } catch (error) {}
}
