import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { InputRow } from '../Profile/InputRow'
import { Button } from '../ui-elements/Button/Button'
import { TextInput } from '../ui-elements/Input/Input'
import { ColorfulBox } from '../ui-elements/ColorfulBox/ColorfulBox'

export interface Iprops {
  changePassword?: any
  updateChange?: any
  error?: any
  passwordSuccess?: any
}
const ChangePassword: React.FunctionComponent<Iprops> = props => {
  return (
    <form onSubmit={props.changePassword} className={'pg-w-1/2 laptop:pg-w-3/4 tablet:pg-w-3/4  mobile-max:pg-w-full'}>
      <h1 className={'pg-text-lg pg-py-5 pg-text-gray-700'}> {t`Change Password`}</h1>
      {props.error && (
        <div className={'pg-mb-4'}>
          <ColorfulBox className={['red', 'lg', 'pg-mb-4']} withClose={true} message={props.error} />
        </div>
      )}
      {props.passwordSuccess && (
        <div className={'pg-mb-4'}>
          <ColorfulBox className={['green', 'lg']} withClose={true} message={t`password has changed successfully!`} />
        </div>
      )}
      <TextInput label={t`Old Password`} type={'password'} name={'currentPassword'} onChange={props.updateChange} />
      <TextInput label={t`New Password`} type={'password'} name={'newPassword'} onChange={props.updateChange} />
      <TextInput label={t`Repeat Password`} name={'repeatPassword'} type={'password'} onChange={props.updateChange} />
      <Button className={['pg-btnPrimary', 'pg-btnSm']}>{t`Submit`}</Button>
    </form>
  )
}
const mapStateToProps = (state: any) => ({ info: state.auth.info })

export default connect(mapStateToProps, null)(ChangePassword)
