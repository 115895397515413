export const removeFiles = (tableData: any, selection: any) => {
tableData = tableData.reduce(function(prev: any, value: any) {
    var isDuplicate = false
    for (var i = 0; i < selection.length; i++) {
      if (value.id == selection[i]) {
        isDuplicate = true
        break
      }
    }

    if (!isDuplicate) {
      prev.push(value)
    }

    return prev
  }, [])
  return tableData
}
