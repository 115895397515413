import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './services/internal/store/configStore'
import * as serviceWorker from './serviceWorker'

import './styles/tailwind.css'

import * as Sentry from '@sentry/browser';
import Routes  from './Routes';

// Sentry.init({dsn: 'https://7583522a157447ea9737788d5020af9c@sentry.io/1371805'});

ReactDOM.render(
  
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
