import React from 'react'
import { Link } from 'react-router-dom';

import styles from './Breadcrumb.module.scss'

import { Breadcrumb } from './Breadcrumb'


export default interface Iprops {
  title?: string
  link?: string
}

export const BreadcrumbSP: React.FunctionComponent<Iprops> = ({ title, link }) => {
  return (
    <Breadcrumb>
      {link ? (
        <Link to={link}>
          <span className={[styles.active, styles.item].join(' ')}> {title}</span>
        </Link>
      ) : (
        <span className={[styles.active, styles.item].join(' ')}> {title}</span>
      )}
    </Breadcrumb>
  )
}
