export const industries = [
  '',
  'وزارتخانه ها و ادارات دولتی',
  'معادن',
  'صنایع دفاعی و نیروی مسلح',
  'موسسات مالی ، اعتباری و بیمه',
  'بهداشت و درمان و خدمات اجتماعی',
  'اطلاعات و ارتباطات و فرهنگی هنری',
  'صنایع نفت، گاز و پتروشیمی',
  'صنایع سنگین(خودروسازی و ...)',
  'صنایع سبک و بنگاه های تولیدی',
  'خدمات گردشگری',
  'خدمات عمرانی',
  'خدمات بازرگانی (عمده فروشی و خرده فروشی)',
  'خدمات فنی و مشاوره ای',
  'خدمات حمل و نقل و انبارداری',
  'خدمات آموزشی',
  'برق و آب'
]
export const industriesEn = [
  '',
  'Ministries and government departments',
  'Mines',
  'Defense Industries and Armed Forces',
  'Financial, credit and insurance institutions',
  'Health and social services',
  'Information and communication and artistic culture',
  'Oil, Gas and Petrochemical Industries',
  'Heavy Industries (Automotive and ...)',
  'Light industry and manufacturing enterprises',
  'Tourism services',
  'Development services',
  'Business Services (Wholesale and retail)',
  'Technical and consulting services',
  'Transportation and warehousing services',
  'Educational services',
  'Electricity and water'
]
