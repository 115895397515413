import React, { useState } from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { Table } from '../Table/Table'
import { Button } from '../ui-elements/Button/Button'
import { Icon } from '../ui-elements/Icon/Icon'

//services
import { getInvoice, setInvoiceItem } from '../../services/internal/store/actions'
import { formatPrice } from '../../services/internal/utils/formatPrice'

// icons
import printIcon from '../../images/Icons/commonIcons/icon-back-copy.2.svg'
import { Hr } from '../ui-elements/Hr/Hr'
import { classNames } from 'react-select/lib/utils'
import share from '../../images/Icons/commonIcons/share.svg'
import { Control } from '../Control/Control'
import { Link } from 'react-router-dom'

interface Iprops {
  index: number
  invoiceClassName?: any
  invoiceObj: invoiceItem
  loading: any
  className?: any
}

export interface invoiceItem {
  name: string
  quota: string
  persianTo: string
  persianFrom: string
  persianCreatedAt: string
  persianApprovedAt: string
  totAmountWithoutSurchage: number
  totalPrice: number
  payablePrice: number
  rate: number
  normalPrice: number
  tax: number
  others: number
  title: string
  base: number
  discount: number
  totalAmountWithoutSurchage: number
  totalNoTax: number
  paid: boolean

  off: boolean
  length: number
  loading: boolean
  invoiceTable: any[]
}
class Invoice extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      printable: false,
      invoice: props.invoice
    }
    // this.props.getInvoice(window.location.pathname.split('/nwvms')[1], 4, 'cws')
  }

  setPrintable = () => {
    if (this.state.printable !== true) this.setState({ printable: true })
  }
  componentDidMount() {
    if (this.state.invoice !== this.props.invoice) this.props.getInvoice(window.location.pathname.split('/nwvms')[1], 4, 'cws')
  }

  print = () => {
    this.setPrintable()
    const printableElements = document.getElementById('printable')!.innerHTML
    const orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
    const oldPage = document.body.innerHTML
    document.body.innerHTML = orderHtml
    window.print()
    this.setState({ printable: false })
    location.reload()
    // document.body.innerHTML = oldPage
  }
  render() {
    const header = [t`index`, t`description`, t`value`, t`rate`, t`cost`]
    let {
      name,
      quota,
      persianTo,
      payablePrice,
      persianFrom,
      persianApprovedAt,
      persianCreatedAt,
      paid,
      title,
      refCode,
      invoiceTable,
      id
    } = this.props.invoiceObj
    console.log(this.props.invoiceObj)
    return (
      <tr className={`pg-w-full pg-bg-white ${this.props.noBorder ? '' : 'pg-border-2'} pg-border-t-0 pg-border-blue-400`} id={'printable'}>
        {this.props.invoiceObj.length == 0 ? (
          <td
            className={`pg-w-full pg-bg-white  pg-text-center ${this.props.className}  lgDesktop:pg-w-3/4 `}
            colSpan={7}
            style={{ textAlign: 'center' }}
          >
            {t`Loading ...`}
          </td>
        ) : (
          <td className={`pg-w-full pg-bg-white  ${this.props.className}`} colSpan={7}>
            <div className={'pg-flex pg-flex-col lgDesktop-max:pg-p-2 lgDesktop:pg-w-3/4 centerize'}>
              <div className={'pg-flex pg-px-8 pg-py-4  pg-justify-between'}>
                <div className={'pg-flex pg-justify-center pg-items-center pg-text-lg'}>
                  {location.pathname.includes('/nwfm') && t`File Storage Invoice`}
                  {` ` + t`Invoice`}
                  <span className={'pg-text-blue-600 pg-mx-2'}>
                    {' ' + title}
                    {quota !== '-' && quota}
                  </span>
                </div>

                {/* {!this.state.printable && (
                        <Button className={['pg-btnDefault', 'pg-btnMd', 'pg-px-2']} style={{ height: 35 }}>
                          <Icon src={printIcon} /> <span className={''}>{t`Print`}</span>
                        </Button>
                      )} */}
                <div className={'pg-flex pg-items-center'}>
                  <div className={'pg-flex pg-flex-col'}>
                    <span className={'pg-mx-3  pg-text-right pg-my-1'}>
                      <span className={'pg-text-blue-600'}> {t`Invoice Number`}: </span>
                      {refCode}
                    </span>
                    <span className={'pg-mx-3 pg-text-right'}>
                      <span className={'pg-text-blue-600'}> {t`Creatied At`}: </span> {persianCreatedAt}
                    </span>
                  </div>
                  {paid && (
                    <>
                      <div className={'pg-flex pg-flex-col'}>
                        <span className={'pg-mx-3  pg-text-right pg-my-1'}>
                          <span className={'pg-text-blue-600'}> {t`Start Date`}: </span>
                          {persianFrom ? persianFrom : '-'}
                        </span>
                        <span className={'pg-mx-3 pg-text-right'}>
                          <span className={'pg-text-blue-600'}> {t`Due Date`}:</span> {persianTo ? persianTo : '-'}
                        </span>
                      </div>
                    </>
                  )}
                  <div>
                    {' '}
                    {!this.state.printable && !this.props.shareInvoice && (
                      <span>
                        <Link to={`${window.location.pathname}/${id}`}>
                          <Control icon={share} width={20} text={'به اشتراک‌گذاری'} />
                        </Link>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className={'pg-px-2 pg-py-2'}>
                <Table
                  selectableRows={false}
                  header={header}
                  sortable={false}
                  tableData={invoiceTable}
                  className={this.props.invoiceClassName}
                  showAll={true}
                  labelShow={[`index`, `price`, `amount`, `rate`, `cost`, `normalprice`]}
                />
                <div style={{ marginRight: '54%' }}>
                  {/* <Table selectableRows={false} hasHeader={false} noBorder={true} sortable={false} tableData={fakeTable} width={'315px'}/>

                <Hr />
                <Table selectableRows={false} hasHeader={false} noBorder={true} sortable={false} tableData={table2} width={'315px'}/> */}
                </div>
                {paid && (
                  <div className={'pg-w-full pg-my-4 pg-flex pg-border pg-border-green-400 pg-p-4 pg-rounded-sm pg-justify-between'}>
                    <div className={'pg-flex pg-items-center'}>
                      {t`Paid`} {persianApprovedAt} {t`Costing`} {formatPrice(payablePrice)}
                    </div>
                    <div onClick={() => setTimeout(this.print, 0)}>
                      {!this.state.printable && (
                        <Button className={['pg-btnDefault', 'pg-btnMd', 'pg-px-2']} style={{ height: 35 }}>
                          <Icon src={printIcon} /> <span className={''}>{t`Print`}</span>
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </td>
        )}
      </tr>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loading: state.loading.invoiceLoading,
  invoice: state.account.invoice,
  invoiceItem: state.account.invoiceItem
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    getInvoice: (payload: any, index: any, invoiceType: any) => dispatch(getInvoice(payload, index, 'invoiceType'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
