import * as React from 'react'
import { connect } from 'react-redux'
import Plan from './Plan'
import Toggle from '../ui-elements/Toggle/Toggle'
import greenArrow from '../../images/Icons/typeIcons/group.svg'
import { Icon } from '../ui-elements/Icon/Icon'
import { t } from 'ttag'
import checked from '../../images/Icons/commonIcons/checked.svg'
import close from '../../images/Icons/commonIcons/closed.svg'
import { BreadcrumbMP } from '../Breadcrumb/BreadCrumbMP'
import { BreadcrumbSP } from '../Breadcrumb/BreadCrumbSP'
import { Tooltip } from '../ui-elements/Tooltip/Tooltip'
import { info } from 'console'
const UpgradePlans: React.FunctionComponent<any> = props => {
  const products = [
    {
      name: 'FREE',
      id: 3,
      valuePriceMonthly: 0,
      valuePriceYearlyPure: 0,
      valuePriceYearlyDiscount: 0,
      info: [
        { label: 'حجم پلن', value: '۸ گیگابایت' },
        { label: 'سقف آپلود از URL', value: '۵۰ مگابایت' },
        { label: 'سقف آپلود هر فایل', value: '۵۰۰ مگابایت' },
        {
          label: 'لینک مستقیم',
          value: (
            <Tooltip text={'عکس، swf, js, html, css'} width={140} height={30} position={'bottom'}>
              <span className={'pg-underline pg-cursor-pointer'}>{t`limited`}</span>
            </Tooltip>
          )
        },
        { label: 'نمایش تبلیغات', value: <Icon src={checked} width={10} /> }
      ]
    },
    {
      name: 'Pro',
      id: 4,
      valuePriceMonthly: 25.0,
      valuePriceYearlyPure: 225.0,
      valuePriceYearlyDiscount: 300.0,
      info: [
        { label: 'حجم پلن', value: '۲۰ گیگابایت' },
        { label: 'سقف آپلود از URL', value: '۲ گیگابایت' },
        { label: 'سقف آپلود هر فایل', value: '۲ گیگابایت' },
        {
          label: 'لینک مستقیم',
          value: 'همه‌ی فایل‌ها'
        },
        {
          label: 'سرعت دانلود و ترافیک',
          value: 'نامحدود'
        },
        { label: 'نمایش تبلیغات', value: <Icon src={close} width={10} /> },
        {
          label: 'تضمین ماندگاری همیشگی همه‌ی فایل‌ها',
          value: <Icon src={checked} width={12} />
        }
      ]
    },
    {
      name: 'Pro',
      id: 5,
      valuePriceMonthly: 60.0,
      valuePriceYearlyPure: 495.0,
      valuePriceYearlyDiscount: 660.0,
      info: [
        { label: 'حجم پلن', value: '۱۰۰ گیگابایت' },
        { label: 'سقف آپلود از URL', value: '۱۰ گیگابایت' },
        { label: 'سقف آپلود هر فایل', value: '۱۰ گیگابایت' },
        {
          label: 'لینک مستقیم',
          value: 'همه‌ی فایل‌ها'
        },
        {
          label: 'سرعت دانلود و ترافیک',
          value: 'نامحدود'
        },
        { label: 'نمایش تبلیغات', value: <Icon src={close} width={10} /> },
        {
          label: 'تضمین ماندگاری همیشگی همه‌ی فایل‌ها',
          value: <Icon src={checked} width={12} />
        },
        {
          label: 'پشتیبانی ۲۴ساعته، تمام ایام هفته',
          value: <Icon src={checked} width={10} />
        }
      ]
    }
  ]

  let products2 = [
    {
      category: 'CFS',
      featureActive: true,
      featureInfo:
        '{ "نمایش تبلیغات": true,"۵۰۰ مگابایت سقف آپلود هر فایل": true,"۵۰ مگابایت سقف آپلود از URL": true,"لینک مستقیم: عکس، swf, js, html, css": true}',
      id: 3,
      jsonInfo:
        '{"quota": 8589934592,"maximumSize": 524288000,"urlMaximumSize": 52428800,"bandwidth": 21474836480,"faname": "رایگان","plan_type": "MONTH"}',
      name: 'FREE',

      priceInfo: 0.0,
      productActive: true,
      required: true,
      staticPlan: false
    },
    {
      category: 'CFS',
      featureActive: true,
      featureInfo:
        '{"نمایش تبلیغات": false,"۲ گیگابایت سقف آپلود هر فایل": true,"۲ گیگابایت سقف آپلود از URL": true,"لینک دانلود مستقیم برای همه\u200cی فایلها": true,"سرعت دانلود و ترافیک نامحدود برای فایل\u200cها": true,"تضمین ماندگاری همیشگی همه\u200cی فایل\u200cها": true}',
      id: 4,
      jsonInfo: '{"quota": 21474836480,"maximumSize": 2147483648,"urlMaximumSize": 2147483648,"bandwidth": 0,"faname" : "PG","plan_type": "MONTH"}',
      jsonPrices: [
        {
          calculationType: 'FIXED',
          quantityBreakId: 4,

          recuringPeriod: 'MONTH',
          title: 'BASE',
          validFrom: 1389299400000,
          validTo: 1608409800000,
          valueApplication: 'FIXED',
          valuePrice: 25.0
        },
        {
          calculationType: 'FIXED',
          quantityBreakId: 65,
          recuringPeriod: 'YEAR',
          title: 'DISCOUNT',
          validFrom: 1389299400000,
          validTo: 1608409800000,
          valueApplication: 'PERCENT',
          valuePrice: 25.0
        },
        {
          calculationType: 'FIXED',
          discountPrice: 30.0,
          quantityBreakId: 64,
          recuringPeriod: 'YEAR',
          title: 'BASE',
          validFrom: 1389299400000,
          validTo: 1608409800000,
          valueApplication: 'FIXED',
          valuePrice: 300.0
        }
      ],
      name: 'Pro',
      uploadSize: '20 GB',
      priceInfo: 4.0,
      productActive: true,
      required: true,
      staticPlan: false
    },
    {
      category: 'CFS',
      featureActive: true,
      featureInfo:
        '{"نمایش تبلیغات": false,"۱۰ گیگابایت سقف آپلود هر فایل": true,"۱۰ گیگابایت سقف آپلود از URL": true,"لینک دانلود مستقیم برای همه\u200cی فایلها": true,"سرعت دانلود و ترافیک نامحدود برای فایل\u200cها": true,"تضمین ماندگاری همیشگی همه\u200cی فایل\u200cها": true,"پشتیبانی ۲۴ساعته، تمام ایام هفته": true}',
      id: 5,
      jsonInfo:
        '{"quota": 107374182400,"maximumSize": 10737418240,"urlMaximumSize": 10737418240,"bandwidth": 0,"faname" : "PG+","plan_type": "MONTH"}',
      jsonPrices: [
        {
          calculationType: 'FIXED',
          quantityBreakId: 8,
          recuringPeriod: 'MONTH',
          title: 'BASE',
          validFrom: 1389299400000,
          validTo: 1608409800000,
          valueApplication: 'FIXED',
          valuePrice: 40.0
        },
        {
          calculationType: 'FIXED',
          quantityBreakId: 68,
          recuringPeriod: 'YEAR',
          title: 'DISCOUNT',
          validFrom: 1389299400000,
          validTo: 1608409800000,
          valueApplication: 'PERCENT',
          valuePrice: 20.0
        },
        {
          calculationType: 'FIXED',
          discountPrice: 60.0,
          quantityBreakId: 67,
          recuringPeriod: 'YEAR',
          title: 'BASE',
          validFrom: 1389299400000,
          validTo: 1608409800000,
          valueApplication: 'FIXED',
          valuePrice: 300.0
        }
      ],
      name: 'Premium',
      uploadSize: '100 GB',
      priceInfo: 35.0,
      productActive: true,
      required: true,
      staticPlan: false
    }
  ]
  // console.log(props.planId , products)
  return (
    <div className={'pg-flex pg-w-full pg-flex-col tablet-max:pg-p-12 mobile-max:pg-p-8'}>
      <BreadcrumbSP title={t`Return to Plan`} link={'/nwaccount/plans'} />
      <div className={'pg-flex pg-w-full pg-justify-center '}>
        <div className={'pg-flex pg-bg-white pg-justify-center pg-relative'}>
          <div className={`pg-text-center pg-p-4 pg-px-10 pg-bg-activated-900 pg-pointer-cursor`}>{t`Personal`}</div>
          <div className={'pg-text-center pg-p-4 pg-px-10 pg-bg-gray-400 '}>{t`Organizational` + t`(Soon)`}</div>
        </div>
      </div>
      <div className={'pg-flex  pg-w-full pg-bg-white  pg-p-10 pg-justify-center t'}>
        <span className={`pg-px-3 pg-py-1`}>{t`Monthly`}</span>
        <span onClick={e => props.onToggle(e)}>
          <Toggle checked={props.monthly} className={'bigToggle'} bothActive={true} />
        </span>
        <span className={`pg-px-3 pg-py-1`}>{t`yearly`}</span>
        <span className={`pg-relative`}>
          <Icon src={greenArrow} style={{ positon: 'absolute', top: '-4px' }} />
        </span>
        <span className={`pg-px-3 pg-py-1 pg-text-xs pg-text-green-400`}>٪۲۰ {t`discount`}</span>
      </div>
      <div className={'pg-flex pg-w-full laptop:pg-flex-col mobile-max:pg-flex-col tablet-max:pg-flex-col '}>
        {products &&
          products.map((plan: any, index: number) => (
            <Plan
              info={plan}
              isFree={props.info && props.info.plan && props.info.plan.name && props.info.plan.name === 'FREE'}
              disable={props.planId > plan.id && index == 0}
              currentPlanId={props.planId}
              active={props.planId == plan.id}
              index={index}
              onClick={props.onClick}
            />
          ))}
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({ products: state.account.products, monthly: state.account.monthly, info: state.account.info })

export default connect(mapStateToProps, null)(UpgradePlans)
