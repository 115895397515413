import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { getTransactions } from '../../../services/internal/store/actions'
import { Loading } from '../../../components/loading/Loading'
import { Table } from '../../../components/Table/Table'
import { Button } from '../../../components/ui-elements/Button/Button'


export interface Iprops {
  onClickOverlay: () => void
  open: boolean
  onItemClick?: any
  onDrop?: any
  sidebarOpen?: boolean
}

class Transactions extends React.Component<any, any> {
  componentDidMount() {
    this.props.getTransactions()
  }
  constructor(props: any) {
    super(props)
    this.state = {
      transactions: props.transactions ? props.transactions : [],
      modifiedTransactions: [],
      count: 20,
      activePage: 1
    }
  }
  makeArray = (array: any, count: any) => {
    this.setState({ count: count + 20 })
    array = array.slice(0, this.state.count)
    return array
  }
  showMore = () => {
    this.setState({ modifiedTransactions: this.makeArray(this.state.transactions, this.state.count) })
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.transactions && nextProps.transactions.length > 0) {
      this.setState({ transactions: nextProps.transactions, modifiedTransactions: this.makeArray(nextProps.transactions, this.state.count) })
    }
  }
  render() {
    let header = [t`index`, t`Date`, t`time`, t`deposit`, t`withdraw`, t`description`]
    return (
      <>
        <h1 className={'pg-text-lg pg-py-5 pg-text-gray-700'}>{t`Your transactions`}</h1>
        {this.props.transactions.length == 0 && !this.props.loading && <div className={'pg-flex pg-h-full'}>{t`No transactions to show`}</div>}
        {this.props.loading && <Loading />}
        {this.state.modifiedTransactions.length > 0 && (
          <div className={'pg-flex pg-justify-center pg-flex-col pg-items-center '}>
            <Table
              header={header}
              labelShow={['index', 'date', 'time', 'deposit', 'withdraw', 'description']}
              tableData={this.state.modifiedTransactions}
              selectableRows={false}
            />
            {/* <Pagination pageNumbers={5} activePage={this.state.activePage} first={this.state.activePage ==1}  /> */}
            <Button
              className={[
                'pg-btnLg',
                'pg-mt-3',
                this.state.modifiedTransactions.length === this.state.transactions.length
                  ? 'pg-btnDisabled'
                  : this.state.transactions.length < 20
                  ? 'pg-btnDisabled'
                  : 'pg-btnDefault'
              ]}
              onClick={this.showMore}
            >{t`show more`}</Button>
          </div>
        )}
      </>
    )
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    getTransactions: () => dispatch(getTransactions())
  }
}
const mapStateToProps = (state: any) => ({
  loading: state.loading.isLoading,
  transactions: state.account.transactions
})
export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
