import React from 'react'
import { connect } from 'react-redux'
import { payInvoice } from '../../../../services/internal/store/actions'
import done from '../../../../images/loading/done.gif'
import checked from '../../../../images/loading/checked.svg'
import { Icon } from '../../Icon/Icon'
import { t } from 'ttag'
import { Button } from '../../Button/Button'
import { formatPrice } from '../../../../services/internal/utils/formatPrice'
import { Link } from 'react-router-dom'

class PaymentDone extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      img: done,
      price: props.price ? props.price / 1000 : '',
      isSure: false,
      product: ''
    }
  }

  componentDidMount() {
    let product
    if (window.location.href.includes('nwfm')) product = 'cfs'
    else if (window.location.href.includes('nwaccount')) product = 'cfs'
    else product = 'cws'
    this.setState({ product })
    // if (this.props.id) this.props.payInvoice({ body: { payType: 'payByBalance' }, id: this.props.id ,product})
    // setTimeout(() => {
    //   this.setState({ img: checked })
    // }, 1500)
  }
  pay = () => {
    this.setState({ isSure: true })
    if (this.props.id) this.props.payInvoice({ body: { payType: 'payByBalance' }, id: this.props.id, product: this.state.product })
    setTimeout(() => {
      this.setState({ img: checked })
    }, 1500)
    // this.setState({ isSure: false })
  }
  componentWillUnmount() {
    this.setState({ price: '' })
  }
  render() {
    return (
      <div className={'pg-flex pg-flex-col pg-justify-center pg-items-center'}>
        {!this.state.isSure ? (
          <>
            <div> {`${t`Are you sure to pay`} ${formatPrice(this.state.price)} ${t`from your wallet?`}`}</div>
            <div className={'pg-flex pg-justify-between pg-w-full pg-mt-8'}>
              <Button className={['pg-btnSuccess', 'pg-btnSm']} onClick={this.pay}>{t`Ok`}</Button>
              <Button className={['pg-btnDefault', 'pg-btnMd', 'pg-mx-2']} style={{ height: '35px' }}>
                <Link to={window.location.pathname.includes('nwvms') ? '/nwbilling/nwvms' : '/nwbilling/nwfm'}> {t`Show invoice`}</Link>
              </Button>
            </div>
          </>
        ) : (
          <>
            <Icon src={this.state.img} width={200} />
            <div className={'pg-mt-3 pg-mb-6'}> {t`Payment Successfully Completed!`}</div>
          </>
        )}
        {/* <Button className={['pg-btnSuccess', 'pg-btnSm']} onClick={this.props.handleClose}>{t`Ok`}</Button> */}
      </div>
    )
  }
}
const mapStateToProps = (state: any) => ({
  loading: state.loading.isLoading,
  invoiceItem: state.account.invoiceItem
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    payInvoice: (value: any) => dispatch(payInvoice(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDone)
