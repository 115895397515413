import * as React from 'react'
import { t } from 'ttag'

// ui-elements
import { Hr } from '../../Hr/Hr'
import { Button } from '../../Button/Button'
import { IconLink } from '../../Icon/IconLink'

// icons
import uploadIcon from '../../../../images/Icons/commonIcons/upload.svg'

// internal components & styles

import { connect } from 'react-redux'

import styles from './DownloadBar.module.scss'
import { formatBytes } from '../../../../services/internal/utils/formatBytes'
import { ClipBoard } from '../../Clipboard/Clipboard'

export interface Iprops {
  onItemClick?: (e: any) => void
  item?: any
}

const DownloadBarImage: React.FunctionComponent<Iprops> = ({ onItemClick,item }) => {

  return (
    <div className={styles.downloadBar}>
      <Button className={['pg-btnSuccess', 'pg-btnLg']} style={{ marginBottom: '15px' }}>
        <IconLink icon={uploadIcon} iconAlt="upload icon" label={t`Download File`} onClick={onItemClick} />
      </Button>
      <div className={styles.downloadBox}>
        آمار: {item.downloadCount ? JSON.stringify(item.downloadCount) : '0'} دانلود | <div className={styles.sizeBox}> حجم فایل:{item.size}</div>{' '}
      </div>
      <div className="pg-w-full pg-justify-center">
        <div className={'pg-justify-center pg-text-xs pg-w-full pg-text-gray-800 pg-mb-2'}>{t`Download direct link:`}</div>
        <ClipBoard placeholder={`${process.env.REACT_APP_URL}/preview/${item.uuid}/image/${item.name}`} />
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({ item: state.sidebar.item })

export default connect(mapStateToProps)(DownloadBarImage)
