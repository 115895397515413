import * as React from 'react'
import { t } from 'ttag'

// ui-elements
import { Progressbar } from '../../../ui-elements/Progressbar/Progressbar'
import { IconLink } from '../../../ui-elements/Icon/IconLink'

// icons & styles
import arrowLeftIcon from '../../../../images/Icons/commonIcons/arrow-left.svg'
import styles from './UpgradeAccount.module.scss'
import { Link } from 'react-router-dom'

export default interface Iprops {
  percent?: number
  forVM?: boolean
  forPreview?: boolean
}

export const UpgradeAccount = ({ percent = 0, forVM, forPreview }: Iprops) => {
  // console.log(percent)
  percent = +percent.toFixed(0)
  return (
    <div className={forVM || forPreview ? 'pg-border pg-border-gray-300 pg-mt-20 pg-py-3 pg-px-1 pg-rounded-sm' : styles.upgradeAccount}>
      <div className={'pg-flex pg-justify-center pg-flex-col pg-h-full pg-px-4'}>
        <div className={'pg-font-vLight '}>
          ٪{percent} {t`Of your capacity is used`}
        </div>
        <div className="pg-py-1 pg-w-full">
          <Progressbar value={percent} height={8} color={'green'} />
        </div>
        <span className={`pg-font-vLight  pg-text-xs pg-text-gray-800 ${styles.deleteQus}`}>{t`Do not want to delete your files?`}</span>
      </div>
      <div>
        <Link to={'/nwaccount/plans/upgrade'}>
          <IconLink
            icon={arrowLeftIcon}
            className={
              forVM || forPreview
                ? `hide`
                : `a pg-pt-8p pg-pb-8p pg-pr-6 pg-pl-6 pg-cursor-pointer pg-font-vMedium pg-text-gray-800 pg-bg-yellow-300 pg-h-10 pg-flex pg-flex pg-items-center ${styles.bottom}`
            }
            iconAlt="arrow-left"
            label={t`Upgrade the account`}
          />
        </Link>
      </div>
    </div>
  )
}
