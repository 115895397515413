import { actionTypes } from '../actions/types'

export interface PayloadInterface {
  data?: object[]
}

export const initialState: any = {
  hostsArray: [],
  domainsArray: [],
  domainAvailable: ''
}

const hostReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_HOSTS:
      return {
        ...state,
        hostsArray: action.payload
      }
    case actionTypes.SET_DOMAINS:
      return {
        ...state,
        domainsArray: action.payload
      }
    case actionTypes.DOMAIN_AVAILABLE_STATUS:
      return {
        ...state,
        domainAvailable: action.payload
      }
    default:
      return state
  }
}

export default hostReducer
