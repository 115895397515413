import React, { useState } from 'react'
import moment from 'moment';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
const format = 'h:mm a';

const now = moment().hour(0).minute(0);

function onChange(value:any) {
  console.log(value && value.format(format));
}
export const TimePickerComponent: React.FunctionComponent<any> = ({ view, changeView }) => {

  return (
    <TimePicker
    showSecond={false}
    defaultValue={now}
    className="xxx"
    onChange={onChange}
    format={format}
    use12Hours
    inputReadOnly
  />
  )
}
