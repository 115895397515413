import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// styles
import style from '../TableOrder.module.scss'

import diskIcon from '../../../../images/Icons/vmIcons/disk.svg'
import cpuIcon from '../../../../images/Icons/vmIcons/cpu.svg'
import ramIcon from '../../../../images/Icons/vmIcons/ram.svg'

import { Button } from '../../../ui-elements/Button/Button'
import { IconLink } from '../../../ui-elements/Icon/IconLink'
import HPC from '../../OldOrder/Steps/HPC'
import { formatPrice } from '../../../../services/internal/utils/formatPrice'
import { switchTimeLang } from '../../../../services/internal/utils/switchTimeLang'
import { StepDescription } from '../../OldOrder/Steps/Details/StepDescription'
import { Loading } from '../../../loading/Loading'

//actions
import { selectVsCard } from '../../../../services/internal/store/actions'

class TableOrder extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      expanded: false,
      activeRow: '',
      index: 0,
      finalInvoice: [],
      disableButton: true,
      stepsPathName: ['choose-plan', 'final-step'],
      stepsMainName: [t`Select a Plan`, t` Submit`]
    }
  }

  componentDidMount() {
    this.props.vmProducts &&
      this.props.vmProducts.map((product: any, index: any) => {
        if (this.props.vsCardMain && this.props.vsCardMain.id == product.id) this.setState({ index })
      })
  }

  onClick = (e: any, index: any, price: any) => {
    this.props.selectVsCard(e)
    if (window.location.pathname.includes('landing')) {
      let tax = 1.09
      this.props.setFinalPrice(price && formatPrice(price * tax))
    }
    this.setState({ index, activeRow: index })
  }

  hoverOn = (activeRow: any) => {
    this.setState({ activeRow })
  }

  hoverOff = () => {
    this.setState({ activeRow: '' })
  }

  getPrice = (serviceDuration: any, price: any, defaultPrice: any) => {
    if (price) {
      switch (serviceDuration) {
        case 'Day':
          price = 0
          break
        case 'YEAR':
          price = formatPrice(price / 12)
          break
        case 'SMONTH':
          price = formatPrice(price / 6)
          break
        case 'TMONTH':
          price = formatPrice(price / 3)
          break
        case 'TYEAR':
          price = formatPrice(price / 24)
          break
        default:
          price = formatPrice(price)
          break
      }
    } else if (!price && defaultPrice) price = formatPrice(defaultPrice)
    else if (!price && !defaultPrice) price = '-'
    return price
  }

  renderTable = () => {
    let { activeRow } = this.state
    let { vmProducts, serviceDuration, os, osName } = this.props
    const serviceDurationIndex = switchTimeLang(serviceDuration, 'index')
    let number = -1
    let osDisplayName: any
    if (os && os.length > 1) osDisplayName = os[1]
    if (osName) osDisplayName = osName
    let removeCards = ['Ubuntu Desktop 16.04', 'ubuntu16.ds', 'Windows Server 2012', 'win2012', 'win2016' ,'win2019']
    if (removeCards.includes(os) || removeCards.includes(osDisplayName)) {
      number = 1
    }
    return vmProducts.map((each: any, index: any) => {
      let price = this.getPrice(serviceDuration, each.jsonPrices3[serviceDurationIndex], each.jsonPrices3[0])
      let tdsData = [
        { label: each.name },
        { label: each.cpu, icon: cpuIcon, linkStyle: { marginRight: '4px', marginLeft: '4px' } },
        { label: each.disk, icon: diskIcon, linkStyle: { marginRight: '4px', marginLeft: '4px' } },
        { label: each.ram, icon: ramIcon, linkStyle: { marginRight: '4px', marginLeft: '4px' } },
        { label: each.unlimited },
        {
          label: (
            <Button
              className={[
                'pg-pgBtn',
                activeRow === index || (this.state.index === index && this.props.finalPrice) ? 'pg-btnPrimary' : 'pg-btnDefault',
                'pg-btnMd'
              ]}
              style={{ width: '135px' }}
            >
              {price}
            </Button>
          )
        }
      ]
      let isTempDisabled = false
      let tempDisable:any = []
      if (tempDisable.includes(each.name)) isTempDisabled = true
      return (
        <tr
          key={index}
          onMouseEnter={() => this.hoverOn(index)}
          onMouseLeave={this.hoverOff}
          className={
            isTempDisabled
              ? style.disableRow
              : !each.jsonPrices3[serviceDurationIndex] || index <= number
              ? style.disableRow
              : index == this.state.index
              ? style.active
              : ''
          }
          {...((each.jsonPrices3[serviceDurationIndex] || index <= number) &&
            !isTempDisabled && {
              onClick: () => this.onClick([each, each], index, each.jsonPrices3[serviceDurationIndex])
            })}
        >
          {tdsData.map((td: any, index: any) => (
            <td className={'pg-text-xs'} key={index}>
              <div className={style.valueTextSm}>
                {td.icon ? <IconLink linkStyle={td.linkStyle} icon={td.icon} iconAlt="cpu icon" label={td.label} /> : td.label}
              </div>
            </td>
          ))}
        </tr>
      )
    })
  }

  render() {
    let { vmProducts, serviceDuration } = this.props
    let steps = this.state.stepsMainName
    let header = [t`Plan Name`, t`cpu`, t`ram`, t`disk`, t`Band Width`, t`price/month`]
    return (
      <div className={`pg-bg-white  pg-w-full ${style.tableScroll}`}>
        <div className={'pg-mb-3 pg-flex pg-w-full'}>
          <div className={'pg-mb-3 pg-flex pg-w-full pg-justify-between'}>
            <StepDescription
              stepNumber={1}
              title={[t`Third step`, `:`, steps[2]].join(' ')}
              subTitle={t`Select the root configuration you want to create.`}
            />
          </div>
        </div>
        <div className={'pg-px-3 pg-py-4'}>
          {vmProducts.length < 1 ? (
            <div className={'pg-pb-8'}>
              <Loading />
            </div>
          ) : serviceDuration !== 'DAY' ? (
            <table className={style.tableOrder}>
              <thead>
                <tr>
                  {header &&
                    header.map((each: any, index: any) => (
                      <th className={'pg-text-xs'} key={index}>
                        <div className={style.valueTextSm}>{each}</div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>{this.renderTable()}</tbody>
            </table>
          ) : (
            <div className={'pg-p-4'}>
              <HPC steps={this.props.steps} noHeader={true} />{' '}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  os: state.vm.os,
  finalPrice: state.vm.finalPrice,
  vmProducts: state.vm.vmProducts2,
  serviceDuration: state.vm.serviceDuration,
  vsCardMain: state.vm.vsCardMain,
  steps: state.vm.steps
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectVsCard: (value: any) => dispatch(selectVsCard(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableOrder)
