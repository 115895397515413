import React from 'react'
import styles from './LoadingOverlay.module.scss'
import loadingIcon from '../../images/loading/loading.gif'
import { Icon } from '../ui-elements/Icon/Icon'

export const withLoadingOverLay = (ComposedComponent: any) =>
  class extends React.Component<any, any> {
    render() {
      return this.props.loading ? (
        <div className={`pg-relative ${this.props.className}` }>
          <div className={styles.overlay} />
          <div className={styles.loading}>
            <Icon src={loadingIcon} width={50} />
          </div>
          <ComposedComponent {...this.props} />
        </div>
      ) : (
        <ComposedComponent {...this.props} />
      )
    }
  }
