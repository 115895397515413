import { actionTypes } from './types'
import { actionBuilder } from './actionBuilder'

export const selectOs = actionBuilder(actionTypes.SELECT_OS, 'payload','id','name')
export const goToNextStep = actionBuilder(actionTypes.STEP_FORWARD, 'payload')
export const goToPreviousStep = actionBuilder(actionTypes.STEP_BACKWARD, 'payload')
export const getOses = actionBuilder(actionTypes.GET_OSES)
export const setOses = actionBuilder(actionTypes.SET_OSES, 'payload')
export const getVmproducts = actionBuilder(actionTypes.GET_VM_PRODUCTS)
export const setVmproducts = actionBuilder(actionTypes.SET_VM_PRODUCTS, 'payload','array')

export const selectStep = actionBuilder(actionTypes.SELECT_STEP, 'payload')
export const getNetwork = actionBuilder(actionTypes.GET_NETWORK)
export const setNetwork = actionBuilder(actionTypes.SET_NETWORK, 'payload')

export const setServiceDuration = actionBuilder(actionTypes.SET_SERVICE_DURATION, 'payload',)
export const selectVsCard = actionBuilder(actionTypes.SELECT_VS_CARD, 'payload')
export const selectVsCardDay = actionBuilder(actionTypes.SELECT_VS_CARD_DAY, 'payload')
export const setPromo = actionBuilder(actionTypes.SET_PROMO, 'payload')
export const getVmBills = actionBuilder(actionTypes.GET_VM_BILLS, 'payload')
export const setvmBills = actionBuilder(actionTypes.SET_VM_BILLS, 'payload','array')
export const setvmBillsNamed = actionBuilder(actionTypes.SET_VM_BILLS_NAMED, 'payload','array')
export const getVmBillsNamed = actionBuilder(actionTypes.GET_VM_BILLS_NAMED, 'payload')
export const activeIdle = actionBuilder(actionTypes.ACTIVE_IDLE, 'payload')
export const doneFirstStep = actionBuilder(actionTypes.DONE_FIRST_STEP, 'payload')

export const createInvoice = actionBuilder(actionTypes.CREATE_INVOICE, 'payload')
export const setName = actionBuilder(actionTypes.SET_NAME, 'payload')
export const selectVm = actionBuilder(actionTypes.SELECT_VM,'payload')

export const deleteOs = actionBuilder(actionTypes.DELETE_OS,'payload')
export const deleteVm = actionBuilder(actionTypes.DELETE_VM,'payload')
export const power = actionBuilder(actionTypes.POWER,'payload')
export const setVmId = actionBuilder(actionTypes.SET_VM_ID,'payload')

export const cancelChanges = actionBuilder(actionTypes.CANCEL_CHANGES,'payload')
export const calculateLeftCredit = actionBuilder(actionTypes.CALCULATE_LEFT_CREDIT,'payload')
export const setLeftCredit = actionBuilder(actionTypes.SET_LEFT_CREDIT,'payload')

export const changeVmBillPlan = actionBuilder(actionTypes.CHANGE_VM_PLAN,'payload')
export const retryVm = actionBuilder(actionTypes.RETRY_VM,'payload')
export const renewalVm = actionBuilder(actionTypes.RENEWAL_VM,'payload')
export const renewalDelete = actionBuilder(actionTypes.RENEWAL_DELETE,'payload')
export const setVpsPeriod = actionBuilder(actionTypes.SET_VPS_PERIOD,'payload')
export const deleteAndCreateInvoice = actionBuilder(actionTypes.DELETE_INVOICE,'payload')
export const updateVm = actionBuilder(actionTypes.UPDATE_VM,'payload')
export const setVMBillError = actionBuilder(actionTypes.SET_VM_BILL_ERROR, 'payload')
export const setFinalPrice = actionBuilder(actionTypes.SET_FINAL_PRICE, 'payload')


export const deleteUpgradeVm = actionBuilder(actionTypes.DELETE_UPGRADE_VM, 'payload')
export const changeVmInfo = actionBuilder(actionTypes.CHANGE_VM_INFO, 'payload')