import { actionTypes } from '../actions/types'

export interface StateInterface {
  documents?: ItemInterface[]
  tem_doucuments: any[]
  modal_documents?: any[]
  value?: any[]
  uploadFiles?: any[]
  response?: any[]
  uploadingFile?: []
}

export interface ItemInterface {
  boost?: object
  cfsFullPath?: string
  createdAt?: number
  deleted?: boolean
  discriminator?: string
  docLocations?: any
  downloadCount?: number
  favourite?: boolean
  fullPath?: string
  genericType?: string
  hasPassword?: boolean
  id?: number
  name?: string
  owner: { displayName: string; id: number; md5: string }
  preview?: boolean
  purchased?: boolean
  shared?: boolean
  sharingStatus: string
  subdomain?: boolean
  treePath?: number
  updatedAt?: number
  uuid?: string
  mimeType?: string
  properties?: string
  uploadProgress?: any
  dragInfo?: any
  size?: number
}

export interface PayloadInterface {
  documents?: ItemInterface[]
  tem_doucuments: any[]
  modal_documents?: any[]
  value?: any[]
  response?: any[]
}

export interface ActionInterface {
  type: string
  payload: PayloadInterface
}

export const initialState: any = {
  documents: [],
  tem_doucuments: [],
  dragInfo: [null, null],
  modal_documents: [],
  uploadingFile: '',
  lastChild: false,
  uploadCount:0,
  parentId: '',
  uploader: '',
  uploadFiles: [],
  uploadProgress: [],
  dragNdropState: false,
  uploadModal: false
}

const documentReducer = (state: StateInterface = initialState, action: any) => {
  //console.log(action)
  switch (action.type) {
    case actionTypes.CREATE_FOLDER:
      return {
        ...state,
        value: action.payLoad
      }
    case actionTypes.SET_DRAG_INFO:
      return {
        ...state,
        dragInfo: action.payload
      }
    case actionTypes.SET_UPLOAD_COUNT:
      return {
        ...state,
        uploadCount: action.payload
      }
    case actionTypes.SET_DRAG_DROP_STATE:
      return {
        ...state,
        dragNdropState: action.payload
      }
    case actionTypes.RENAME_FOLDER:
      return {
        ...state,
        value: action.payLoad
      }
    case actionTypes.SET_UPLOAD_MODAL:
      return {
        ...state,
        uploadFiles: action.payload == false ? [] : state.uploadFiles,
        uploadModal: action.payload
      }
    case actionTypes.SET_UPLOAD_FILES:
      //console.log(state)
      return {
        ...state,
        uploadFiles: action.payload
      }
    case actionTypes.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload
      }
    case actionTypes.SET_MODAL_DOCUMENTS:
      return {
        ...state,
        modal_documents: action.data
      }
    case actionTypes.SET_LAST_CHILD:
      return {
        ...state,
        lastChild: action.lastChild
      }
    case actionTypes.SET_RESPONSE:
      return {
        ...state,
        response: action.payload
      }
    case actionTypes.UPLOAD_SERVER:
      return {
        ...state,
        uploadingFile: action.payload
      }
    case actionTypes.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload
      }

    case actionTypes.SET_TEMP_DOCUMENTS:
      return {
        ...state,
        temp_documents: action.payload
      }
    case actionTypes.SET_PARENT_ID:
      return {
        ...state,
        parentId: action.payload
      }
    case actionTypes.SET_UPLOADER:
      return {
        ...state,
        uploader: action.payload
      }
    default:
      return state
  }
}

export default documentReducer
