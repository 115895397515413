import * as React from 'react'
import { Link } from 'react-router-dom'
import { t } from 'ttag'

// ui-elements
import { IconLink } from '../../ui-elements/Icon/IconLink'

// icons
import fileCloudIcon from '../../../images/Icons/navbarIcons/file-cloud.svg'
import vpsIcon from '../../../images/Icons/navbarIcons/vps.svg'
import ticketIcon from '../../../images/Icons/navbarIcons/finance-copy.svg'

export default interface Iprops {}

export const Nav: React.FunctionComponent<Iprops> = () => {
  const altIcon = 'Icon'
  return (
    <div className="pg-mt-4 pg-mb-2 nav">
      {/* <Link to={`/nwfm`}>
        <IconLink
          icon={fileCloudIcon}
          className="pg-mb-13p pg-text-gray-700 iconLink"
          iconAlt={`content-delivery ${altIcon}`}
          label={t`File hosting`}
        />
      </Link> */}
      <Link to={`/nwvms`}>
        <IconLink icon={vpsIcon} className="pg-mb-13p pg-text-gray-700 iconLink" iconAlt={`vps ${altIcon}`} label={t`Virtual Server`} />
      </Link>
      <a href="http://us.cdn.persiangig.com/vm/support">
        <IconLink icon={ticketIcon} iconAlt={`vps ${altIcon}`} className="pg-mb-13p pg-text-gray-700 iconLink" iconWidth={24} label={t`Support`} />
      </a>
      {/* <abbr>
        <IconLink icon={vpsIcon} className="pg-mb-13p pg-text-gray-700 iconLink" iconAlt={`vps ${altIcon}`} label={t``} />
      </Link> */}
      {/* <IconLink icon={internetIcon} className="pg-mb-13p pg-text-gray-700 iconLink" iconAlt={`Internet ${altIcon}`} label={t`Internet`} />
      <IconLink icon={notifIcon} className="pg-mb-13p pg-text-gray-700 iconLink" iconAlt={`notif ${altIcon}`} label={t`Tickets`} />
      <IconLink icon={financeIcon} className="pg-mb-13p pg-text-gray-700 iconLink" iconAlt={`finance ${altIcon}`} label={t`Invoices`} /> */}
    </div>
  )
}
