export const translate = (word: string) => {
  let translate
  switch (word) {
    case 'BASE':
      translate = 'قیمت پایه'
      break
    case 'DISK':
      translate = 'دیسک‌'
      break
    case 'CPU':
      translate = 'پردازنده'
      break
    case 'RAM':
      translate = 'رم'
      break
    case 'CANCEL':
      translate = 'لغو'
      break
    default:
      translate = word
      break
  }
  return translate
}
