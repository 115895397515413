import React, { useState } from 'react'
import { AreaChart, Area, ResponsiveContainer, YAxis, CartesianGrid, Tooltip } from 'recharts'

export const LegendBox: React.FunctionComponent<any> = ({ number, lines, value, color }) => {
  return (
    <div className={'pg-w-full pg-flex pg-flex-col'}>
      <span>Computer</span>
      <span>{number+1}</span>
      {lines.map((line: any) => {
        return (
          <span className={'pg-flex pg-items-center pg-text-10p pg-w-full'} style={{color:`${line.fontColor}`}}>
            <span style={{ height: '5px', width: '10px', backgroundColor: `${line.color}`,display:'flex' ,marginRight:'2px'}} />
            <span className={''}>{line.label} : </span>
            <span>{line.value}</span>
          </span>
        )
      })}
    </div>
  )
}
