import { t } from 'ttag'

export const errors: any = {
  fa: {
    5002: 'اکانت شما قفل شده است.',
    9002: 'نام کاربر یا رمز عبور اشتباه است',
    2: 'مشکلی رخ داده است. لطفا مجددا تلاش کنید'
  },
  en: {
    5002: t`User account is locked`,
    9002: 'Username or password is invalid',
    2: 'Something went wrong. please try again'
  }
}
export const getErrorMessage = (errorCode: number | string, errorMsg?: any) => {
  let langCode = localStorage.getItem('__language') || 'fa'
  const err = errors[langCode][+errorCode]

  return err ? err[0].toUpperCase() + err.substr(1) : errorMsg
}
