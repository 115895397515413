import React from 'react'
import styles from './Skeleton.module.scss'
import styles2 from '../ui-elements/Card/Card.module.scss'
export const GridSkeleton: React.FunctionComponent<any> = ({ rowsNumber = 5 }) => {
  return (
    <div className={styles.container}>
      {Array.from(Array(rowsNumber), (e, i) => (
        <div className={styles2.item} key={i}>
          <div className={styles.loadingBigPic}/>
          <div className={styles.loadingLineSm} />
          <div className={styles.loadingLineM} />
        </div>
      ))}
    </div>
  )
}
