import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

import Navbar from '../../components/Navbar/Navbar'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import { Main } from '../../components/ui-elements/Main/Main'
import Content from '../../components/Content/Content'
import VMContent from '../../components/VMContent/Content/VMContent'
import toast from '../../components/ui-elements/Toast/Toast'
import Account from '../Account/Account'
import Billing from '../Account/Billing/Billing'
import HTML5Backend from 'react-dnd-html5-backend'
//styles
import './App.css'

//services
import { removeMessages, getUserInfo, setHistory, getSettingState } from '../../services/internal/store/actions'
import { DndProvider } from 'react-dnd'
import { MainFooter } from '../../components/ui-elements/MainFooter/MainFooter'
import HypePage from '../AdminPanel/HypeMonitor/HypePage'

interface Iprops {
  getUserInfo: () => void
  getSettingState: () => void
  removeMessages: () => void
  setHistory: (e: any) => void
  messages?: any
  open: boolean
  history: any
  previewState?: boolean
  language?: any
  isVmDisabled?: boolean
  isUploadDisabled?: boolean
}
interface Istate {
  isOpenMenu: boolean
}

class App extends Component<Iprops, Istate> {
  constructor(props: any) {
    super(props)
    this.state = {
      isOpenMenu: false
    }
  }

  componentDidMount() {
    this.props.setHistory(this.props.history)
    this.props.getUserInfo()
    this.props.getSettingState()
  }

  toggleHamburgerMenu = () => {
    this.setState({
      isOpenMenu: !this.state.isOpenMenu
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.messages && nextProps.messages.errors && nextProps.messages.errors.length > 0) {
      if (nextProps.messages.errors !== this.props.messages.errors) toast.error(nextProps.messages.errors)
      this.props.removeMessages()
    }

    if (nextProps.messages.msgs.length > 0) {
      toast.success(nextProps.messages.msgs)
      this.props.removeMessages()
    }
  }

  render() {
    let language = this.props.language && this.props.language.language && this.props.language.language.code
    return (
      <>
        <DndProvider backend={HTML5Backend} options={{ enableTouchEvents: false, enableMouseEvents: true }}>
          <Navbar toggleHamburgerMenu={this.toggleHamburgerMenu} previewState={this.props.previewState} />
          <Sidebar
            open={this.state.isOpenMenu}
            sidebarOpen={this.props.open}
            onClickOverlay={this.toggleHamburgerMenu}
            isVmDisabled={this.props.isVmDisabled}
            isUploadDisabled={this.props.isUploadDisabled}
          />
          <Main
            showSideBar={this.props.open}
            previewState={this.props.previewState}
            language={language}
            footer={
              <>
                Powered by <a className="pg-text-blue-400 pg-font-vMedium">PersianGig</a> IaaS Cloud Platform
              </>
            }
          >
            <Switch>
              <Route path={`/nwfm`} component={Content} />
              <Route path={`/nwaccount`} component={Account} />
              <Route path={`/nwadmin-area/stats/hypervisors`} render={props => <HypePage open={this.props.open} {...props} />} />
              <Route path={`/nwbilling`} render={props => <Billing open={this.props.open} {...props} />} />
              <Route path={`/nwvms`} render={() => <VMContent isDisabled={this.props.isVmDisabled} />} />
              <Redirect path="/" to="/nwvms" />
            </Switch>
          </Main>
          {!this.props.previewState && (
            <MainFooter
              footer={
                <>
                  Powered by{' '}
                  <a className="pg-text-blue-400 pg-font-vMedium" href="https://www.persiangig.com/">
                    PersianGig
                  </a>{' '}
                  IaaS Cloud Platform
                </>
              }
              language={language}
            />
          )}
        </DndProvider>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  open: state.sidebar.open,
  messages: state.messages,
  previewState: state.sidebar.previewState,
  language: state.account.info,
  isVmDisabled: state.account.disableCreateVm.status,
  isUploadDisabled: state.account.disableUpload.status
})
const mapDispatchToProps = (dispatch: any) => {
  return {
    removeMessages: () => dispatch(removeMessages()),
    getUserInfo: () => dispatch(getUserInfo()),
    getSettingState: () => dispatch(getSettingState()),
    setHistory: (value: any) => dispatch(setHistory(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
