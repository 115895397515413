import React, { useState, } from 'react'
import { t } from 'ttag'
//style

import { Checkbox } from '../../ui-elements/Checkbox/Checkbox'
import { TextInput } from '../../ui-elements/Input/Input'
import { RenderIcon } from '../../RenderIcon/RenderIcon'
import { Button } from '../../ui-elements/Button/Button'

//interface
import { TableItemProps } from './TableBody.interface'

import styles from '../Table.module.scss'

export const splitter = (className: any) => {
  return className && className.map((cls: any) => styles[cls]).join(' ')
}

export const TableItem: React.FunctionComponent<TableItemProps> = ({
  label,
  checkbox,
  name,
  sortable,
  editableId,
  onSort,
  id,
  sortType,
  className,
  mimetype,
  handleNavigate,
  onCheckAll,
  checked,
  itemName,
  selection,
  item,
  hovered,
  selectableRows,
  onCheck,
  shareButton,
  hasIcon,
  renameText,
  openModal,
  checkboxType,
  handleChange,
  style,
  uuid
}) => {
  let imgSrc = item && `${process.env.REACT_APP_URL}/preview/${uuid}/medium/${item.name}`
  const [hoveredButton, setHovered] = useState(false)
  const toggleHover = () => setHovered(!hoveredButton)
  // const [{ isDragging }, drag, preview] = useDrag({
  //   item: { type: 'ROW' },
  //   collect: monitor => ({
  //     isDragging: !!monitor.isDragging()
  //   })
  // })
  return (
    <td
      data-label={name}
      // ref={drag}
      className={className ? splitter(className) : ''}
      style={style}
      align={'center'}
      {...(sortable && { onClick: () => onSort && onSort(label, sortType) })}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <div className={name == 'index' || name == t`ردیف` ? 'rowItem pg-flex pg-justify-center' : 'rowItem'}>
        {checkbox && selectableRows && (
          <div className={selection && selection.length > 0 ? 'rowItem' : hovered ? 'rowItem' : [styles.invisible, 'rowItem'].join(' ')}>
            <Checkbox type={checkboxType}
            //  onChange={(e: any) =>alert('hi')} 
             checked={checked} /> 
          </div>
        )}
        <div
          className={'rowItem'}
          {...(handleNavigate && { onClick: e => handleNavigate({ e: e, name: itemName, id: id, uuid: uuid, item: item && item.item }) })}
        >
          {hasIcon && <RenderIcon mimetype={mimetype} imgSrc={imgSrc} name={name} id={'icon'} />}
          {/* //   mimetype && mimetype === 'image' ? ( 
          //     <Icon src={imgSrc} className={'imageIcon icon'} />
          //   ) : (
          //     <Icon mimetype={window.location.pathname.includes('trash') && name == 'name' ? 'icon' : mimetype} />
          //   )
          // ) : (
          //   ''
          // )} */}
          <div className={styles.name}>
            {name == 'name' && editableId == id ? (
              <div>
                <TextInput placeholder={label} value={renameText} onChange={handleChange} />
              </div>
            ) : (
              <span className={name == 'index' || name == t`index` ? 'pg-text-center' : ''}> {label} </span>
            )}
          </div>
        </div>
        {sortable && (
          <div className={styles.sort}>
            <span>▲</span>
            <span>▼</span>
          </div>
        )}
        {shareButton && (
          <Button
            className={hoveredButton ? ['pg-btnPrimary ', 'pg-btnPrimaryOutline'] : ['pg-btnDefault', 'pg-btnDefaultOutline']}
            extraClassName={[styles.share, hovered ? '' : styles.invisible]}
            onClick={() => openModal(id, t`Share File`)}
          >
            {t`share`}
          </Button>
        )}
      </div>
    </td>
  )
}
