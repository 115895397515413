import { RestInterface } from '../../external/rest/rest'
import { throwError } from '../utils/sentry'

export interface AccountInterface {}

class Account implements AccountInterface {
  private _rest: RestInterface
  date: number
  constructor(rest: RestInterface) {
    this._rest = rest
    let date = new Date()
    let timestamp = date.getTime()
    this.date = timestamp
  }

  async getSettingState() {
    const url = '/vm/settings'
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async getUserInfo() {
    const url = '/cfs/rest/users/currentUser'
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async getTransactions() {
    const url = '/cfs/rest/transactions?where={%22transactionType%22:{%22$neq%22:%22GIFT%22}}&sort=-createdAt'
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }

  async getInvoices(product: string = 'CWS') {
    let url = `/cfs/rest/invoices?where=[{"product.category":{"$eq":"CFS"}},{"dueDate":{"$gt":${this.date}},"paid":{"$eq":true}}]&match=AND&matchInner=OR&sort=+paid,-createdAt`

    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async hostInvoices() {
    let url = `/cfs/rest/hostInvoices?where={"invoice.dueDate":{"$gt":${this.date}},"invoice.paid":{"$eq":true}}&match=OR&sort=+invoice.paid,-invoice.createdAt`
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }

  async vmBillInvoices(product: string = 'CWS') {
    let url = `/cws/rest/vmBillInvoices?where={"invoice.product.category":{"$eq":CWS}},{"invoice.dueDate":{"$gt":${this.date}},"invoice.paid":{"$eq":true}}&match=OR&sort=+invoice.paid,-invoice.createdAt`
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async domainInvoices() {
    let url = `cfs/rest/domainInvoices?where={"invoice.dueDate":{"$gt":${this.date}},"invoice.paid":{"$eq":true}}&match=OR&sort=+invoice.paid,-invoice.createdAt`
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }

  async getInvoice(id: number, product?: string) {
    let url
    switch (product) {
      case 'cfs':
        url = `/cfs/rest/invoices/${id}`
        break
      case 'cws':
        url = `/cws/rest/vmBillInvoices?where={%22id%22:{%22$eq%22:${id}}}`
        break
      case 'hosting':
        url = `/cfs/rest/hostInvoices?where={"id":{"$eq":${id}}}`
        break
      case 'domain':
        url = `cfs/rest/domainInvoices?where={"id":{"$eq":${id}}}`
        break
      default:
        url = `/cfs/rest/invoices/${id}`
        break
    }

    try {
      return await this._rest.get({ url, headers: { Accept: '*/*' } })
    } catch (error) {
      throw error
    }
  }
  async getProducts() {
    const url = '/cfs/rest/products/user/products?category=CFS'
    try {
      return await this._rest.get({ url })
    } catch (error) {
      throw error
    }
  }
  async changePlan(id: number, additionalInfo: string, applyNow: boolean) {
    const url = '/cfs/rest/users/changePlan'
    const body = {
      plan: {
        additionalInfo,
        applyNow,
        id
      }
    }
    try {
      return await this._rest.put({ url, body })
    } catch (error) {
      throw error
    }
  }
  async changeProfile(body: any) {
    const url = '/cfs/rest/users/changeProfile?verify=false'
    try {
      return await this._rest.put({ url, body })
    } catch (error) {
      throw error
    }
  }
  async getWallet(body: any) {
    const url = '/cws/rest/giftWallets/getGiftWalletBalance'

    try {
      return await this._rest.post({ url, body })
    } catch (error) {
      throw error
    }
  }
  async addToBalance(amount: any) {
    const url = `/cfs/rest/payments/addToBalance?amount=${amount}`

    try {
      return await this._rest.get({ url ,headers:{Accept: 'application/json'},justData:true})
    } catch (error) {
      throw error
    }
  }

  async doPay(body: any) {
    const url = `/cfs/rest/payments/${body.id}/doPay?gateway=mellat`

    try {
      return await this._rest.put({ url, body })
    } catch (error) {
      throw error
    }
  }
  async payInvoice(body: any, id: any,product:any) {
    const url = `/${product}/rest/invoices/${id}/${body.payType}`
    try {
      return await this._rest.put({ url, body })
    } catch (error) {
      throw error
    }
  }

  async mellatBank(body: any) {
    const url = `https://bpm.shaparak.ir/pgwchannel/startpay.mellat`
    const headers = { 'Content-type': 'application/x-www-form-urlencoded' }

    try {
      return await this._rest.post({ url, headers, body })
    } catch (error) {
      throw error
    }
  }
}

export default Account
