import * as React from 'react'

// styles
import styles from './Stepbar.module.scss'

export default interface Iprops {
  steps: string[]
  currentStep: any
  order?: boolean
  isRtl?: boolean
  horizontal?: any
  onStepClick?: (e: any) => void
}

const renderSteps = (steps: string[], currentStep: number, order: any) => {
  return steps.map((stepTitle, index) => {
    let stepClassName = ''
    currentStep = currentStep || 0

    if (index < currentStep) stepClassName = `${styles.completed}`

    if (index === currentStep) stepClassName = `${styles.current}`

    return (
      <li className={`${styles.step} ${stepClassName} `} key={index}>
        <a className={index == steps.length - 1 ? [styles.item, styles.lastItem].join(' ') : styles.item} style={{ fontSize: '10px' }}>
          <span className={styles.circle} />
          <span className={`${styles.caption} ${order && styles.centerizeCaption}`}>{stepTitle}</span>
        </a>
      </li>
    )
  })
}

export const Stepbar = ({ steps, currentStep, onStepClick, horizontal, isRtl ,order}: Iprops) => (
  <div className={isRtl ? styles.stepbar : [styles.stepbar, styles.isLtr].join(' ')}>
    {horizontal && <div className={styles.horizontalLine} />}
    <ul className={horizontal ? styles.stepsWrapper2 : styles.stepsWrapper}>{renderSteps(steps, currentStep,order)}</ul>
  </div>
)
