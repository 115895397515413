import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { deleteAndCreateInvoice } from '../../../services/internal/store/actions'

import RenderButton from './RenderButton'
import { Button } from '../../ui-elements/Button/Button'
import { IconLink } from '../../ui-elements/Icon/IconLink'
import { VmBrush } from '../../ui-elements/VmBrush/VmBrush'
import { Tooltip } from '../../ui-elements/Tooltip/Tooltip'

import invoiceIcon from '../../../images/Icons/commonIcons/invoice.svg'
import purchaseIcon from '../../../images/Icons/vmIcons/purchase.svg'

const CardFooter: React.FunctionComponent<any> = props => {
  let {
    state,
    vmBillStatus,
    title,
    vpsId,
    cpu,
    brush,
    id,
    purchase,
    disk,
    deleteAndCreateInvoice,
    onClick,
    ram,
    openModal,
    invoiceItem,
    recurringTime,
    buttonState,
    osName,
    paymentId,
    idleDate,
    isDisabled,
    openCardModal
  } = props

  let button = null
  let invoiceObj = {
    id: id,
    payload: {
      name: `${title}`,
      nicInfos: [{ ipCount: 1, network: 'Internet180_0' }],
      os: `${osName}`,
      plan: {
        additionalInfo: JSON.stringify({ cpu: cpu, ram: ram, disk: disk, managed: false }),
        id: vpsId,
        jsonInfo: JSON.stringify({ plan_type: recurringTime })
      }
    }
  }

  button = (
    <RenderButton
      title={title}
      state={state}
      vmBillStatus={vmBillStatus}
      onClick={onClick}
      id={id}
      paymentId={paymentId}
      openCardModal={openCardModal}
      openModal={openModal}
      idleDate={idleDate}
      invoiceItem={invoiceItem}
      buttonState={buttonState}
    />
  )
  if (brush) button = <VmBrush brush={brush} /> 
  if (purchase)
    button = !isDisabled ? (
      <Link to="/nwvms/order" className={'pg-w-full'}>
        <Button className={['pg-btnSuccess', 'pg-btnLg']} style={{ width: '100%' }}>
          <IconLink icon={purchaseIcon} iconAlt="upload icon" label={t`Buy new server`} />
        </Button>
      </Link>
    ) : (
      <Link to="/nwvms/order" className={'pg-w-full'}>
        <Tooltip text={t`buying is disabled`} width={130} height={37} position={'bottom'}>
          <Button className={['pg-btnDisabled', 'pg-btnLg']} style={{ width: '100%' }}>
            <IconLink icon={purchaseIcon} iconAlt="upload icon" label={t`Buy new server`} />
          </Button>
        </Tooltip>
      </Link>
    )
  else if (buttonState && buttonState.includes('expiredVm'))
    button = (
      <Button style={{ width: '100%' }} className={['pg-btnWarning', 'pg-btnLg']} onClick={() => deleteAndCreateInvoice(invoiceObj)}>
        <IconLink icon={invoiceIcon} label={t`Issuance`} />
      </Button>
    )
  return button
}

const mapStateToProps = (state: any) => ({
  vmBills: state.vm.vmBills,
  isDisabled: state.account.disableCreateVm.status
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteAndCreateInvoice: (value: any) => dispatch(deleteAndCreateInvoice(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardFooter)
