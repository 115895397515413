import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { Switch, Route } from 'react-router-dom'

// components
import Plans from '../../components/Plans/Plans'
import { Security } from '../../components/Security/Security'
import UpgradePlans from '../../components/Plans/UpgradePlans'
import { Wallet } from '../../components/Wallet/Wallet'
import PayModal from '../../components/ui-elements/Modal/PayModal/PayModal'
import Transactions from './Transactions/Transactions'
import Profile from './Profile/Profile'

// services
import {
  changePassword,
  getProducts,
  setProductToggle,
  changePlan,
  setInvoiceItem,
  setChangePasswordSuccess
} from '../../services/internal/store/actions'

export interface Iprops {
  changePassword: any
  info: any
  getProducts: any
  setProductToggle: any
  monthly: any
  changePlan: any
  setInvoiceItem?: any
  passwordSuccess?: boolean
  setPasswordSuccess?: any
}

class Account extends React.Component<Iprops, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      newPassword: '',
      repeatPassword: '',
      currentPassword: '',
      input: [],
      modalView: '',
      showModal: false,
      monthly: true,
      planId: props.info && props.info.plan && props.info.plan.id
    }
  }

  componentDidMount = () => {
    this.props.getProducts()
    document.title = t`Account`
  }
  UNSAFE_componentWillReceiveProps(props: any) {
    if (props.info && props.info.plan && props.info.plan.id) {
      this.setState({ planId: props.info && props.info.plan && props.info.plan.id })
    }
  }
  onToggle = (e: any) => {
    e.preventDefault()
    this.setState({ monthly: !this.state.monthly })
    this.props.setProductToggle(!this.props.monthly)
  }

  changePassword = (e: any) => {
    if (e) e.preventDefault()
    if (this.state.repeatPassword && this.state.newPassword == this.state.repeatPassword) {
      this.props.changePassword(this.state.currentPassword, this.state.newPassword)
      this.setState({ currentPassword: '', newPassword: '', repeatPassword: '' })
    } else this.setState({ error: t`passwords don't match` })
  }

  updateChange = (e: any) => {
    this.props.setPasswordSuccess(false)
    this.setState({ error: ``, [e.target.name]: e.target.value, input: [{ name: [e.target.name], value: e.target.value }] })
  }

  onClick = (e: any) => {
    this.setState({ modalView: 'pay', showModal: true, planId: e.target.name })
    // console.log(e.target)
    // this.props.setInvoiceItem()
    this.props.changePlan(e.target.name, `{ plan_type: ${this.props.monthly ? 'MONTH' : 'YEAR'}, managed: false }`, false)
  }
  closeModal = () => {
    if (this.state.modalView) this.setState({ showModal: false, modalView: '' })
  }
  openWalletModal = () => {
    this.setState({ modalView: 'walletModal', showModal: true })
  }
  onCloseModal = () => {
    this.setState({ showModal: false })
  }
  changePlan = (id: number, additionalInfo: string, applyNow: boolean) => {
    this.props.changePlan(id, additionalInfo, applyNow)
    this.setState({ modalView: 'pay', showModal: true})
  }
  render() {
    return (
      <div className={'pg-w-full'}>
        <div className={'pg-py-4'}>
          <Switch>
            <Route path={`/nwaccount/profile`} render={() => <Profile />} />

            <Route
              path={`/nwaccount/plans/upgrade`}
              render={() => <UpgradePlans planId={this.state.planId} monthly={this.props.monthly} onToggle={this.onToggle} onClick={this.onClick} />}
            />
            <Route path={`/nwaccount/plans`} render={() => <Plans changePlan={this.changePlan} />} />
            <Route
              path={`/nwaccount/changePassword`}
              render={() => (
                <Security
                  changePassword={this.changePassword}
                  updateChange={this.updateChange}
                  error={this.state.error}
                  passwordSuccess={this.props.passwordSuccess}
                />
              )}
            />
            <Route
              path={`/nwaccount/wallet`}
              render={() => (
                <Wallet
                  info={this.props.info}
                  openWalletModal={this.openWalletModal}
                  onCloseModal={this.onCloseModal}
                  showModal={this.state.showModal && this.state.modalView === 'walletModal'}
                />
              )}
            />
            <Route path={`/nwaccount/transactions`} render={() => <Transactions />} />
          </Switch>
        </div>
        {this.state.modalView == 'pay' && (
          <PayModal showModal={this.state.showModal} handleCFClose={this.closeModal} payment={this.state.payment == 'done'} />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getProducts: () => dispatch(getProducts()),
    setProductToggle: (value: any) => dispatch(setProductToggle(value)),
    changePassword: (currentPassword: string, newPassword: string) => dispatch(changePassword(currentPassword, newPassword)),
    changePlan: (id: number, additionalInfo: string, applyNow: boolean) => dispatch(changePlan(id, additionalInfo, applyNow)),
    setInvoiceItem: (value: any) => dispatch(setInvoiceItem(value)),
    setPasswordSuccess: (value: any) => dispatch(setChangePasswordSuccess(value))
  }
}
const mapStateToProps = (state: any) => ({
  info: state.account.info,
  monthly: state.account.monthly,
  passwordSuccess: state.auth.passwordSuccess
})
export default connect(mapStateToProps, mapDispatchToProps)(Account)
