import * as React from 'react'
import { Link } from 'react-router-dom'
import { t } from 'ttag'

// ui-elements
import { IconLink } from '../../ui-elements/Icon/IconLink'

// icons
import edit from '../../../images/Icons/typeIcons/icon-edit.svg'
import security from '../../../images/Icons/typeIcons/icon-lock.svg'
import wallet from '../../../images/Icons/typeIcons/icon-wallet.svg'
import transaction from '../../../images/Icons/sidebarIcons/transaction.svg'
import plans from '../../../images/Icons/typeIcons/shopping-bag.svg'
// internal components & styles
import '../Sidebar.scss'
import { Nav } from './Nav'
import { Hr } from '../../ui-elements/Hr/Hr'


export interface Iprops {
  onItemClick?: (e: any) => void
  item?: any
  profileView?: string
  downloadToken?: string
  setProfileTab: any
}

export const AccountSideBar: React.FunctionComponent<Iprops> = () => {
  return (
    <div className="sidebar-menu">
      <Link to={'/nwaccount/profile'}>
        <IconLink
          icon={edit}
          className={`iconLink upFromUrl pg-p-3 ${window.location.pathname == '/nwaccount/profile' && 'pg-bg-gray-0'}`}
          iconAlt="upload icon"
          label={t`Profile`}
        />
      </Link>
      {/*<Link to={'/nwaccount/plans'}>
        <IconLink
          icon={plans}
          className={`iconLink upFromUrl pg-p-3 ${
            (window.location.pathname.includes('plans') && 'pg-bg-gray-0')}`}
          iconAlt="upload icon"
          label={t`Plan`}
        />
      </Link>*/}
      <Link to={'/nwaccount/changePassword'}>
        <IconLink
          icon={security}
          className={`iconLink upFromUrl  pg-p-3 ${window.location.pathname == '/nwaccount/changePassword' && 'pg-bg-gray-0'}`}
          iconAlt="upload icon"
          label={t`Change Password`}
        />
      </Link>
      <Link to={'/nwaccount/wallet'}>
        <IconLink
          icon={wallet}
          className={`iconLink upFromUrl  pg-p-3 ${window.location.pathname == '/nwaccount/wallet' && 'pg-bg-gray-0'}`}
          iconAlt="upload icon"
          label={t`Wallet`}
        />
      </Link>
      <Link to={'/nwaccount/transactions'}>
        <IconLink
          icon={transaction}
          className={`iconLink upFromUrl  pg-p-3 ${window.location.pathname == '/nwaccount/transactions' && 'pg-bg-gray-0'}`}
          iconAlt="upload icon"
          label={t`transactions`}
        />
      </Link>
      <Hr/>
      <Nav />
    </div>
  )
}
