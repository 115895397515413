import { put } from 'redux-saga/effects'
import * as actions from '../actions'

import { bottle } from '../../../index'
import { renderHostInfo } from '../../utils/hosts'

const host = bottle.container.Host
const catchError = bottle.container.CatchError

export function* getHosts() {
  try {
    let result = yield host.getHosts()
    let editedArray = renderHostInfo(result)
    yield put(actions.setHosts(editedArray))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}

export function* orderHost(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield host.orderHost(
      `{product:{id:${action.payload.id}},domain:{domainName:${action.payload.domain},"register":${action.payload.hasDomain},"nicHandle":"undefined"}}`
    )
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* getDomains() {
  try {
    let result = yield host.getDomains()
    let editedArray = renderHostInfo(result)
    yield put(actions.setDomains(editedArray))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}

export function* setAutoRenewal(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield host.autoRenewal(action.payload.id, action.payload.state)
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* isDomainAvailable(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setButtonLoadingState(true))
    yield host.isDomainAvailable(action.payload.domain, action.payload.suffix)
    yield put(actions.domainAvailableStatus(true))
    yield put(actions.setLoadingState(false))
    yield put(actions.setButtonLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.domainAvailableStatus(false))
    yield put(actions.setLoadingState(false))
    yield put(actions.setButtonLoadingState(false))
  }
}

export function* createHostBill(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield host.createHostBill(action.payload.body)
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* deleteBill(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    let Bills = action.payload.bills
    Bills.filter((obj: any) => {
      return obj.id === action.payload.id
    })
    yield put(actions.setMessage('صورت حساب حذف شد'))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
