import React, { useState } from 'react'
import { AreaChart, Area, ResponsiveContainer, YAxis, CartesianGrid, Tooltip } from 'recharts'
import styles from '../Monitoring.module.scss'
export const AreaChartComponenet: React.FunctionComponent<any> = ({ data, label, value, color }) => {
  return (
    <div style={{ width: '100%', height: 50, position: 'relative' }}>
      <span className={`pg-absolute pg-text-xs ${styles.info}`}>
       <span className={'pg-font-black'}> {label}</span > <span className={'pg-text-10p pg-font-medium'}>{value}</span>
      </span>
      <ResponsiveContainer>
        <AreaChart
          height={50}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area type="monotone" dataKey="uv" stroke={color} fill={color} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
