import { Redirect, Route } from 'react-router-dom'
import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { getInvoice, setInvoiceItem } from '../../../services/internal/store/actions'

import { formatPrice } from '../../../services/internal/utils/formatPrice'
import { Button } from '../../../components/ui-elements/Button/Button'
import PayModal from '../../../components/ui-elements/Modal/PayModal/PayModal'
import Invoice from '../../../components/Invoice/Invoice'

class InvoiceContainer extends React.Component<any, any> {
  state = {
    showModal: false,
    payment: ''
  }

  componentDidMount() {
    this.props.getInvoice(window.location.pathname.split('/nwvms/')[1], undefined, 'cws')
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { invoiceItem } = nextProps
    if (invoiceItem) {
      this.setState({ itemPrice: invoiceItem.payablePrice, currentInvoiceItem: invoiceItem })
      if (invoiceItem.payablePrice < this.props.userInfo.balance) this.setState({ payment: 'done' })
    }
  }

  handleClose = () => {
    this.setState({ showModal: false, modalView: '' })
  }

  pay = (e: any, item: any) => {
    e.stopPropagation()
    this.props.setInvoiceItem(item)
    this.setState({ showModal: true })
  }
  
  render() {
    let { persianApprovedAt, payablePrice, paid } = this.props.billingItem
    return (
      <div className={'pg-w-full pg-flex pg-justify-center pg-flex-col pg-bg-white pg-border-blue-400 pg-border-2'}>
        {this.props.billingItem && !paid && (
          <div className={'pg-w-full pg-flex pg-justify-center'}>
            <div
              className={
                'pg-w-3/4 pg-border pg-mx-20 pg-justify-center pg-my-4 pg-flex pg-p-4  pg-border-green-400 pg-border-1 pg-rounded-sm pg-justify-between'
              }
            >
              <div className={'pg-flex pg-justify-center pg-items-center'}>
                {t`Pay`} {persianApprovedAt} {t`Costing`} {formatPrice(payablePrice)}
              </div>
              <div>
                <Button
                  isArray={true}
                  name={this.props.billingItem}
                  className={['pg-btnPrimary', 'pg-btnMd', 'pg-px-2']}
                  style={{ height: 35 }}
                  onClick={this.pay}
                >
                  {t`Pay`}
                </Button>
              </div>
            </div>
          </div>
        )}
        <Invoice invoiceObj={this.props.billingItem} className={'pg-flex pg-justify-center'} noBorder={true} shareInvoice={true} />
        <PayModal showModal={this.state.showModal} handleCFClose={this.handleClose} payment={this.state.payment == 'done'} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getInvoice: (payload: any, index: any, invoiceType: any) => dispatch(getInvoice(payload, index, invoiceType)),
    setInvoiceItem: (value: any) => dispatch(dispatch(setInvoiceItem(value)))
  }
}
const mapStateToProps = (state: any) => ({
  loading: state.loading.isLoading,
  billingItem: state.account.billingItem,
  invoiceItem: state.account.invoiceItem,
  userInfo: state.account.info
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceContainer)
