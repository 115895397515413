import React from 'react'
import { t } from 'ttag'

import remote from '../../../images/remote.png'

import styles from '../VMDetails.module.scss'

export interface Iprops {
  ip?: string
  windows?: boolean
}

export const VMRemote: React.FunctionComponent<Iprops> = ({ ip, windows }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t`Connect to server via SSH"`}</div>
      {!windows ? (
        <>
          <div className={styles.codeWrapper}>
            <pre>$ ssh root@{ip}</pre>
          </div>
          <span className={`${styles.hint} pg-flex`}>
            {t`* The password has been sent to your email.`}
          </span>
        </>
      ) : (
        <div className={'pg-flex'}>
          <div className={'pg-mt-4 pg-p-4 pg-w-1/2'}>
            به سرور مجازی از طریق اتصال Remote به منوی استارت رفته و عبارت remote را تایپ کنید و برنامه Remote Desktop Connection را باز کنید.در گوشه
            سمت چپ گزینه‌ی Options را کلیک کنید.آدرس آی پی ({ip}) و نام کاربری خود (Administrator) را وارد کنید و کلید enter را بزنید.در پنجره Next
            رمز عبور خود را که به ایمیل شما ارسال شده است، وارد کنید تا وارد میز کار سرور مجازی خود شوید.
          </div>
          <div className={'pg-relative pg-w-1/2'}>
            <img src={remote} alt="" className={styles.windowsImg} />
            <input value={ip} className={styles.windowsInput} />
            <input value={'Administrator'} className={styles.windowsAdministrator} />
          </div>
        </div>
      )}
    </div>
  )
}
