import React, { useState } from 'react'
import { AreaChart, Area, ResponsiveContainer, YAxis, CartesianGrid, Tooltip } from 'recharts'
import  SimpleLineChart from './SimpleLineChart'
import { LegendBox } from './LegendBox/LegendBox'

export const DataChart: React.FunctionComponent<any> = ({ index, label, value, color }) => {
  let color1 = '#8884d8'
  let color2 = '#82ca9d'
  let lines = [
    { label: 'Write', color: '#8884d8', value: '33%', fontColor: ' #bdbec0' },
    { label: 'Read', color: '#82ca9d', value: '66%' }
  ]
  return (
    <div className={'pg-bg-white pg-shadow pg-flex pg-mt-4 pg-w-full '} style={{ height: '100' }}>
      <div className={' pg-py-4  pg-px-2 pg-pl-4 pg-flex pg-items-center'} style={{width: 100}}>
        <LegendBox lines={lines} number={index} />
      </div>
      <SimpleLineChart color1={color1} color2={color2} />
    </div>
  )
}
