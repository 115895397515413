import { takeEvery, all, takeLatest, retry } from 'redux-saga/effects'

import { actionTypes } from '../actions/types'
import { login, register, signout, forgetPassword, changePassword, reactivate } from './auth'
import {
  getDocuments,
  getSharedDocuments,
  generateLink,
  downloadDirectory,
  createFolder,
  renameFolder,
  moveDocuments,
  shareDocuments,
  removeFolder,
  getTrashDocuments,
  getModalDocuments,
  restoreFiles,
  deleteDocument,
  uploadDocuments,
  changeSharingStatus,
  urlUpload,
  uploadServer,
  dragDocuments,
} from './documents'
import { getProducts, getUserInfo, changePlan, changeProfile, getInvoices, getInvoice, addToBalance, getVmBill, getWallet, payInvoice, vmBillInvoices, getTransactions, getSettingState, hostInvoices, domainInvoices } from './account'
import { getOses, getNetwork,retryVm, getVmproducts, setPromo, getVmBills, createInvoice, deleteOs, power, activeIdle,deleteVm, renewalVm, changeVmBillPlan, calculateLeftCredit, deleteAndCreateInvoice, cancelChanges, renewalDelete, getVmBillsNamed, updateVm, deleteUpgradeVm, changeVmInfo } from './vm'
import { mellatBank, } from '../actions/account';

import { setToggle ,setImagePreview} from './select';
import { setModalState} from '../actions';
import { getHosts, setAutoRenewal, getDomains, deleteBill,isDomainAvailable, orderHost } from './hosts';

// import { getUserInfo } from './user'

function* watchAuth() {
  yield takeEvery(actionTypes.INIT_LOGIN, login)
  yield takeEvery(actionTypes.REGISTER, register)
  yield takeEvery(actionTypes.SIGNOUT, signout)
  yield takeEvery(actionTypes.FORGET_PASSWORD, forgetPassword)
  yield takeEvery(actionTypes.CHANGE_PASSWORD, changePassword)

    yield takeEvery(actionTypes.REACTIVATE, reactivate)
  //   yield takeEvery(actionTypes.LOGOUT, logout)
  //   yield takeEvery(actionTypes.GET_USER_INFO, getUserInfo)
}

function* watchVm() {
  yield takeEvery(actionTypes.GET_OSES, getOses)
  yield takeEvery(actionTypes.DELETE_UPGRADE_VM, deleteUpgradeVm)
  yield takeEvery(actionTypes.ACTIVE_IDLE, activeIdle)
  yield takeEvery(actionTypes.GET_VM_PRODUCTS, getVmproducts)
  yield takeEvery(actionTypes.GET_NETWORK, getNetwork)
  yield takeEvery(actionTypes.GET_VM_BILLS, getVmBills)
  yield takeEvery(actionTypes.GET_VM_BILLS_NAMED, getVmBillsNamed)
  yield takeEvery(actionTypes.SET_PROMO, setPromo)
  yield takeEvery(actionTypes.CREATE_INVOICE, createInvoice)
  yield takeEvery(actionTypes.DELETE_OS, deleteOs)
  yield takeEvery(actionTypes.POWER, power)
  yield takeEvery(actionTypes.DELETE_VM, deleteVm)
  yield takeEvery(actionTypes.RETRY_VM, retryVm)
  yield takeEvery(actionTypes.CHANGE_VM_PLAN, changeVmBillPlan)
  yield takeLatest(actionTypes.CALCULATE_LEFT_CREDIT, calculateLeftCredit)
  yield takeEvery(actionTypes.DELETE_INVOICE, deleteAndCreateInvoice)
  yield takeEvery(actionTypes.RENEWAL_VM, renewalVm)
  yield takeEvery(actionTypes.RENEWAL_DELETE, renewalDelete)
  yield takeEvery(actionTypes.CANCEL_CHANGES, cancelChanges)
  yield takeEvery(actionTypes.UPDATE_VM, updateVm)
  yield takeEvery(actionTypes.CHANGE_VM_INFO, changeVmInfo)
}
function* watchHosts() {
  yield takeEvery(actionTypes.GET_HOSTS, getHosts)
  yield takeEvery(actionTypes.ORDER_HOST, orderHost)
  yield takeEvery(actionTypes.IS_DOMAIN_AVAILABLE, isDomainAvailable)
  yield takeEvery(actionTypes.DELETE_BILL, deleteBill)
  yield takeEvery(actionTypes.GET_DOMAINS, getDomains)
  yield takeEvery(actionTypes.SET_AUTO_RENEWAL, setAutoRenewal)
}
function* watchUser() {
  yield takeEvery(actionTypes.GET_PRODUCTS, getProducts)
  yield takeEvery(actionTypes.GET_SETTING_STATE, getSettingState)
  yield takeEvery(actionTypes.GET_TRANSACTIONS, getTransactions)
  yield takeEvery(actionTypes.GET_USER_INFO, getUserInfo)
  yield takeEvery(actionTypes.CHANGE_PLAN, changePlan)
  yield takeEvery(actionTypes.CHANGE_PROFILE, changeProfile)
  yield takeEvery(actionTypes.GET_INVOICES, getInvoices)
  yield takeEvery(actionTypes.GET_VM_BILL, getVmBill)
  yield takeEvery(actionTypes.VM_BILL_INVOICES, vmBillInvoices)
  yield takeEvery(actionTypes.HOST_INVOICES, hostInvoices)
  yield takeEvery(actionTypes.DOMAIN_INVOICES, domainInvoices)
  yield takeEvery(actionTypes.GET_INVOICE, getInvoice)
  yield takeEvery(actionTypes.MELLAT_BANK, mellatBank)
  yield takeEvery(actionTypes.ADD_TO_BALANCE, addToBalance)
  yield takeEvery(actionTypes.GET_WALLET, getWallet)
  yield takeEvery(actionTypes.PAY_INVOICE, payInvoice)

}
function* watchDocuments() {
  yield takeEvery(actionTypes.GET_DOCUMENTS, getDocuments)
  yield takeEvery(actionTypes.GET_MODAL_DOCUMENTS, getModalDocuments)
  yield takeEvery(actionTypes.URL_UPLOAD, urlUpload)
  yield takeEvery(actionTypes.CREATE_FOLDER, createFolder)
  yield takeEvery(actionTypes.RENAME_FOLDER, renameFolder)
  yield takeLatest(actionTypes.REMOVE_FOLDER, removeFolder)
  yield takeLatest(actionTypes.SHARE_DOCUMENTS, shareDocuments)
  yield takeEvery(actionTypes.MOVE_DOCUMENTS, moveDocuments)
  yield takeEvery(actionTypes.DRAG_DOCUMENTS, dragDocuments)
  yield takeEvery(actionTypes.GET_TRASH_DOCUMENTS, getTrashDocuments)
  yield takeEvery(actionTypes.GET_SHARED_DOCUMENTS, getSharedDocuments)
  yield takeEvery(actionTypes.GENERATE_DOWNLOAD_LINK, generateLink)
  yield takeEvery(actionTypes.UPLOAD_SERVER, uploadServer)

  yield takeEvery(actionTypes.RESTORE_FILES, restoreFiles)
  yield takeLatest(actionTypes.DELETE_DOCUMENT, deleteDocument)
  yield takeLatest(actionTypes.DOWNLOAD_DIRECTORY, downloadDirectory)
  yield takeEvery(actionTypes.UPLOAD_DOCUMENT, uploadDocuments)
  yield takeEvery(actionTypes.CHANGE_SHARING_STATUS, changeSharingStatus)
}
function* watchSelect() {
  yield takeEvery(actionTypes.SET_TOGGLE, setToggle)
  yield takeEvery(actionTypes.SET_PREVIEW_IMAGE, setImagePreview)
}

export function* rootSaga() {
  yield all([watchAuth(), watchDocuments(), watchUser(), watchVm(), watchSelect(),watchHosts()])
}
