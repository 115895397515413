import React from 'react'

// styles
import styles from '../../Order.module.scss'

export default interface Iprops {
  stepNumber: number
  title: string
  subTitle: string
}

export const StepDescription: React.FunctionComponent<Iprops> = ({ stepNumber, title, subTitle }) => {
  return (
    <div className={styles.stepDescription}>
      {/* <div className={styles.number}>{stepNumber}</div> */}
      <div className={styles.text}>
        <div className={styles.title}>{title}    <span className={styles.subTitle}>{subTitle}</span></div>
     
      </div>
    </div>
  )
}
