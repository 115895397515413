import React from 'react'

import { DragLayer } from 'react-dnd'
import DragPreviewContainer from './DragPreviewContainer'

export const ItemTypes = {
  CARD: 'card'
}

const getItemStyles = (currentOffset: any, isGrid?: any,width?:any) => {
  if (!currentOffset) {
    return {
      display: 'none'
    }
  }
  const { x, y } = currentOffset
  return {
    transform: isGrid ? `translate(${x-width +120}px, ${y }px)` : `translate(${x - 340}px, ${y + 40}px)`,
    filter: 'drop-shadow(0 2px 12px rgba(0,0,0,0.45))'
  }
}

const collect = (monitor: any) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging()
})

class CardDragLayer extends React.Component<any, any> {
  renderItem(type: any, item: any, imgSrc: any, ids?: any) {
    switch (type) {
      case ItemTypes.CARD:
        return <DragPreviewContainer selection={item} src={imgSrc} ids={ids} />
      default:
        return null
    }
  }

  render() {
    const { itemType, currentOffset, isDragging,width, src, selection, ids, isGrid } = this.props
    if (!isDragging) {
      return null
    }

    return (
      <div
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          zIndex: 100,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        }}
      >
        <div style={getItemStyles(currentOffset, isGrid,width)}>{this.renderItem(itemType, selection, src, ids)}</div>
      </div>
    )
  }
}

export default DragLayer(collect)(CardDragLayer)
