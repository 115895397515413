import React from 'react'
import { t } from 'ttag';

import { Tooltip } from '../ui-elements/Tooltip/Tooltip'
import { Icon } from '../ui-elements/Icon/Icon'

// Styles
import styles from './Content.module.scss'


export default interface Iprops {
  switchView: (e: string) => void
  view: string
  nextView: string
  icon?: string
  tooltipText?: string
  activeIcon?: string
}

export const SwitchIcon: React.FunctionComponent<Iprops> = ({ switchView,tooltipText, view, icon, activeIcon, nextView }) => {
  return (
    <div className={styles.viewMode} onClick={() => switchView(nextView)}>
      <Tooltip text={tooltipText ? tooltipText : nextView === 'grid' ? t`List View` : t`Grid View`} width={90} height={27} position={'bottom'}>
        <Icon src={view !== nextView ? icon : activeIcon} />
      </Tooltip>
    </div>
  )
}
