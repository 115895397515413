import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { Modal } from '../Modal'
import { AddDescription } from './AddDescription'
import ShareLinks from './ShareLinks'

//services
import { shareDocuments, changeSharingStatus, addDescription } from '../../../../services/internal/store/actions/documents'
import { setSidebarItems } from '../../../../services/internal/store/actions'

export interface Iprops {
  showModal?: boolean
  handleCFClose?: () => void
  createFolder?: any
  getDocuments?: () => void
  document?: any
  item?: any
  setItem?: any
  shareDocuments?: any
  addDescription?: any
  changeSharingStatus?: any
}

export interface Istate {
  name?: string
  description?: string
  message?: string
  userEmail?: string
  selected?: string
  shareLink?: string
  view?: string
}

class ShareModal extends React.Component<Iprops, Istate> {
  constructor(props: any) {
    super(props)
    // console.log(props.item && props.item.sharingStatus)
    this.state = {
      name: '',
      description: '',
      message: '',
      userEmail: '',
      selected: this.getSelected(props.item && props.item.sharingStatus),
      shareLink: '',
      view: 'ShareLink'
    }
  }

  getSelected = (name: any) => {
    let selected
    switch (name) {
      case 'PRIVATE':
        selected = 'خصوصی'
        break
      case 'WITH_LINK':
        selected = 'با لینک'
        break
      default:
        selected = 'عمومی'
        break
    }
    return selected
  }
  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  shareDocument = (e: any) => {
    e.preventDefault()
    this.props.shareDocuments(this.state.userEmail, this.props.item.id)
  }

  changeSharingStatus = (e: any) => {
    let selected = e.target.value
    let item = this.props.item
    if (this.state.selected !== selected) {
      this.setState({ selected })
      switch (selected) {
        case t`Link`:
          item.sharingStatus = 'WITH_LINK'
          this.props.setItem(item)
          this.props.changeSharingStatus({ id: this.props.item.id, sharingStatus: 'WITH_LINK' })
          break
        case t`Private`:
          item.sharingStatus = 'PRIVATE'
          this.props.setItem(item)
          this.props.changeSharingStatus({ id: this.props.item.id, sharingStatus: 'PRIVATE' })
          break
        case t`Public`:
          item.sharingStatus = 'PUBLIC'
          this.props.setItem(item)
          this.props.changeSharingStatus({ id: this.props.item.id, sharingStatus: 'PUBLIC' })
          break
      }
    }
  }
  componentDidMount() {
    if (this.props.item.description) this.setState({ description: this.props.item.description })
    if (this.props.item.discriminator == 'F')
      this.setState({
        shareLink: `${process.env.REACT_APP_URL}/download/${this.props.item.uuid}/${this.props.item.name}/dl`
      })
    else
      this.setState({
        shareLink: `${process.env.REACT_APP_URL}/public/${this.props.item.uuid}`
      })
  }
  componentWillReceiveProps(nextProps: any) {
    // console.log(nextProps)
    if (nextProps.item && nextProps.item.sharingStatus) {
      this.setState({ selected: this.getSelected(nextProps.item.sharingStatus) })
    }
  }
  changeView = (view: string) => {
    this.setState({ view })
  }

  addDescription = () => {
    this.props.addDescription({ id: this.props.item.id, description: this.state.description })
    this.changeView('ShareLink')
  }

  render() {
    const { showModal, handleCFClose, item } = this.props
    // console.log(this.state.selected)
    let view
    switch (this.state.view) {
      case 'AddDesc':
        view = (
          <AddDescription
            handleChange={this.handleChange}
            value={this.state.description}
            addDescription={this.addDescription}
            changeView={this.changeView}
          />
        )
        break
      case 'ShareLink':
        view = (
          <ShareLinks
            handleChange={this.handleChange}
            value={this.state.userEmail}
            changeView={this.changeView}
            item={this.props.item}
            changeSharingStatus={this.changeSharingStatus}
            selected={this.state.selected}
            shareDocument={this.shareDocument}
          />
        )
        break
    }
    return (
      <Modal show={showModal} handleClose={handleCFClose} width={640} title={t`Share File`}>
        {view}
      </Modal>
    )
  }
}

const mapStateToProps = (state: any) => ({ document: state.document.documents, item: state.sidebar.item })

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeSharingStatus: (value: any) => dispatch(changeSharingStatus(value)),
    shareDocuments: (userEmails: any, documentIds: any) => dispatch(shareDocuments(userEmails, documentIds)),
    addDescription: (value: any) => dispatch(addDescription(value)),
    setItem: (value: any) => dispatch(setSidebarItems(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal)
