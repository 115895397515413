import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// styles
import { EnhanceDropdown as enhancer } from '../../../ui-elements/Dropdown/EnhanceDropdown'
import Dropdown from '../../../ui-elements/Dropdown/Dropdown'
const EnhancedDropdown = enhancer(Dropdown)
import OsDropDown from '../DropDown/OsDropDown'

import bigger from '../../../../images/Icons/commonIcons/bigger.white.svg'

import { Icon } from '../../../ui-elements/Icon/Icon'
import { Loading } from '../../../loading/Loading'
import { selectOs } from '../../../../services/internal/store/actions'
import { StepDescription } from '../../OldOrder/Steps/Details/StepDescription'
import { GetImgByOsName } from '../../OldOrder/Steps/SelectOs'

import styles from '../../OldOrder/Order.module.scss'

export interface Iprops {
  isOpen?: any
  durationData?: boolean
  serviceDuration?: any
  expanded?: any
}

class SelectOs extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      oses: props.oses ? props.oses : [],
      expanded: false,
      isOsOpen: false,
      osMainName: 'CentOS',
      isMonthOpen: true,
      index: props.vsCardMain && props.vsCardMain.name === 'VPS2' ? 1 : '',
      finalInvoice: [],
      disableButton: true,
      stepsPathName: ['choose-plan', 'final-step'],
      stepsMainName: [t`Select a Plan`, t` Submit`]
    }
  }
  componentDidMount() {
    let { os, oses } = this.props
    this.setState({ osMainName: os && os[2] })
  }

  setOsClose = () => {
    this.setState({ isOsOpen: false })
  }
  selectOs = (main: any, version: any, name: any) => {
    this.props.selectOs(main, version, name)
    this.setState({ isOsOpen: false, version })
  }
  chooseOs = () => {
    this.setState({ dropdown: true })
  }
  onOsImgClick = (osMainName: any, osArray: any) => {
    // if (this.state.osMainName == osMainName)
    osArray.map((each: any) => {
      if (each.name == osMainName) this.props.selectOs(each.mainName[0], each.version[0], each.name)
    })
    this.setState({ osMainName })

    // else    this.props.selectOs(main, version, name)
  }
  render() {
    let { os, oses } = this.props
    let array =
      oses &&
      oses.map((each: any) => ({
        name: each.displayName.replace(/ .*/, ''),
        version: each.displayName,
        displayName: each.displayName,
        mainName: each.name
      }))

    let osArray: any = []

    array &&
      array.forEach((item: any, index: number) => {
        let existing = osArray.filter((v: any, i: any) => {
          return v.name == item.name
        })
        if (existing.length) {
          let existingIndex = osArray.indexOf(existing[0])
          osArray[existingIndex].version = osArray[existingIndex].version.concat(item.version)
          osArray[existingIndex].mainName = osArray[existingIndex].mainName.concat(item.mainName)
        } else {
          if (typeof item.version == 'string') item.version = [item.version]
          if (typeof item.mainName == 'string') item.mainName = [item.mainName]
          osArray.push(item)
        }
      })

    let osData = !this.props.oses ? (
      <div className={'pg-pb-4'}>
        <Loading />
      </div>
    ) : (
      <OsDropDown selectOs={this.selectOs} osArray={osArray} osMainName={this.state.osMainName} />
    )
    return (
      <div className={'pg-w-full pg-flex pg-items-center pg-px-2  pg-py-2 pg-justify-between lgDesktop-max:pg-flex-col'}>
        <StepDescription stepNumber={1} title={[t`First step`, `:`, this.state.stepsMainName[2]].join(' ')} subTitle={t`Choose os`} />

        <div className={`pg-flex pg-items-center pg-shadow-sm pg-my-4 ${[styles.allBoxes].join(' ')}`} onClick={this.chooseOs}>
          {['Debian', 'CentOS', 'Ubuntu', 'Windows', 'Fedora'].map((each: any, index: any) => (
            <div
              key={index}
              className={`${this.state.osMainName == each && styles.activeBackground} ${styles.bg}`}
              onClick={() => this.onOsImgClick(each, osArray)}
            >
              <span className={`pg-flex pg-justify-center  ${styles.osImg} `}>
                {this.state.osMainName == each && styles.activeBackground ? GetImgByOsName(each, true) : GetImgByOsName(each)}
              </span>
            </div>
          ))}
          <EnhancedDropdown
            width={'135px'}
            noButton={true}
            optionSelected={0}
            insideData={osData}
            dropdownStyle={styles.dropDown}
            isOpen={this.state.isOsOpen}
            vmDropdown={true}
            customButton={
              <div className={'pg-cursor-pointer pg-w-full pg-flex pg-justify-center'}>
                <span className={'pg-mx-2 pg-w-full pg-text-xs'}>{os && os[0] ? os[0] : t`Choose`}</span>
                <Icon className={this.state.expanded ? styles.collapseIcon : styles.expandIcon} src={bigger} />
              </div>
            }
            data={[]}
          />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectOs: (osId: string, id: any, name: any) => dispatch(selectOs(osId, id, name))
  }
}

const mapStateToProps = (state: any) => ({
  os: state.vm.os,
  oses: state.vm.oses,
  currentStep: state.vm.currentStep,
  vmName: state.vm.vmName,
  vmProducts: state.vm.vmProducts2,
  vsCardMain: state.vm.vsCardMain,
  serviceDuration: state.vm.serviceDuration,
  steps: state.vm.steps,
  networkName: state.account.networkName
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectOs)
