import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { t } from 'ttag'

// styles
import styles from '../OldOrder/Order.module.scss'

import { ColorfulBox } from '../../ui-elements/ColorfulBox/ColorfulBox'
import TableOrder from './Steps/TableOrder'
import { Stepbar } from '../../Stepbar/Stepbar'
import FirstPage from './Steps/FirstPage'
import FinalStep from './Steps/FinalStep'

class TableRouter extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      finalInvoice: [],
      stepsPathName: ['choose-plan', 'final-step'],
      stepsMainName: [t`Select a Plan`, t`Submit Name and VM`]
    }
  }

  render() {
    let steps = this.state.stepsMainName

    return (
      <div className={`${window.location.pathname.includes('nwlandings') && 'pg-mx-20'}`}>
        <ColorfulBox
          className={['green', 'lg']}
          withClose={true}
          message="سرور ها بصورت سیستمی و آنی بر روی یکی از لوکیشن های اروپا ارائه میشوند ، در حال حاضر انتخاب لوکیشن امکان پذیر نیست."
        />
        <div className={styles.stepbarWrapper}>
          <Stepbar isRtl={true} steps={steps} currentStep={this.props.currentStep} order={true} />
        </div>

        <Switch>
          <Route exact path={`/nwlandings/vm-campaign/choose-plan`} render={(props: any) => <FirstPage steps={steps} {...this.props} />} />
          <Route exact path={`/nwlandings/vm-campaign/final-step`} render={() => <FinalStep steps={steps} hasButton={true} {...this.props} />} />
          <Route exact path={`/nwvms/order/choose-plan`} render={(props: any) => <FirstPage steps={steps} {...this.props} />} />
          <Route exact path={`/nwvms/order/final-step`} render={() => <FinalStep steps={steps} hasButton={true} {...this.props} />} />
          <Route exact path={`/nwvms/order`} render={() => <FirstPage steps={steps} {...this.props} />} />

          <Route render={() => <TableOrder steps={steps} {...this.props} />} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  currentStep: state.vm.currentStep,
  steps: state.vm.steps
})

const mapDispatchToProps = (dispatch: any) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TableRouter)
