import React from 'react'
import { t } from 'ttag'
import { DragSource } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { TableDndItem } from './TableItem'
import { Icon } from '../../Icon/Icon'

import bigger from '../../../../images/Icons/commonIcons/bigger.black.svg'
import styles from '../Table.module.scss'
import '../drag.scss'

class TrDndContainter extends React.Component<any, any> {
  componentDidMount() {
    if (this.props.connectDragPreview) {
      this.props.connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      })
    }
  }

  render() {
    let {
      item,
      expanded,
      setHovered,
      setUnHovered,
      renderInvoice,
      children,
      hoverable,
      selection,
      isMoveModal,
      width,
      handleNavigate,
      setEditStatus,
      dragSource,
      isDragging,
      checkbox,
      showAll,
      hoverStyle,
      handleChange,
      hasDnd,
      onCheck,
      canDrop,
      hasIcon,
      editableId,
      renameText,
      labelShow,
      openModal,
      modalSelection,
      expandableRow,
      handleOnRowClick,
      id,
      isActive,
      onMouseOver,
      selectableRows,
      smPadding = false
    } = this.props
    let dragStyle = canDrop ? styles.droppableRow : ''

    const hidden = ['type', 'id', 'fullPath', 'discriminator', 'uuid', 'item', 'genericType', 'prices', 'unlimited', 'core', 'mainName']

    return dragSource(
      <div
        key={item && item.id}
        className={
          isMoveModal && modalSelection === item.id
            ? [styles.activeRow, dragStyle && !isDragging ? [styles.draggingRow, dragStyle].join(' ') : dragStyle].join(' ')
            : expanded
            ? 'pg-bg-white pg-border-2 pg-border-b-0 pg-border-blue-400'
            : isActive
            ? [styles.isDropping, 'table-row'].join(' ')
            : isDragging
            ? [styles.draggingRow, dragStyle, 'table-row'].join(' ')
            : selection && selection.includes(item.id)
            ? [styles.draggingRow, dragStyle, 'table-row'].join(' ')
            : dragStyle
            ? `table-row ${styles.droppableRow}`
            : `table-row`
        }
        onMouseEnter={setHovered}
        onMouseLeave={setUnHovered}
        {...(selection && !selection.includes(item && item.id) && { onDragStart: () => handleOnRowClick(item.id) })}
        {...(handleOnRowClick && { onClick: (e:any) => (expandableRow ? renderInvoice(item.id) : handleOnRowClick(item.id,e)) })}
      >
        {Object.keys(item).map((k, i) => {
          if (!hidden.includes(k)) {
            return (
              <TableDndItem
                name={k}
                selectableRows={selectableRows}
                key={i}
                hoverStyle={hoverStyle}
                onMouseOver={onMouseOver}
                style={{ width: width }}
                editableId={editableId}
                renameText={renameText}
                id={item.id}
                hovered={hoverable}
                hasIcon={hasIcon}
                handleChange={handleChange}
                uuid={item.uuid}
                handleNavigate={ handleNavigate}
                openModal={openModal}
                setEditStatus={setEditStatus}
                label={item[k]}
                itemName={item.mainName}
                item={item}
                selection={selection}
                onCheck={onCheck}
                shareButton={k == 'size'}
                checked={typeof item.id != 'undefined' && selection && selection.includes(item.id)}
                className={
                  showAll
                    ? ['show']
                    : k == 'index'
                    ? ['pg-text-center']
                    : k === 'name' || (labelShow && labelShow.includes(k))
                    ? smPadding
                      ? ['smPadding', 'show']
                      : ['show']
                    : smPadding
                    ? ['smPadding']
                    : ''
                }
                checkbox={checkbox === false ? checkbox : k === 'name' ? true : selectableRows && i == 0 ? true : false}
                mimetype={k === 'name' ? item.type : ''}
              />
            )
          }
        })}

        {children}
        {expandableRow && (
          <div className={[styles.show, styles.left].join(' ')} data-name={id} onClick={() => renderInvoice(item.id)}>
            <div className={'pg-px-8'}>
              {t`Details`} <Icon className={expanded ? styles.collapseIcon : styles.expandIcon} src={bigger} />
            </div>
          </div>
        )}
      </div>
    )
  }
}
const card = {
  beginDrag({ setDragnDropState }: any) {
    return setDragnDropState(true)
  }
}

const collect = (connect: any, monitor: any) => ({
  dragSource: connect.dragSource(),
  dragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview()
})
export const TRDND = 'card'
export default DragSource(TRDND, card, collect)(TrDndContainter)
