import { formatPrice } from './formatPrice'

export const renderDomainInvoice = (result: any) => {
  let array
  let product

  if (product !== 'cfs')
    array = result.map((each: any) => {
      let id = each.id
      let vmBillName = each.domain.domainName
      each.invoice.mainId = id
      each.invoice.vmBillName = vmBillName
      return each.invoice
    })
  let tableData: any = []
  array.map((each: any, index: number) => {
    result && result[0].invoice.product.name == each.vmBillName
    tableData.push({
      index: +index + 1,
      id: each.mainId && each.mainId,
      refCode: each.refCode,
      productName: each.vmBillName,
      created_at: each.persianCreatedAt,
      payablePrice: formatPrice(each.payablePrice),
      paid: each.paid,
      rawData: each
    })
  })
  return { tableData, array }
}
