import React, { useState } from 'react'
import { AreaChart, Area, ResponsiveContainer, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { AreaChartComponenet } from './AreaChart/AreaChart'

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
]

export const OCP: React.FunctionComponent<any> = props => {
  let totalComputers = 100
  return (
    <div>
      <div className={'pg-flex pg-justify-between pg-text-gray-700'}>
        <span>Overall Computer Performances</span>
        <span className={'pg-font-sans'}>
          Total Computers : <span>{totalComputers}</span>{' '}
        </span>
      </div>
      <AreaChartComponenet label={'CPU'} value={'4.00Ghz(94%)'} color={'#90cdf4'} data={data} />
      <AreaChartComponenet label={'RAM'} value={'55.5/12 GB (43%)'} color={'#fae68e'} data={data} />
      <AreaChartComponenet label={'DISK'} value={'READ 45% - WRITE 3%'} color={'#9ae6b4'} data={data} />
      <AreaChartComponenet label={'NET'} value={'Out 655/700 Mbps - In 31/700 Mbps'} color={'#b794f4'} data={data} />
    </div>
  )
}
