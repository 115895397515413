import * as React from 'react'

import { TextInput } from '../../Input/Input'
import { Button } from '../../Button/Button'

import styles from '../CreateFolderModal/CreateFolder.module.scss'
import { FMModal } from '../FMModal/FMModal'
import { renameFolder } from '../../../../services/internal/store/actions'
import { connect } from 'react-redux'
import { ItemInterface } from '../../../../services/internal/store/reducers/documentReducer'
import { t } from 'ttag'

interface Iprops {
  value?: string
  showModal?: boolean
  item: ItemInterface
  tableData?: any
  updateTable?: (tableData: any) => void
  handleSubmit?: (e: any) => void
  changeHandler?: (e: any) => void
  renameFolder: (e: any) => void
  handleClose?: () => void
}

class RenameFile extends React.Component<Iprops, any> {
  textInput: any
  state = {
    renameInput: this.props.item.name
  }
   
  constructor(props: any) {
    super(props)
    // create a ref to store the textInput DOM element
    this.textInput = React.createRef()
    this.focus = this.focus.bind(this)
  }
  focus() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    this.textInput.current.focus()
  }

  componentDidMount() {
    this.focus()
  }

  onRenameDocument = async (result: any) => {
    let tableData = this.props.tableData
    tableData.map((each: any) => {
      if (each.id === this.props.item.id) each.name = result.payload.name
    })
    this.props.updateTable && this.props.updateTable(tableData)
  }
  changeHandler = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleSubmit = (e: any) => {
    if (e) e.preventDefault()

    let result = this.props.renameFolder({ folderId: this.props.item.id, name: this.state.renameInput })
    this.onRenameDocument(result)
  }
  render() {
    let { handleClose, showModal } = this.props
    return (
      <FMModal show={showModal} width={640} title={t`Change Name`} formDescription={t`Enter the new name`} handleClose={handleClose}>
        <form onSubmit={this.handleSubmit}>
          <TextInput value={this.state.renameInput} style={{ width: 300 }} onChange={this.changeHandler} name={'renameInput'}  wrapperRef={this.textInput} />
          <div className={styles.submitButton}>
            <Button className={['pg-btnPrimary', 'pg-btnSm']}>{t`Change Name`}</Button>
          </div>
        </form>
      </FMModal>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    renameFolder: (value: any) => dispatch(renameFolder(value))
  }
}

const mapStateToProps = (state: any) => ({
  item: state.sidebar.item
})

export default connect(mapStateToProps, mapDispatchToProps)(RenameFile)
