export const switchTimeLang = (faTime: any, lang?: any) => {
  let time
  if (lang == 'index') {
    switch (faTime) {
      case 'MONTH':
      case 'ماهانه':
      case 'یک ماهه':
      case '1 month':
        time = 0
        break
      case 'TMONTH':
      case 'سه ماهه':
      case '3 month':
        time = 1
        break
      case 'SMONTH':
      case 'شش ماهه':
      case '6 month':
        time = 2
        break
      case 'YEAR':
      case 'یـکساله':
      case 'یکساله':
      case 'one year':
        time = 3
        break
      case 'TYEAR':
      case 'دوساله':
      case 'دوساله':
      case 'two year':
        time = 4
        break
    }
  } else if (lang == 'en') {
    switch (faTime) {
      case 'MONTH':
        time ='ماهانه'
        break
        case 'TYEAR':
        time = 'دوساله'
        break
      case 'TMONTH':
        time = 'سه ماهه'
        break
      case 'SMONTH':
        time = 'شش ماهه'
        break
      case 'YEAR':
        time = 'یـکساله'
        break
      case 'DAY':
        time = 'روزانه'
        break
    }
  } else if (lang == 'fa') {
    switch (faTime) {
      case 'ماهانه':
      case 'یک ماهه':
      case '1 month':
        time = 'MONTH'
        break
      case 'سه ماهه':
      case '3 month':
        time = 'TMONTH'
        break
      case 'شش ماهه':
      case '6 month':
        time = 'SMONTH'
        break
      case 'یـکساله':
      case 'یکساله':
      case 'one year':
        time = 'YEAR'
        break
        case 'دوساله':
      case 'دوساله':
      case 'two year':
        time = 'TYEAR'
        break
      case 'HPC':
      case 'سفارشی':
        time = 'DAY'
        break

      default:
        time = faTime
        break
    }
  }
  return time
}
