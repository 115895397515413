import * as React from 'react'

// ui-elements
import { MainFooter } from '../MainFooter/MainFooter'

// styles
import styles from './Main.module.scss'

export default interface Iprops {
  children?: any
  showSideBar?: boolean
  showModal?: boolean
  footer?: any
  previewState: any
  language: string
}

export const Main: React.FunctionComponent<Iprops> = props => {
  return (
    <main
      className={`pg-relative pg-flex pg-flex-col
    pg-mt-70p  pg-pt-10 pg-pb-8
    pg-pr-30p pg-pl-30p tablet-max:pg-mt-20
    tablet-max:pg-mr-0 tablet-max:pg-pt-5
    tablet-max:pg-pr-15p tablet-max:pg-pl-15p ${window.location.href.includes('order')|| window.location.href.includes('landings')? styles.mainNoscroll: styles.main} ${!props.showSideBar ? ' pg-mr-sidebar' : 'kkkkk'} ${
        props.previewState == true ? 'pg-w-full' : ''
      } ${!location.pathname.includes('preview') ? '' : styles.scrollLock}`}
    >
      <section className="pg-min-h-screen">{props.children}</section>
      {/* <MainFooter footer={props.footer} language={props.language}/> */}
    </main>
  )
}
