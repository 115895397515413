import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// ui-elements

import { Icon } from '../../../ui-elements/Icon/Icon'
import { StepDescription } from './Details/StepDescription'
import Card from './Card/Card'

// services
import { selectOs } from '../../../../services/internal/store/actions'

// icons & styles
import windowsOs from '../../../../images/Icons/vmIcons/osIcons/windows.png'
import windowsWOs from '../../../../images/Icons/vmIcons/osIcons/Windows_white.svg'
import ubuntuOs from '../../../../images/Icons/vmIcons/osIcons/ubuntu2.svg'
import debianOs from '../../../../images/Icons/vmIcons/osIcons/debian2.svg'
import centOs from '../../../../images/Icons/vmIcons/osIcons/centos2.svg'
import ubuntuOsW from '../../../../images/Icons/vmIcons/osIcons/ubuntu_white.svg'
import debianOsW from '../../../../images/Icons/vmIcons/osIcons/debian_white.svg'
import centOsW from '../../../../images/Icons/vmIcons/osIcons/Centos_white.svg'
import fedoraOs from '../../../../images/Icons/vmIcons/osIcons/fedora.svg'
import fedoraOsW from '../../../../images/Icons/vmIcons/osIcons/fedora_white.svg'

import styles from '../../OldOrder/Order.module.scss'
import expand from '../../../../images/Icons/commonIcons/expand-button.svg'
import expandWhite from '../../../../images/Icons/commonIcons/expand-button-white.svg'
export interface Iprops {
  osName?: string
  os: string
  steps: string[]
  selectOs?: any
  onSelectVersion?: any
  oses?: any
}

const SelectOs: React.FunctionComponent<Iprops> = ({ os, oses, selectOs, steps }) => {
  let array =
    oses &&
    oses.map((each: any) => ({
      name: each.displayName.replace(/ .*/, ''),
      version: each.displayName,
      displayName: each.displayName,
      mainName: each.name
    }))

  let osArray: any = []

  array.forEach((item: any, index: number) => {
    let existing = osArray.filter((v: any, i: any) => {
      return v.name == item.name
    })
    if (existing.length) {
      let existingIndex = osArray.indexOf(existing[0])
      osArray[existingIndex].version = osArray[existingIndex].version.concat(item.version)
      osArray[existingIndex].mainName = osArray[existingIndex].mainName.concat(item.mainName)
    } else {
      if (typeof item.version == 'string') item.version = [item.version]
      if (typeof item.mainName == 'string') item.mainName = [item.mainName]
      osArray.push(item)
    }
  })

  return (
    <React.Fragment>
      <StepDescription
        stepNumber={1}
        title={[t`First step`, `:`, steps[0]].join(' ')}
        subTitle={t`Select the operating system that you plan to install on your virtual server.`}
      />
      <div className={styles.cardWrapper}>
        {osArray &&
          osArray.map((osData: any, index: number) => {
            let dropdownData = osData.version.map((each: any, index: number) => ({ label: each, id: osData.mainName[index], onClick: selectOs }))
            return (
              <Card
                key={index}
                isOsCard={true}
                version={dropdownData}
                onClickCard={selectOs}
                selected={os && os[0] && os[0].includes(osData.name)}
                activeOs={
                  os && os[0] && osData.version.includes(os[0]) ? (
                    <span>
                      {os[0]}
                      <Icon src={expandWhite} width={10} className={'pg-mx-3'} />
                    </span>
                  ) : (
                    <span>
                      <Icon src={expand} width={10} className={'pg-mx-3'} /> {t`select version`}
                    </span>
                  )
                }
              >
                <div className={'pg-w-1/4'}>{GetImgByOsName(osData.name)}</div>
              </Card>
            )
          })}
      </div>
    </React.Fragment>
  )
}

export const GetImgByOsName = (osName: string, color?: boolean) => {
  switch (osName) {
    case 'Debian':
      if (color) return <Icon src={debianOsW} width={23} />
      else return <Icon src={debianOs} width={23} />

    case 'CentOS':
      if (color) return <Icon src={centOsW} width={23} />
      else return <Icon src={centOs} width={23} />

    case 'Ubuntu':
      if (color) return <Icon src={ubuntuOsW} width={23} />
      else return <Icon src={ubuntuOs} width={23} />

    case 'Windows':
      if (color) return <Icon src={windowsWOs} width={23} />
      else return <Icon src={windowsOs} width={23} />

    case 'Fedora':
     if (color) return <Icon src={fedoraOsW} width={23} />
      else return <Icon src={fedoraOs} width={23} />

    default:
      return <Icon src={centOs} width={23} />
  }
}

const mapStateToProps = (state: any) => ({
  os: state.vm.os,
  oses: state.vm.oses
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectOs: (osId: string, id: number) => dispatch(selectOs(osId, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOs)
