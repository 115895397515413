import React from 'react'
import { t } from 'ttag'

import { Stepbar } from '../../Stepbar/Stepbar'
import { ColorfulBox } from '../../ui-elements/ColorfulBox/ColorfulBox'

export const VmBrushDescripted: React.FunctionComponent<any> = ({ startedTimes, steps }) => {
  
  steps && steps[0] != 'start' && steps.unshift('start')

  let currentStep = startedTimes && startedTimes.length
  steps = steps && steps.map((each: string) => each.toLowerCase())

  return (
    <ColorfulBox className={['wheat', 'lg']} fontSize={12}>
      <div className={'pg-w-full '}>
        <h2 className={'pg-mb-2'}>{t`Function steps`}</h2>
        <div className={'pg-mb-6'}>
          <Stepbar steps={steps} currentStep={currentStep} isRtl={false} />
        </div>
      </div>
    </ColorfulBox>
  )
}
