import * as React from 'react'

// ui-elements
import { TextInput } from '../Input/Input'
import { Button } from '../Button/Button'
import toast from '../../../components/ui-elements/Toast/Toast'
import { toast as toastify} from 'react-toastify';

// styles
import styles from './Clipboard.module.scss'
import { t } from 'ttag'

export default interface Iprops {
  placeholder: string
  hasToastify?: boolean
}

export const ClipBoard = ({ placeholder, hasToastify = true }: Iprops) => {
  const copyToClipboard = () => {
    const textField = document.createElement('textarea')
    textField.innerText = placeholder
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    if (hasToastify && ! toastify.isActive(23) ) toast.success((t`Text's Copied`), {
      toastId: 23
    })
  }
  return (
    <div className={styles.clipboard}>
      <TextInput value={placeholder} onClick={copyToClipboard} />
      <Button onClick={copyToClipboard}>
        <span className={styles.copy}>{t`Copy`}</span>
      </Button>
    </div>
  )
}
