import { actionTypes } from './types'
import { actionBuilder } from './actionBuilder'

export const getHosts = actionBuilder(actionTypes.GET_HOSTS)
export const setHosts = actionBuilder(actionTypes.SET_HOSTS,'payload')
export const getDomains = actionBuilder(actionTypes.GET_DOMAINS)
export const setDomains = actionBuilder(actionTypes.SET_DOMAINS,'payload')
export const setAutoRenewal = actionBuilder(actionTypes.SET_AUTO_RENEWAL,'payload')
export const createHostBill = actionBuilder(actionTypes.CREATE_HOST_BILL,'payload')
export const deleteBill = actionBuilder(actionTypes.DELETE_BILL,'payload')
export const isDomainAvailable = actionBuilder(actionTypes.IS_DOMAIN_AVAILABLE,'payload')
export const domainAvailableStatus = actionBuilder(actionTypes.DOMAIN_AVAILABLE_STATUS,'payload')
export const orderHost = actionBuilder(actionTypes.ORDER_HOST,'payload')

