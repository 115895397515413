import React from 'react'
import { Route } from 'react-router'

import { Breadcrumb } from './Breadcrumb'
import { BreadcrumbsItem } from './BreadCrumbItem'

export default interface Iprops {
    MainTitleArray?: any
}

export const BreadCrumbAuto: React.FunctionComponent<Iprops> = ({ MainTitleArray }) => {
  return (
    <Breadcrumb>
      <Route path="/:path" render={(props: any) => <BreadcrumbsItem {...props} MainTitleArray={MainTitleArray} />} />
    </Breadcrumb>
  )
}
