import { toast } from 'react-toastify'
import { css } from 'glamor'
import './Toast.css'
import { withRouter } from 'react-router'

toast.configure({
  draggable: false,
  closeButton: false,
  rtl: true,
  bodyClassName: css({
    fontFamily: 'vazir-regular'
  })
})

export default {
  success(msg: any, options = {}) {
    if (msg && msg.length < 30) {
      return toast.success(msg, {
        ...options,
        autoClose: 5000,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_LEFT
      })
    } else {
      return toast.success(msg, {
        ...options,
        autoClose: 5000,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_LEFT
      })
    }
  },
  select(msg: any, options = {}) {
    return toast(msg, {
      ...options,
      toastId: 1,
      autoClose: false,
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_CENTER,
      // bodyClassName: css({
      //   background: 'white'
      // })
    })
  },
  warning(msg: any, options = {}) {
    if (msg && msg.length < 30) {
      return toast.warn(msg, {
        ...options,
        autoClose: false,
        hideProgressBar: true,
        closeButton: true,
        closeOnClick: true,
        position: toast.POSITION.BOTTOM_RIGHT,
        progressClassName: css({
          background: '#f2bf27',

          marginBottom: '20px'
        })
      })
    } else {
      return toast.warn(msg, {
        ...options,
        autoClose: false,
        hideProgressBar: true,
        closeButton: true,
        closeOnClick: true,
        position: toast.POSITION.BOTTOM_RIGHT,
        progressClassName: css({
          background: '#f2bf27',
          marginBottom: '20px'
        })
      })
    }
  },
  timed(msg: any, options = {}) {
    return toast.success(msg, {
      ...options,
      autoClose: false,
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_LEFT
    })
  },
  succeed(msg: any, options = {}) {
    return toast.success(msg, {
      ...options,
      autoClose: 30000,
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_LEFT
    })
  },
  error(msg: string, options = {}) {
    //console.log(msg.length)
    if (msg && msg.length < 30) {
      return toast.error(msg, {
        ...options,
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        progressClassName: css({
          background: '#007aff'
        })
      })
    } else {
      return toast.error(msg, {
        ...options,
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        progressClassName: css({
          background: '#007aff'
        })
      })
    }
  },
  errorTop(msg: string, options = {}) {
    return toast.error(msg, {
      ...options,
      autoClose: 3000,
      position: toast.POSITION.BOTTOM_RIGHT,

      hideProgressBar: true,

      progressClassName: css({
        background: '#007aff'
      })
    })
  },
  dismiss() {
    return toast.dismiss()
  }
}
