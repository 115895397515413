import { t } from 'ttag'
import moment from 'moment-jalaali'
import { formatPrice } from './formatPrice'

export const renderUserInfo = (vmInfo: any) => {
  let array: any = []

  if (vmInfo.profile) {
    let info = vmInfo
    array = {
      displayName: info.displayName,
      email: info.email,
      id: info.id,
      phone: info.phone,
      mobileNumber: info.mobileNumber,
      name: info.name,
      family: info.family,
      nationalId: info.profile.nationalId,
      province: info.profile.province,
      city: info.profile.city,
      postalCode: info.profile.postalCode,
      planId: info.plan.id,
      fax: info.profile.fax,
      customerType: info.profile.customerType,
      customerTypeFa: info.profile.customerType !== 'business' ? t`Person` : t`Business`,
      address: info.profile.address,
      profile: vmInfo.profile,
      profileId: vmInfo.profile.id,
      company: vmInfo.profile.company,
      registerNo: vmInfo.profile.registerNo,
      industryType: vmInfo.profile.industryType,
      businessCode: vmInfo.profile.businessCode,
      companyNationalCode: vmInfo.profile.companyNationalCode,
      companyStaffCount: vmInfo.profile.companyStaffCount
    }
  } else if (!vmInfo.profile) {
    array = {
      displayName: vmInfo.displayName,
      customerTypeFa: t`Person`,
        customerType: 'person',
      mobileNumber: vmInfo.mobileNumber,
      email:vmInfo.email,
      name: vmInfo.name,
      family: vmInfo.family
    }
  }
  let infoArray = {
    notChangebleInfo: [{ label: t`Email`, value: array.email }],
    basicInfo: [
      { label: t`Display Name`, value: array.displayName, name: 'displayName' },
      { label: t`Telephone`, value: array.mobileNumber, name: 'phone' },
      { label: t`customerType`, value: array.customerTypeFa, name: 'customerType' }
    ],
    profileInfo: {
      visibleArray: [
        { label: t`National Id`, value: array.nationalId, name: 'nationalId' },
        { label: t`Adress`, value: array.address, name: 'address' },
        { label: t`Postal Code`, value: array.postalCode, name: 'postalCode' },
        { label: t`Fax`, value: array.fax, name: 'fax' }
      ],

      invisibleArray: [
        { label: t`name`, value: array.name, name: 'name' },
        { label: t`family`, value: array.family, name: 'family' },
        { label: t`city`, value: array.city, name: 'city' },
        { label: t`province`, value: array.province, name: 'province' }
      ]
    },

    companyInfo: {
      unSelectableArray: [
        { label: t`Company Name`, value: array.company, name: 'company' },
        { label: t`Business Code`, value: array.businessCode, name: 'businessCode' },
        { label: t`Register Number`, value: array.registerNo, name: 'registerNo' },
        { label: t`Company National Code'`, array: array.companyNationalCode, name: 'companyNationalCode' }
      ],
      selectableArray: [
        { label: t`Industry Type`, value: array.industryType, name: 'industryType' },
        { label: t`Company Staff Count`, value: array.companyStaffCount, name: 'companyStaffCount' }
      ]
    },
    submitInfo: {
      id: array.id,
      language: { id: 1 },
      displayName: array.displayName,
      name: array.name,
      family: array.family,
      terms: true,
      mobileNumber: array.mobileNumber,
      profile: {
        address:  array.address,
        businessCode: array.businessCode,
        company: array.company,
        customerType: array.customerType,
        fax: array.fax,
        nationalId: array.nationalId,
        phone: array.phone,
        registerNo: array.registerNo,
        province: array.province,
        city: array.city,
        postalCode: array.postalCode,
        companyStaffCount: array.companyStaffCount,
        companyNationalCode: array.companyNationalCode,
        industryType: array.industryType,
        id: array.profileId
      }
    }
  }
  return infoArray
}

export const splitInfo = (vmInfo: any) => {
  let profileBasic = [
    { label: t`Display Name`, value: vmInfo.displayName, name: 'displayName' },
    { label: t`Telephone`, value: vmInfo.mobileNumber, name: `mobileNumber` },
    { label: t`Customer Type`, value: vmInfo.customerTypeFa, name: `customerTypeFa` }
  ]
  let personalInfo = [
    { label: t`Name`, value: vmInfo.name + ` ` + vmInfo.family, name: `fullName` },
    { label: t`National Id`, value: vmInfo.nationalId, name: `nationalId` },
    {
      label: t`Location`,
      value: vmInfo.province,
      name: `location`
    },
    { label: t`Adress`, value: vmInfo.address, name: 'address' },
    { label: t`Postal Code`, value: vmInfo.postalCode, name: 'postalCode' },
    { label: t`Fax`, value: vmInfo.fax, name: 'fax' }
  ]
  let companyInfo = [
    { label: t`Company Name`, value: vmInfo.company, name: 'company' },
    { label: t`Business Code`, value: vmInfo.businessCode, name: 'businessCode' },
    { label: t`Register Number`, value: vmInfo.registerNo, name: 'registerNo' },
    { label: t`Company National Code'`, value: vmInfo.companyNationalCode, name: 'companyNationalCode' },
    { label: t`Industry Type`, value: vmInfo.industryType, name: 'industryType' },
    { label: t`Company Staff Count`, value: vmInfo.companyStaffCount, name: 'companyStaffCount' }
  ]
  return [profileBasic, personalInfo, companyInfo]
}

export const renderInfo = (vmInfo: any) => {
  let array: any = []
  localStorage.getItem('__language') == 'fa' && moment().locale('fa')
  vmInfo.map((each: any, index: number) => {
    let deposit: any = '-',
      withdraw: any = '-'

    if (each.amount < 0) deposit = formatPrice(Math.abs(each.amount))
    else withdraw = formatPrice(Math.abs(each.amount))
    const defaultDesc = { CFS: 'خرید اشتراک سرویس مدیریت فایل', CWS: 'خرید سرور مجازی', HOST: 'خرید هاست', ALL: 'شارژ کیف پول', DOMAIN: 'خرید دامنه' }

    array.push({
      index: index + 1,
      date: moment(each.createdAt).format('jYYYY/jM/jD'),
      time: moment(each.createdAt).format('HH:mm:ss'),

      withdraw,
      deposit,
      description: each.description
        ? each.description
        : `${each.to == 'CFS' ? defaultDesc.CFS : ''}
        ${each.to == 'CWS' ? defaultDesc.CWS : ''}
        ${each.to == 'HOST' ? defaultDesc.HOST : ''}
        ${each.to == 'DOMAIN' ? defaultDesc.DOMAIN : ''}
        ${each.to == 'ALL' ? defaultDesc.ALL : ''}`
    })
  })
  return array
}
