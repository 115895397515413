import { actionTypes } from '../actions/types'

export interface PayloadInterface {
  data?: object[]
}

export const initialState: any = {
  modalState: '',
  modalView: false
}

const modalReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_MODAL_STATE:
      return {
        ...state,
        modalState: action.payload
      }
    case actionTypes.SET_MODAL_VIEW:
      return {
        ...state,
        modalState: true,
        modalView: action.payload
      }
    default:
      return state
  }
}

export default modalReducer
