import React from 'react'
import { t } from 'ttag'

import notFound from '../../../images/Icons/commonIcons/not-found.svg'
import { Icon } from '../../ui-elements/Icon/Icon'

export interface Iprops {
  vmPathName: string
}

export const VMNotFound: React.FunctionComponent<Iprops> = ({ vmPathName }) => {
  return (
    <div className={'pg-text-center pg-text-xlg pg-py-12 pg-flex pg-flex-col pg-dir'}>
      <span>
        <Icon src={notFound} width={50} />
      </span>
      <span>
        {vmPathName} {t`not found`}
      </span>
      <span className={'pg-text-xs pg-text-gray-500 pg-my-1'}>{t`sorry! we havent found the page you looking for.`}</span>
    </div>
  )
}
