import * as React from 'react'
import { t } from 'ttag'

import { Prompt } from '../../Prompt/Prompt'
import { TextInput } from '../../../Input/Input'

export class DeleteVm extends React.Component<any, any> {
  textInput: any

  constructor(props: any) {
    super(props)
    this.textInput = React.createRef()
    this.focus = this.focus.bind(this)
  }
  focus() {
    this.textInput.current.focus()
  }

  componentDidMount() {
    this.focus()
  }

  render() {
    let { showModal, handleClose, name, deleteVm, error, errorMsg, handleChange } = this.props
    return (
      <Prompt
        showModal={showModal}
        handleClose={handleClose}
        title={t`Delete`}
        notClickable={!name}
        formDescription={t`Enter server name to confirm`}
        onClick={deleteVm}
      >
        <TextInput
          errIcon={false}
          withIcon={error}
          error={error}
          wrapperRef={this.textInput}
          value={name}
          name={'vmName'}
          message={errorMsg}
          onChange={handleChange}
          style={{ width: '90%' }}
        />
      </Prompt>
    )
  }
}
