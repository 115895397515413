import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// styles
import styles from './Footer.module.scss'

import { switchTimeLang } from '../../../services/internal/utils/switchTimeLang'
import { formatPrice } from '../../../services/internal/utils/formatPrice'
import { setFinalPrice } from '../../../services/internal/store/actions'

export interface Iprops {
  os?: string
  price?: string
  totalPrice?: string
  serviceDuration?: string
  disk?: string
  ram?: string
  vsCardDay?: any
  cpu?: string
  isWindows?: boolean
  finalPrice?: any
  discount?: string
  vmCard: any
  setFinalPrice?: any
}

class SidebarInfoDay extends React.Component<Iprops, any> {
  constructor(props: any) {
    super(props)
    let vsCard = this.props.vmCard.vsCard
    this.state = {
      cpu: vsCard && vsCard.cpu ? vsCard.cpu : '',
      vmInfoObj: [],
      ram: vsCard && vsCard.ram ? vsCard.ram : '',
      disc: vsCard && vsCard.disc ? vsCard.disc : '',
      price: 20000,
      finalPrice: 0
    }
  }

  calculatePrice = (cpu: any, ram: any, disk: any, isWindows?: any) => {
    let unitPrice = 1,
      normalDefault = isWindows ? 23 : 20
    cpu = parseFloat(cpu) ? parseFloat(cpu) - 4 : 0
    ram = parseFloat(ram) ? parseFloat(ram) - 8 : 0
    disk = parseFloat(disk) ? (isWindows ? parseFloat(disk) - 110 : parseFloat(disk) - 80) : 0
    let price

    if (this.props.os) {
      price = (+normalDefault + +cpu + +ram + +disk) * +unitPrice
    }
    return price ? price : 0
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    let vsCard = nextProps.vmCard.vsCard
    if (nextProps.os) {
      if (nextProps.os == 'Windows Server 2012' && this.state.disc == '80 GB') {
        this.setState({ disc: 110 })
      }
    }

    if (vsCard) {
      let { ram, disk, cpu } = vsCard
      this.setState({
        cpu,
        ram,
        disc: disk,
        priceX: nextProps.finalPrice
      })
      if (nextProps.os == 'Windows Server 2012' && parseFloat(disk) == 80) {
        disk = 110
      }
      let price = 20
      let totalCostNotax = this.calculatePrice(cpu, ram, disk, nextProps.os == 'Windows Server 2012')
      let tax = 1.09
      let priceCost = price ? formatPrice(price) : ''

      let vmInfoObj = [
        { name: t`OS`, value: nextProps.os },
        { name: t`Service term`, value: switchTimeLang(nextProps.serviceDuration, 'en') },
        { name: t`Processor`, value: cpu },
        { name: t`Memory`, value: ram },
        {
          name: t`Disk space`,
          value: disk
        },
        {
          name: t`Cost of service`,
          value: priceCost
        },
        { name: t`Discount site`, value: nextProps.discount ? nextProps.discount : '-' },
        {
          name: t`Total cost(+TAX)`,
          value: totalCostNotax && formatPrice(totalCostNotax * tax)
        }
      ]

      if (
        nextProps.vsCardDay.ram !== this.props.vsCardDay.ram ||
        nextProps.vsCardDay.disk !== this.props.vsCardDay.disk ||
        nextProps.vsCardDay.cpu !== this.props.vsCardDay.cpu ||
        this.props.finalPrice !== formatPrice(totalCostNotax * tax)
      ) {
        this.props.setFinalPrice(totalCostNotax && formatPrice(totalCostNotax * tax))
      }
      if (nextProps.os == 'Windows Server 2012' && this.state.disc == '80 GB') {
        this.props.setFinalPrice(totalCostNotax && formatPrice(totalCostNotax * tax))
      }
      this.setState({ vmInfoObj })
    }
  }

  render() {
    let { vmInfoObj } = this.state
    return (
      <div>
        <div className={styles.info}>
          <div className={styles.item}>
            <ul className={'pg-border pg-border-gray-300'}>
              {vmInfoObj.map((each: any, index: number) => (
                <li className={'pg-flex pg-flex-col'} key={index}>
                  <span className={' pg-w-full pg-bg-gray-300 pg-p-1 pg-text-center'}> {each.name}</span>
                  <span className={'pg-p-1 pg-text-gray-800 pg-w-full pg-text-center'} style={{ height: '30px', direction: 'ltr' }}>
                    {each.value}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  vmCard: state.vm,
  vsCardDay: state.vm.vsCardDay,
  finalPrice: state.vm.finalPrice,
  os: state.vm.os[0],
  serviceDuration: state.vm.serviceDuration
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFinalPrice: (value: any) => dispatch(setFinalPrice(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarInfoDay)
