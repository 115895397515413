import { actionTypes } from '../actions/types'

// Interfaces
interface StateInterface {
  isLoading: boolean
  modalLoading: boolean
  downloadLoading: boolean
  invoiceLoading?: any
  buttonLoading?: boolean
  documentLoading?: boolean
  vmLoadingid?: any
  isInvoiceLoading?: any
}

interface ActionInterface {
  type: string
  isLoading?: boolean

  modalLoading?: boolean
  downloadLoading?: boolean
  invoiceLoading?: any
  buttonLoading?: boolean
}

const initialState: StateInterface = {
  isLoading: false,
  modalLoading: false,
  downloadLoading: false,
  isInvoiceLoading: false,
  vmLoadingid: null,
  buttonLoading: false,
  invoiceLoading: [],
  documentLoading: false
}

const loadingReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_LOADING_STATE:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case actionTypes.SET_LOADING_INVOICE_STATE:
      return {
        ...state,
        isInvoiceLoading: action.payload
      }

    case actionTypes.SET_DOCUMENTS_LOADING:
      return {
        ...state,
        documentLoading: action.payload
      }
    case actionTypes.SET_VM_LOADING_ID:
      return {
        ...state,
        vmLoadingid: action.payload
      }

    case actionTypes.SET_MODAL_LOADING_STATE:
      return {
        ...state,
        modalLoading: action.modalLoading
      }
    case actionTypes.SET_BUTTON_LOADING_STATE:
      return {
        ...state,
        buttonLoading: action.buttonLoading
      }

    case actionTypes.SET_DOWNLOAD_LOADING:
      return {
        ...state,
        downloadLoading: action.downloadLoading
      }
    case actionTypes.SET_INVOICE_LOADING:
      let invoiceLoading = state.invoiceLoading
      if (!invoiceLoading) invoiceLoading = []
      invoiceLoading[action.index] = action.invoiceLoading
      return {
        ...state,
        invoiceLoading
      }
    default:
      return state
  }
}

export default loadingReducer
