import React, { useState } from 'react'
import { t } from 'ttag'
import { TableItem } from '../Table/TableBody/TableItem'
import styles from './Skeleton.module.scss'
export const TableSkeleton: React.FunctionComponent<any> = ({ rowsNumber }) => {
  return (
    <div className={`pg-flex pg-flex-col`}>
      <table>
        <tbody>
        {Array.from(Array(rowsNumber), (e, i) => (
          <tr key={i} className={'pg-border-t pg-border-b pg-border-gray-400 pg-text-center '}>
            <TableItem
              label={
                <div className={'pg-flex pg-justify-center pg-items-center'}>
                  <div className={styles.loadingPic} />
                  <div className={styles.loadingLine} />
                </div>
              }
              className={['smPadding', 'show']}
              style={{ verticalAlign: 'middle' }}
              mimetype={''}
              hasIcon={true}
            />
            <TableItem
              label={<div className={styles.loadingLine} />}
              className={['smPadding', 'show']}
              style={{ verticalAlign: 'middle' }}
              mimetype={''}
              hasIcon={true}
            />
            <TableItem
              label={<div className={styles.loadingLineSm} />}
              className={['smPadding', 'show']}
              style={{ verticalAlign: 'middle' }}
              mimetype={''}
              hasIcon={true}
            />
            <TableItem
              label={<div className={styles.loadingLine} />}
              className={['smPadding', 'show']}
              style={{ verticalAlign: 'middle' }}
              mimetype={''}
              hasIcon={true}
            />
            {/* <TableItem
            label={<div className={styles.loadingLine}/>}
            className={['smPadding', 'show']}
            style={{ width: '25%', verticalAlign: 'middle' }}
            mimetype={''}
            hasIcon={true}
          /> */}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}
