import * as React from 'react'
import { t } from 'ttag'

// import { generateDownloadLink } from '../../../../services/internal/store/actions'
import { ClipBoard } from '../../../ui-elements/Clipboard/Clipboard'
import { Button } from '../../../ui-elements/Button/Button'
import { IconLink } from '../../../ui-elements/Icon/IconLink'

// icons
import uploadIcon from '../../../../images/Icons/commonIcons/upload.svg'

export const DownloadBar: React.FunctionComponent<any> = ({ item, downloadLoading, generateDownloadLink, imageUrl }) => {
  return (
    <>
      <Button className={['pg-btnSuccess', 'pg-btnLg']} style={{ marginBottom: '15px' }} loading={downloadLoading}>
        <IconLink
          icon={uploadIcon}
          iconAlt="upload icon"
          label={t`Download File`}
          onClick={() => generateDownloadLink({ uuid: item.uuid, name: item.name })}
        />
      </Button>
      <div className="pg-flex pg-flex-row pg-flex-wrap pg-justify-center pg-border pg-mt-19p pg-rounded-sm pg-text-xs pg-p-8p">
        آمار: {JSON.stringify(item.downloadCount)} دانلود
      </div>
      <div className="pg-mt-37p pg-mb-5 pg-justify-center">
        <div className={'pg-justify-center pg-text-xs pg-w-full pg-text-gray-800 pg-mb-2'}>{t`Download direct link:`}</div>
        <ClipBoard placeholder={`${process.env.REACT_APP_URL}/preview/${item.uuid}${imageUrl ? imageUrl : ''}/${item.name}`} />
      </div>
    </>
  )
}
