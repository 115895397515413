import React, { useState } from 'react'
import { t } from 'ttag'

//interface
import { TableItemProps } from './TableBody.interface'

import styles from '../Table.module.scss'
import { Checkbox } from '../../Checkbox/Checkbox'
import { RenderIcon } from '../../../RenderIcon/RenderIcon'
import { TextInput } from '../../Input/Input'
import { Button } from '../../Button/Button'

//style
import '../drag.scss'
import { Tooltip } from '../../Tooltip/Tooltip'

export const splitter = (className: any) => {
  if (!className.includes('show')) className = ['hidden']
  return className && className.map((cls: any) => styles[cls]).join(` `)
}

export const TableDndItem: React.FunctionComponent<TableItemProps> = ({
  label,
  checkbox,
  name,
  sortable,
  editableId,
  onSort,
  id,
  sortType,
  className,
  mimetype,
  handleNavigate,
  onCheckAll,
  checked,
  itemName,
  selection,
  item,
  hovered,
  selectableRows,
  onCheck,
  shareButton,
  hasIcon,
  hoverStyle,
  renameText,
  openModal,
  checkboxType,
  onMouseOver,
  handleChange,
  style,
  uuid
}) => {
  let imgSrc = item && `${process.env.REACT_APP_URL}/preview/${uuid}/medium/${item.name}`
  const [hoveredButton, setHovered] = useState(false)
  const toggleHover = () => setHovered(!hoveredButton)

  let numList = ['تاریخ', 'مالک', 'حجم', t`owner`, t`created_at`, t`size`]
  let namey = name && numList.includes(name) ? (name == 'حجم' || name == t`size` ? 'share' : 'num') : name ? 'text name' : 'text'

  return (
    <div
      data-label={name}
      className={splitter(className) + ' ' + namey}
      style={style}
      {...(sortable && { onClick: () => onSort && onSort(label, sortType) })}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      {...(onMouseOver && { onMouseMove: onMouseOver})}
      {...(handleNavigate && name === 'name' && { onDoubleClick: e => handleNavigate({ e: e, name: itemName, id: id, uuid: uuid, item: item && item.item }) })}
    >
        
        
      {name === 'name'  && <span className={`tooltip`} id={'tooltip'} style={hoverStyle}>Double Click to open </span>}
      <div className={name == 'index' || name == t`ردیف` ? 'rowItem pg-flex pg-justify-center' : 'rowItem'}>
        {checkbox && selectableRows && (
          <div className={selection && selection.length > 0 ? 'rowItem' : hovered ? 'rowItem' : [styles.invisible, 'rowItem'].join(' ')}>
             <Checkbox type={checkboxType} 
            onChange={(e: any) => (onCheckAll ? onCheckAll() : onCheck && id && onCheck(id, e,true))} 
           checked={checked} /> 
          </div>
        )}
        <div
          className={'rowItem'}
        >
          {hasIcon && <RenderIcon mimetype={mimetype} imgSrc={imgSrc} name={name} id={'icon'} />}

          <div className={styles.name}>
            {name == 'name' && editableId == id ? (
              <div>
                <TextInput placeholder={label} value={renameText} onChange={handleChange} />
              </div>
            ) : (
              <span className={name == 'index' || name == t`index` ? 'pg-text-center' : ''}> {label} </span>
            )}
          </div>
        </div>
        {sortable && (
          <div className={styles.sort}>
            <span>▲</span>
            <span>▼</span>
          </div>
        )}
        {shareButton && (
          <Button
            className={hoveredButton ? ['pg-btnPrimary ', 'pg-btnPrimaryOutline'] : ['pg-btnDefault', 'pg-btnDefaultOutline']}
            extraClassName={[styles.share, hovered ? '' : styles.invisible]}
            onClick={() => openModal(id, t`Share File`)}
          >
            {t`share`}
          </Button>
        )}
      </div>
    </div>
  )
}
