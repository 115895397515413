import React from 'react'
import { t } from 'ttag'
import { connect } from 'react-redux'

// ui-elements
import { ColorfulBox } from '../../ui-elements/ColorfulBox/ColorfulBox'
import { Button } from '../../ui-elements/Button/Button'

// styles
import styles from './Footer.module.scss'

import { formatPrice } from '../../../services/internal/utils/formatPrice'
import SidebarInfo from './SidebarInfo'
import SidebarInfoDay from './SidebarInfoDay'

export interface Iprops {
  id?: number
  hide?: boolean
  steps?: any
  period?: string
  leftCredit?: number
  update?: boolean
  isVpsCard?: boolean
  vpsPrice?: number
  serviceDuration?: any
  hpc?: boolean
  os?: any
  name?: string
  onSelectVps?: (value: any) => void
  calculateLeftCredit?: any
  setFinalPrice?: any
}

export const VmInfo: React.FunctionComponent<Iprops> = props => {
  let leftCredit = props.leftCredit ? props.leftCredit : 0
  return !props.update ? (
    props.serviceDuration == 'DAY' ? (
      <SidebarInfoDay />
    ) : (
      <SidebarInfo />
    )
  ) : props.vpsPrice || props.hpc ? (
    <ColorfulBox className={['blue', 'lg', 'pg-mb-3']} fontSize={14} marginDown={'10px'} hide={props.hide}>
      <div className={`${styles.info} pg-w-full`}>
        <div className={styles.item}>
          <ul className={'pg-flex pg-justify-between pg-text-gray-800 pg-items-center'}>
            <li>
              <span className={'pg-font-thin pg-text-xs pg-text-xs pg-text-gray-800'}>{props.name}</span>
            </li>
            <li>
              <span className={'pg-font-thin pg-text-xs pg-text-xs pg-text-gray-800'}>{props.period && props.period}</span>
            </li>
            <li>
              {t`Refund cost`}: <span className={'pg-font-thin pg-text-xs pg-text-xs pg-text-gray-700'}>{formatPrice(leftCredit)}</span>
            </li>
            <li>
              {t`Cost of service`}:{' '}
              <span className={'pg-font-thin pg-text-xs pg-text-xs pg-text-gray-700'}>{props.vpsPrice && formatPrice(props.vpsPrice)}</span>
            </li>
            <li>
              <span className={'pg-font-thin pg-text-xs pg-text-xs pg-text-gray-800'}>{t`Total cost`}: </span>
              <span className={'pg-font-thin pg-text-xs pg-text-xs pg-text-gray-700'}>
                {props.hpc
                  ? props.vpsPrice && props.vpsPrice - leftCredit > 0 && formatPrice(+((props.vpsPrice - leftCredit) * 1.09).toFixed(2))
                  : props.vpsPrice && formatPrice(props.vpsPrice - leftCredit)}
              </span>
            </li>
            <li>
              {props.vpsPrice && props.vpsPrice - leftCredit > 0 ? (
                <Button className={['pgbtn', 'pg-btnPrimary', 'pg-btnMd']} style={{ height: '35px' }} onClick={props.onSelectVps}>
                  {t`Export Invoice`}
                </Button>
              ) : (
                <Button className={['pgbtn', 'pg-btnDisabled', 'pg-btnMd']} style={{ height: '35px' }}>
                  {t`Export Invoice`}
                </Button>
              )}
            </li>
          </ul>
        </div>
      </div>
    </ColorfulBox>
  ) : props.isVpsCard ? (
    <ColorfulBox
      className={['blue', 'lg', 'pg-mb-3']}
      marginDown={'10px'}
      hide={props.hide}
      direction={'ltr'}
      message={t`Please select the vps plan you want and click on Issuance for next steps`}
    />
  ) : (
    <div />
  )
}
