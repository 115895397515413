import React from 'react'
import { Select } from '../../../Select/Select'
import { TextInput } from '../../../Input/Input'
import { Radio } from '../../../Radio/Radio'
import { Checkbox } from '../../../Checkbox/Checkbox'
import { isNumber } from 'util'

export default interface Iprops {}

export const InputBox: React.FunctionComponent<any> = props => {
  let { type, index, onChange, name, checked, optionsArray, value, className, disabled } = props
  let input
  switch (type) {
    case 'select':
      if (!isNumber(value) && optionsArray[value] && optionsArray[value].code) value = optionsArray[value].value
      input = (
        <div className={'pg-flex pg-w-full pg-justify-end'} key={index}>
          <Select onChange={onChange} name={name} optionsArray={optionsArray} disabled={disabled} value={value} />
        </div>
      )
      break
    case 'radio':
      input = (
        <div className={'pg-flex pg-w-full  pg-items-center pg-my-2'} key={index}>
          <Radio name={name} value={value} onChange={onChange} checked={checked} />
          <span className={'pg-mx-4'}>{value}</span>
        </div>
      )
      break
    case 'checkbox':
      input = (
        <div className={'pg-flex pg-w-full  pg-items-center pg-my-2'} key={index}>
          <Checkbox checked={value} onChange={onChange} />
        </div>
      )
      break
    default:
      input = <TextInput value={value} key={index} className={className} style={{ width: '100%' }} onChange={onChange} name={name} />
      break
  }
  return input
}
