import { actionTypes } from './types'
import { actionBuilder } from './actionBuilder'

export const getSettingState = actionBuilder(actionTypes.GET_SETTING_STATE)

export const setSettingState = actionBuilder(actionTypes.SET_SETTING_STATE, 'payload')

export const getUserInfo = actionBuilder(actionTypes.GET_USER_INFO)

export const getWallet = actionBuilder(actionTypes.GET_WALLET, 'payload')

export const setCancleToken = actionBuilder(actionTypes.SET_CANCEL_TOKEN, 'payload')

export const setWallet = actionBuilder(actionTypes.SET_WALLET, 'payload')

export const setUserInfo = actionBuilder(actionTypes.SET_USER_INFO, 'info')

export const setUserProfile = actionBuilder(actionTypes.SET_USER_PROFILE, 'info')



export const getProducts = actionBuilder(actionTypes.GET_PRODUCTS)

export const setProducts = actionBuilder(actionTypes.SET_PRODUCTS, 'products')

export const setFormState = actionBuilder(actionTypes.SET_FORM_STATE, 'payload')

export const setProductToggle = actionBuilder(actionTypes.SET_PRODUCT_TOGGLE, 'payload')
export const setExpirationNotification = actionBuilder(actionTypes.SET_EXPIRATION_NOTIFICATION, 'payload')
export const changePlan = actionBuilder(actionTypes.CHANGE_PLAN, 'id', 'additionalInfo', 'applyNow')

export const changeProfile = actionBuilder(actionTypes.CHANGE_PROFILE, 'payload')

export const setCities = actionBuilder(actionTypes.SET_CITIES, 'payload')

export const getInvoices = actionBuilder(actionTypes.GET_INVOICES, 'payload')

export const vmBillInvoices = actionBuilder(actionTypes.VM_BILL_INVOICES, 'payload')
export const hostInvoices = actionBuilder(actionTypes.HOST_INVOICES)
export const domainInvoices = actionBuilder(actionTypes.DOMAIN_INVOICES)

export const setInvoices = actionBuilder(actionTypes.SET_INVOICES, 'payload')

export const getInvoice = actionBuilder(actionTypes.GET_INVOICE, 'payload', 'index', 'invoiceType')

export const setSubmitInfo = actionBuilder(actionTypes.SET_SUBMIT_INFO, 'payload',)

export const getVmBill = actionBuilder(actionTypes.GET_VM_BILL, 'payload')

export const setInvoice = actionBuilder(actionTypes.SET_INVOICE, 'payload', 'index')

export const removeInvoice = actionBuilder(actionTypes.REMOVE_INVOICE)

export const setInvoiceItem = actionBuilder(actionTypes.SET_INVOICE_ITEM, 'payload')

export const addToBalance = actionBuilder(actionTypes.ADD_TO_BALANCE, 'payload')

export const setRef = actionBuilder(actionTypes.SET_REF, 'payload')

export const setValidationErr = actionBuilder(actionTypes.SET_VALIDATION_ERR, 'payload')

export const doPay = actionBuilder(actionTypes.DO_PAY, 'payload')

export const mellatBank = actionBuilder(actionTypes.MELLAT_BANK, 'payload')

export const setVmInvoices = actionBuilder(actionTypes.SET_VM_INVOICE, 'payload')

export const setHostInvoices = actionBuilder(actionTypes.SET_HOST_INVOICE, 'payload')

export const setDomainInvoices = actionBuilder(actionTypes.SET_DOMAIN_INVOICE, 'payload')

export const payInvoice = actionBuilder(actionTypes.PAY_INVOICE, 'payload')

export const setInvoicesArray = actionBuilder(actionTypes.SET_INVOICES_ARRAY, 'payload')

export const setProfileArray = actionBuilder(actionTypes.SET_PROFILE_ARRAY, 'payload')

export const setIpCountry = actionBuilder(actionTypes.SET_IP_COUNTRY, 'payload')

export const getTransactions = actionBuilder(actionTypes.GET_TRANSACTIONS)

export const setTransactions = actionBuilder(actionTypes.SET_TRANSACTIONS, 'payload')
export const setLockedAccount = actionBuilder(actionTypes.SET_LOCKED_ACCOUNT, 'payload')
export const setEmail = actionBuilder(actionTypes.SET_EMAIL, 'payload')
export const setInvoicesVm = actionBuilder(actionTypes.SET_INVOICES_VM, 'payload')
export const setInvoicesFm = actionBuilder(actionTypes.SET_INVOICES_FM, 'payload')
export const setInvoicesHost = actionBuilder(actionTypes.SET_INVOICES_HOST, 'payload')
export const setInvoicesDomain = actionBuilder(actionTypes.SET_INVOICES_DOMAIN, 'payload')