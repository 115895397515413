import React from 'react'

import { trimLongStr } from '../../../services/internal/utils/trimLongStr'
import { Button } from '../Button/Button'

import closeIcon from '../../../images/Icons/buttonIcons/icon-close.svg'
import { Icon } from '../Icon/Icon'
import styles from './Preview.module.scss'

interface Iprops {
  width: number
  item: any
  handleClose: any
}
export const PreviewHeader: React.FunctionComponent<Iprops> = ({ width, item, handleClose }) => {
  let itemName = item.name
  if (width < 500 && item.name.length > 15) itemName = trimLongStr({ str: item.name, length: 15 })
  return (
    <div className={styles.header}>
      <span className={styles.fileName}>{itemName}</span>
      <Button className={['pg-btnControl', 'pg-btnSm']} onClick={handleClose}>
        <Icon src={closeIcon} className={styles.icon} /> <span className={styles.text}>بستن</span>
      </Button>
    </div>
  )
}
