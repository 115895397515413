import React from 'react'
import { formatPrice } from '../../../../../../services/internal/utils/formatPrice';
import { RangeBar } from '../../../../../ui-elements/RangeBar/RangeBar';

export const RangeBarCard: React.FunctionComponent<any> = ({ rangebarObject, updateRange }) => {
  let { text, number, unit, min, max, step, value } = rangebarObject
  return (
    <div
      className={
        'pg-w-full pg-p-4 pg-bg-white pg-border pg-flex pg-flex-row-reverse pg-justify-between pg-text-center pg-items-center pg-border-rounded pg-my-4'
      }
    >
      <div className={'pg-flex pg-flex-col'}>
        <div className={'pg-uppercase'}>{text}</div>
        <div className={'pg-text-blue-500 '} style={{ direction: 'ltr' }}>
          {number} {unit}
        </div>
      </div>
      <RangeBar width={300} min={min} max={max} updateRange={updateRange} direction={'ltr'} step={step} value={value} name={text} />

      <div className={'pg-content-center pg-text-gray-700 pg-text-xs'} style={{ direction: 'ltr' }}>
        1 {unit} = {formatPrice(1)}
      </div>
    </div>
  )
}
