import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// components
import { ContentHeader } from './ContentHeader'
import ContentBody from './ContentBody'

// Services
import {
  getDocuments,
  setSidebarItems,
  getTrashDocuments,
  getSharedDocuments,
  setParentId,
  setEditStatus,
  setRenameText,
  removeFolder,
  uploadServer,
  setDocuments,
  generateDownloadLink,
  setDownloadToken,
  setLoadingState,
  dragDocuments,
  setPreviewState,
  setUploadFiles,
  setIpCountry,
  setUploader,
  setUploadProgress,
  setCancleToken,
  setDragnDropState,
  setDragInfo
} from '../../services/internal/store/actions'

import { setSelections, removeSelection, setToggle, selectAll } from '../../services/internal/store/actions/selections'
import { IGenerateLinkInput, IRenameFolderInput, IRemoveFolderInput, IMoveDocumentsInput } from '../../services/internal/repositories/documents'
import { sortData } from '../../services/internal/utils/sortData'

// styles & icons
import styles from './Content.module.scss'

import ModalContent from './SubContent/ModalContent'
import { ItemInterface } from '../../services/internal/store/reducers/documentReducer'
import toast from '../../components/ui-elements/Toast/Toast'
import { ColorfulBox } from '../ui-elements/ColorfulBox/ColorfulBox'

import UploadingModal from '../ui-elements/Modal/UploadingModal/UploadingModal'
import DropZoneContainer from '../Dropbox/DropZoneContainer'

export interface IProps {
  getDocuments?: any
  data?: any
  history?: any
  setPreviewState?: any
  setEditStatus?: any
  location?: any
  item: ItemInterface
  isUploadDisabled?: boolean
  document?: any
  loading?: boolean
  setSelections: (e: Array<number>) => void
  removeFolder?: any
  setRenameText?: any
  setItem: (e: any) => void
  selection: Array<number>
  image?: string
  removeSelection?: any
  getSharedDocuments?: any
  moveDocuments?: any
  getTrashDocuments?: any
  setToggle?: any
  msg?: string
  setParentId?: any
  setLoading?: any
  selectAll?: any
  setDownloadToken?: any
  uploadServer?: any
  setDocuments?: any
  downloadToken?: any
  generateDownloadLink?: any
}

export interface navigateObject {
  e: any
  name?: string
  id?: number
  item?: any
  uuid?: string
}

export interface IState {
  tableData: any
  checkAll: boolean
  view: string
  ascendingSize: boolean
  ascendingDate: boolean
  ascendingName: boolean
  showMore: boolean
  selectedArray: number[]
  preLoading: boolean
  [key: string]: any
}

class Content extends React.Component<any, any> {
  step = 10
  uploader: any

  constructor(props: any) {
    super(props)
    this.state = {
      tableData: [],
      filteredTable: [],
      checkAll: false,
      selectedArray: [],
      view: localStorage.getItem('displayView') ? localStorage.getItem('displayView') || '{}' : 'grid',
      showMore: false,
      modalView: '',
      ascendingSize: false,
      searchValue: '',
      ascendingDate: true,
      ascendingName: false,
      name: '',
      search: false,
      showModal: false,
      showToaster: false,
      description: '',
      preLoading: false,
      showToast: false,
      message: '',
      loading: false,
      toRemove: [],
      files: []
    }
  }
  componentWillUnmount() {
    this.props.setSelections([])
  }
  async componentDidMount() {
    this.setState({ preLoading: true })
    if (
      this.props.location.pathname === '/nwfm' ||
      this.props.location.pathname === '/nwfm/' ||
      this.props.location.pathname === '/nwfm/cfs' ||
      this.props.location.pathname.includes('preview')
    ) {
      this.onGetDocument(false)
      this.props.setToggle([false, false])
      this.setState({ tableData: this.props.data })
    } else {
      if (this.props.location.pathname.split('/nwfm/')[1]) {
        if (this.props.location.pathname.split('/nwfm/')[1].includes('trash')) {
          this.props.getTrashDocuments()
          // this.props.setToggle([false, true])
        } else if (this.props.location.pathname.split('/nwfm/')[1].includes('shared')) {
          this.props.getSharedDocuments()
          // this.props.setToggle([true, false])
        } else {
          this.onGetDocument(true, this.props.location.pathname.split('/nwfm/')[1], this.props.item.id)
        }
      } else {
        this.onGetDocument(true, this.props.location.pathname.split('/nwfm/')[1], this.props.item.id)
      }
    }
    document.title = t`File Storage`
  }

  /**back button */
  componentDidUpdate(prevProps: any) {
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      this.props.location.pathname.includes('/nwfm') &&
      !prevProps.location.pathname.includes('/preview') &&
      !this.props.location.pathname.includes('/preview')
    ) {
      if (!this.props.location.pathname.split('/nwfm')[1] || this.props.location.pathname.split('/nwfm')[1] == '/') {
        this.onGetDocument(false)
        // this.props.setToggle([false, false])
      } else if (
        !this.props.location.pathname.split('/nwfm')[1].includes('/trash') &&
        !this.props.location.pathname.split('/nwfm')[1].includes('/shared') &&
        this.props.location.pathname.includes('/preview')
      ) {
        this.onGetDocument(true, this.props.location.pathname.split('/nwfm/')[1], this.props.item.id)
      } else if (this.props.location.pathname.split('/nwfm')[1].includes('/trash')) {
        this.props.getTrashDocuments()
      } else if (this.props.location.pathname.split('/nwfm')[1].includes('/shared')) {
        this.props.getSharedDocuments()
      } else {
        this.onGetDocument(true, this.props.location.pathname.split('/nwfm/')[1], this.props.item.id)
      }
    } else if (this.props.location.pathname.includes('/preview') && this.state.modalView === '') {
      this.props.history.push('/nwfm')
    }
  }

  /**
   * gets documnet if children goes inside folder
   */
  onGetDocument = (isChildren?: boolean, path?: any, parentId?: number) => {
    this.setState({ loading: true })
    if (localStorage.getItem('csrf_token')) {
      if (isChildren == true) {
        this.props.setParentId(parentId)
        this.props.getDocuments({ isChildren: true, path, parentId })
      } else {
        this.props.setParentId(0)
        this.props.getDocuments()
      }
    }
  }

  /**
   * gets data and makes an obj
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.item) {
      this.setState({
        item: nextProps.item
      })
    }
    if (nextProps.downloadToken && nextProps.downloadToken.length > 0) {
      if (nextProps.isDirectory) {
        window.location.href = `http://cdn.persiangig.com/cfs/rest/documents/downloadArchive?token=${nextProps.downloadToken}`
      } else window.location.href = `${process.env.REACT_APP_URL}/dl/${nextProps.downloadToken}/${this.props.item.uuid}/${this.props.item.name}`
      this.props.setDownloadToken('')
    }
    if (
      (!this.state.search && nextProps.selection.length == 0) ||
      (nextProps.selection.length > 0 && nextProps.document.documents !== this.state.mainTable)
    ) {
      this.setState({
        tableData: nextProps.document.documents,
        mainTable: nextProps.document.documents,
        filteredTable: nextProps.document.documents.length === 0 ? ['empty'] : nextProps.document.documents,
        preLoading: nextProps.document.documents.length !== 0 && false
      })
    }
  }

  //sort types
  onSort = (sortBy: string) => {
    let tableData = this.state.tableData
    switch (sortBy) {
      case t`Name`:
        tableData = sortData(tableData, sortBy, this.state.ascendingName)
        this.setState({
          ascendingName: !this.state.ascendingName
        })
        break
      case t`Content`:
        tableData = sortData(tableData, sortBy, this.state.ascendingSize)
        this.setState({
          ascendingSize: !this.state.ascendingSize
        })

        break
      case t`Date`:
        tableData = sortData(tableData, sortBy, this.state.ascendingDate)
        this.setState({
          ascendingDate: !this.state.ascendingDate
        })
        break
    }

    this.setState({
      tableData,
      mainTable: tableData,
      filteredTable: this.state.tableData
    })
  }
  handleChange = (event: any) => {
    this.props.setRenameText({ [event.target.name]: event.target.value })
  }

  //on check all documents and uncheck
  onCheckAll = () => {
    let ids = this.state.tableData.map((a: any) => a.id)
    if (this.props.selection.length == 0 && this.props.selection.sort().toString() !== ids.sort().toString()) {
      this.props.setSelections(ids)
      this.props.selectAll(true)
    } else {
      this.props.removeSelection()
      this.props.selectAll(false)
    }
  }

  //switch between grid and list
  switchView = (view: string) => {
    this.setState({ view })
    localStorage.setItem('displayView', view)
  }

  // navigate to directories
  handleNavigate = ({ e, name, id, item }: navigateObject) => {
    if (e.target.tagName != 'INPUT') {
      let discriminator = this.state.tableData.filter((obj: any) => {
        return obj.name == name
      })
      discriminator = discriminator && discriminator[0] && discriminator[0].discriminator
      if (discriminator === 'D') {
        this.props.history.push(`${this.props.history.location.pathname}/${name}`)
        // this.onGetDocument(true, `${this.props.history.location.pathname.split('nwfm/')[1]}`, item.id)
      } else {
        this.props.setPreviewState(true)
        this.props.history.push(
          `${this.props.history.location.pathname}/preview/${item.genericType}${
            item.genericType === 'image' ? (this.props.image ? '/' + this.props.image : '/medium') : ''
          }/${name}`
        )

        this.props.setItem(item)
        this.setState({ modalView: 'previewModal', previewId: id, fileName: name, [`item${id}`]: item })
      }
    }
  }

  onOpenCFModal = () => {
    this.setState({ modalView: 'createFolder', showModal: true })
  }

  updateTable = (tableData: any) => {
    if (this.state.tableData !== tableData) this.setState({ tableData })
    this.closeModal()
  }

  // open modal
  openModal = (renameFileId: number, modalView?: string) => {
    let item = this.state.tableData.filter((obj: any) => {
      return obj.id === renameFileId
    })[0]
    this.props.setItem(item)
    // if (modalView == t`Change Name`) this.props.setEditStatus(renameFileId)
    // else
    this.setState({ modalView, showModal: true })
  }
  downloadFile = (fileId: number) => {
    let item = this.state.tableData.filter((obj: any) => {
      return obj.id === fileId
    })[0]
    this.props.setItem(item)
    let { uuid, name } = item
    let result = this.props.generateDownloadLink({ uuid, name })
  }
  // handle search
  onChangeSearchInput = (val: string) => {
    let filteredTable = this.state.tableData.filter((obj: any) => {
      let path = obj.cfsFullPath
      return path.toLocaleLowerCase().includes(val.toLocaleLowerCase())
    })
    this.setState({
      filteredTable: filteredTable.length == 0 ? ['empty'] : filteredTable,
      searchValue: val,
      search: true
    })
    // this.updateTable({ tableData :filteredTable})
  }
  handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.onChangeSearchInput(e.target.value)
    }
  }
  onRemoveDocument = (fileId: number) => {
    let tableData = this.state.tableData

    try {
      let result = this.props.removeFolder({ folderId: fileId })
      tableData = tableData.filter((x: any) => x.id !== fileId)
      this.updateTable({ tableData })
      toast.success(`File has been deleted`)
    } catch (error) {
      //console.log('E: ', error)
    }
  }

  arraysEqual = (a: any, b: any) => {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length != b.length) return false

    a.sort()
    b.sort()
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
    return true
  }
  moveRow = (id: any, dragId: any) => {
    // this.props.moveDocuments({ targetId: id, documentIds: [dragId] })
    // let documents = this.props.document.documents
    // if (dragId) {
    //   documents.filter((x: any) => {
    //     console.log(x.id, dragId)
    //     x.id !== dragId
    //   })
    // this.props.setDocuments(documents)
    // }
    // this.props.moveDocuments({ targetId: id, documentIds: [dragId] })
    // this.setState({ hovered: true, hoverable: true })
  }

  // on item check
  onCheck = (id: number, event?: any, isList?: boolean) => {
    event && event.preventDefault()
    if (!isList) {
      let selectedArray = this.props.selection
      if (selectedArray.indexOf(id) === -1) selectedArray.push(id)
      else
        selectedArray = selectedArray.filter((obj: any) => {
          return obj !== id
        })
      let ids = this.state.tableData.map((a: any) => a.id)
      if (this.arraysEqual(ids, selectedArray)) this.props.selectAll(true)
      this.props.setSelections(selectedArray)
      this.setState({ selectedArray })
    }
  }
  onClick = (id: number, event?: any) => {
    if (!event) {
      let selectedArray = this.props.selection
      if (selectedArray.indexOf(id) === -1) selectedArray.push(id)
      // else if (this.props.dragNdropState == true) return null
      else
        selectedArray = selectedArray.filter((obj: any) => {
          return obj !== id
        })

      let ids = this.state.tableData.map((a: any) => a.id)
      if (this.arraysEqual(ids, selectedArray)) this.props.selectAll(true)
      this.props.setSelections(selectedArray)
      this.setState({ selectedArray })
    }
  }
  closeModal = () => {
    if (this.state.modalView) this.setState({ modalView: '' })
  }

  allowDrop = (ev: any) => {
    ev.stopPropagation()
    ev.preventDefault()
    ev.dataTransfer.dropEffect = 'copy'
    let isFile: any
    if (ev.dataTransfer.types) {
      for (var i = 0; i < ev.dataTransfer.types.length; i++) {
        if (ev.dataTransfer.types[i] == 'Files') {
          isFile = true
        }
      }
    }

    if (!this.state.dragged && isFile) {
      this.setState({ dragged: true })
    }
    if (!isFile && !this.props.dragNdropState) {
      this.props.setDragnDropState(true)
    }
  }

  drop = (ev: any) => {
    ev.preventDefault()
    this.props.setDragnDropState(false)
  }

  dragLeaveHandler = () => {
    if (this.state.dragged) {
      this.setState({ dragged: false })
      this.props.setDragnDropState(false)
    }
  }

  handleClose = () => {
    this.setState({ showModal: false })
  }
  setDraggedFalse = () => {
    this.setState({ dragged: false })
  }
  handleOnRowClick = (id: any, e?: any) => {
    e.preventDefault()
    this.onClick(id)
  }
  openUploadModal = () => {
    this.setState({ showModal: true })
  }

  onDragStart = (start: any) => {
    const id = start.draggableId
    const selected = this.props.selection.find((taskId: any) => taskId == id)
    this.props.setDragInfo([+id, +selected])
    this.setState({
      draggingTaskId: start.draggableId,
      selected
    })
  }

  onDragEnd = (result: any) => {
    const { destination } = result

    if (!destination) {
      this.setState({
        draggingTaskId: null
      })
      this.props.setDragInfo([null, null])
      return
    }
    this.props.setDragInfo([null, null])
    this.setState({
      draggingTaskId: null
    })
  }

  public render() {
    let dropDownData = [
      { label: t`Download File`, onClick: this.downloadFile },
      { label: t`Change Name`, onClick: this.openModal }
    ]
    return (
      <div
        onDrop={(event: any) => {
          this.drop(event)
        }}
        style={{ height: '100%' }}
        onDragOver={(event: any) => this.allowDrop(event)}
        onDragLeave={() => this.dragLeaveHandler()}
      >
        {this.state.dragged ? (
          <DropZoneContainer
            dragged={this.state.dragged}
            openUploadModal={this.openUploadModal}
            setDraggedFalse={this.setDraggedFalse}
            handleClose={this.handleClose}
            showModal={this.state.showModal}
          />
        ) : (
          <React.Fragment>
            {this.props.isUploadDisabled && (
              <div className={'pg-my-2 pg-w-full'}>
                <ColorfulBox className={['ylw', 'lg']} withClose={false} direction={'rtl'}>
                  <div>{this.props.msg}</div>
                </ColorfulBox>
              </div>
            )}
            <ContentHeader
              view={this.state.view}
              switchView={this.switchView}
              tableData={this.state.filteredTable}
              handleKeyDown={this.handleKeyDown}
              searchValue={this.state.searchValue}
              handleSearchInput={(e: any) => this.onChangeSearchInput(e)}
            />

            <ContentBody
              onCheckAll={this.onCheckAll}
              moveRow={this.moveRow}
              preLoading={this.state.preLoading}
              onDragEnd={this.onDragEnd}
              onDragStart={this.onDragStart}
              onCheck={this.onCheck}
              handleOnRowClick={this.handleOnRowClick}
              setDragnDropState={this.props.setDragnDropState}
              view={this.state.view}
              onOpenCFModal={this.onOpenCFModal}
              tableData={this.state.filteredTable}
              dropDownData={dropDownData}
              handleChange={this.handleChange}
              openModal={this.openModal}
              loadingStyle={styles.loading}
              onSort={this.onSort}
              handleNavigate={this.handleNavigate}
            />
            {this.state.modalView && (
              <ModalContent
                history={this.props.history}
                closeModal={this.closeModal}
                modalView={this.state.modalView}
                tableData={this.state.tableData}
                updateTable={this.updateTable}
              />
            )}
            {this.state.files.length > 0 && <UploadingModal handleClose={this.handleClose} showModal={this.state.showModal} />}
          </React.Fragment>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  document: state.document,
  dragNdropState: state.document.dragNdropState,
  loading: state.loading.isLoading,
  item: state.sidebar.item,
  image: state.selection.image,
  selection: state.selection.selection,
  fullScreen: state.selection.fullScreen,
  downloadToken: state.sidebar.downloadToken,
  isDirectory: state.sidebar.isDirectory,
  msg: state.account.disableUpload.message,
  uploadFiles: state.document.uploadFiles,
  isDisabled: state.account.disableUpload.status,
  downloadLoading: state.loading.downloadLoading,
  uploader: state.document.uploader,
  parentId: state.document.parentId,
  uploadProgress: state.document.uploadProgress,
  cancelToken: state.account.cancelToken
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDocuments: (value: IGenerateLinkInput) => dispatch(getDocuments(value)),
    setDragInfo: (value: any) => dispatch(setDragInfo(value)),
    setDragnDropState: (value: any) => dispatch(setDragnDropState(value)),
    moveDocuments: (value: IMoveDocumentsInput) => dispatch(dragDocuments(value)),
    setSelections: (value: Array<number>) => dispatch(setSelections(value)),
    setItem: (value: any) => dispatch(setSidebarItems(value)),
    setDownloadToken: (value: any) => dispatch(setDownloadToken(value)),
    removeSelection: () => dispatch(removeSelection()),
    setPreviewState: (value: any) => dispatch(setPreviewState(value)),
    getTrashDocuments: () => dispatch(getTrashDocuments()),
    setToggle: (value: any) => dispatch(setToggle(value)),
    getSharedDocuments: () => dispatch(getSharedDocuments()),
    setParentId: (value: any) => dispatch(setParentId(value)),
    setUploadFiles: (value: any) => dispatch(setUploadFiles(value)),
    setEditStatus: (value: any) => dispatch(setEditStatus(value)),
    selectAll: (value: boolean) => dispatch(selectAll(value)),
    setRenameText: (value: any) => dispatch(setRenameText(value)),
    removeFolder: (value: IRemoveFolderInput) => dispatch(removeFolder(value)),
    uploadServer: (value: any) => dispatch(uploadServer(value)),
    setDocuments: (value: any) => dispatch(setDocuments(value)),
    generateDownloadLink: (value: IGenerateLinkInput) => dispatch(generateDownloadLink(value)),
    setLoading: (value: any) => dispatch(setLoadingState(value)),
    setIpCountry: (value: any) => dispatch(setIpCountry(value)),
    setUploader: (value: any) => dispatch(setUploader(value)),
    setUploadProgress: (value: any) => dispatch(setUploadProgress(value)),
    setCancelToken: (value: any) => dispatch(setCancleToken(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Content)
