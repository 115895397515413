import React, { useState } from 'react'
import { t } from 'ttag'

import { Iprops } from './TableHeader.interface'

import { TableDndItem } from '../TableBody/TableItem'
import { IconLink } from '../../Icon/IconLink'

import newFolderIcon from '../../../../images/Icons/sidebarIcons/newfolder.svg'

import styles from '../Table.module.scss'
import '../drag.scss'

export const TableDndHeader: React.FunctionComponent<Iprops> = ({
  titles,
  labelShow,
  sortable,
  onOpenCFModal,
  selection,
  dropdown,
  selectableRows,
  onSort,
  selectionNumber,
  documentsLength,
 showAll,
  onCheckAll,
  tabletView,
  editableId
}) => {
  const altIcon = 'Icon'
  const [hovered, setHovered] = useState(false)
  const toggleHover = () => setHovered(!hovered)

  let isChecked = selectionNumber && selectionNumber.length !== 0
  let checkboxType = documentsLength && documentsLength > selectionNumber.length ? 'indeterminate' : ''

  let isShowable = [t`type`, t`id`, t`fullPath`]

  
  return (
    <div className={'pg-w-full'}>
      {titles && (
        <div onMouseEnter={toggleHover} onMouseLeave={toggleHover} className={'table-row header'}>
          {titles.map((label: string, i: number) => {
            let isName = label == t`Name`
            if (!isShowable.includes(label)) {
              return (
                <TableDndItem
                  key={i}
                  name={label}
                  checkbox={i == 0 && selectableRows}
                  editableId={editableId}
                  checkboxType={checkboxType}
                  label={label}
                  onCheckAll={onCheckAll}
                  selection={selection}
                  selectableRows={selectableRows}
                  onSort={onSort}
                  hovered={hovered && hovered}
                  checked={isChecked}
                  sortable={sortable}
                  sortType={isName ? 'alphabet' : ' '}
                  className={   showAll?  ['header', 'show'] :isName || (labelShow && labelShow.includes(label)) ? ['header', 'show'] : ['header']}
                />
              )
            }
          })}

          {dropdown && tabletView ? (
            <div className={styles.show}>
              <IconLink className={styles.icn} icon={newFolderIcon} iconAlt={`new-folder ${altIcon}`} label={t`New Folder`} onClick={onOpenCFModal} />
            </div>
          ) : (
            <div/>
          )}
        </div>
      )}
    </div>
  )
}
