import React from 'react'
import { t } from 'ttag'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { Button } from '../../ui-elements/Button/Button'
import { IconLink } from '../../ui-elements/Icon/IconLink'
import MultipleControl from '../MultipleControl/MultipleControl'

import purchaseIcon from '../../../images/Icons/vmIcons/purchase.svg'

import { retryVm, renewalVm, renewalDelete, cancelChanges } from '../../../services/internal/store/actions'

const RenderFunction: React.FunctionComponent<any> = props => {
  let {
    state,
    vmBillStatus,
    onClick,
    title,
    id,
    paymentId,
    openCardModal,
    openModal,
    invoiceItem,
    buttonState,
    retry,
    renewalDelete,
    idleDate,
    renewal,
    cancelChanges
  } = props

  let button = (
    <MultipleControl
      openCardModal={openCardModal}
      vmBillStatus={vmBillStatus}
      idleDate={idleDate}
      state={state}
      shouldUpgrade={buttonState && buttonState.includes('hasChanges')}
      idle={buttonState && buttonState.includes('idle')}
      title={title}
      problem={state == 'PROBLEM'}
      onClick={onClick}
      tmpId={id}
    />
  )

  let buttonStates = [
    {
      name: 'create',
      buttonArray: [
        {
          classNames: ['pg-btnWarning', 'pg-btnLg'],
          style: { width: '100%' },
          label: t`Create Server`,
          icon: purchaseIcon,
          onClick: () => retry(paymentId)
        }
      ]
    },
    {
      name: 'renewInvoice',
      buttonArray: [
        { classNames: ['pg-btnPrimary', 'pg-btnSm'], style: { width: '100%' }, label: t`Renew Invoice`, onClick: () => renewal({ id: id }) }
      ]
    },
    {
      name: 'renewInvoicedelete',
      buttonArray: [
        { classNames: ['pg-btnPrimary', 'pg-btnSm'], label: t`Renew Invoice`, style: { width: '115px' }, onClick: () => renewal({ id }) },
        {
          classNames: ['pg-btnDefault', 'pg-btnSm'],
          style: { width: '75px' },
          label: t`Delete Server`,
          onClick: () => openCardModal({ vmId: id, vmName: title, modalView: 'prompt', deleteOs: true })
        }
      ]
    },

    {
      name: 'paymentCreatedBefore',
      buttonArray: [
        {
          classNames: ['pg-btnPrimary', 'pg-btnSm', 'pg-ml-2'],
          style: { width: '115px' },
          label: `Invoice payment`,
          onClick: () => openModal(invoiceItem)
        },
        {
          classNames: ['pg-btnDefault', 'pg-btnSm'],
          style: { width: '75px' },
          label: t`cancel`,
          onClick: () => cancelChanges({ id })
        }
      ]
    },
    {
      name: 'payment',
      buttonArray: [
        {
          classNames: ['pg-btnPrimary', 'pg-btnSm', 'pg-ml-2'],
          style: { width: '115px' },
          label: t`Invoice payment`,
          onClick: () => openModal(invoiceItem)
        },
        {
          classNames: ['pg-btnDefault', 'pg-btnSm'],
          style: { width: '75px' },
          label: t`Delete`,
          onClick: () => openCardModal({ vmId: id, vmName: title, modalView: 'prompt', deleteOs: true })
        }
      ]
    },
    {
      name: 'paymentOverDue',
      buttonArray: [
        {
          classNames: ['pg-btnDefault', 'pg-btnLg'],
          style: { width: '100%' },
          label: t`Delete Server`,
          onClick: () => openCardModal({ vmId: id, vmName: title, modalView: 'prompt', deleteOs: true })
        }
      ]
    },
    {
      name: 'deleteNotCreated',
      buttonArray: [
        {
          classNames: ['pg-btnDefault', 'pg-btnLg'],
          style: { width: '100%' },
          label: t`Delete Server`,
          onClick: () => openCardModal({ vmId: id, vmName: title, modalView: 'prompt', deleteOs: true })
        }
      ]
    },
    {
      name: 'renewPay',
      buttonArray: [
        {
          classNames: ['pg-btnPrimary', 'pg-btnSm', 'pg-ml-2'],
          style: { width: '115px' },
          label: t`Invoice payment`,
          onClick: () => openModal(invoiceItem)
        },
        {
          classNames: ['pg-btnDefault', 'pg-btnSm'],
          style: { width: '75px' },
          label: t`cancel`,
          onClick: () => renewalDelete(id)
        }
      ]
    },
    {
      name: 'retriable',
      buttonArray: [
        {
          classNames: ['pg-btnWarning', 'pg-btnLg'],
          style: { width: '100%' },
          label: t`Recreate Server`,
          icon: purchaseIcon,
          onClick: () => retry(paymentId)
        }
      ]
    },

    {
      name: 'renewable',
      buttonArray: [
        {
          classNames: ['pg-btnWarning', 'pg-btnLg'],
          style: { width: '100%' },
          label: t`Extend Plan`,
          icon: purchaseIcon,
          onClick: () => renewal({ id })
        }
      ]
    },
    {
      name: 'cancelUpgrade',
      buttonArray: [
        {
          classNames: ['pg-btnPrimary', 'pg-btnSm', 'pg-ml-2'],
          style: { width: '115px' },
          label: t`Invoice payment`,
          onClick: () => openModal(invoiceItem)
        },
        {
          classNames: ['pg-btnDefault', 'pg-btnSm'],
          style: { width: '75px' },
          label: t`cancel`,
          onClick: () => cancelChanges({ id })
        }
      ]
    }
  ]
  //  console.log(buttonState)
  // buttonState.push('renewInvoice')
  buttonStates.map((each: any) => {
    if (buttonState && buttonState.includes(each.name)) {
      button = each.buttonArray.map((bt: any) => {
        if (bt.link) {
          return (
            <Link to={bt.link} className={'pg-w-full'}>
              <Button className={bt.classNames} style={bt.style}>
                <IconLink icon={bt.icon} iconAlt="upload icon" label={bt.label} />
              </Button>
            </Link>
          )
        } else {
          return (
            <Button className={bt.classNames} style={bt.style} onClick={bt.onClick}>
              {bt.icon && <IconLink icon={bt.icon} iconAlt="upload icon" label={bt.label} />}
              {!bt.icon && bt.label}
            </Button>
          )
        }
      })
    }
  })
  return button
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    cancelChanges: (value: any) => dispatch(cancelChanges(value)),
    retry: (value: any) => dispatch(retryVm(value)),
    renewal: (value: any) => dispatch(renewalVm(value)),
    renewalDelete: (value: any) => dispatch(renewalDelete(value))
  }
}

export default connect(null, mapDispatchToProps)(RenderFunction)
