import * as React from 'react'

import modalStyles from '../Modal/Modal.module.scss'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import arrowLeft from '../../../images/Icons/commonIcons/arrow-left-white.svg'
import Fullscreen from 'react-full-screen'

import styles from './Preview.module.scss'

export const splitter = (className: any) => {
  return className.map((cls: any) => styles[cls]).join(' ')
}

export default interface Iprops {
  children?: any
  handleClose?: () => void
  show?: boolean
  item?: any
  type?: string
  onDownloadFile?: any
  goTo: (e: number) => void
}

import DownloadBarImage from './DownloadBar/DownlaodBarImage'
import { ZoomBar } from '../../ZoomBar/ZoomBar'
import { MainFooter } from '../MainFooter/MainFooter'
import { PreviewHeader } from './PreviewHeader'

export class Preview extends React.Component<Iprops, any> {
  container: any
  constructor(props: any) {
    super(props)

    this.state = {
      isFull: false
    }
  }
  componentDidMount() {
    this.setState({ width: this.container.offsetWidth, height: this.container.offsetHeight })
  }
  goFull = () => {
    this.setState({ isFull: true })
  }

  render() {
    const { width } = this.state
    return (
      <div
        ref={el => (this.container = el)}
        className={this.props.show ? [styles.modal, styles.displayBlock].join(' ') : [styles.modal, styles.displayNone].join(' ')}
      >
        <div className={styles.previewBox}>
          <PreviewHeader width={width} item={this.props.item} handleClose={this.props.handleClose} />

          <div className={styles.previewBody}>
            <div className={styles.arrows}>
              <Button
                className={[
                  'pg-btnControl',
                  'pg-btnCircle',
                  'pg-right',
                  'pg-rounded-full',
                  'tablet-max:pg-absolute',
                  styles.rightArrow,
                  styles.flexButton
                ]}
                onClick={() => this.props.goTo(+1)}
              >
                <Icon src={arrowLeft} className={[styles.icon, styles.iconRight].join(' ')} />
              </Button>
              <section className={[modalStyles.modalMain, styles.previewMain].join(' ')}>
                <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({ isFull })}>
                  <div className={styles.image}>{this.props.children}</div>
                </Fullscreen>
              </section>
              <Button
                className={['pg-btnControl', 'pg-btnCircle', 'pg-left', 'pg-rounded-full', 'tablet-max:pg-absolute', styles.leftArrow]}
                onClick={() => this.props.goTo(-1)}
              >
                <Icon src={arrowLeft} className={styles.icon} />
              </Button>
            </div>

            {this.props.item.genericType === 'image' && <ZoomBar setFull={this.goFull} />}
            <DownloadBarImage onItemClick={this.props.onDownloadFile} />
          </div>
        </div>
        <MainFooter
          footer={
            <>
              Powered by{' '}
              <a className="pg-text-blue-400 pg-font-vMedium" href="https://www.persiangig.com/">
                PersianGig
              </a>{' '}
              IaaS Cloud Platform
            </>
          }
          language={localStorage.getItem('__language')}
        />
      </div>
    )
  }
}
