import React from 'react'
import { connect } from 'react-redux'

// internal-component
import { Hr } from '../../../components/ui-elements/Hr/Hr'
import Card from '../../../components/VMContent/Cards/Card'
import { Icon } from '../../../components/ui-elements/Icon/Icon'
import VMModalRenderer from '../ModalRendere.tsx/VMModalRenderer'
import CardOverlay from '../../../components/CardOverlay/CardOverlay'
import { SwitchIcon } from '../../../components/Content/SwitchIcon'
import { PurchaseCard } from '../../../components/VMContent/Cards/PurchaseCard'
import { VMGridSkeleton } from '../../../components/SkeletonLoading/VMGridSkeleton'
import { ColorfulBox } from '../../../components/ui-elements/ColorfulBox/ColorfulBox'

import rocketIcon from '../../../images/Icons/vmIcons/rocketYellow.svg'
import list from '../../../images/Icons/switchViewIcons/list-view.svg'
import grid from '../../../images/Icons/switchViewIcons/grid-view.svg'
import listViewActive from '../../../images/Icons/switchViewIcons/list-view-active.svg'
import gridViewActive from '../../../images/Icons/switchViewIcons/grid-view-active.svg'

//services
import { getVmBills, setVmId, selectVm, setInvoiceItem, setvmBillsNamed } from '../../../services/internal/store/actions'

//styles & icons
import styles from './VMGrid.module.scss'
import cardstyles from '../../../components/VMContent/Cards/Card.module.scss'

export interface Iprops {
  getVmBills: () => void
  setVmBillsNamed?: any
  setVmId: (value: string) => void
  selectVm: (value: any) => void
  setInvoiceItem: (value: any) => void
  history: any[]
  cancelToken: any
  isDisabled?: boolean
  vmBillError?: boolean
  loading?: boolean
  msg?: string
  userInfo?: any
  vmArray?: any
  deleteOs?: any
  getVmProducts?: any
  generalMessage?: any
  vmLoadingid?: any
  hasGeneralMessage?: any
  vmBills?: any
}

// nwvms page
class VMGrid extends React.Component<Iprops, any> {
  timeout: any = 0
  count: number = 5000
  container: any
  constructor(props: any) {
    super(props)
    this.props.getVmProducts()
    this.state = {
      changeUrl: false,
      showModal: false,
      vmloading: false,
      focus: true,
      vmName: '',
      errorMsg: '',
      name: '',
      isSelected: [],
      view: localStorage.getItem('vmDisplayView') ? localStorage.getItem('vmDisplayView') || '{}' : 'grid',
      fullCard: localStorage.getItem('vmDisplayView') && localStorage.getItem('vmDisplayView') == 'list' ? false : true
    }
  }

  componentDidMount() {
    this.props.getVmBills()
    this.props.setVmBillsNamed([])
    window.addEventListener('visibilitychange', this.onFocus)
    // window.addEventListener('visibilitychange', this.onBlur)
    this.setState({ vmloading: true, width: this.container.offsetWidth, height: this.container.offsetHeight })
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
    window.removeEventListener('visibilitychange', this.onFocus)
  }
  onFocus = (e: any) => {
    if (document.visibilityState === 'hidden') this.setState({ focus: false })
    else {
      this.setState({ focus: true })
      this.callVmBill()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.vmBills !== nextProps.vmBills) {
      clearTimeout(this.timeout)
      if (nextProps.vmBills && this.state.focus) {
        this.callVmBill()
      }
    }
    if (nextProps.vmBillError === true) {
      setTimeout(() => this.props.getVmBills(), 5000)
    }
  }

  callVmBill() {
    this.timeout = setTimeout(() => this.props.getVmBills(), 5000)
  }

  // handle input change changes
  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value, error: false })
  }

  // handle changing url to each page
  handleCardClick = (props: any) => {
    this.props.setVmId(props.id)
    this.props.selectVm(props)
    this.props.history.push(`/nwvms/${props.name}`)
    this.setState({ isSelected: props })
  }

  handleClose = () => {
    this.setState({ showModal: false, modalView: '', name: '', upgradeName: '' })
  }

  openPayModal = (invoice: any) => {
    this.props.setInvoiceItem(invoice)
    if (invoice.payablePrice < this.props.userInfo.balance) this.setState({ payment: 'done' })
    this.setState({ showModal: true, modalView: 'pay' })
  }

  //idle modal
  openCardModal = ({ vmId, modalView, vmName, deleteOs }: any) => {
    this.setState({ name: '', vmName: '' })
    this.setState({ showModal: true, vmId, modalView })
    if (vmName) {
      this.setState({ checkvmName: vmName })
    }
    if (deleteOs) this.setState({ deleteOs })
  }

  // grid and list switch
  switchView = (view: string) => {
    this.setState({ view, fullCard: !this.state.fullCard })
    localStorage.setItem('vmDisplayView', view)
  }

  render() {
    let { vmArray, vmBills, msg, isDisabled, vmLoadingid, loading, vmBillError } = this.props
    const { width, view, fullCard, changeUrl, gridFullCard, isSelected, vmloading, modalView, vmId, deleteOs, checkvmName, showModal } = this.state
    // UPGRADE warning
    let needUpgrade = vmArray && vmArray.some((each: any) => each.hasChanges)
    // has any vms
    let hasVm = vmArray && vmArray.length !== 0
    //loading style
    let gridLoading =
      (vmArray && vmArray.length === 0 && vmloading && vmBills && vmBills[0] !== 'empty' && !vmBillError) ||
      (!vmArray && vmloading && !this.props.vmBillError)
    // needs to show purchase card
    let purchaseCard = (!isSelected.os && vmArray && vmArray.length !== 0) || (vmBills && vmBills[0] == 'empty')
    let bigScreeen = width > 768

    return (
      <>
        {bigScreeen && (
          <div className={styles.left}>
            <SwitchIcon switchView={this.switchView} nextView={'list'} icon={grid} activeIcon={gridViewActive} view={view} />
            <SwitchIcon switchView={this.switchView} nextView={'grid'} icon={list} activeIcon={listViewActive} view={view} />
          </div>
        )}

        <div className={fullCard ? 'pg-flex pg-flex-col' : styles.container} ref={el => (this.container = el)}>
          {isDisabled && (
            <div className={'pg-my-2 pg-w-full'}>
              <ColorfulBox className={['ylw', 'lg']} withClose={false} direction={'rtl'}>
                <div>{msg}</div>
              </ColorfulBox>
            </div>
          )}

          {needUpgrade && (
            <ColorfulBox className={['ylw', 'lg']} withClose={true} direction={'rtl'}>
              <div>
                برای ارتقای سرور مجازی خود روی دکمه ارتقا (<Icon src={rocketIcon} />) کلیک کنید
              </div>
            </ColorfulBox>
          )}
          <Hr className={'pg-my-3'} />

          {hasVm &&
            vmArray.map((vm: any) => (
              <CardOverlay
                loading={changeUrl ? false : vmLoadingid == vm.id && loading}
                className={
                  fullCard
                    ? gridFullCard
                      ? [cardstyles.item, cardstyles.shadow, 'pg-mb-4'].join(' ')
                      : [cardstyles.item, cardstyles.height].join(' ')
                    : [cardstyles.item, cardstyles.border].join(' ')
                }
              >
                <Card
                  key={vm.id}
                  loading={vmLoadingid == vm.id && loading}
                  gridFullCard={width > 768 && fullCard}
                  handleCardClick={this.handleCardClick}
                  fullCard={width > 768 && fullCard}
                  openModal={this.openPayModal}
                  vm={vm}
                  openCardModal={this.openCardModal}
                />
              </CardOverlay>
            ))}

          {gridLoading && <VMGridSkeleton fullCard={view == 'grid'} />}
          {purchaseCard && <PurchaseCard fullCard={view == 'list'} purchase={true} handleCardClick={this.handleCardClick} />}

          {this.state.showModal && (
            <VMModalRenderer
              modalView={modalView}
              VMGrid={true}
              vmId={vmId}
              isdeleteOs={deleteOs}
              checkvmName={checkvmName}
              showModal={showModal}
              handleClose={this.handleClose}
            />
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  id: state.vm.vmId,
  vmArray: state.vm.vmArray,
  vmBills: state.vm.vmBills,
  userInfo: state.account.info,
  loading: state.loading.isLoading,
  vmBillError: state.vm.vmBillError,
  vmLoadingid: state.loading.vmLoadingid,
  msg: state.account.disableCreateVm.message,
  isDisabled: state.account.disableCreateVm.status,
  generalMessage: state.account.generalMessage.message,
  hasGeneralMessage: state.account.generalMessage.status
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    getVmBills: () => dispatch(getVmBills()),
    getVmProducts: () => dispatch(getVmBills()),
    setVmId: (value: any) => dispatch(setVmId(value)),
    selectVm: (value: any) => dispatch(selectVm(value)),
    setVmBillsNamed: (value: any) => dispatch(setvmBillsNamed(value)),
    setInvoiceItem: (value: any) => dispatch(setInvoiceItem(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VMGrid)
