import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { t } from 'ttag'

// ui-elements
import { TextInput } from '../../../components/ui-elements/Input/Input'
import { Button } from '../../../components/ui-elements/Button/Button'
import { Icon } from '../../../components/ui-elements/Icon/Icon'
import { IconLink } from '../../../components/ui-elements/Icon/IconLink'
import { Authentication } from '../Authentication'

// icons & styles
import loading from '../../../images/loading/tail-spin.svg'
import styles from '../Authentication.module.scss'
import error from '../../../images/Icons/commonIcons/error.svg'
import success from '../../../images/Icons/commonIcons/icon-upload-done.svg'

// services
import { forgetPassword, removeMessages } from '../../../services/internal/store/actions'
import { PayloadInterface } from '../../../services/internal/store/reducers/authReducer'

class ForgetPassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      email: props.email ? props.email : '',
      msg: false,
      loading: false
    }
  }
  handleChange = (e: any) => {
    this.setState({
      msg: false,
      [e.target.name]: e.target.value
    })
  }

  onBlur = () => {
    this.props.removeMessages()
  }

  handleSubmit = (e: any) => {
    if (e) e.preventDefault()
    this.setState({ msg: true })
    try {
      let result = this.props.forgetPassword({ email: this.state.email })
    } catch (error) {
      console.log(error)
    }
  }
  componentWillUnmount() {
    this.props.removeMessages()
  }
  render() {
    return (
      <Authentication>
        <form className={styles.login} onSubmit={this.handleSubmit}>
          <span className={styles.title}>{t`Password recovery`}</span>
          <p className={styles.description}>{t`Please enter your email to send you the password recovery link`}</p>
          <TextInput
            placeholder={t`Email`}
            name={'email'}
            onChange={this.handleChange}
            onBlur={this.onBlur}
            autoComplete={'email'}
            value={this.state.email}
          />
          <div className={styles.row}>
            <div className={styles.switch}>
              <Link to={'/nwlogin'}>{t`Login`}</Link>
              {/* <Link to={'/nwregister'}>
                <span className={styles.link}>{t`Register`}</span>
              </Link> */}
            </div>

            <Button className={['pg-btnSuccess', 'pg-btnSm']} loading={this.props.isLoading && !this.state.loading} style={{ width: '35%' }}>
              {t`Send Email`}
            </Button>
          </div>
          {this.props.messages.errors.length > 0 && this.state.msg && (
            <div className={[styles.wrongVerify, styles.warn].join(' ')}>
              <IconLink icon={error} label={`${this.props.messages.errors}`} />
            </div>
          )}
          {this.props.messages.msgs.length > 0 && this.state.msg && (
            <div className={[styles.wrongVerify, styles.success].join(' ')}>
              <IconLink icon={success} label={`${this.props.messages.msgs}`} noPointer={true} />
            </div>
          )}
        </form>
      </Authentication>
    )
  }
}

const mapStateToProps = (state: any) => ({
  isLoading: state.loading.isLoading,
  email: state.account.email,
  auth: state.auth,
  messages: state.messages
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    forgetPassword: (payload: PayloadInterface) => dispatch(forgetPassword(payload)),
    removeMessages: (payload: PayloadInterface) => dispatch(removeMessages(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)
