import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'

import TrDndContainter from './TrContainter'
import Invoice from '../../../Invoice/Invoice'

import styles from '../Table.module.scss'

class TableDndRow extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      hovered: false,
      expanded: false,
      loading: false,
      hoverStyle:{}
    }
  }

  setHovered = () => {
    this.setState({ hovered: true, hoverable: true })
  }

  setUnHovered = () => {
    this.setState({ hovered: false, hoverable: false })
  }

  renderInvoice = (e: any) => {
    this.setState({ expanded: !this.state.expanded, loading: !this.state.loading, id: e })
    this.props.handleOnRowClick(e, this.props.index)
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.invoice && nextProps.invoice.length > 0) {
      this.setState({ loading: false, name: nextProps.invoice.name })
    }
  }
  onMouseOver = (e: any) => {
    var tooltipSpan = document.getElementsByClassName('tooltip')
    var x = e.pageX,
      y = e.pageY
    let hoverStyle = { top: y + 20 + 'px', left : x + 20 + 'px' }
    this.setState({hoverStyle})
  }

  render() {
    const {
      item,
      invoiceClassName,
      isMoveModal,
      width,
      index,
      tableData,
      expandableRow,
      canDrop,
      handleOnRowClick,
      selectableRows,
      dropTarget,
      isOver
    } = this.props
    const { expanded } = this.state
    const isActive = isOver && canDrop

    return dropTarget(
      <div>
        <TrDndContainter
          expandableRow={expandableRow}
          item={item}
          hoverStyle={this.state.hoverStyle}
          hoverable={this.state.hoverable}
          isMoveModal={isMoveModal}
          onMouseOver={this.onMouseOver}
          expanded={expanded}
          tableData={tableData}
          canDrop={canDrop}
          setHovered={this.setHovered}
          setDragnDropState={this.props.setDragnDropState}
          isActive={isActive}
          setUnHovered={this.setUnHovered}
          renderInvoice={this.renderInvoice}
          handleOnRowClick={handleOnRowClick}
          selectableRow={selectableRows}
          width={width}
          moveRow={this.props.moveRow}
          moveDocuments={this.props.moveDocuments}
          {...this.props}
        />
        {expandableRow && expanded ? (
          <Invoice
            index={index}
            id={this.state.id}
            invoiceObj={this.props.invoice[+this.props.indexNumber] ? this.props.invoice[+this.props.indexNumber] : []}
            className={styles.show}
            invoiceClassName={invoiceClassName}
          />
        ) : (
          <div />
        )}
      </div>
    )
  }
}

const collect = (connect: any, monitor: any) => ({
  dropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
  isOver: monitor.isOver()
})
const cardTarget = {
  canDrop({ item }: any, monitor: any) {
    return item.type === 'folder'
  },

  drop({ item, selection, moveDocuments, documents }: any, monitor: any) {
    if (item.type === 'folder') {
      moveDocuments({ targetId: item.id, documentIds: selection, documents: documents })
    }
  }
}

export const TRDND = 'card'
export default DropTarget(TRDND, cardTarget, collect)(TableDndRow)
