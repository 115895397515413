import { put } from 'redux-saga/effects'
import * as actions from '../actions'
import { AnyAction } from 'redux'
import { bottle } from '../../../index'
import { removeFiles } from '../../utils/documents'

const documents = bottle.container.Documents
const catchError = bottle.container.CatchError
// const httpClient = bottle.container.Config.get('httpClient')
// const CancelToken = httpClient.CancelToken;
// const cancelToken = CancelToken.source()

export function* getDocuments(action: AnyAction) {
  //console.log(localStorage.getItem('token'))
  let base = { headers: { token: localStorage.getItem('csrf_token'), Cookie: `token="${localStorage.getItem('csrf_token')}"` } }
  let folderInfo
  if (action.payload && action.payload.isChildren)
    folderInfo = {
      isChildren: action.payload.isChildren,
      path: action.payload.path,
      headers: { token: localStorage.getItem('csrf_token'), Cookie: `token="${localStorage.getItem('csrf_token')}"` }
    }

  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setDocumentLoadingState(true))
    // console.log('122')
    let data = yield documents.getDocuments(folderInfo ? folderInfo : base)
    if (folderInfo && folderInfo.isChildren === true) {
      yield put(actions.setParentId(data.parent.id))
      data = data.children
    }
    yield put(actions.setDocuments(data))
    yield put(actions.setLoadingState(false))
    yield put(actions.setDocumentLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err && err.errors && err.errors[0].msg))
    yield put(actions.setLoadingState(false))
    yield put(actions.setDocumentLoadingState(false))
  }
}
export function* getModalDocuments(action: AnyAction) {
  let folderInfo,
    lastChild = false
  if (action.payload) folderInfo = { isChildren: action.payload.isChildren, path: action.payload.path, headers: action.payload.headers }
  try {
    yield put(actions.setModalLoadingState(true))
    let data = yield documents.getDocuments(folderInfo)
    if (folderInfo && folderInfo.isChildren === true) {
      if (data.children.length < 1) {
        lastChild = true
        data = data.parent
      } else {
        if (data.children.every((each: any) => each.discriminator == 'F')) lastChild = true
        data = data.children
      }
    }
    yield put(actions.setLastChild(false))
    lastChild ? yield put(actions.setLastChild(lastChild)) : yield put(actions.setModalDocuments(data))
    yield put(actions.setModalLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setModalLoadingState(false))
  }
}
export function* getTrashDocuments() {
  try {
    yield put(actions.setLoadingState(true))
    let data = yield documents.getTrashDocuments()
    yield put(actions.setDocuments(data))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* getSharedDocuments() {
  try {
    yield put(actions.setLoadingState(true))
    let data = yield documents.getSharedDocuments()
    yield put(actions.setDocuments(data))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* removeFolder(action: AnyAction) {
  let folderInfo = { folderId: action.payload.folderId }
  try {
    // yield put(actions.setLoadingStat-دe(true))
    let response = yield documents.removeFolder(folderInfo)
    yield put(actions.setMessage('حذف انجام شد'))
    yield put(actions.setSelections([]))
    // yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* deleteDocument(action: AnyAction) {
  let folderInfo = { documentIds: action.payload.folderId }
  try {
    yield put(actions.setButtonLoadingState(true))
    yield documents.deleteDocument(folderInfo)
    let data = removeFiles(action.payload.documents, action.payload.folderId)
    yield put(actions.setMessage('حذف انجام شد'))
    yield put(actions.setSelections([]))
    yield put(actions.setButtonLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setButtonLoadingState(false))
  }
}
export function* createFolder(action: AnyAction) {
  let getFolderInfo
  let folderInfo = { name: action.payload.name, description: action.payload.description, parentId: action.payload.parentId }
  if (action.payload.parentId) getFolderInfo = { isChildren: true, path: action.payload.parentName }
  try {
    yield put(actions.setLoadingState(true))
    let response = yield documents.createFolder(folderInfo)
    let data = yield documents.getDocuments(getFolderInfo)
    if (getFolderInfo && getFolderInfo.isChildren == true) data = data.children
    yield put(actions.setDocuments(data))
    yield put(actions.setResponse(response))
    yield put(actions.setMessage('پوشه ایجاد شد'))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* renameFolder(action: AnyAction) {
  let renameInfo = { name: action.payload.name, folderId: action.payload.folderId }
  try {
    yield put(actions.setLoadingState(true))
    yield documents.renameFolder(renameInfo)
    let response = yield documents.renameFolder(renameInfo)
    yield put(actions.setMessage('تغییر نام انجام شد'))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* moveDocuments(action: any) {
  console.log('paylaod', action.payload)
  let moveInfo = { targetId: action.payload.targetId, documentIds: action.payload.documentIds }
  // if (action.payload.documents) documentsInfo = action.payload.documents
  console.log('paylaod', action.payload)
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setMessage('در حال جا به جایی'))
    let result = yield documents.moveDocuments(moveInfo)
    window.location.pathname.split('nwfm/')[1]
      ? yield put(actions.getDocuments({ isChildren: true, path: window.location.pathname.split('nwfm/')[1] }))
      : yield put(actions.getDocuments())

    yield put(actions.setMessage('فایل جا به جا شد'))
    yield put(actions.setSelections([]))
    yield put(actions.setModalSelections([]))
    yield put(actions.getModalDocuments({ modal: true }))
    yield put(actions.setHistory([]))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* dragDocuments(action: any) {
  let moveInfo = { targetId: action.payload.targetId, documentIds: action.payload.documentIds }

  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setMessage('در حال جا به جایی'))
    let result = yield documents.moveDocuments(moveInfo)
    yield put(actions.setMessage('فایل جا به جا شد'))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* shareDocuments(action: any) {
  console.log(action.userEmails)
  let shareInfo = { userEmails: [action.userEmails], documentIds: [action.documentIds] }
  
  //console.log(shareInfo)
  try {
    yield documents.shareDocuments(shareInfo)
    yield put(actions.setMessage('ایمیل اشتراک گذاری فرستاده شد'))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}

export function* generateLink(action: AnyAction) {
  let uuid = { uuid: action.payload.uuid }
  try {
    yield put(actions.setDownloadLoadingState(true))
    let result = yield documents.generateDownloadLink(uuid)
    if (result.token) yield put(actions.setDownloadToken(result.token,false))
    // setTimeout(() => {
    //   if (result) window.location.href = `${process.env.REACT_APP_URL}/dl/${result}/${action.payload.uuid}/${action.payload.name}`
    // }, 1000)
    yield put(actions.setMessage('درحال آماده‌سازی دانلود'))
    yield put(actions.setDownloadLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setDownloadLoadingState(false))
  }
}
export function* addDescription(action: AnyAction) {
  try {
    let result = yield documents.addDescription({ id: action.payload.id, description: action.payload.description })
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* downloadDirectory(action: AnyAction) {
  let info = { type: action.payload.downloadType, documentIds: action.payload.documentIds }
  try {
    yield put(actions.setDownloadLoadingState(true))
    let result = yield documents.downloadDirectory(info)
    if (result.token) yield put(actions.setDownloadToken(result.token,true))
    yield put(actions.setMessage('درحال آماده‌سازی دانلود'))
    yield put(actions.setDownloadLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setDownloadLoadingState(false))
  }
}
export function* restoreFiles(action: AnyAction) {
  try {
    yield put(actions.setButtonLoadingState(true))
    yield documents.restoreFiles({ documentIds: action.payload.documentIds })
    let data = removeFiles(action.payload.documents, action.payload.documentIds)
    yield put(actions.setMessage('فایل بازیابی شد'))
    yield put(actions.setDocuments(data))
    yield put(actions.setSelections([]))
    yield put(actions.setButtonLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    yield put(actions.setButtonLoadingState(false))
  }
}
export function* uploadDocuments(action: AnyAction) {
  try {
    const body = new FormData()
    body.append('file', action.payload.file)
    body.append('pathId', '0')
    let result = yield documents.uploadDocument({ body, setUploadProgress: action.payload.setUploadProgress })
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* urlUpload(action: any) {
  //console.log(action)
  try {
    yield put(actions.setButtonLoadingState(true))
    yield documents.urlUpload({ path: action.payload.url, parentId: action.payload.parentId })
    yield put(actions.setMessage('فایل در حال آپلود شدن'))
    yield put(actions.setButtonLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setButtonLoadingState(false))
  }
}

export function* changeSharingStatus(action: any) {
  //console.log(action)
  try {
    let result = yield documents.changeSharingStatus({ id: action.payload.id, sharingStatus: action.payload.sharingStatus })
    actions.setSidebarItems(result)
    yield put(actions.setMessage('دسترسی تغییر داده شد.'))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* uploadServer(action: any) {
  //console.log(action)

  try {
    yield put(actions.setUploadModal(true))
    yield documents.uploadServer({
      name: action.payload.name,
      size: action.payload.size,
      id: action.payload.id,
      uuid: action.payload.uuid,
      parent: action.payload.parent,
      mode: action.payload.mode,
      origin: action.payload.origin
    })
    // yield put(actions.setUploadModal(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
    // yield put(actions.setUploadModal(false))
  }
}
