import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// ui-elements
import { ColorfulBox } from '../../../ui-elements/ColorfulBox/ColorfulBox'
import { Hr } from '../../../ui-elements/Hr/Hr'

// internal-component
import { StepDescription } from './Details/StepDescription'

// services
import { goToNextStep, goToPreviousStep } from '../../../../services/internal/store/actions'

// styles
import styles from '../Order.module.scss'

export interface Iprops {
  steps?: any
  noStep?: boolean
  networkName?: any
}

const ChooseNetworkCard: React.FunctionComponent<Iprops> = ({ steps, networkName, noStep }) => {
  return (
    <div className={styles.chooseNetworkCard}>
      {!noStep && (
        <StepDescription stepNumber={4} title={[t`Fourth step`, `:`, steps[3]].join(' ')} subTitle={t`Network card info is shown bellow`} />
      )}
      <div className={!noStep ? styles.wrapper :''}>
        <ColorfulBox className={['white', 'lg']} fontSize={14}>
          <div className="networkInfo">
            <div className="header">
              <div>{t`Network name`}</div>
              <div>{t`IP Number`}</div>
            </div>
            <Hr backgroundColor={'#e5e5e5'} height={'1px'} />
            <div className="body">
              {/* <div>Internet180_0</div> */}
              <div className={'pg-font-sans'}>{networkName}</div>
              <div>1</div>
            </div>
          </div>
        </ColorfulBox>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    goToNextStep: (stepNumber: number) => dispatch(goToNextStep(stepNumber)),
    goToPreviousStep: (stepNumber: number) => dispatch(goToPreviousStep(stepNumber))
  }
}

const mapStateToProps = (state: any) => ({
  networkName: state.account.networkName
})

export default connect(mapStateToProps, mapDispatchToProps)(ChooseNetworkCard)
