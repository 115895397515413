import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// internal-component
import { StepDescription } from './Details/StepDescription'
import { ColorfulBox } from '../../../ui-elements/ColorfulBox/ColorfulBox'
import { RangeBarCard } from './Details/RangeBarCard/RangeBarCard'

import styles from '../Order.module.scss'

import { setServiceDuration, selectVsCard, selectVsCardDay } from '../../../../services/internal/store/actions'

export interface Iprops {
  history?: object
  currentStep: number
  setServiceDuration: any
  serviceDuration: any
  goToNextStep?: (e: any) => void
  goToPreviousStep?: (e: any) => void
}

class HPC extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    // console.log(props)
    this.state = {
      core: 4,
      ram: 8,
      disk: (props && props.os && props.os[0] == 'Windows Server 2012') || props.isWidnows ? 110 : 80,
      json: []
    }
  }

  updateRange = (times: any, variable: string) => {
    let json = this.state.json
    let jsonInfo = json[0]
    switch (variable) {
      case 'core':
        jsonInfo.cpu = `${times} Cores `
        jsonInfo.core = times
        break
      case 'ram':
        jsonInfo.ram = `${times} GB `
        break
      case 'disk':
        jsonInfo.disk = `${times} GB `
        break
    }

    json[0] = jsonInfo
    json[1] = json[0]
    this.setState({ json })
    this.props.selectVsCard(json)
    this.props.selectVsCardDay(json)
    if (this.props.noFooter) {
      this.props.onSelectHPCVps(json[0])
    }
    this.setState({ [variable]: times })
  }
  componentDidMount() {
    let disk = this.props.vsCardDay && this.props.vsCardDay.disk ? this.props.vsCardDay.disk : 80
    if ((this.props.os[0] == 'Windows Server 2012' || this.props.isWindows) && disk == 80) disk = 110
    if (parseFloat(disk) < 80) disk = 80
    let cpu = this.props.vsCardDay && this.props.vsCardDay.core ? parseFloat(this.props.vsCardDay.core) : 4
    let ram: any = this.props.vsCardDay && this.props.vsCardDay.ram ? parseFloat(this.props.vsCardDay.ram) : 8
    if (!this.props.firstStep) {
      ram = '8'
      cpu = 4
    }

    let json = [
      {
        cpu: `${cpu} Cores`,
        ram: ram,
        disk: disk,
        core: cpu
      },
      {
        cpu: `${cpu} Cores`,
        ram: ram,
        disk: disk,
        core: cpu
      }
    ]
    if (this.props.firstStep) {
      this.setState({ json, core: cpu, ram, disk })
    } else {
      this.setState({ json, core: 4, ram: 8, disk: `${this.props.os[0] == 'Windows Server 2012' || this.props.isWindows ? 110 : 80}` })
    }
    this.props.selectVsCard(json)
    if (this.props.noFooter && this.props.vmArrayNamed && this.props.vmArrayNamed.cpuN) {
      this.setState({
        core: parseFloat(this.props.vmArrayNamed.cpuN),
        ram: parseFloat(this.props.vmArrayNamed.ramN),
        disk: parseFloat(this.props.vmArrayNamed.diskN)
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.os && nextProps.os[0] == 'Windows Server 2012' && this.state.disk == 80) {
      this.setState({ disk: 110 })
    } else if (nextProps.os && nextProps.os[0] !== 'Windows Server 2012' && this.state.disk == 110) {
      this.setState({ disk: 80 })
    }
  }

  render() {
    let isWindows = this.props.os[0] == 'Windows Server 2012'
    let ramRangeBox = {
      min: this.props.noFooter  && this.props.vmArrayNamed && this.props.vmArrayNamed.cpuN ? parseFloat(this.props.vmArrayNamed.ramN) : 8,
      max: 60,
      unit: 'GB',
      value: this.state.ram,
      number: this.state.ram,
      text: 'ram'
    }
    let coreRangeBox = {
      steo: 1,
      min: this.props.noFooter && this.props.vmArrayNamed && this.props.vmArrayNamed.cpuN? parseFloat(this.props.vmArrayNamed.cpuN) : 4,
      max: 20,
      unit: 'core',
      value: this.state.core,
      number: this.state.core,
      text: 'core'
    }
    let diskRangeBox = {
      steo: 10,
      min: this.props.noFooter && this.props.vmArrayNamed && this.props.vmArrayNamed.cpuN ? parseFloat(this.props.vmArrayNamed.diskN) : !isWindows ? (this.props.isWindows ? 110 : 80) : 110,
      max: 250,
      unit: 'GB',
      value: this.state.disk,
      number: (isWindows || this.props.isWindows) && this.state.disk == 80 ? 110 : this.state.disk,
      text: 'disk'
    }

    return (
      <div className={styles.serviceDuration}>
        {!this.props.noHeader && (
          <StepDescription
            stepNumber={3}
            title={[t`Third step`, `:`, this.props.steps[2]].join(' ')}
            subTitle={t`Select the root configuration you want to create.`}
          />
        )}

        <RangeBarCard rangebarObject={coreRangeBox} updateRange={this.updateRange} />
        <RangeBarCard rangebarObject={ramRangeBox} updateRange={this.updateRange} />
        <RangeBarCard rangebarObject={diskRangeBox} updateRange={this.updateRange} />
        {!this.props.noFooter && (
          <ColorfulBox
            className={['blue', 'lg']}
            direction={'rtl'}
            message={`حداقل منابع برای پلن روزانه ${this.props.os[0]} : CPU:4Cores, RAM:8GB, DISK:${!isWindows ? '80GB' : '110GB '} است.`}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  currentStep: state.vm.currentStep,
  firstStep: state.vm.firstStep,
  vsCard: state.vm.vsCard,
  vsCardDay: state.vm.vsCardDay,
  serviceDuration: state.vm.serviceDuration,
  vmArrayNamed: state.vm.vmArrayNamed && state.vm.vmArrayNamed[0],
  os: state.vm.os
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setServiceDuration: (stepNumber: number) => dispatch(setServiceDuration(stepNumber)),
    selectVsCard: (data: number) => dispatch(selectVsCard(data)),
    selectVsCardDay: (data: any) => dispatch(selectVsCardDay(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HPC)
