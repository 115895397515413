import * as React from 'react'
import { t } from 'ttag'

export interface Iprops {
  border: boolean
  name?: string
  label: string
  bigger?: any
  editable: boolean
  onEdit?: any
  value: any
}

export const InputRow: React.FunctionComponent<Iprops> = props => {
  let isEmpty=false
  if(props.value,props.value =='undefined undefined' ) isEmpty = true
  else if(props.value,props.value =='undefined - undefined' ) isEmpty = true

  return (
    <div className={`pg-flex pg-text-gray-700 ${props.border && ' pg-border-b-2'} ${props.bigger &&'pg-py-2'}`}>
      <span className={`pg-flex ${props.bigger ? '  pg-w-1/3' :  '  pg-w-1/4'} pg-px-1 pg-items-center`}> {props.label}</span>
      <span className={'pg-h-12 pg-items-center pg-text-left pg-justify-end pg-flex  pg-w-3/4 pg-mt-1'}>{props.value && !isEmpty ? props.value :'-'} </span>
      {props.editable && (
        <span
          className={'pg-text-blue-400  pg-text-center pg-items-center pg-mx-3 pg-flex pg-text-blue-400 pg-cursor-pointer'}
          onClick={() => props.onEdit(props.name, props.label)}
        >
          {t`Edit`}
        </span>
      )}
    </div>
  )
}
