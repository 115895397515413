import * as React from 'react'
import { t } from 'ttag'
import { connect } from 'react-redux'
// ui-elements
import { Button } from '../../ui-elements/Button/Button'
import { IconLink } from '../../ui-elements/Icon/IconLink'
import { Hr } from '../../ui-elements/Hr/Hr'

// internal components & styles
import '../Sidebar.scss'
import { setProfileTab } from '../../../services/internal/store/actions'
import { Link } from 'react-router-dom'
import fileCloudIcon from '../../../images/Icons/navbarIcons/file-cloud.svg'
import vpsIcon from '../../../images/Icons/navbarIcons/vps.svg'
import { Nav } from './Nav'

export interface Iprops {
  onItemClick?: (e: any) => void
  item?: any
  profileView?: string
  downloadToken?: string
  setProfileTab: any
}

export const BillingSideBar: React.FunctionComponent<Iprops> = () => {
  return (
    <div className="sidebar-menu billing">
      <Link  to={{pathname: '/nwbilling/nwfm', state: { prevPath: location.pathname }}}>
        <IconLink
          icon={fileCloudIcon}
          className={`iconLink upFromUrl pg-p-3 ${window.location.pathname == '/nwbilling/nwfm' && 'pg-bg-gray-0'}`}
          iconAlt="upload icon"
          label={t`File Storage`}
        />
      </Link>
      <Link to={{pathname: '/nwbilling/nwvms', state: { prevPath: location.pathname }}}>
        <IconLink
          icon={vpsIcon}
          className={`iconLink upFromUrl pg-p-3 ${window.location.pathname == '/nwbilling/nwvms'  && 'pg-bg-gray-0'}`}
          iconAlt="upload icon"
          label={t`VM`}
        />
      </Link>
      <Nav />
    </div>
  )
}
