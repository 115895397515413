import { addLocale, useLocale } from 'ttag'

const LOCALE_STORAGE = '__language'

export const getLocale = () => localStorage.getItem(LOCALE_STORAGE) || 'fa'
export const saveLocale = (locale: string) => localStorage.setItem(LOCALE_STORAGE, locale)

// setup
const locale = getLocale()

if (locale !== 'en') {
  const translationsObj = require(`./fa.po.json`)
  addLocale(locale, translationsObj)
  useLocale(locale)
  saveLocale('fa')
  document.body.setAttribute('lang', `fa`)
} else {
  document.body.setAttribute('lang', 'en')
  saveLocale('en')
}
