import React from 'react'
import { t } from 'ttag'

// ui-elements
import { Footer } from '../../Footer'
import { Icon } from '../../ui-elements/Icon/Icon'
import CardFooter from '../FooterState/CardFooter'

// icons
import createServerIcon from '../../../images/Icons/vmIcons/create-server.svg'

// styles & internal-component
import styles from './Card.module.scss'

export const PurchaseCard: React.FunctionComponent<any> = props => {
  return (
    <div className={[styles.item, props.fullCard ? styles.shadow : styles.border].join(' ')} style={{ height: '100%' }}>
      <div className={styles.top}>
        <div className={styles.iconWrapper}>
          <Icon src={createServerIcon} />
        </div>
      </div>
      <div className={styles.show}>
        <div className={styles.center}>
          <div className={styles.description}>{t`Create a virtual server to your liking`}</div>
        </div>
        <Footer className={`${styles.footer} ${styles.oneBtn} `}>
          <div className={styles.buy}>
            <CardFooter {...props} />
          </div>
        </Footer>
      </div>
    </div>
  )
}
