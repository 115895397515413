import { ConfigInterface } from '../../internal/config/config'
import * as Sentry from '@sentry/browser'

export interface CatchErrorInterface {
  throwError(error: string): any
}

class CatchError implements CatchErrorInterface {
  constructor(config: ConfigInterface) {}
  throwError = (error: string) => {
    process.env.NODE_ENV == 'production' ? Sentry.captureException(error) : console.log('err', error)
  }
}

export default CatchError
