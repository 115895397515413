import * as React from 'react'

// ui-elements
import { Tooltip } from '../ui-elements/Tooltip/Tooltip'
import { Button } from '../ui-elements/Button/Button'
import { Icon } from '../ui-elements/Icon/Icon'

import styles from './Control.module.scss'

export interface Iprops {
  icon?: string
  text?: string
  className?: string
  onClick?: any
  off?: boolean
  tmpId?: number
  disabled?: boolean
  updateVm?: any
  id?: number
  width?: any
}

export const Control: React.FunctionComponent<Iprops> = ({ text, icon, className, onClick, id, disabled,width }) => {
  let style = disabled ? { opacity: 0.3, cursor: 'auto' } : {}
  return (
    <div
      className={className ? `${styles.control} ${styles[className]}` : styles.control}
      {...(onClick && { onClick: () => onClick && onClick(text, id) })}
      style={style}
    >
      {!disabled ? (
        <Tooltip text={text} width={86} height={27} position={'bottom'} lineHeight={'11px'}>
          <Button disabled={disabled}>
            <Icon src={icon} style={style}  width={width}/>
          </Button>
        </Tooltip>
      ) : (
        <Button disabled={disabled} className={['pg-btnDisabled']}>
          <Icon src={icon} style={style}  width={width}/>
        </Button>
      )}
    </div>
  )
}
