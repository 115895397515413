import React from 'react'
import { t } from 'ttag'

import { Hr } from '../ui-elements/Hr/Hr'
import { Checkbox } from '../ui-elements/Checkbox/Checkbox'

//styles
import styles from './Grid.module.scss'
import { connect } from 'react-redux'

export interface Iprops {
  onCheckAll?: () => void
  onSort?: (sortBy: string, type?: string) => void
  sortable?: boolean
  selectAll?: boolean
  documents?: number
  selectionNumber?: any
}

export const GridHeader: React.FunctionComponent<Iprops> = ({ onCheckAll, sortable, selectAll, onSort, selectionNumber, documents }) => {
  return (
    <div className={styles.header} {...(onSort && { onClick: () => onSort(t`Name`) })}>
      <div className={styles.title + ' rowItem'}>
        <Checkbox
          onChange={() => onCheckAll && onCheckAll()}
          checked={selectionNumber.length !== 0}
          type={documents && documents > selectionNumber.length ? 'indeterminate' : ''}
        />
        <span className={styles.label}>{t`Name`}</span>
        {sortable && (
          <div className={styles.sort}>
            <span>▲</span>
            <span>▼</span>
          </div>
        )}
      </div>
      <Hr />
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  selectAll: state.selection.selectAll,
  selectionNumber: state.selection.selection,
  documents: state.document.documents.length
})

export default connect(mapStateToProps)(GridHeader)
