import React from 'react'
import { t } from 'ttag'

import { FMModal } from '../FMModal/FMModal'
import { Button } from '../../Button/Button'

export const Prompt = ({ showModal, errorMsg, children, handleClose, onClick, title, formDescription, notClickable ,width=340}: any) => {
  return (
    <FMModal show={showModal} handleClose={handleClose} width={width} title={title} formDescription={formDescription}>
      {children}
      <div className={'pg-flex pg-justify-end pg-w-full pg-mt-3'}>
        <Button className={notClickable ? ['pg-btnSm', 'pg-btnDisabled'] : ['pg-btnSm', 'pg-btnSuccess']} onClick={onClick}>
          {title}
        </Button>
        <Button className={['pg-btnSm', 'pg-btnDefault', 'pg-mx-2']} onClick={handleClose}>
          {t`Cancel`}
        </Button>
      </div>
    </FMModal>
  )
}
