import * as React from 'react'

// ui-element
import { Icon } from '../Icon/Icon'

//styles & icon
import cancelIcon from '../../../images/Icons/vmIcons/cancel.svg'
import styles from './ColorfulBox.module.scss'
import { useState } from 'react'

export default interface Iprops {
  className?: string[]
  width?: number
  fontSize?: number
  height?: number
  margin?: string
  marginDown?: any
  message?: any
  handleClose?: () => void
  hide?: boolean
  withClose?: boolean
  children?: any
  direction?: any
}

const classCreator = (className: any) => {
  return className && className.map((name: any) => styles[name]).join(' ')
}

export const ColorfulBox = ({
  className = ['white', 'lg'],
  width,
  fontSize,
  height,
  margin,
  message,
  handleClose,
  hide,
  withClose,
  children,
  marginDown,
  direction
}: Iprops) => {
  const [closeState, close] = useState(hide)

  return (
    <div
      className={
        hide
          ? `${styles.colorfulBox} ${styles.hide}`
          : closeState
          ? `${styles.colorfulBox} ${styles.hide}`
          : `${styles.colorfulBox} ${classCreator(className)}`
      }
      style={{ width: width, margin: margin, height: height, fontSize: fontSize, direction: direction ,marginBottom:marginDown}}
    >
      <div className={styles.__message}>{message}</div>
      {withClose && (
        <div onClick={() => close(true)} className={styles.close}>
          <Icon className={styles.closeIcon} src={cancelIcon} />
        </div>
      )}
      {children}
    </div>
  )
}
