import React from 'react'
import { connect } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import App from './containers/Home/App'
import Login from './containers/Authentication/Login/Login'
import TableRouter from './components/VMContent/TableOrder/TableRouter'
import LockedAccount from './containers/Authentication/Locked/LockedAccount'
import ForgetPassword from './containers/Authentication/Forget/ForgetPassword'
import ReactivateAccount from './containers/Authentication/Reactivate/ReactivateAccount'

import { Main } from './components/ui-elements/Main/Main'
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute'

class Routes extends React.Component<any> {
  state = {
    isAuth: false,
    isOpenMenu: false
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/reactivate" component={ReactivateAccount} />
          <Route
            path={'/nwlandings/vm-campaign'}
            render={(props: any) => (
              <>
                <Main
                  showSideBar={true}
                  previewState={this.props.previewState}
                  language={'fa'}
                  footer={
                    <>
                      Powered by <a className="pg-text-blue-400 pg-font-vMedium">PersianGig</a> IaaS Cloud Platform
                    </>
                  }
                >
                  <TableRouter {...props} />
                </Main>
              </>
            )}
          />

          <Route path="/locked" component={LockedAccount} />
          <Route path="/nwforgetpassword" component={ForgetPassword} />
          <Route path="/nwlogin" component={Login} />
          <PrivateRoute isAuthenticated={this.props.isAuth ? true : false} path="/" component={App} />
          <PrivateRoute isAuthenticated={this.props.isAuth ? true : false} path="/nwfm/:foldername" component={App} />
          <PrivateRoute isAuthenticated={this.props.isAuth ? true : false} path="/nwfm" component={App} {...this.props} />
          <PrivateRoute isAuthenticated={this.props.isAuth ? true : false} path="/nwvms" component={App} />
          {/* {!process.env.REACT_APP_DISABLE_VM_ORDER && <PrivateRoute isAuthenticated={this.props.isAuth ? true : false} path="/nwvms/order" component={Order} />} */}
          <Router>
            <Switch>
              <PrivateRoute isAuthenticated={this.props.isAuth ? true : false} path="/nwfm/preview/image/:size/:name" component={App} />
            </Switch>
          </Router>
          <PrivateRoute component={App} />
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = (state: any) => ({ isAuth: state.auth.token, username: state.auth.username, lockedAccount: state.account.lockedAccount })

export default connect(mapStateToProps, null)(Routes)
