import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

// internal components & styles
import FM from './components/FM'
import  VM  from './components/VM'
import './Sidebar.scss'
import PreviewSideBar from './components/Preview/PreviewSideBar'
import PreviewSideBarImage from './components/Preview/PreviewSideBarImage'
import TrashSideBar from './components/TrashSideBar'
import { AccountSideBar } from './components/AccountSideBar'
import { BillingSideBar } from './components/BillingSideBar'
import  VMINFO from './components/VMInfo'
import { HostingSidebar } from './components/HostingSidebar';

export default interface Iprops {
  onClickOverlay: () => void
  open: boolean
  onItemClick?: any
  onDrop?: any
  isVmDisabled?: boolean
  isUploadDisabled?: boolean
  sidebarOpen?: boolean
}

export const Sidebar: React.FunctionComponent<Iprops> = ({ isUploadDisabled,sidebarOpen, onClickOverlay, open, onItemClick, onDrop,isVmDisabled }: Iprops) => {
  return (
    <aside className={open ? 'pg-relative  sidebar open' : 'pg-relative   sidebar'}>
      <div
        className="pg-fixed pg-inset-0 pg-hidden pg-z-40 pg-bg-black pg-cursor-pointer pg-opacity-50 overlay"
        onClick={e => {
          e.preventDefault()
          onClickOverlay()
        }}
      />
      <div
        className={`pg-w-sidebar pg-border-t-2 pg-flex-col pg-flex pg-bg-white pg-opacity-99 pg-z-50 pg-fixed pg-top-71p pg-right-0 pg-left-auto pg-bottom-0 tablet-max:pg-top-80p ${sidebarOpen &&
          'pg--right-260 menuWrapper'} tablet-max:pg--right-260 menuWrapper`}
      >
        <Switch>
          <Route path={`/nwfm/:id?/:id?/:id?/:id?/:id?/:id?/:id?/:id?/preview/image`} render={() => <PreviewSideBarImage onItemClick={onItemClick} />} />
          <Route path={`/nwfm/:id?/:id?/:id?/:id?/:id?/:id?/:id?/:id?/preview`} render={() => <PreviewSideBar onItemClick={onItemClick} />} />
          <Route path={`/nwfm/trash`} render={() => <TrashSideBar onItemClick={onItemClick} />} />
          <Route path={`/nwfm`} render={() => <FM onItemClick={onItemClick} onDrop={onDrop} isUploadDisabled={isUploadDisabled} />} />
          <Route path={`/nwvms/order`} render={() => <VMINFO   isVmDisabled={isVmDisabled}/>} />

          <Route path={`/nwvms`} render={() => <VM   isVmDisabled={isVmDisabled}/>}  />
          <Route path={`/nwaccount`} component={AccountSideBar} />
          <Route path={`/nwbilling`} component={BillingSideBar} sidebarOpen={sidebarOpen} />
          <Route path={`/nwhosting`} component={HostingSidebar} sidebarOpen={sidebarOpen} />
        </Switch>
 
      </div>
    </aside>
  )
}
