import { t } from 'ttag'
import moment from 'moment'
const renderStatus = (status: any) => {
  let changed
  switch (status) {
    case 'PENDINGBILLING':
      changed = 'پرداخت نشده'
      break
    case 'ACTIVE':
      changed = 'فعال'
      break
    default:
      changed = 'فعال'
      break
  }
  return changed
}
export const renderHostInfo = (info: any) => {
  let array: any = []
  info.map((each: any, index: number) => {
    let dateTo = each.invoice && each.invoice.to
    let dateToFa = each.invoice && each.invoice.persianTo

    let dateFrom = each.invoice && each.invoice.from
    let dateFromFa = each.invoice && each.invoice.persianFrom
    array.push({
      domainName: each.domain && each.domain.domainName,
      dmName: each.domainName && each.domainName,
      invoiceId: each.invoice && each.invoice.id,
      invoice: each.invoice,
      dmStatus: each.domainStatus && renderStatus(each.domainStatus),
      domainStatus: each.domain && each.domain.domainStatus,
      username: each.userName,
      serverDns: each.serverDNS && each.serverDNS.split(','),
      hostStatus: each.hostStatus && renderStatus(each.hostStatus),
      id: each.id,
      autoRenewal: each.autoRenewal,
      dateTo: localStorage.getItem('__language') == 'fa' ? dateToFa : moment(dateTo).format('YYYY/MM/DD'),
      dateFrom: localStorage.getItem('__language') == 'fa' ? dateFromFa : moment(dateFrom).format('YYYY/MM/DD'),
      type: renderName(each.invoice && each.invoice.product && each.invoice.product.name)
    })
  })
  return array
}

const renderName = (name: string) => {
  let title
  switch (name) {
    case 'Plan_B':
      title = t`Personal`
      break
    case 'Plan_P':
      title = t`Pro`
      break
    case 'Plan_A1':
      title = t`Commercial`
      break
  }
  return title
}
