const checkMeliCode=(code:any)=> {
  if (!/^\d{8,10}$/.test(code) || /^(0{8,10}|1{8,10}|2{8,10}|3{8,10}|4{8,10}|5{8,10}|6{8,10}|7{8,10}|8{8,10}|9{8,10})$/.test(code))
      return false;
  var L = code.length,
      _ = 0;
  for (let i:any = 0; i < L - 1; i++)
      _ += code.charAt(i) * (L - i);
  _ %= 11;
  return (code.charAt(L - 1) == ((_ < 2) ? _ : 11 - _))
}
export const validateInput = (name: string, value: any) => {
  switch (name) {
    case 'email':
      if (!value.match(/^\S+@\S+\.\S+$/)) return 'is invalid'
      break
    case 'nationalId':
      if (!value.match(/\d{10}/)) return 'لطفا کد ملی را به صورت ۱۰ رقمی و بدون خط فاصله وارد نمایید.'
       if (!checkMeliCode(value.toString())) return 'کد ملی مجاز نیست'  
      break
    case 'postalCode':
      if (!value.match(/\d{10}/)) return 'لطفا کد پستی را به صورت ۱۰ رقمی و بدون خط فاصله وارد نمایید.'
      break

    case 'companyNationalCode':
      if (!value.match(/\d{11}/)) return 'لطفا شناسه ملی شرکت را به صورت ۱۱ رقمی وارد نمایید'
      break

    default:
      break
  }
  return true
}
