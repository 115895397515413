import React, { FunctionComponent } from 'react'
import { t } from 'ttag'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { SearchInput } from '../ui-elements/SearchInput/SearchInput'
import { SwitchIcon } from './SwitchIcon'

// Icons
import list from '../../images/Icons/switchViewIcons/list-view.svg'
import grid from '../../images/Icons/switchViewIcons/grid-view.svg'
import listViewActive from '../../images/Icons/switchViewIcons/list-view-active.svg'
import gridViewActive from '../../images/Icons/switchViewIcons/grid-view-active.svg'

// Styles
import styles from './Content.module.scss'
import { SuggestionBox } from '../ui-elements/SearchInput/SuggestionBox'
import { BreadcrumbSP } from '../Breadcrumb/BreadCrumbSP'
import { BreadCrumbAuto } from '../Breadcrumb/BreadCrumbAuto'

export default interface Iprops {
  switchView: (e: string) => void
  handleSearchInput: (e: string) => void
  view: string
  searchValue?: any
  tableData?: any
  handleKeyDown?: any
}

export const ContentHeader: React.FunctionComponent<Iprops> = ({ switchView, view, handleKeyDown, searchValue, handleSearchInput, tableData }) => {
  let MainTitleArray: any = [{ urlPath: 'nwfm', title: t`Main Folder` }]
  return (
    <div className={styles.header}>
      {window.location.pathname == '/nwfm/trash' ? (
        <BreadcrumbSP title={t`Trash`} />
      ) : !window.location.pathname.includes('preview') ? (
        <BreadCrumbAuto MainTitleArray={MainTitleArray} />
      ) : (
        ''
      )}
      <div className={styles.left} style={{ position: 'relative' }}>
        <SwitchIcon switchView={switchView} nextView={'tableData'} icon={grid} activeIcon={gridViewActive} view={view} />
        <SwitchIcon switchView={switchView} nextView={'grid'} icon={list} activeIcon={listViewActive} view={view} />
        <div>
          <SearchInput
            handleKeyDown={handleKeyDown}
            placeHolder={t`Search`}
            value={searchValue}
            withSetting={true}
            handleInputChange={(e: string) => handleSearchInput(e)}
          />
          <SuggestionBox open={false} tableData={tableData} />
        </div>
      </div>
    </div>
  )
}
