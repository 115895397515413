import * as React from 'react'
import { Link } from 'react-router-dom'
import { t } from 'ttag'

// ui-elements
import { Button } from '../../ui-elements/Button/Button'
import { IconLink } from '../../ui-elements/Icon/IconLink'
import { Hr } from '../../ui-elements/Hr/Hr'

// icons
import upFromUrlIcon from '../../../images/Icons/sidebarIcons/upfromurl.svg'
import purchaseIcon from '../../../images/Icons/vmIcons/purchase.svg'

// internal components & styles
import { Nav } from './Nav'
import '../Sidebar.scss'
import { VmInfo } from '../../VMContent/VmInfo/VmInfo'
import { Tooltip } from '../../ui-elements/Tooltip/Tooltip'
import { connect } from 'react-redux'
import { ColorfulBox } from '../../ui-elements/ColorfulBox/ColorfulBox'

export interface Iprops {
  onItemClick?: (e: any) => void
  isVmDisabled?: boolean
}

const VMINFO: React.FunctionComponent<any> = (props: any) => {
  return (
    <div className="sidebar-menu overflow-y">
      {!props.isVmDisabled ? (
        <Link to="/nwvms/order">
          <Button className={['pg-btnSuccess', 'pg-btnLg']} style={{ marginBottom: '15px' }}>
            <IconLink icon={purchaseIcon} iconAlt="upload icon" label={t`Buy new server`} />
          </Button>
        </Link>
      ) : (
        <Link to="/nwvms/order">
          <Tooltip text={t`buying is disabled`} width={130} height={37} position={'bottom'}>
            <Button className={['pg-btnDisabled', 'pg-btnLg']} style={{ marginBottom: '15px' }}>
              <IconLink icon={purchaseIcon} iconAlt="upload icon" label={t`Buy new server`} />
            </Button>
          </Tooltip>
        </Link>
      )}

      {!props.isVmDisabled && <VmInfo  os={props.os} serviceDuration={props.serviceDuration}/>}

      {props.hasGeneralMessage && (
        <div className={'pg-my-2 pg-w-full'}>
          <ColorfulBox className={['ylw', 'lg']} withClose={false} direction={'rtl'}>
            <div dir={'rtl'} style={{ lineHeight: '24px' }}>
              {props.generalMessage}
            </div>
          </ColorfulBox>
        </div>
      )}
      <div className={'pg-my-3'}>
        <Hr />
      </div>
      <Nav />
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  generalMessage: state.account.generalMessage.message,
  serviceDuration: state.vm.serviceDuration,
  os: state.vm.os[1],
  hasGeneralMessage: state.account.generalMessage.status
})
const mapDispatchToProps = (dispatch: any) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(VMINFO)
