import { actionTypes } from './types'
import { actionBuilder } from './actionBuilder'

export const getDocuments = actionBuilder(actionTypes.GET_DOCUMENTS, 'payload')
export const getModalDocuments = actionBuilder(actionTypes.GET_MODAL_DOCUMENTS, 'payload')
export const getTrashDocuments = actionBuilder(actionTypes.GET_TRASH_DOCUMENTS)
export const getSharedDocuments = actionBuilder(actionTypes.GET_SHARED_DOCUMENTS)
export const createFolder = actionBuilder(actionTypes.CREATE_FOLDER, 'payload')
export const renameFolder = actionBuilder(actionTypes.RENAME_FOLDER, 'payload')
export const removeFolder = actionBuilder(actionTypes.REMOVE_FOLDER, 'payload')
export const moveDocuments = actionBuilder(actionTypes.MOVE_DOCUMENTS,'payload')
export const dragDocuments = actionBuilder(actionTypes.DRAG_DOCUMENTS,'payload')
export const shareDocuments = actionBuilder(actionTypes.SHARE_DOCUMENTS,'userEmails','documentIds')
export const setDocuments = actionBuilder(actionTypes.SET_DOCUMENTS, 'payload')
export const setParentId = actionBuilder(actionTypes.SET_PARENT_ID, 'payload')
export const setTempDocuments = actionBuilder(actionTypes.SET_TEMP_DOCUMENTS, 'payload')
export const setResponse = actionBuilder(actionTypes.SET_RESPONSE, 'payload')
export const setModalDocuments = actionBuilder(actionTypes.SET_MODAL_DOCUMENTS, 'data')
export const generateDownloadLink = actionBuilder(actionTypes.GENERATE_DOWNLOAD_LINK, 'payload')
export const downloadDirectory = actionBuilder(actionTypes.DOWNLOAD_DIRECTORY, 'payload')
export const restoreFiles = actionBuilder(actionTypes.RESTORE_FILES, 'payload')
export const setLastChild =  actionBuilder(actionTypes.SET_LAST_CHILD, 'lastChild')
export const deleteDocument =  actionBuilder(actionTypes.DELETE_DOCUMENT, 'payload')
export const uploadDocument =  actionBuilder(actionTypes.UPLOAD_DOCUMENT, 'payload')
export const urlUpload =  actionBuilder(actionTypes.URL_UPLOAD, 'payload')
export const changeSharingStatus =  actionBuilder(actionTypes.CHANGE_SHARING_STATUS, 'payload')
export const uploadServer =  actionBuilder(actionTypes.UPLOAD_SERVER, 'payload')
export const setUploader =  actionBuilder(actionTypes.SET_UPLOADER, 'payload')
export const addDescription =  actionBuilder(actionTypes.ADD_DESCRIPTION, 'payload')
export const setUploadModal =  actionBuilder(actionTypes.SET_UPLOAD_MODAL, 'payload')
export const setUploadFiles =  actionBuilder(actionTypes.SET_UPLOAD_FILES, 'payload')
export const setUploadProgress =  actionBuilder(actionTypes.SET_UPLOAD_PROGRESS, 'payload')
export const setUploadCount=  actionBuilder(actionTypes.SET_UPLOAD_COUNT, 'payload')
export const setDragnDropState=  actionBuilder(actionTypes.SET_DRAG_DROP_STATE, 'payload')
export const setDragInfo=  actionBuilder(actionTypes.SET_DRAG_INFO, 'payload')