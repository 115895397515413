import React, { useState } from 'react'
import { t } from 'ttag'
import { connect } from 'react-redux'

import { IconLink } from '../../ui-elements/Icon/IconLink'

//icon
import newFolderIcon from '../../../images/Icons/sidebarIcons/newfolder.svg'

//styles
import styles from '../Table.module.scss'
import { TableItem } from '../TableBody/TableItem'
import { Iprops } from './TableHeader.interface'

export const TableHeader: React.FunctionComponent<Iprops> = ({
  titles,
  labelShow,
  sortable,
  onOpenCFModal,
  selection,
  dropdown,
  selectableRows,
  onSort,
  selectionNumber,
  documentsLength,
 showAll,
  onCheckAll,
  tabletView,
  editableId
}) => {
  const altIcon = 'Icon'
  const [hovered, setHovered] = useState(false)
  const toggleHover = () => setHovered(!hovered)
  let isChecked = selectionNumber && selectionNumber.length !== 0

  let checkboxType = documentsLength && documentsLength > selectionNumber.length ? 'indeterminate' : ''
  let isShowable = [t`type`, t`id`, t`fullPath`]
  return (
    <thead>
      {titles && (
        <tr onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
          {titles.map((label: string, i: number) => {
            // console.log(label)
            let isName = label == t`Name`
            if (!isShowable.includes(label)) {
              return (
                <TableItem 
                  key={i}
                  name={label}
                  checkbox={i == 0 && selectableRows}
                  editableId={editableId}
                  checkboxType={checkboxType}
                  label={label}
                  onCheckAll={onCheckAll}
                  selection={selection}
                  selectableRows={selectableRows}
                  onSort={onSort}
                  hovered={hovered && hovered}
                  checked={isChecked}
                  sortable={sortable}
                  sortType={isName ? 'alphabet' : ' '}
                  className={   showAll?  ['header', 'show'] :isName || (labelShow && labelShow.includes(label)) ? ['header', 'show'] : ['header']}
                />
              )
            }
          })}

          {dropdown && tabletView ? (
            <td className={styles.show}>
              <IconLink className={styles.icn} icon={newFolderIcon} iconAlt={`new-folder ${altIcon}`} label={t`New Folder`} onClick={onOpenCFModal} />
            </td>
          ) : (
            <td />
          )}
        </tr>
      )}
    </thead>
  )
}
