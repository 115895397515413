import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { Hr } from '../../ui-elements/Hr/Hr'
import { Button } from '../../ui-elements/Button/Button'
import { IconLink } from '../../ui-elements/Icon/IconLink'
import { Tooltip } from '../../ui-elements/Tooltip/Tooltip'
import { ColorfulBox } from '../../ui-elements/ColorfulBox/ColorfulBox'

import deleteIcon from '../../../images/Icons/sidebarIcons/delete.1.svg'
import deleteGrayIcon from '../../../images/Icons/sidebarIcons/delete-gray.svg'

export interface Iprops {
  vmBills?: any
  deleteVm?: any
  openCardModal?: any
}

const VMNetwork: React.FunctionComponent<Iprops> = props => {
  let isOn = props.vmBills && props.vmBills[0] && props.vmBills[0].vm && props.vmBills[0].vm.state == 'ON'
  let isProblem = props.vmBills && props.vmBills[0] && props.vmBills[0].vm && props.vmBills[0].vm.state == 'PROBLEM'
  //id for delete
  let vmId = props.vmBills && props.vmBills[0] && props.vmBills[0].vm && props.vmBills[0].vm.id
  //name
  let vmName = props.vmBills && props.vmBills[0] && props.vmBills[0].vm && props.vmBills[0].vm.name
  //network name

  let networkName =
    props.vmBills &&
    props.vmBills[0] &&
    props.vmBills[0].vm &&
    props.vmBills[0].vm.nics &&
    props.vmBills[0].vm &&
    props.vmBills[0].vm.nics[0] &&
    props.vmBills[0].vm &&
    props.vmBills[0].vm.nics[0].network

  return (
    <div className={''}>
      <ColorfulBox className={['wheat', 'lg']} fontSize={12}>
        <div className="networkInfo">
          <div className="header">
            <div>{t`Network name`}</div>
            <div>{t`IP Number`}</div>
          </div>
          <Hr backgroundColor={'#e5e5e5'} height={'1px'} />
          <div className="body">
            <div className={'pg-font-sans'}>{networkName && networkName.name}</div>
            <div>1</div>
          </div>
        </div>
      </ColorfulBox>
      <div className={'pg-mt-8'}>
        <ColorfulBox className={['wheat', 'lg']} fontSize={12}>
          <div className={'pg-flex pg-justify-around pg-w-full mobile-max:pg-flex-col pg-items-center'}>
            <div className="networkInfo">
              <div>{t`Delete Perminently`}</div>
              <div className={'pg-mt-4 pg-font-vLight pg-text-gray-700 '}>{t`Your info will be lost forever.`}</div>
            </div>
            <div className={`pg-flex pg-items-center`}>
              {isOn ? (
                <Tooltip text={isProblem ? t`Contact Support` : t`Server is on`} width={isProblem ? 180 : 90} height={27} position={'bottom'}>
                  <div className={`networkInfo `}>
                    <Button className={['pg-btnLg', 'pg-btn', 'pg-btnDanger', isOn ? 'pg-btnDisabled' : '']} onClick={() => props.deleteVm(vmId)}>
                      <IconLink icon={deleteGrayIcon} iconAlt="upload icon" linkStyle={{ margin: '0 10px' }} label={t`Delete`} />
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <div className="networkInfo">
                  <Button
                    className={['pg-btnLg', 'pg-btn', 'pg-btnDanger', isOn ? 'pg-btnDisabled' : '']}
                    onClick={() => props.openCardModal({ vmId, modalView: 'prompt', vmName, deleteOs: false })}
                  >
                    <IconLink icon={deleteIcon} iconAlt="upload icon" label={t`Delete`} linkStyle={{ margin: '0 10px' }} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ColorfulBox>
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  vmBills: state.vm.vmBillsNamed
})

export default connect(mapStateToProps, null)(VMNetwork)
