import React, { Component } from 'react'
import { Icon } from '../ui-elements/Icon/Icon'

export const RenderIcon: React.FunctionComponent<any> = ({ name, mimetype, imgSrc,id,width }) => {
  return mimetype && mimetype === 'image' ? (
    <Icon src={imgSrc} className={'imageIcon icon'} id={id} width={width}/>
  ) : (
    <Icon mimetype={window.location.pathname.includes('trash') && name == 'name' ? 'icon' : mimetype}  width={width}/>
  )
}
