import React from 'react'
import { Link } from 'react-router-dom'
import { t } from 'ttag'

import styles from './Breadcrumb.module.scss'

import { Breadcrumb } from './Breadcrumb'
import { Icon } from '../ui-elements/Icon/Icon'
import bigger from '../../images/Icons/commonIcons/bigger.svg'

export default interface Iprops {
  title?: string
}

export const BreadcrumbMP: React.FunctionComponent<Iprops> = ({ title }) => {
  return (
    <Breadcrumb>
      <Link className={[ styles.item].join(' ')} to={'/nwvms'}>
        {t`Main Page`}
      </Link>
      <Icon src={bigger} />
      <span className={[styles.active, styles.item].join(' ')}> {window.location.pathname.split('/nwvms/')[1].split('/setting')[0]}</span>
    </Breadcrumb>
  )
}
