import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import PayModal from '../../../components/ui-elements/Modal/PayModal/PayModal'
import { DeleteVm } from '../../../components/ui-elements/Modal/VMModals/DeleteVm/DeleteVm'
import { UpgradeVm } from '../../../components/ui-elements/Modal/VMModals/UpgradeVm/UpgradeVm'
import { Prompt } from '../../../components/ui-elements/Modal/Prompt/Prompt'

import {
  setInvoiceItem,
  getVmBillsNamed,
  setvmBills,
  getVmproducts,
  deleteUpgradeVm,
  deleteOs,
  deleteVm
} from '../../../services/internal/store/actions'

class VMModalRenderer extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    let vmPathName

    if (this.props.location) {
      vmPathName = this.props.location.pathname.split('/nwvms/')[1]
      if (this.props.location.pathname.includes('/setting')) vmPathName = vmPathName.split('/setting')[0]
    }

    this.state = {
      vmPathName: vmPathName ? vmPathName : '',
      errorMsg: '',
      vmName: '',
      error: false
    }
  }

  // handle input change changes
  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value, error: false })
  }

  activeIdle = () => {
    this.setState({ showModal: false })
  }

  //delete vmbill or delete os
  deleteVm = () => {
    if (!this.props.VMGrid) {
      if (this.state.vmName == this.state.vmPathName) {
        if (this.props.isdeleteOs) this.props.deleteOs({ id: this.props.vmId })
        else this.props.deleteVm(this.props.vmId)

        this.props.handleClose()
      } else this.setState({ error: true, errorMsg: t`نام سرور اشتباه است` })
    } else {
      if (this.state.vmName == this.props.checkvmName) {
        let vmArray = this.props.vmArray
        vmArray = vmArray.filter((item: any) => item.id != this.props.vmId)
        this.setState({ vmArray })

        if (this.props.isdeleteOs) this.props.deleteOs({ id: this.props.vmId, vmArray })
        else this.props.deleteVm({ id: this.props.vmId, vmArray })

        this.props.handleClose()
      } else this.setState({ error: true, errorMsg: t`نام سرور اشتباه است` })
    }
  }

  upgradeVm = () => {
    if (!this.props.VMGrid) {
      let vmId = this.props.vmBillsNamed && this.props.vmBillsNamed[0] && this.props.vmBillsNamed[0].vm && this.props.vmBillsNamed[0].vm.id
      if (this.state.upgradeName == this.state.vmPathName) this.props.deleteUpgradeVm({ id: this.props.vmId, tmpId: vmId })
      else this.setState({ error: true, errorMsg: t`نام سرور اشتباه است` })
    } else {
      if (this.state.upgradeName == this.props.checkvmName) {
        let vmArray = this.props.vmArray
        let vm = vmArray.filter((item: any) => item.id == this.props.vmId && item)
        this.props.deleteUpgradeVm({ id: vm[0].id, tmpId: vm[0].vmId })
        this.props.handleClose()
      } else this.setState({ error: true, errorMsg: t`نام سرور اشتباه است` })
    }
  }

  render() {
    let modal
    const { vmName, error, errorMsg, upgradeName } = this.state
    const { showModal, handleClose } = this.props
    switch (this.props.modalView) {
      case 'pay':
        modal = <PayModal showModal={showModal} handleCFClose={handleClose} payment={true} />
        break
      case 'prompt':
        modal = (
          <DeleteVm
            showModal={showModal}
            handleClose={handleClose}
            name={vmName}
            deleteVm={this.deleteVm}
            error={error}
            errorMsg={errorMsg}
            handleChange={this.handleChange}
          />
        )

        break
      case 'idlePrompt':
        modal = (
          <Prompt
            showModal={showModal}
            handleClose={handleClose}
            title={t`Idle`}
            formDescription={t`You can not activate untill 24 hours`}
            onClick={this.activeIdle}
          />
        )
        break
      case 'upgradePrompt':
        modal = (
          <UpgradeVm
            showModal={showModal}
            handleClose={handleClose}
            upgradeName={upgradeName}
            upgradeVm={this.upgradeVm}
            error={error}
            errorMsg={errorMsg}
            handleChange={this.handleChange}
          />
        )
    }
    return modal
  }
}

const mapStateToProps = (state: any) => ({
  loading: state.loading.isLoading,
  vmArray: state.vm.vmArray,
  invoiceItem: state.account.invoiceItem,
  userInfo: state.account.info,
  vmBillsNamed: state.vm.vmBillsNamed
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    getVmProducts: () => dispatch(getVmproducts()),
    setInvoiceItem: (value: any) => dispatch(setInvoiceItem(value)),
    getVmBills: (value: any) => dispatch(getVmBillsNamed(value)),
    setvmBills: (value: any) => dispatch(setvmBills(value)),
    deleteUpgradeVm: (value: any) => dispatch(deleteUpgradeVm(value)),
    deleteOs: (vmId: number) => dispatch(deleteOs(vmId)),
    deleteVm: (vmId: number) => dispatch(deleteVm(vmId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VMModalRenderer)
