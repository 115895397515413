import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// ui-elements
import { ColorfulBox } from '../../../ui-elements/ColorfulBox/ColorfulBox'
import { TextInput } from '../../../ui-elements/Input/Input'
import { Button } from '../../../ui-elements/Button/Button'
import { formatPrice } from '../../../../services/internal/utils/formatPrice'
import { Footer } from '../../OldOrder/Footer/Footer'
import { StepDescription } from '../../OldOrder/Steps/Details/StepDescription'

// services
import { goToPreviousStep, setPromo, setName, createInvoice, selectStep } from '../../../../services/internal/store/actions'

// styles
import styles from '../../OldOrder/Order.module.scss'

export interface Iprops {
  currentStep: number
  goToPreviousStep?: (e: any) => void
  setPromo?: any
  steps?: any
}

class FinalStep extends React.Component<any, any> {
  textInput: any
  constructor(props: any) {
    super(props)
    this.state = {
      errMsg: false,
      promo: '',
      name: props.name ? props.name : ''
    }
    this.textInput = React.createRef()
    this.focus = this.focus.bind(this)
  }


  

  focus() {
    this.textInput.current.focus()
  }
  componentDidMount() {
    this.props.selectStep(1)
    this.focus()
  }

  handleChange = (e: any) => {
    if (e.target.name == 'name') {
      this.setState({ errMsg: false })
      if (e.target.value && e.target.value.match(/^[a-z](?:_?[-a-z0-9]+)*$/i)) {
        this.setState({ [e.target.name]: e.target.value, disableButton: false })
        this.props.setName(e.target.value)
      } else if (!e.target.value) this.setState({ [e.target.name]: '', errMsg: true, disableButton: true })
      else this.setState({ errMsg: true, disableButton: true })
    }
    if (!e.target.value.includes('_')) {
      this.setState({ disableButton: false })
    } else {
      this.setState({ errMsg: true, disableButton: true })
    }
  }

  goToPreviousStep = () => {
    let { history, goToPreviousStep } = this.props
    history.push(`/nwvms/order/choose-plan`)
    goToPreviousStep()
  }

  createInvoice = () => {
    let { cpu, ram, disk, core } = this.props.vsCardMain
    if (this.props.serviceDuration == 'DAY') cpu = core
    if (this.props.serviceDuration == 'DAY') {
      if (cpu < 4) cpu = 4
      if (ram < 8) ram = 8
      if (this.props.os[1] == 'win2012' && disk < 110) disk = 110
      else if (this.props.os[1] !== 'win2012' && disk < 80) disk = 80
    }
    ram = parseFloat(ram)
    disk = parseFloat(disk)
    // console.log('%%', ram)
    let finalInvoice = {
      name: `${this.props.vmName}`,
      nicInfos: [{ ipCount: 1, network: this.props.networkName }],
      os: `${this.props.os[1]}`,
      plan: {
        additionalInfo: JSON.stringify({ cpu: cpu, ram: ram, disk: disk, managed: false }),
        id: this.props.serviceDuration == 'DAY' ? 20000 : this.props.vsCardMain.id,
        jsonInfo: JSON.stringify({ plan_type: this.props.serviceDuration })
      }
    }

    this.setState({ finalInvoice })
    this.props.createInvoice(finalInvoice)
  }

  render() {
    let props = this.props
    let { promo } = this.state
    let vmInfo: any
    if (props && props.vmCard && props.vmCard.cpu && props.os)
      vmInfo = {
        cpuCores: parseFloat(props.vmCard.cpu.split('(')[2]),
        os: props.os[1],
        primaryDisk: parseFloat(props.vmCard.disk),
        ram: parseFloat(props.vmCard.ram),
        name: this.state.name
      }
    return (
      <div className={`${styles.FinalStep}`}>
        <div className={` pg-ml-2 pg-px-4 pg-bg-white pg-pt-4 pg-w-full  pg-shadow-sm `}>
          <StepDescription stepNumber={1} title={[t`Fourth step`, `:`, t` Submit`].join(' ')} subTitle={t`Select your server name`} />
          <div className={'pg-flex pg-my-4 pg-pb-4 pg-items-center pg-justify-center laptop-max:pg-flex-col'}>
            <div className={'pg-w-full pg-pl-3 laptop-max:pg-pl-0'}>
              <ColorfulBox className={['white', 'lg']} margin={'0 0 30px 0'} height={155}>
                <div className={`${styles.serverName} pg-flex pg-flex-col`}>
                  <span className={styles.caption}>{t`Enter the server name`}:</span>
                  <p
                    className={`${this.state.errMsg && styles.redText} ${styles.dscp} `}
                  >{t`The server name must be English and only include letters, numbers and dashes.`}</p>
                  <TextInput
                    onChange={this.handleChange ? this.handleChange : this.props.handleChange}
                    onErr={this.state.errMsg}
                    value={this.state.name}
                    wrapperRef={this.textInput}
                    name={'name'}
                  />
                </div>
              </ColorfulBox>
            </div>
            <div className={'pg-w-full pg-pr-3 laptop-max:pg-pr-0 laptop-max:pg-mt-4'}>
              <ColorfulBox className={['green', 'lg']} margin={'0 0 30px 0'} height={155}>
                <div className={'pg-w-full'}>
                  <div className={styles.discount}>
                    <span className={styles.caption}>{t`Enter the discount code`}:</span>
                    <p className={styles.dscp}>{t`If you have a discount code, enter it for review.`}</p>
                    <div className={'pg-flex'}>
                      <TextInput onChange={this.props.handleChange} name={'promo'} value={this.props.promo} />
                      <Button
                        className={['pg-btnDefault', 'pg-btnSm']}
                        onClick={() => props.setPromo({ promo, body: { vmInfo } })}
                      >{t`Check`}</Button>
                    </div>
                  </div>
                </div>
              </ColorfulBox>
            </div>
          </div>
        </div>
        <ColorfulBox className={['white', 'lg']} margin={'30px 0 20px 0'}>
          <div className={'pg-w-full pg-flex pg-h-full'}>
            <span className={`${styles.caption} pg-mx-2 pg-w-full`}>{t`Total Price`}:</span>
            <span className={styles.finalPrice}>{props.finalPrice ? props.finalPrice : formatPrice(props.finalPrice)}</span>
          </div>
        </ColorfulBox>
        <Footer
          homePage={'nwvms'}
          hasLink={true}
          previousStep={window.location.pathname.includes('nwvms') ? '/nwvms/order/choose-plan' : '/nwlandings/vm-campaign/choose-plan'}
          handlePreviousStep={this.goToPreviousStep}
          nextStep={'/nwvms/order/final-step'}
          finalStep={true}
          currentStep={1}
          buttonLoading={this.props.buttonLoading}
          enableButton={this.state.name && !this.state.errMsg}
          disableButton={this.state.errMsg}
          createInvoice={this.createInvoice}
          steps={this.props.steps}
        />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  currentStep: state.vm.currentStep,
  vmCard: state.vm.vsCard,
  buttonLoading: state.loading.buttonLoading,
  finalPrice: state.vm.finalPrice,
  os: state.vm.os,
  oses: state.vm.oses,
  vmName: state.vm.vmName,
  vmProducts: state.vm.vmProducts2,
  vsCardMain: state.vm.vsCardMain,
  serviceDuration: state.vm.serviceDuration,
  steps: state.vm.steps,
  networkName: state.account.networkName
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    goToPreviousStep: (stepNumber: number) => dispatch(goToPreviousStep(stepNumber)),
    setPromo: (value: any) => dispatch(setPromo(value)),
    setName: (value: any) => dispatch(setName(value)),
    selectStep: (value: any) => dispatch(selectStep(value)),
    createInvoice: (value: string) => dispatch(createInvoice(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinalStep)
