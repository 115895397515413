import React from 'react'

import { Icon } from '../../Icon/Icon'
import styles from '../Table.module.scss'

const DragPreviewContainer = ({ selection, ids }: any) => {
  return (
    <div className={'pg-relative pg-cursor-pointer'}>
      {selection.length > 0 && <span className={styles.number}> {selection.length} </span>}
      {selection.slice(0, 3).map((select: any, i: any) => {
        return (
          <div
            key={ids[i]}
            className={`card ${styles.cardDragged}`}
            style={{
              zIndex: 1000 - i,
              cursor: 'grabbing !important' ,
              border: '1px solid #d4d4d4',
              borderRadius: '3px',
              transform: `rotateZ(${-i * 4.5}deg)`
            }}
          >
            <Icon src={select} width={40} height={40} style={{ objectFit: 'cover',maxHeight: '40px',maxWidth: '40px' }} />
          </div>
        )
      })}
    </div>
  )
}

export default DragPreviewContainer
