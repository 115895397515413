export const actionTypes = {
  SET_TOKEN: 'SET_TOKEN',
  SET_USER_CREDENTIALS: 'SET_USER_CREDENTIALS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CHANGE_AUTH_FORM_STATE: 'CHANGE_AUTH_FORM_STATE',
  SET_CITY: 'SET_CITY',
  SET_USER_INFO: 'SET_USER_INFO',
  SIGNOUT: 'SIGNOUT',
  SET_AUTO_RENEWAL:'SET_AUTO_RENEWAL',
  LOGOUT: 'LOGOUT',
  SET_VM_LOADING_ID:'SET_VM_LOADING_ID',
  ADD_ADDRESS: 'ADD_ADDRESS',
  ORDER_HOST:'ORDER_HOST',
  SET_USER_ADDRESSES: 'SET_USER_ADDRESSES',
  SET_USER_DEFAULT_ADDRESS: 'SET_USER_DEFAULT_ADDRESS',
  SET_DEFAULT_ADDRESS: 'SET_DEFAULT_ADDRESS',
  DELETE_USER_ADDRESS: 'DELETE_USER_ADDRESS',
  DELETE_ADDRESS: 'DELETE_ADDRESS',
  ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
  REMOVE_ITEM_FROM_CART: 'REMOVE_ITEM_FROM_CART',
  INCREASE_ITEM_QUANTITY: 'INCREASE_ITEM_QUANTITY',
  DECREASE_ITEM_QUANTITY: 'DECREASE_ITEM_QUANTITY',
  SEND_AS_GIFT: 'SEND_AS_GIFT',
  SET_GIFT_CODE: 'SET_GIFT_CODE',
  SET_HOST_INVOICE: 'SET_HOST_INVOICE',
  SET_CANCEL_TOKEN: 'SET_CANCEL_TOKEN',
  SET_PREVIEW_STATE:'SET_PREVIEW_STATE',
  GET_HOSTS:'GET_HOSTS',
  SET_FINAL_PRICE:'SET_FINAL_PRICE',
  SET_HOSTS:'SET_HOSTS',
  GET_DOMAINS:'GET_DOMAINS',
  SET_DOMAINS:'SET_DOMAINS',
  CREATE_HOST_BILL:'CREATE_HOST_BILL',
  DOMAIN_INVOICES:'DOMAIN_INVOICES',
  SET_DOMAIN_INVOICE:'SET_DOMAIN_INVOICE',
  CHECK_GIFT_CODE: 'CHECK_GIFT_CODE',
  SET_INVOICE_LOADING: 'SET_INVOICE_LOADING',
  SET_INVOICE_ID: 'SET_INVOICE_ID',
  SET_UPLOAD_COUNT:'SET_UPLOAD_COUNT',
  SET_PAYABLE_PRICE: 'SET_PAYABLE_PRICE',
  SET_INVOICES: 'SET_INVOICES',
  HOST_INVOICES: 'HOST_INVOICES',
  GET_INVOICES: 'GET_INVOICES',
  PAY_INVOICE: 'PAY_INVOICE',
  CREATE_INVOICE: 'CREATE_INVOICE',
  SET_IP_COUNTRY: 'SET_IP_COUNTRY',
  CHARGE_WALLET: 'CHARGE_WALLET',
  ACTIVE_IDLE:'ACTIVE_IDLE',
  DELETE_BILL:'DELETE_BILL',
  SET_VM_BILL_ERROR:'SET_VM_BILL_ERROR',
  IS_DOMAIN_AVAILABLE:'IS_DOMAIN_AVAILABLE',
  DOMAIN_AVAILABLE_STATUS:'DOMAIN_AVAILABLE_STATUS',
  SET_DRAG_DROP_STATE:'SET_DRAG_DROP_STATE',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_DRAG_INFO:'SET_DRAG_INFO',
  SET_VM_BILLS_NAMED: 'SET_VM_BILLS_NAMED',
  GET_VM_BILLS_NAMED: 'GET_VM_BILLS_NAMED',
  CHANGE_VM_INFO:'CHANGE_VM_INFO',
  SET_DOCUMENTS_LOADING: 'SET_DOCUMENTS_LOADING',
  LOGIN: 'LOGIN',
  SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
  SET_UPLOAD_MODAL: 'SET_UPLOAD_MODAL',
  SET_UPLOAD_FILES: 'SET_UPLOAD_FILES',
  SET_INVOICES_ARRAY: 'SET_INVOICES_ARRAY',
  INIT_LOGIN: 'INIT_LOGIN',
  REGISTER: 'REGISTER',
  FORGET_PASSWORD: 'FORGET_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  VERIFY_MOBILE: 'VERIFY_MOBILE',
  GET_USER_ADDRESSES: 'GET_USER_ADDRESSES',
  GET_USER_INFO: 'GET_USER_INFO',
  GET_DOCUMENTS: 'GET_DOCUMENTS',
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  CREATE_FOLDER: 'CREATE_FOLDER',
  RENAME_FOLDER: 'RENAME_FOLDER',
  MOVE_DOCUMENTS: 'MOVE_DOCUMENTS',
  SHARE_DOCUMENTS: 'SHARE_DOCUMENTS',
  REMOVE_FOLDER: 'REMOVE_FOLDER',
  SET_BUTTON_LOADING_STATE: 'SET_BUTTON_LOADING_STATE',
  DELETE_INVOICE: 'DELETE_INVOICE',
  SET_RESPONSE: 'SET_RESPONSE',
  SET_SELECTIONS: 'SET_SELECTIONS',
  GET_TRASH_DOCUMENTS: 'GET_TRASH_DOCUMENTS',
  GET_SHARED_DOCUMENTS: 'GET_SHARED_DOCUMENTS',
  URL_UPLOAD: 'URL_UPLOAD',
  SET_MODAL_DOCUMENTS: 'SET_MODAL_DOCUMENTS',
  GET_MODAL_DOCUMENTS: 'GET_MODAL_DOCUMENTS',
  SET_ROUTER: 'SET_ROUTER',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  SET_SIDEBAR_ITEM: 'SET_SIDEBAR_ITEM',
  SET_PREVIEW_IMAGE: 'SET_PREVIEW_IMAGE',
  GENERATE_DOWNLOAD_LINK: 'GENERATE_DOWNLOAD_LINK',
  SET_HISTORY: 'SET_HISTORY',
  DOWNLOAD_DIRECTORY: 'DOWNLOAD_DIRECTORY',
  SET_TOGGLE: 'SET_TOGGLE',
  DELETE_UPGRADE_VM:'DELETE_UPGRADE_VM',
  RESTORE_FILES: 'RESTORE_FILES',
  SET_TEMP_DOCUMENTS: 'SET_TEMP_DOCUMENTS',
  SELECT_OS: 'SELECT_OS',
  SET_MODAL_STATE: 'SET_MODAL_STATE',
  SET_MODAL_VIEW: 'SET_MODAL_VIEW',
  STEP_FORWARD: 'STEP_FORWARD',
  STEP_BACKWARD: 'STEP_BACKWARD',
  SET_ERROR: 'SET_ERROR',
  SET_MESSAGES: 'SET_MESSAGES',
  SET_PROFILE_ARRAY: 'SET_PROFILE_ARRAY',
  REMOVE_MESSAGES: 'REMOVE_MESSAGES',
  REMOVE_SELECTION: 'REMOVE_SELECTION',
  SET_DOWNLOAD_TOKEN: 'SET_DOWNLOAD_TOKEN',
  UPDATE_VM: 'UPDATE_VM',
  SET_MODAL_SELECTIONS: 'SET_MODAL_SELECTIONS',
  SET_FULLSCREEN: 'SET_FULLSCREEN',
  SET_PARENT_ID: 'SET_PARENT_ID',
  SET_PARENT_LIST:'SET_PARENT_LIST',
  SET_MODAL_LOADING_STATE: 'SET_MODAL_LOADING_STATE',
  SET_LAST_CHILD: 'SET_LAST_CHILD',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  SELECT_VS_CARD_DAY:'SELECT_VS_CARD_DAY',
  UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
  CHANGE_SHARING_STATUS: 'CHANGE_SHARING_STATUS',
  SELECT_ALL: 'SELECT_ALL',
  SET_EDIT_STATUS: 'SET_EDIT_STATUS',
  UPDATE_RENAME_TEXT: 'UPDATE_RENAME_TEXT',
  SET_PROFILE_TAB: 'SET_PROFILE_TAB',
  SET_FORM_STATE: 'SET_FORM_STATE',
  GET_PRODUCTS: 'GET_PRODUCTS',
  SET_PRODUCT_TOGGLE: 'SET_PRODUCT_TOGGLE',
  CHANGE_PLAN: 'CHANGE_PLAN',
  UPLOAD_SERVER: 'UPLOAD_SERVER',
  SET_UPLOADER: 'SET_UPLOADER',
  CHANGE_PROFILE: 'CHANGE_PROFILE',
  SET_CITIES: 'SET_CITIES',
  ADD_DESCRIPTION: 'ADD_DESCRIPTION',
  SET_DOWNLOAD_LOADING: 'SET_DOWNLOAD_LOADING',
  SET_CHANGE_PASSWORD_SUCCESS: 'SET_CHANGE_PASSWORD_SUCCESS',
  GET_INVOICE: 'GET_INVOICE',
  REMOVE_INVOICE:'REMOVE_INVOICE',
  DONE_FIRST_STEP:'DONE_FIRST_STEP',
  SET_INVOICE: 'SET_INVOICE',
  SET_INVOICE_ITEM: 'SET_INVOICE_ITEM',
  SET_LOCKED_ACCOUNT: 'SET_LOCKED_ACCOUNT',
  SET_EMAIL: 'SET_EMAIL',
  REACTIVATE: 'REACTIVATE',
  SET_INVOICES_VM: 'SET_INVOICES_VM',
  SET_SUBMIT_INFO:'SET_SUBMIT_INFO',
  SET_INVOICES_FM:'SET_INVOICES_FM',
  SET_INVOICES_HOST: 'SET_INVOICES_HOST',
  SET_LOADING_INVOICE_STATE:'SET_LOADING_INVOICE_STATE',
  SET_USER_PROFILE:'SET_USER_PROFILE',
  SET_INVOICES_DOMAIN:'SET_INVOICES_DOMAIN',
  SET_REGISTER_STATE: 'SET_REGISTER_STATE',
  ADD_TO_BALANCE: 'ADD_TO_BALANCE',
  SET_VALIDATION_ERR: 'SET_VALIDATION_ERR',
  GET_OSES: 'GET_OSES',
  SET_OSES: 'SET_OSES',
  GET_NETWORK: 'GET_NETWORK',
  SET_NETWORK: 'SET_NETWORK',
  DO_PAY: 'DO_PAY',
  MELLAT_BANK: 'MELLAT_BANK',
  SELECT_STEP: 'SELECT_STEP',
  GET_VM_PRODUCTS: 'GET_VM_PRODUCTS',
  SET_VM_PRODUCTS: 'SET_VM_PRODUCTS',
  SET_SERVICE_DURATION: 'SET_SERVICE_DURATION',
  SELECT_VS_CARD: 'SELECT_VS_CARD',
  SET_PROMO: 'SET_PROMO',
  GET_VM_BILLS: 'GET_VM_BILLS',
  SET_VM_BILLS: 'SET_VM_BILLS',
  RENEWAL: 'RENEWAL',
  SET_NAME: 'SET_NAME',
  DELETE_OS: 'DELETE_OS',
  POWER: 'POWER',
  SET_VM_ID: 'SET_VM_ID',
  CANCEL_CHANGES: 'CANCEL_CHANGES',
  CALCULATE_LEFT_CREDIT: 'CALCULATE_LEFT_CREDIT',
  CHANGE_VM_PLAN: 'CHANGE_VM_PLAN',
  SELECT_VM: 'SELECT_VM',
  DELETE_VM: 'DELETE_VM',
  GET_VM_BILL: 'GET_VM_BILL',
  RETRY_VM: 'RETRY_VM',
  RENEWAL_VM: 'RENEWAL_VM',
  SET_LEFT_CREDIT: 'SET_LEFT_CREDIT',
  SET_VPS_PERIOD: 'SET_VPS_PERIOD',
  SET_VM_INVOICE: 'SET_VM_INVOICE',
  SET_REF: 'SET_REF',
  GET_WALLET: 'GET_WALLET',
  SET_WALLET: 'SET_WALLET',
  VM_BILL_INVOICES: 'VM_BILL_INVOICES',
  SET_EXPIRATION_NOTIFICATION: 'SET_EXPIRATION_NOTIFICATION',
  RENEWAL_DELETE: 'RENEWAL_DELETE',
  SET_SIDEBAR_STATE: 'SET_SIDEBAR_STATE',
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  SET_TRANSACTIONS: 'SET_TRANSACTIONS',
  GET_SETTING_STATE: 'GET_SETTING_STATE',
  SET_SETTING_STATE: 'SET_SETTING_STATE',
  DRAG_DOCUMENTS: 'DRAG_DOCUMENTS'
}
