import React from 'react'
import { Link, Route } from 'react-router-dom'

import { Icon } from '../ui-elements/Icon/Icon'
import bigger from '../../images/Icons/commonIcons/bigger.svg'

import styles from './Breadcrumb.module.scss'
import  DropppableTitle  from './DroppableTitle'

export const BreadcrumbsItem: React.FunctionComponent<any> = ({ match, vm, MainTitleArray, ...rest }) => {
  let title = match.params.path
  MainTitleArray &&
    MainTitleArray.map((each: any) => {
      if (match.params.path.includes(each.urlPath)) title = each.title
    })
  return (
    <span>
      <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
      {!match.isExact && <Icon src={bigger} />}
      <Link className={match.isExact ? [styles.active, styles.item].join(' ') : styles.item} to={match.url || ''}>
        <DropppableTitle title={title} {...rest}/>
      </Link>
    </span>
  )
}
