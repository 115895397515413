import React from 'react'
import { connect } from 'react-redux'

import VpsCard from '../../../components/VMContent/VpsCard/VpsCard'
import { VmInfo } from '../../../components/VMContent/VmInfo/VmInfo'

import { setVpsPeriod, changeVmBillPlan, calculateLeftCredit, setVmproducts, changeVmInfo } from '../../../services/internal/store/actions'

import { switchTimeLang } from '../../../services/internal/utils/switchTimeLang'

import styles from './VpsCards.module.scss'
import HPC from '../../../components/VMContent/OldOrder/Steps/HPC'

interface Iprops {
  setVpsPeriod?: any
  changeVmBillPlan?: any
  price?: any
  activePlanNum?: number
  osName?: string
  id?: number
  leftCredit?: any
  recurringTime?: string
  planId?: number
  calculateLeftCredit?: any
  hasActiveFooter?: any
  changeVmInfo?: any
  setVmproducts?: any
}

interface Istate {
  price: number
  planId: number
  plansId?: number
  period: string
  activePlanNum: number
  vpsName: string
  hpc: boolean
  time2?: any
  ram?: any
  primaryDisk?: any
  vpsPrice: number
  selectedPrice: number
  osName?: string
  leftCredit?: number
  cpuCores?: any
}

//vps cards compoments that contain each vps card and  handles the state of upgrade function of each vps and invoice info
class VpsCards extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      price: 0,
      hpc: props.period === 'DAY',
      planId: props.planId ? props.planId : 0,
      plansId: props.planId ? props.planId : 0,
      period: props.recurringTime ? props.recurringTime : '',
      activePlanNum: props.activePlanNum ? props.activePlanNum : 0,
      vpsName: '',
      cpuCores: '',
      primaryDisk: '',
      vpsPrice: 0,
      leftCredit: 0,
      selectedPrice: 0
    }
  }

  componentDidMount() {
    this.setState({ isWindows: this.props.vmArrayNamed.osName == 'win2012' })
    this.props.calculateLeftCredit({ id: this.props.id })
  }

  componentWillUnmount() {
    this.props.setVmproducts([], [])
  }

  /**
   * on Click of each button sets the vminfo invoice
   * period is the period time of the vps
   * and plan is each plan info object
   */
  vpsCardClick = (period: any, plan: any, time?: any) => {
    let index2 = switchTimeLang(period, 'index')
    this.props.setVpsPeriod(period)
    this.setState({
      vpsName: plan.name,
      vpsPrice: plan.jsonPrices3[index2],
      planId: plan.id,
      period: switchTimeLang(period, 'fa'),
      time2: period,
      activePlanNum: plan.name.match(/\d+/)[0] - 1,
      selectedPrice: plan.jsonPrices3[switchTimeLang(period, 'index')]
    })
  }

  // select temperory
  onSelectVps = () => {
    if (this.state.hpc)
      this.props.changeVmInfo({
        id: this.props.id,
        body: { id: this.props.id, cpuCores: this.state.cpuCores, ram: this.state.ram, primaryDisk: this.state.primaryDisk }
      })
    else this.props.changeVmBillPlan({ id: this.props.id, plan: this.state.planId, period: this.state.period })
  }
  calculatePrice = (cpu: any, ram: any, disk: any, isWindows?: any) => {
    let unitPrice = 1,
      normalDefault = isWindows ? 23 : 20
    cpu = parseFloat(cpu) ? parseFloat(cpu) - 4 : 0
    ram = parseFloat(ram) ? parseFloat(ram) - 8 : 0
    disk = parseFloat(disk) ? (isWindows ? parseFloat(disk) - 110 : parseFloat(disk) - 80) : 0
    let price

    if (this.props.os) {
      price = (+normalDefault + +cpu + +ram + +disk) * +unitPrice
    }
    price = price && price
    this.setState({ vpsPrice: price })
    return price ? price : 0
  }
  // select temperory
  onSelectHPCVps = (json: any) => {
    this.setState({
      hpc: true,
      cpuCores: parseFloat(json.cpu),
      ram: parseFloat(json.ram),
      primaryDisk: parseFloat(json.disk)
    })
    this.calculatePrice(json.cpu, json.ram, json.disk)
  }

  render() {
    let { price, osName, leftCredit, hasActiveFooter, id } = this.props
    let { vpsPrice, vpsName, period, activePlanNum, time2, plansId } = this.state
    console.log(period)
    return (
      <div className={styles.cardWrapper}>
        <VmInfo
          hpc={this.state.hpc}
          update={true}
          isVpsCard={true}
          id={id}
          name={vpsName}
          vpsPrice={vpsPrice}
          leftCredit={leftCredit}
          period={switchTimeLang(period, 'en')}
          onSelectVps={this.onSelectVps}
        />
        {period === 'DAY' ? (
          <HPC noHeader={true} noFooter={true} onSelectHPCVps={this.onSelectHPCVps} isWindows={this.state.isWindows} />
        ) : (
          <VpsCard
            timed={true}
            time2={time2}
            osName={osName}
            planId={plansId}
            selectedPrice={price}
            activePlanNum={activePlanNum}
            hasActiveFooter={hasActiveFooter}
            period={switchTimeLang(period, 'en')}
            vpsCardClick={this.vpsCardClick}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  leftCredit: state.vm.leftCredit,
  vmArrayNamed: state.vm.vmArrayNamed[0],
  finalPrice: state.vm.finalPrice,
  os: state.vm.os[0]
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setVpsPeriod: (time: any) => dispatch(setVpsPeriod(time)),
    changeVmInfo: (value: any) => dispatch(changeVmInfo(value)),
    changeVmBillPlan: (value: any) => dispatch(changeVmBillPlan(value)),
    calculateLeftCredit: (vmId: number) => dispatch(calculateLeftCredit(vmId)),
    setVmproducts: (value: any, value2: any) => dispatch(setVmproducts(value, value2))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VpsCards)
