import React from 'react'
import { connect } from 'react-redux'
import ContentBody from '../ContentBody'
import { Modal } from '../../ui-elements/Modal/Modal'
import { t } from 'ttag'
import RenameFile from '../../ui-elements/Modal/ModalContent/RenameFile'
import ShareModal from '../../ui-elements/Modal/ShareModal.tsx/ShareModal'
import PreviewContent from './PreviewContent'
import CFModal from '../../ui-elements/Modal/CreateFolderModal/CreateFolder'
import { ToastUndo } from '../../ui-elements/Toast/ToastUndo/ToastUndo'
import toast from '../../../components/ui-elements/Toast/Toast'
import { setDocuments, removeFolder, setPreviewState } from '../../../services/internal/store/actions'
import { IRemoveFolderInput } from '../../../services/internal/repositories/documents'
import ReactDOM from 'react-dom'

class ModalContent extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      showModal: true,
      toRemove: [],
      removeArray: []
    }
  }

  // componentWillUnmount() {
  //   document.body.style.overflow = 'unset'
  // }
  handleClose = () => {
    if (this.props.history.location.pathname.includes('preview')) {
      let previousUrl = this.props.history.location.pathname && this.props.history.location.pathname.split('/preview')[0]
      this.props.history.push(previousUrl)
    }
    this.props.setPreviewState('false')
    this.props.closeModal()
  }
  escFunction=(event: any)=> {
    if (event.keyCode === 27) {
      if (this.props.history && this.props.history.location.pathname.includes('preview')) {
        let previousUrl = this.props.history.location.pathname && this.props.history.location.pathname.split('/preview')[0]
        this.props.history.push(previousUrl)
      }
      this.props.setPreviewState('false')
      this.props.closeModal()
    }
  }
  // remove modal
  openRemoveModal = (isSelected: number) => {
    let removeItem = this.props.document.documents.filter((obj: any, index: number) => {
      return obj.id == isSelected
    })[0]

    let removedIndex = this.props.document.documents.indexOf(removeItem)
    this.setState({
      toRemove: [...this.state.toRemove, isSelected],
      removeItem,
      removedIndex
    })

    let documents = this.props.document.documents.filter((i: any) => i.id !== isSelected)
    this.props.setDocuments(documents)
    toast.success(<ToastUndo undo={this.undo} id={isSelected} />, {
      onClose: this.cleanCollection
    })
  }

  undo = (id: any) => {
    let documents = this.props.document.documents
    this.setState({
      toRemove: this.state.toRemove.filter((v: any) => v !== id)
    })

    documents.splice(this.state.removedIndex, 0, this.state.removeItem)
    this.props.setDocuments(documents)
  }

  cleanCollection = () => {
    this.onRemoveDocument()
    this.setState({
      toRemove: []
    })
  }

  onRemoveDocument = () => {
    let tableData = this.props.tableData
    if (this.state.toRemove.length > 0)
      try {
        let result = this.props.removeFolder({ folderId: this.state.toRemove })
        tableData = tableData.filter((x: any) => x.id !== result.payload.folderId)
        this.props.updateTable({ tableData })
      } catch (error) {
        //console.log('E: ', error)
      }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
    if (this.props.modalView == t`Delete File`) {
      this.openRemoveModal(this.props.item.id)
    }
  }

  public render() {
    let modal
    //console.log(this.props.modalView)
    switch (this.props.modalView) {
      case t`Change Name`:
        modal = <RenameFile handleClose={this.handleClose} showModal={true} tableData={this.props.tableData} updateTable={this.props.updateTable} />
        break
      case t`Share File`:
        modal = <ShareModal handleCFClose={this.handleClose} showModal={true} />
        break
      case 'createFolder':
        modal = <CFModal handleCFClose={this.handleClose} showModal={true} />
        break
      case 'previewModal':
        modal = <PreviewContent handleClose={this.handleClose} showModal={true} history={this.props.history} />
        break
      default:
        modal = <></>
    }
    return modal
  }
}

const mapStateToProps = (state: any) => ({ document: state.document, item: state.sidebar.item })

const mapDispatchToProps = (dispatch: any) => {
  return {
    setDocuments: (value: any) => dispatch(setDocuments(value)),
    setPreviewState: (value: any) => dispatch(setPreviewState(value)),
    removeFolder: (value: IRemoveFolderInput) => dispatch(removeFolder(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContent)
