import * as React from 'react'
import { connect } from 'react-redux'
import { Button } from '../ui-elements/Button/Button'
import { t } from 'ttag'
import { Tooltip } from '../ui-elements/Tooltip/Tooltip'
import { formatPrice } from '../../services/internal/utils/formatPrice'
import { Icon } from '../ui-elements/Icon/Icon'
import checked from '../../images/Icons/commonIcons/checked.svg'
export const translateName = (name: string, title?: string) => {
  let translate
  if (localStorage.getItem('__language') == 'en') return name
  switch (name) {
    case 'FREE':
      translate = 'رایگان'
      break
    case 'Pro':
      translate = 'حرفه ای'
      break
    case 'Premium':
      translate = 'ویژه'
      break
    default:
      translate = title
      break
  }
  return translate
}

export const Plan: React.FunctionComponent<any> = props => {
  let product = props.info
  let buttonText = t`Plan is active`
  if (!props.active)
    switch (product.id) {
      case 3:
        buttonText = t`Get Plan`
        break
      case 4:
        buttonText = t`Upgrade to Premium`
        break
      case 5:
        buttonText = t`Upgrade to Pro`
        break
    }
  if (props.buttonText) buttonText = props.buttonText

  let style
  if (props.index == 1)
    style = {
      backgroundImage: 'linear-gradient(to bottom, #ffffff, #eff4fb)'
    }
  let jsonInfo = props.planType && props.planType.plan && props.planType.plan.jsonInfo && JSON.parse(props.planType.plan.jsonInfo)
  let planDuration = jsonInfo && jsonInfo.plan_type
  let isActive, isDisable
  if ((planDuration === 'MONTH' && props.monthly) || (planDuration === 'YEAR' && !props.monthly)) {
    isDisable = true
    isActive = true
  } else if (planDuration === 'MONTH' && !props.monthly) {
    isDisable = false
    isActive = false
  } else if (planDuration === 'YEAR' && props.monthly) {
    isDisable = true
    buttonText = 'ارتقا به پلن'
    isActive = true
  } else if (planDuration === 'YEAR' && props.index == 0) {
    isDisable = true
    buttonText = 'پلن رایگان'
    isActive = false
  }
  // console.log(props.index)
  if (props.index == 0) {
    isDisable = true
    buttonText = 'پلن رایگان'
    isActive = true
  }
  if (props.currentPlanId > props.info.id) {
    isActive = true
    // isDisable = false
  }
  if (props.currentPlanId < props.info.id) {
    isDisable = false
    console.log(isActive,isDisable)
  }
  if (props.isFree && props.index !== 0) {
    isActive = true
    isDisable = false
  }
  return (
    <div className={`pg-flex pg-flex-col pg-bg-white pg-w-1/3 pg-p-8 laptop:pg-w-full mobile-max:pg-w-full tablet-max:pg-w-full `} style={style}>
      <div className={'pg-text-blue-500 pg-text-center   pg-text-base'}>{translateName(product.name, product.title)}</div>
      <div className={'pg-text-gray-900 pg-text-center pg-text-lg pg-p-2'}>
        {props.monthly ? (
          product.valuePriceMonthly ? (
            formatPrice(product.valuePriceMonthly)
          ) : (
            '0'
          )
        ) : product.valuePriceYearlyPure ? (
          <div className={'pg-flex'}>
            <span className={'pg-w-full'} style={{ color: '#e63c3c' }}>
              <div className={'diagonalLine'} />
              {formatPrice(product.valuePriceYearlyDiscount)}
            </span>
            <span className={'pg-w-full'}>{formatPrice(product.valuePriceYearlyPure)}</span>
          </div>
        ) : (
          '0'
        )}
      </div>
      <div className={'pg-text-gray-600 pg-text-center'}>{props.timeText ? props.timeText : props.monthly ? t`monthly` : t`yearly`}</div>
      <div className={'pg-text-gray-600 pg-text-center'}>
        <Button
          className={[
            props.active && isActive
              ? 'pg-btnDisabled'
              : isDisable && props.disable
              ? 'pg-btnDisabled'
              : isDisable
              ? 'pg-btnDisabled'
              : 'pg-btnSuccess',
            'pg-btnLg',
            'pg-mt-4',
            'pg-mb-10'
          ]}
          onClick={props.onClick}
          name={product.id}
        >
          {props.active && !isActive ? 'ارتقا به پلن' : buttonText}
        </Button>
      </div>

      {product.info.map((info: any, index: any) => {
        return (
          <div
            className={`pg-flex pg-justify-between pg-p-3 pg-text-xs pg-border-dashed  pg-border-gray-300 ${index !== +product.info.length - 1 &&
              'pg-border-b-2'}`}
          >
            <span>{info.label}</span> <span>{info.value}</span>
          </div>
        )
      })}
    </div>
  )
}
const mapStateToProps = (state: any) => ({ monthly: state.account.monthly, planType: state.account.info })
export default connect(mapStateToProps, null)(Plan)
