import { actionTypes } from '../actions/types'

export interface PayloadInterface {
  data?: object[]
}

export const initialState: any = {
  
}

const vmSettingReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
  
    default:
      return state
  }
}

export default vmSettingReducer
