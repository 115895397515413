import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// styles
import styles from './Footer.module.scss'

import { switchTimeLang } from '../../../services/internal/utils/switchTimeLang'
import { formatPrice } from '../../../services/internal/utils/formatPrice'
import { setFinalPrice } from '../../../services/internal/store/actions'

export interface Iprops {
  os?: string
  price?: string
  totalPrice?: string
  serviceDuration?: string
  disk?: string
  ram?: string
  cpu?: string
  finalPrice?: any
  discount?: string
  vmCard: any
  setFinalPrice?: any
}

class SidebarInfo extends React.Component<Iprops, any> {
  constructor(props: any) {
    super(props)
    let vsCard = this.props.vmCard.vsCard
    this.state = {
      cpu: vsCard && vsCard.cpu ? vsCard.cpu : '',
      vmInfoObj: [],
      ram: vsCard && vsCard.ram ? vsCard.ram : '',
      disc: vsCard && vsCard.disc ? vsCard.disc : '',
      price: 0,
      finalPrice: 0
    }
  }

  componentDidMount() {
    let vsCard = this.props.vmCard.vsCard
    if (this.props.os) {
      if (this.props.os == 'Windows Server 2012' && this.state.disc == '80 GB') {
        this.setState({ disc: 110 })
      }
    }

    if (vsCard) {
      let { ram, disc, cpu } = vsCard
      this.setState({
        cpu,
        ram,
        disc,
        priceX: this.props.finalPrice
      })

      const index = switchTimeLang(this.props.serviceDuration, 'index')
      let price = this.props.vmCard && this.props.vmCard.vsCard && this.props.vmCard.vsCard.jsonPrices3 && this.props.vmCard.vsCard.jsonPrices3[index]
      let totalCostNotax = price ? price : ''
      if (this.props.os == 'Windows Server 2012') {
        totalCostNotax = price ? price : ''
      }
      let tax = 1.09
      let priceCost: any = price ? formatPrice(price) : ''
      if (this.props.os === 'Windows Server 2012') {
        priceCost = price ? formatPrice(price) : ''
      }
      let diskSpace = this.props.vmCard.vsCard.disk || this.state.disc

      let vmInfoObj = [
        { name: t`OS`, value: this.props.os },
        { name: t`Service term`, value: switchTimeLang(this.props.serviceDuration, 'en') },
        { name: t`Processor`, value: this.state.cpu },
        { name: t`Memory`, value: this.state.ram },
        {
          name: t`Disk space`,
          value: diskSpace
        },
        {
          name: t`Cost of service`,
          value: priceCost
        },
        { name: t`Discount site`, value: this.props.discount ? this.props.discount : '-' },
        {
          name: t`Total cost(+TAX)`,
          value: totalCostNotax && formatPrice(totalCostNotax * tax)
        }
      ]
      this.setState({ vmInfoObj })

      if (totalCostNotax && this.props.finalPrice !== formatPrice(totalCostNotax * tax))
        this.props.setFinalPrice(totalCostNotax && formatPrice(totalCostNotax * tax))
      else if (this.props.finalPrice && !totalCostNotax) this.props.setFinalPrice(0)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    let vsCard = nextProps.vmCard.vsCard
    if (nextProps.os) {
      if (nextProps.os == 'Windows Server 2012' && this.state.disc == '80 GB') {
        this.setState({ disc: 110 })
      }
    }

    if (vsCard) {
      let { ram, disc, cpu } = vsCard
      this.setState({
        cpu,
        ram,
        disc,
        priceX: nextProps.finalPrice
      })

      const index = switchTimeLang(nextProps.serviceDuration, 'index')
      let price = nextProps.vmCard && nextProps.vmCard.vsCard && nextProps.vmCard.vsCard.jsonPrices3 && nextProps.vmCard.vsCard.jsonPrices3[index]
      let totalCostNotax = price ? price : ''
      if (nextProps.os == 'Windows Server 2012') {
        totalCostNotax = price ? price : ''
      }
      let tax = 1.09
      let priceCost: any = price ? formatPrice(price) : ''
      if (nextProps.os === 'Windows Server 2012') {
        priceCost = price ? formatPrice(price) : ''
      }
      let diskSpace = nextProps.vmCard.vsCard.disk || this.state.disc

      let vmInfoObj = [
        { name: t`OS`, value: nextProps.os },
        { name: t`Service term`, value: switchTimeLang(nextProps.serviceDuration, 'en') },
        { name: t`Processor`, value: this.state.cpu },
        { name: t`Memory`, value: this.state.ram },
        {
          name: t`Disk space`,
          value: diskSpace
        },
        {
          name: t`Cost of service`,
          value: priceCost
        },
        { name: t`Discount site`, value: nextProps.discount ? nextProps.discount : '-' },
        {
          name: t`Total cost(+TAX)`,
          value: totalCostNotax && formatPrice(totalCostNotax * tax)
        }
      ]
      this.setState({ vmInfoObj })

      if (totalCostNotax && nextProps.finalPrice !== formatPrice(totalCostNotax * tax) && nextProps.serviceDuration !== 'DAY')
        this.props.setFinalPrice(totalCostNotax && formatPrice(totalCostNotax * tax))
      else if (nextProps.finalPrice && !totalCostNotax && nextProps.serviceDuration !== 'DAY') this.props.setFinalPrice(0)
    }
  }

  render() {
    let { vmInfoObj } = this.state
    return (
      <div>
        <div className={styles.info}>
          <div className={styles.item}>
            <ul className={'pg-border pg-border-gray-300'}>
              {vmInfoObj.map((each: any, index: number) => (
                <li className={'pg-flex pg-flex-col'} key={index}>
                  <span className={' pg-w-full pg-bg-gray-300 pg-p-1 pg-text-center'}> {each.name}</span>
                  <span className={'pg-p-1 pg-text-gray-800 pg-w-full pg-text-center'} style={{ height: '30px', direction: 'ltr' }}>
                    {each.value}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  vmCard: state.vm,
  finalPrice: state.vm.finalPrice,
  os: state.vm.os[0],
  serviceDuration: state.vm.serviceDuration
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFinalPrice: (value: any) => dispatch(setFinalPrice(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarInfo)
