import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

// ui-elements
import { Button } from '../../../ui-elements/Button/Button'
import { IconLink } from '../../../ui-elements/Icon/IconLink'
import { ClipBoard } from '../../../ui-elements/Clipboard/Clipboard'
import { ButtonGroup } from '../../../ui-elements/Button/ButtonGroup'

// icons
import uploadIcon from '../../../images/Icons/commonIcons/upload.svg'

// internal components & styles
import '../../Sidebar.scss'

//to be added
import { Comment } from '../../../Comment/Comment'
import { CommentInput } from '../../../Comment/CommentInput'
import { IGenerateLinkInput } from '../../../../services/internal/repositories/documents'
import { generateDownloadLink, setPreviewImage } from '../../../../services/internal/store/actions'
import { DownloadBar } from './DownloadBar'

export interface Iprops {
  onItemClick?: (e: any) => void
  item?: any
  image?: any
  generateDownloadLink?: any
  downloadLoading?: boolean
  setPreviewImage?: any
  history?: any
  downloadToken?: string
}

const PreviewSideBarImage: React.FunctionComponent<Iprops> = ({ setPreviewImage, item, image, history, downloadLoading, generateDownloadLink }) => {
  let imageUrl
  if (image) imageUrl = `/${image}`
  return (
    <div className="sidebar-menu">
      <DownloadBar imageUrl={imageUrl} item={item} downloadLoading={downloadLoading} generateDownloadLink={generateDownloadLink} />
      <div className="pg-mb-46p">
        <ButtonGroup
          list={[
            { label: t`Main Size`, active: !image, onClick: () => setPreviewImage('', history) },
            { label: t`Large`, active: image == 'large', onClick: () => setPreviewImage('large', history) },
            { label: t`Medium`, active: image == 'medium', onClick: () => setPreviewImage('medium', history) },
            { label: t`Small`, active: image == 'small', onClick: () => setPreviewImage('small', history) }
          ]}
        />
      </div>
      <div>
        {/* <CommentInput /> */}
        {/* <Comment user={'shaghz'} comment={'12/2/32'} details={'عکس بسیار قشنگی است. لطفا این قبیل کارها را ادامه بده'}/> */}
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  item: state.sidebar.item,
  history: state.history.history,
  image: state.selection.image,
  downloadLoading: state.loading.downloadLoading,
  downloadToken: state.sidebar.downloadToken
})
const mapDispatchToProps = (dispatch: any) => {
  return {
    generateDownloadLink: (value: IGenerateLinkInput) => dispatch(generateDownloadLink(value)),
    setPreviewImage: (value: any, history: any) => dispatch(setPreviewImage(value, history))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSideBarImage)
