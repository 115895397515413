import * as React from 'react'

// ui-elements
import { Icon } from '../Icon/Icon'

// icon
import avatarIcon from '../../../images/Icons/navbarIcons/avatar.svg'

export interface Iprops {
  profileImg?: string
}

export const Avatar = ({ profileImg }: Iprops) => (
  <Icon src={profileImg ? profileImg : avatarIcon} alt="Avatar Image" className="pg-rounded-full tablet-max:pg-w-34p tablet-max:pg-h-34p" />
)
