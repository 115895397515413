import * as React from 'react'
import { t } from 'ttag'

// ui-elements

// styles & icons
import profile from '../../../images/Icons/profileIcons/profile.svg'
import signOut from '../../../images/Icons/commonIcons/signout.svg'
import bill from '../../../images/Icons/navbarIcons/bill.svg'
import wallet from '../../../images/Icons/navbarIcons/wallet.svg'
import styles from '../NavbarProfileList/NavbarProfileList.module.scss'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IconLink } from '../../ui-elements/Icon/IconLink'

export interface Iprops {
  balance?: any
}

export const NavbarBillingList: React.FunctionComponent<Iprops> = ({ balance }) => {
  return (
    <>
   
      <li className={`pg-p-2 ${styles.item}`}>
        <Link to={'/nwbilling/nwvms'}>
          <IconLink icon={bill} iconAlt="profile" label={t`Invoices`} iconWidth={20} linkStyle={{ marginRight: '5px', fontSize: '12px' }} />
        </Link>
      </li>
      <li className={`pg-p-2 pg-text-xs ${styles.item}`}>
      <Link to={'/nwaccount/wallet'}>
        <IconLink icon={wallet} iconWidth={20}  iconAlt="logout" label={t`Wallet`} />
        </Link>
      </li>
    </>
  )
}

