import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { setSidebarState } from '../../../services/internal/store/actions'
import { OCP } from '../../../components/Monitoring/OCP'
import { ViewBar } from '../../../components/Monitoring/ViewBar/ViewBar'
import { DataChart } from '../../../components/Monitoring/DataChart/DataChart'

interface Iprops {
  getUserInfo: () => void
  getSettingState: () => void
  removeMessages: () => void
  setHistory: (e: any) => void
  messages?: any
  open: boolean
  history: any
  previewState?: boolean
  language?: any
  isVmDisabled?: boolean
  isUploadDisabled?: boolean
}
interface Istate {
  isOpenMenu: boolean
}

class HypePage extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      isOpenMenu: false,
      view: 'DISK',
      data:[1,2,3]
    }
  }

  componentDidMount() {
    this.props.setSidebarState(true)
  }
  changeView = (view: any) => {
    this.setState({ view })
  }
  componentWillUnmount() {
    this.props.setSidebarState(false)
  }
  render() {
    return (
      <div dir={'ltr'}>
        <div className={'pg-bg-white pg-shadow pg-w-full pg-p-4'}>
          <OCP />
        </div>
        <div className={'pg-flex'}>
          <ViewBar view={this.state.view} changeView={this.changeView} />
          <div className={'pg-w-full pg-flex pg-flex-col pg-mt-1'}> 
          {this.state.data.map((dat:any,index:any)=>
            <DataChart index={index}/>
          )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({})
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSidebarState: (value: any) => dispatch(setSidebarState(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HypePage)
