import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { TextInput } from '../../Input/Input'
import { Button } from '../../Button/Button'

import styles from '../CreateFolderModal/CreateFolder.module.scss'

//services
import { urlUpload } from '../../../../services/internal/store/actions/documents'
import { FMModal } from '../FMModal/FMModal'

export interface Iprops {
  showModal?: boolean
  handleCFClose?: () => void
  createFolder?: any
  getDocuments?: () => void
  document?: any
  item?: any
  parentId?: number
  urlUpload?: any
  loading?: boolean
  changeSharingStatus?: any
}

export interface Istate {
  name?: string
  description?: string
  message?: string
  userEmail?: string
  selected?: string
  shareLink?: string
  urlInput?: string
}

class UrlUploadModal extends React.Component<Iprops, Istate> {
  state = {
    urlInput: ''
  }
  textInput: any

  constructor(props: any) {
    super(props)
    this.textInput = React.createRef()
    this.focus = this.focus.bind(this)
  }
  focus() {
    this.textInput.current.focus()
  }

  componentDidMount() {
    this.focus()
  }
  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  urlUpload = (e: any) => {
    e.preventDefault()
    this.props.urlUpload({ url: this.state.urlInput, parentId: this.props.parentId })
  }
  render() {
    const { showModal, handleCFClose, item } = this.props

    return (
      <FMModal
        show={showModal}
        width={640}
        handleClose={handleCFClose}
        title={t`Upload from Url`}
        formDescription={t`For Upload Enter the url in the box bellow:`}
      >
        <div className={styles.row}>
          <TextInput style={{ width: 300 }} name={'urlInput'} type={'email'} onChange={this.handleChange} wrapperRef={this.textInput} />
          <Button loading={this.props.loading} className={['pg-btnPrimary', 'pg-btnSm']} onClick={this.urlUpload}>{t`Upload`}</Button>
        </div>
      </FMModal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  document: state.document.documents,
  item: state.sidebar.item,
  parentId: state.document.parentId,
  loading: state.loading.buttonLoading
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    urlUpload: (value: any) => dispatch(urlUpload(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlUploadModal)
