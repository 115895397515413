import * as React from 'react'
import { t } from 'ttag'

import { Button } from '../ui-elements/Button/Button'
import { IconLink } from '../ui-elements/Icon/IconLink'
import toast from '../../components/ui-elements/Toast/Toast'
import { ToastUndo } from '../ui-elements/Toast/ToastUndo/ToastUndo'
import styles from './delete.module.scss'


export class UndoTimed extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      collection: props.documents ? props.documents : [],
      toRemove: [],
      dragOver: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.documents.length > 0) {
      this.setState({ collection: nextProps.documents })
    }
  }

  // Remove the row id from the buffer
  undo = (id: any) => {
    let { collection } = this.state
    let documents = collection.push(this.state.toRemove[0])
    this.props.setDocuments(collection)
    this.setState({
      toRemove: []
    })
  }

  // Remove definetly
  cleanCollection = () => {
    this.setState({
      collection: this.state.collection.filter((v: any) => {
        return !this.state.toRemove.includes(v.id)
      }),
      toRemove: []
    })
  }

  onRemoveDocument = () => {
    let { collection } = this.state
    let tableData = collection
    let result: any
    //console.log(this.state.toRemove)
    if (this.state.toRemove.length > 0) {
      result = this.props.removeFolder({ folderId: this.props.selection })
      let selection = this.props.selection
      tableData = tableData.filter((x: any, index: number) => {
        return x.id !== result.payload.folderId[index]
      })
      tableData = tableData.reduce(function(prev: any, value: any) {
        var isDuplicate = false
        for (var i = 0; i < selection.length; i++) {
          if (value.id == selection[i]) {
            isDuplicate = true
            break
          }
        }

        if (!isDuplicate) {
          prev.push(value)
        }

        return prev
      }, [])
      //console.log(table)
      this.props.setDocuments(tableData)
      this.setState({ showRemove: false })
    }
  }

  removeRow = (e?: any) => {
    let { collection } = this.state
    let removeItem = collection.filter((obj: any, index: number) => {
      return this.props.selection.includes(obj.id)
    })

    let removedIndex = collection.indexOf(removeItem[0])
    //console.log(removeItem)
    this.setState({
      toRemove: [...this.state.toRemove, ...removeItem],
      removeItem,
      removedIndex
    })

    let documents = collection.filter((i: any, index: number) => !this.props.selection.includes(i.id))
    this.props.setDocuments(documents)
    toast.success(<ToastUndo undo={this.undo} id={this.props.selection} msg={`حذف شد`} />, {
      onClose: this.onRemoveDocument
    })
  }

  drop = (ev: any) => {
    ev.preventDefault()
    if (this.state.dragOver) {
      this.setState({ dragOver: false })
    }
    this.removeRow()
  }

  dragLeaveHandler = () => {
    if (this.state.dragOver) {
      this.setState({ dragOver: false })
    }
  }

  allowDrop = (ev: any) => {
    ev.stopPropagation()
    ev.preventDefault()
    ev.dataTransfer.dropEffect = 'copy'
    let isFile: any
    if (ev.dataTransfer.types) {
      for (var i = 0; i < ev.dataTransfer.types.length; i++) {
        if (ev.dataTransfer.types[i] == 'Files') {
          isFile = true
        }
      }
    }

    if (!isFile) {
      this.setState({ dragOver: true })
    }
  }

  render() {
    let { disabled, onItemClick, btnIcon, label, button, selection } = this.props
    return (
      <>
        {button ? (
          <div
            className={this.state.dragOver ? styles.allowDrop : ''}
            onDrop={(event: any) => {
              this.drop(event)
            }}
            style={{ height: '100%' }}
            onDragOver={(event: any) => this.allowDrop(event)}
            onDragLeave={() => this.dragLeaveHandler()}
          >
            <div onClick={selection.length > 0 ? this.removeRow : () => toast.error(t`Please choose a file`)}>{button}</div>
          </div>
        ) : (
          <div className={this.state.dragOver ? styles.allowDrop : ''}>
            <Button className={[disabled ? 'pg-btnSuccess' : 'pg-btnDisabled', 'pg-btnLg']} style={{ marginBottom: '15px' }}>
              <IconLink onClick={onItemClick} icon={btnIcon} iconAlt="upload icon" label={label} />
            </Button>
          </div>
        )}
      </>
    )
  }
}
