import React from 'react'
import { t } from 'ttag'

// components
import { VMContentHeader } from './VMContentHeader'
import { VMContentBody } from './VMContentBody'

export interface Iprops {
  history?: any
  location?: any
  isDisabled?: boolean
}
export const VMContent: React.FunctionComponent<Iprops> = ({ location ,isDisabled}) => {
  const history = [{ title: t`List of servers`, link: '/nwvms', active: false }]
  if (location && location.pathname !== '/nwvms') history.push({ title: location.pathname.split('/'), link: location.pathname, active: true })
  return (
    <React.Fragment>
      <VMContentHeader history={history} />
      <VMContentBody isDisabled={isDisabled} />
    </React.Fragment>
  )
}

export default VMContent
