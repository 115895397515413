import React, { Component } from 'react'
import styles from './Card.module.scss'
import { DropTarget } from 'react-dnd'

const Card: React.FunctionComponent<any> = ({ dropTarget, canDrop, isOver, isDragging, selection, children }) => {
  return dropTarget(
    <div
      className={
        selection.length > 0 && canDrop && isOver && !isDragging
          ? [styles.item, styles.isDropping].join(' ')
          : selection.length > 0 && canDrop && !isDragging
          ? [styles.item, styles.droppableRow].join(' ')
          : isDragging
          ? [styles.item, styles.draggingRow].join(' ')
          : styles.item
      }
    >
      {children}
    </div>
  )
}
const collect = (connect: any, monitor: any) => ({
  dropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
  isOver: monitor.isOver()
})
const cardTarget = {
  canDrop({ item }: any, monitor: any) {
    return item.type === 'folder'
  },

  drop({ item, selection, moveDocuments, documents }: any, monitor: any) {
    if (item.type === 'folder') {
      moveDocuments({ targetId: item.id, documentIds: selection, documents: documents })
    }
  }
}

export const TRDND = 'card'
export default DropTarget(TRDND, cardTarget, collect)(Card)
