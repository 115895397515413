// TODO: if has notification add hasNotif classname
// TODO: current page has current classname

import { NavLink, Link, Switch, Route } from 'react-router-dom'
import * as React from 'react'
import { t } from 'ttag'

// ui-elements
import { IconLink } from '../ui-elements/Icon/IconLink'
import { Avatar } from '../ui-elements/Avatar/Avatar'
import { Icon } from '../ui-elements/Icon/Icon'
import { NavbarDropDown } from './DropDown/NavbarDropDown'
import Signout from './NavbarProfileList/NavbarProfileList'

// icons & styles
import fileCloudIcon from '../../images/Icons/navbarIcons/file-cloud.svg'
import vpsIcon2 from '../../images/Icons/navbarIcons/icon-vps.svg'
import fileCloudIcon2 from '../../images/Icons/navbarIcons/icon-file-cloud.svg'
import financeIcon from '../../images/Icons/navbarIcons/finance.svg'
import statusIcon from '../../images/Icons/navbarIcons/status.svg'
import hostingIcon from '../../images/Icons/navbarIcons/internet.svg'
import ticketIcon from '../../images/Icons/navbarIcons/finance-copy.svg'
import vpsIcon from '../../images/Icons/navbarIcons/vps.svg'
import telegramBanner from '../../images/Icons/navbarIcons/telegramBanner.svg'
import logo from '../../images/Icons/commonIcons/pg-logo.svg'
import menu from '../../images/Icons/commonIcons/hmenu.svg'
import banner from '../../images/banner.svg'
import './Navbar.scss'
import Status from '../Status/Status'
import NavbarProfileList from './NavbarProfileList/NavbarProfileList'
import { NavbarBillingList } from './NavbarBillingList/NavbarBillingList'
import { connect } from 'react-redux'
import { formatPrice } from '../../services/internal/utils/formatPrice'
import { useRef } from 'react'
import notifications from '@storybook/api/dist/modules/notifications'
import OutsideCloser from './DropDown/OutsideCloser'

interface Iprops {
  toggleHamburgerMenu?: () => void
  toggle?: any
  open?: boolean
  username?: string
  notificationCount?: number
  balance?: any
  previewState?: boolean
  isOpenStatus?: boolean
}

const Navbar: React.FunctionComponent<Iprops> = ({
  balance,
  isOpenStatus,
  toggleHamburgerMenu,
  username,
  notificationCount,
  previewState
}: Iprops) => {
  const altIcon = 'Icon'
  const user = localStorage.getItem('user') || username
  const [toggleState, setToggleState] = React.useState(false)
  const [statusState, setStatusState] = React.useState('')

  function toggle(status: string) {
    if (statusState == status || !statusState) setToggleState(toggleState === false ? true : false)
    setStatusState(status)
  }
  // console.log(previewState)
  return (
    <nav
      className={`flex-row-wrap-withspace
      tablet-max:pg-h-RSheader
      tablet-max:pg-pr-15p
      tablet-max:pg-pl-15p
      tablet-max:pg-pt-0
      tablet-max:pg-pb-0
     ${previewState == true ? 'displayNone' : 'displayYes'}
      pg-shadow-header
      fixed-on-top
      pg-h-header
      pg-bg-white
      pg-z-1000
      navbar`}
    >
      <div className="pg-flex pg-flex-row pg-flex-wrap right">
        {!window.location.pathname.includes('nwlandings/vm-campaign/choose-plan') && (
          <a
            onClick={e => {
              e.preventDefault()
              toggleHamburgerMenu && toggleHamburgerMenu()
            }}
            className="pg-hidden
          tablet-max:pg-flex
          pg-cursor-pointer
          pg-items-center"
          >
            <Icon src={menu} className="pg-ml-6p menu" alt={`menu ${altIcon}`} />
          </a>
        )}
        <a
          href="https://www.persiangig.com/"
          className="flex-center
          pg-w-sidebar
          tablet-max:pg-w-auto
          laptop:pg-w-140p"
        >
          <Icon src={logo} className="logo" alt="pg-logo" width={58} />
        </a>
        <div
          className="pg-flex hover:pg-text-pink-500 
          tablet-max:pg-hidden
          pg-flex-wrap
          pg-flex-row
          pg-p-0
          pg-mt-23p
          pg-mb-23p
          pg-mr-0
          pg-ml-0
          nav"
        >
          {/* <NavLink to={`/nwfm`} className="pg-ml-30p item" activeClassName="current">
            <IconLink icon={fileCloudIcon} iconAlt={`File-cloud ${altIcon}`} iconWidth={24} label={t`File hosting`} />
          </NavLink> */}
          <NavLink to={`/nwvms`} className="pg-ml-30p item" activeClassName="current">
            <IconLink icon={vpsIcon} iconAlt={`vps ${altIcon}`} iconWidth={24} label={t`Virtual Server`} />
          </NavLink>
          {/* <NavLink to={`/nwhosting`} className="pg-ml-30p item" activeClassName="current">
            <IconLink icon={hostingIcon} iconAlt={`vps ${altIcon}`} iconWidth={24} label={t`Hosting`} />
          </NavLink> */}
          <a className="pg-ml-30p item" href="http://us.cdn.persiangig.com/vm/support">
            <IconLink icon={ticketIcon} iconAlt={`vps ${altIcon}`} iconWidth={24} label={t`Support`} />
          </a>
          <a className="item" href="https://t.me/PersianGig_Cloud">
            <IconLink icon={banner} iconAlt={`vps ${altIcon}`} iconWidth={160} />
          </a>
          
<div className="pg-text-gray-600">
  </div>

<Switch>
            {/* <Route
              path={`/nwfm`}
              render={() => (
                <a className="pg-ml-30p item" href="http://cdn.persiangig.com/fm/cfs">
                  <IconLink icon={fileCloudIcon} iconAlt={`vps ${altIcon}`} iconWidth={24} label={t`Old Panel`} />
                </a>
              )}
            /> */}
		{/*
            <Route
              path={`/nwvms`}
              render={() => (
                <a className="pg-ml-30p item" href=" http://us.cdn.persiangig.com/vm/">
                  <IconLink icon={vpsIcon} iconAlt={`vps ${altIcon}`} iconWidth={24} label={t`Old Panel`} />
                </a>
              )}
            />
		*/}
		{/*
            <Route
              path={`/nwbilling`}
              render={() => (
                <a className="pg-ml-30p item" href=" http://us.cdn.persiangig.com/billings/">
                  <IconLink icon={financeIcon} iconAlt={`vps ${altIcon}`} iconWidth={24} label={t`Old Panel`} />
                </a>
              )}
            /> */}
		{/*
            <Route
              render={() => (
                <a className="pg-ml-30p item" href=" http://us.cdn.persiangig.com/fm/cfs/">
                  <IconLink icon={financeIcon} iconAlt={`vps ${altIcon}`} iconWidth={24} label={t`Old Panel`} />
                </a>
              )}
            />
		*/}
          </Switch>
          {/* {location.pathname.includes('nwfm') && (
            <a className="pg-ml-30p item" hrefc="http://cdn.persiangig.com/fm/cfs">
              <IconLink icon={fileCloudIcon} iconAlt={`vps ${altIcon}`} iconWidth={24} label={t`Old Panel`} />
            </a>
          )}
          {location.pathname.includes('nwvms') && (
            <a className="pg-ml-30p item" href=" http://us.cdn.persiangig.com/vm/">
              <IconLink icon={vpsIcon} iconAlt={`vps ${altIcon}`} iconWidth={24} label={t`Old Panel`} />
            </a>
          )} */}

          {/* <NavLink to={`#`} className="pg-ml-30p item" activeClassName="current">
            <IconLink icon={internetIcon} iconAlt={`Internet ${altIcon}`} iconWidth={24} label={t`Internet`} />
          </NavLink> */}
        </div>
      </div>
      {localStorage.getItem('csrf_token') && (
        <div
          className="pg-flex
        pg-items-center
        pg-ml-30p
        tablet-max:pg-ml-0 
        left"
        >
          <div
            className="
          
          pg-flex
          pg-flex-row
          pg-flex-wrap
          nav"
          >
            <OutsideCloser toggle={setToggleState} toggleState={toggleState && statusState == 'billing'} id={'navbar'}>
              <NavbarDropDown
                toggle={() => toggle('billing')}
                // onHover={() => toggle('billing')}
                header={
                  <div className={`pg-flex pg-justify-between`}>
                    <div>{t`Your credit`}:</div>
                    <div className={'pg-text-gray-700'}>{balance ? formatPrice(balance) : formatPrice(0)}</div>
                  </div>
                }
                iconLink={
                  <IconLink
                    icon={financeIcon}
                    className="iconLink
          
            pg-ml-23p"
                    iconAlt={`Finance ${altIcon}`}
                    iconWidth={24}
                  />
                }
                open={toggleState && statusState == 'billing'}
              >
                <NavbarBillingList />
              </NavbarDropDown>
            </OutsideCloser>
            <OutsideCloser toggle={toggle} toggleState={toggleState && statusState == 'service'} id={'navbar'}>
              <NavbarDropDown
                toggle={() => toggle('service')}
                header={<span>{t`Service Status`}</span>}
                iconLink={
                  <div className={'pg-relative'}>
                    <IconLink
                      icon={statusIcon}
                      className="iconLink
            pg-ml-23p
            pg-w-30p
            pg-h-30p
            pg-text-center
            pg-rounded-full
            tablet-max:pg-w-34p
            tablet-max:pg-h-34p
            tablet-max:pg-ml-10p
            status"
                      iconAlt={`Status ${altIcon}`}
                      iconWidth={24}
                    />
                    {/* {notificationCount && <span className={'notification'}>{notificationCount && '1'}</span>} */}
                  </div>
                }
                width={'348px'}
                open={toggleState && statusState == 'service'}
              >
                <Status open={isOpenStatus} icons={[fileCloudIcon2, vpsIcon2]} />
              </NavbarDropDown>
            </OutsideCloser>
            <OutsideCloser toggle={toggle} toggleState={toggleState && statusState == 'profile'} id={'navbar'}>
              <NavbarDropDown
                toggle={() => toggle('profile')}
                iconLink={<Avatar />}
                open={toggleState && statusState == 'profile'}
                header={<span>{user ? user : 'unknown'}</span>}
              >
                <NavbarProfileList />
              </NavbarDropDown>
            </OutsideCloser>
          </div>
        </div>
      )}
    </nav>
  )
}
const mapStateToProps = (state: any) => ({
  balance: state.account.info.balance,
  username: state.account.info.username,
  notificationCount: state.account.expirationNotification
})
export default connect(mapStateToProps, null)(Navbar)
