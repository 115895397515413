import { combineReducers } from 'redux'
import loadingReducer from './loadingReducer'
import authReducer from './authReducer'
import documentReducer from './documentReducer'
import selectReducer from './selectReducer'
import routerReducer from './routerReducer'
import sidebarReducer from './sidebarReducer'
import historyReducer from './historyReducer'
import vmReducer from './vmReducer'
import messagesReducer from './messagesReducer'
import accountReducer from './accountReducer'
import vmSettingReducer from './vmSettingReducer';
import modalReducer from './modalReducer';
import hostReducer from './hostReducer';


export default combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  document: documentReducer,
  selection: selectReducer,
  modal: modalReducer,
  router: routerReducer,
  sidebar: sidebarReducer,
  history: historyReducer,
  vm: vmReducer,
  host: hostReducer,
  messages: messagesReducer,
  account: accountReducer,
  vmSettingReducer: vmSettingReducer
})
