import React from 'react'
import { connect } from 'react-redux'


import Dropdown from '../Dropdown/Dropdown'
import { TableDndHeader } from './TableHeader/TableHeader'
import { EnhanceDropdown as enhancer } from '../Dropdown/EnhanceDropdown'
import TableDndRow from './TableBody/TableRow'


import { Item } from './Table.interface'

import styles from './Table.module.scss'
import './drag.scss'

const EnhancedDropdown = enhancer(Dropdown)

const TableDnd: React.FunctionComponent<any> = ({
  tableData,
  hovered,
  snapshot,
  dropdown,
  setHoverState,
  moveDocuments,
  onCheckAll,
  width,
  renameText,
  showAll,
  editableId,
  checkAll,
  isMoveModal,
  onSort,
  setEditStatus,
  tabletView,
  onSelect,
  dropDownData,
  handleNavigate,
  optionSelected,
  checkbox,
  openModal,
  modalSelection,
  onCheck,
  invoiceClassName,
  handleOnRowClick,
  setDragnDropState,
  labelShow,
  smPadding = false,
  onOpenCFModal,
  handleChange,
  selectionNumber,
  documentsLength,
  selection,
  documents,
  moveRow,
  dragInfo,
  expandableRow,
  hasDnd,
  sortable,
  getInvoice,
  hasIcon,
  header,
  invoice,
  selectableRows = true,
  hasHeader = true
}) => {
  const dropDownStyle = {
    position: 'absolute',
    width: 138
  }

  return (
    <div className={'container-fluid'}>
      {hasHeader && (
        <TableDndHeader
          titles={header}
          dropdown={dropdown}
          sortable={sortable}
          documentsLength={documentsLength}
          selectionNumber={selectionNumber}
          labelShow={labelShow}
          editableId={editableId}
          selectableRows={selectableRows}
          selection={selection}
          showAll={showAll}
          onSort={onSort}
          tabletView={tabletView}
          onOpenCFModal={onOpenCFModal}
          {...(onCheckAll && { checkAll: checkAll, onCheckAll: onCheckAll })}
        />
      )}
      <div>
        {tableData &&
          tableData.map((item: Item, index: number) => (
            <TableDndRow
              showAll={showAll}
              setDragnDropState={setDragnDropState}
              moveRow={moveRow}
              item={item}
              documents={documents}
              moveDocuments={moveDocuments}
              expandableRow={expandableRow}
              key={index}
              setEditStatus={setEditStatus}
              id={item.id}
              hovered={hovered}
              setHoverState={setHoverState}
              labelShow={labelShow}
              getInvoice={getInvoice}
              modalSelection={modalSelection}
              renameText={renameText}
              editableId={editableId}
              snapshot={snapshot}
              index={index}
              invoice={invoice}
              indexNumber={index}
              selectableRows={selectableRows}
              hasIcon={hasIcon}
              isMoveModal={isMoveModal}
              selection={selection}
              handleChange={handleChange}
              checkbox={checkbox}
              handleNavigate={handleNavigate}
              onCheck={onCheck}
              hasDnd={hasDnd}
              smPadding={smPadding}
              tableData={tableData}
              invoiceClassName={invoiceClassName}
              width={width}
              openModal={openModal}
              handleOnRowClick={handleOnRowClick}
            >
              {!expandableRow && dropdown && (
                <div className={[styles.show, styles.left].join(' ')}>
                  <EnhancedDropdown
                    width={dropDownStyle.width}
                    optionSelected={optionSelected}
                    onSelect={onSelect}
                    fileType={item.discriminator}
                    position={dropDownStyle.position}
                    data={dropDownData && dropDownData}
                    id={item.id}
                  />
                </div>
              )}
            </TableDndRow>
          ))}
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  dragInfo: state.document.dragInfo,
  documents: state.document
})

export default connect(mapStateToProps, null)(TableDnd)
