import React from 'react'
import { t } from 'ttag'

import { Icon } from '../ui-elements/Icon/Icon'
import bigger from '../../images/Icons/commonIcons/bigger.svg'

import styles from './Breadcrumb.module.scss'

export default interface Iprops {
  history?: any
  modal?: boolean
  className?: string
  parentId?: number
  MainTitleArray?: any
  vm?: boolean
}

export const Breadcrumb: React.FunctionComponent<Iprops> = ({ history, children, modal, className }) => {
  let historyArray = [{ link: '/', name: t`Main Folder` }]
  if (history && history.link) {
    history.link.split('/').map((item: any, index: number) => {
      let slug = history.link.split(item).pop()
      if (index !== 0 && item) historyArray.push({ link: slug, name: item })
    })
  }

  return (
    <div className={` ${modal ? [styles.breadcrumb, styles.modalbread, className].join(' ') : styles.breadcrumb}`}>
      {children}
      {history &&
        historyArray.map((item: any, index: number) => (
          <>
            <span
              onClick={() => history.onClick(true, item.link, history.id)}
              className={index == historyArray.length - 1 ? [styles.active, styles.item].join(' ') : styles.item}
            >
              {item.name}
            </span>
            {index !== historyArray.length - 1 && <Icon src={bigger} />}
          </>
        ))}
    </div>
  )
}
