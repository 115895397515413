import * as React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import cloud from '../../images/cloud-computing.svg'
import {
  uploadServer,
  setUploader,
  setUploadFiles,
  setUploadProgress,
  setCancleToken,
  setIpCountry,
  getDocuments,
  setDocuments,
  setUploadCount
} from '../../services/internal/store/actions'
import toast from '../ui-elements/Toast/Toast'
import { throwError } from '../../services/internal/utils/sentry'
import Dropzone from 'react-dropzone'
import UploadingModal from '../ui-elements/Modal/UploadingModal/UploadingModal'
import { Icon } from '../ui-elements/Icon/Icon'

import styles from './Dropbox.module.scss'

class DropZoneContainer extends React.Component<any, any> {
  uploader: any
  constructor(props: any) {
    super(props)
    this.state = {
      files: [],
      counter: 0
    }
  }
  getGeoInfo = () => {
    axios
      .get('http://ip-api.com/json')
      .then(response => {
        let data = response.data
        this.props.setIpCountry(data.countryCode)
      })
      .catch(error => {
        console.log(error)
      })
  }

  addItem = (obj: any, item: any) => {
    let index = obj && obj.findIndex((x: any) => x.id == item.id)
    if (index === -1) {
      obj.push(item)
    } else {
      obj[index].percentCompleted = item.percentCompleted
    }
    return obj
  }

  upload = async (files: any) => {
    this.getGeoInfo()
    let CancelToken = axios.CancelToken
    let token = localStorage.getItem('csrf_token')
    let id = this.props.uploadCount
    let config = {
      headers: { token, Cookie: `token="${token}"` },
      cancelToken: new CancelToken((c: any) => {
        // An executor function receives a cancel function as a parameter
        this.props.setCancelToken(c)
      }),
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        let uploadProgressArray = this.props.uploadProgress
        uploadProgressArray = this.addItem(uploadProgressArray, { id, percentCompleted })
        this.props.setUploadProgress(uploadProgressArray)
      }
    }
    let documents = this.props.document.documents
    // console.log(documents)
    let body = new FormData()
    body.append('file', files)
    body.append('pathId', this.props.parentId)
    //hello world added to coces
    axios
      .post(`${process.env.REACT_APP_URL}:9234/nwuploads`, body, config)
      .then(res => {
        documents.unshift(res.data)
        this.props.setDocuments(documents)
        //  this.props.handleUploadClose()
      })
      .catch(err => {
        let { uploadFiles } = this.props
        if (err.response && err.response.data && err.response.data.errors[0].msg.includes('وجود دارد')) {
          uploadFiles = uploadFiles.filter((each: any) => each.name !== files.name)
          this.props.setUploadFiles(uploadFiles)
          if (uploadFiles.length == 0) this.props.handleClose()
        }
        err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors[0].msg &&
          toast.errorTop(err.response && err.response.data.errors[0].msg)
      })
  }
  onDrop = async (files: any) => {
    this.setState({ files })
    this.props.openUploadModal()
    this.setState({ files: [...this.props.uploadFiles, files[0]], counter: this.state.counter + 1 })
    this.props.setUploadCount(this.props.uploadCount + 1)
    let file = files[0]
    this.props.setUploadFiles([...this.props.uploadFiles, files[0]])
    try {
      await this.upload(file)
    } catch (error) {
      throwError(error)
    }
    if (this.props.dragged) {
      this.props.setDraggedFalse()
    }
  }

  render() {
    return (
      <>
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <section className="container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className={'pg-flex pg-flex-col pg-justify-center pg-items-center'}>
                  <div className={styles.imgAnimation}>
                    <Icon src={cloud} width={100} />
                  </div>
                  <p>فایل را به این کادر بکشید تا آپلود شروع شود</p>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        {this.state.files && <UploadingModal handleClose={this.props.handleClose} showModal={this.props.showModal} />}
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  uploadFiles: state.document.uploadFiles,
  uploader: state.document.uploader,
  document: state.document,
  uploadCount:state.document.uploadCount,
  parentId: state.document.parentId,
  uploadProgress: state.document.uploadProgress,
  cancelToken: state.account.cancelToken
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    uploadServer: (value: any) => dispatch(uploadServer(value)),
    setDocuments: (value: any) => dispatch(setDocuments(value)),
    setUploadCount: (value: any) => dispatch(setUploadCount(value)),
    setIpCountry: (value: any) => dispatch(setIpCountry(value)),
    setUploader: (value: any) => dispatch(setUploader(value)),
    setUploadFiles: (value: any) => dispatch(setUploadFiles(value)),
    setUploadProgress: (value: any) => dispatch(setUploadProgress(value)),
    setCancelToken: (value: any) => dispatch(setCancleToken(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropZoneContainer)
