import * as React from 'react'
import { connect } from 'react-redux'

import { t } from 'ttag'
import {
  getInvoices,
  setInvoiceItem,
  getWallet,
  getUserInfo,
  setSidebarState,
  getInvoice,
  removeInvoice,
  vmBillInvoices,
  hostInvoices,
  domainInvoices
} from '../../../services/internal/store/actions'

import { Table } from '../../../components/Table/Table'
import PayModal from '../../../components/ui-elements/Modal/PayModal/PayModal'

import { Loading } from '../../../components/loading/Loading'

import styles from './Billing.module.scss'

export interface Iprops {
  loading: boolean
  billing: any
  getBilling: () => void
  setInvoiceItem: any
  getWallet: any
  userInfo?: any
  changeUrl?: any
  invoiceItem: any
  getInvoice?: any
  wallet?: number
  invoice?: any
  removeInvoice?: any
  payInvoice?: any
  open?: boolean
  location?: any
}

export interface Istate {
  tableData: any[]
  showModal: boolean
  payableModal: boolean
  currentInvoiceItem: any[]
  billing: any[]
  itemPrice: any
}

class BillingTable extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      tableData: [],
      showModal: false,
      payableModal: false,
      currentInvoiceItem: [],
      billing: [],
      view: 'nwfm',
      itemPrice: 0
    }
  }

  componentDidMount = () => {
    let pathname = window.location.pathname
    console.log('hi dudedeee')
    if (pathname.includes('/nwfm')) {
      this.setState({ view: 'nwfm' })
      this.props.getFmInvoice()
    } else if (pathname.includes('/nwvms')) {
      this.setState({ view: 'nwvms' })
      this.props.getVmBilling()
    }
  }
  componentWillReceiveProps(nextProps: any) {
    let pathname = nextProps.match.path
    if (pathname.includes('/nwfm') && this.state.view !== 'nwfm') {
      this.setState({ view: 'nwfm' })
      this.props.getFmInvoice()
      this.props.removeInvoice()
    } else if (pathname.includes('/nwvms') && this.state.view !== 'nwvms') {
      this.setState({ view: 'nwvms' })
      this.props.getVmBilling()
      this.props.removeInvoice()
    }
    else if (pathname.includes('/hosting') && this.state.view !== 'hosting') {
      this.setState({ view: 'hosting' })
      this.props.getHostBilling()
      this.props.removeInvoice()
    }
    else if (pathname.includes('/domain') && this.state.view !== 'domain') {
      this.setState({ view: 'domain' })
      this.props.getDomainBilling()
      this.props.removeInvoice()
    }
  }
  renderInvoice = (e: any, index: number) => {
    this.props.getInvoice(e, index)
  }

  handleClose = () => {
    this.setState({ showModal: false })
  }
  render() {
    const header = [t`index`, t`Invoice Number`, t`Product`, t`Date`, t`Payable`, t`Status`]
    return (
      <div className={'pg-w-full'}>
        {this.props.loading ? (
          <Loading />
        ) : this.props.tableData.length > 0 ? (
          <Table
            getInvoice={this.props.getInvoice}
            invoice={this.props.invoice}
            header={header}
            selectableRows={false}
            invoiceClassName={styles.billing}
            dropdown={true}
            labelShow={['id', 'refCode', 'productName', 'created_at', 'payablePrice', 'invoiceStatus']}
            tableData={this.props.tableData}
            expandableRow={true}
            handleOnRowClick={this.renderInvoice}
          />
        ) : (
          <div className={'pg-flex  pg-mt-10 pg-text-center pg-justify-center pg-items-center'}>{t`No Invoices`}</div>
        )}
        <PayModal showModal={this.state.showModal} handleCFClose={this.handleClose} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getFmInvoice: () => dispatch(getInvoices()),
    getVmBilling: () => dispatch(vmBillInvoices()),
    getDomainBilling: () => dispatch(domainInvoices()),
    getHostBilling: () => dispatch(hostInvoices()),
    setInvoiceItem: (value: any) => dispatch(dispatch(setInvoiceItem(value))),
    getWallet: (value: any) => dispatch(getWallet(value)),
    setSidebarState: (value: any) => dispatch(setSidebarState(value)),
    getInvoice: (value: any, index: any) => dispatch(getInvoice(value, index)),
    getUserInfo: () => dispatch(getUserInfo()),
    removeInvoice: () => dispatch(removeInvoice())
  }
}
const mapStateToProps = (state: any) => ({
  loading: state.loading.isLoading,
  billing: state.account.billing,
  invoice: state.account.invoice,
  userInfo: state.account.info,
  billingItem: state.account.billingItem,
  vmInvoices: state.account.vmInvoices,
  giftWallet: state.account.wallet,
  invoiceItem: state.account.invoiceItem
})

export default connect(mapStateToProps, mapDispatchToProps)(BillingTable)
