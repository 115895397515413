import { put, call } from 'redux-saga/effects'
import * as actions from '../actions'
import { t } from 'ttag'

import { bottle } from '../../../index'

import { formatPrice } from '../../utils/formatPrice'
import { formatBytes } from '../../utils/formatBytes'
import { renderUserInfo, renderInfo } from '../../utils/account'
import { formatTime } from '../../utils/formatTime'
import { translate } from '../../utils/translate'
import { renderInvoice } from '../../utils/renderInvoice'
import { findProduct } from '../../utils/findProduct'
import { renderDomainInvoice } from '../../utils/renderDomainInvoice'

const account = bottle.container.Account
const catchError = bottle.container.CatchError

export function* getUserInfo() {
  try {
    let result = yield account.getUserInfo()

    let editedArray = renderUserInfo(result)
    yield put(actions.setUserProfile(editedArray))
    yield put(actions.setUserInfo(result))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}

export function* getSettingState() {
  try {
    let result = yield account.getSettingState()

    let currentUser = localStorage.getItem('user')
    let adminUsersResult = {
      disableCreateVm: {
        status: false,
        message: ''
      },
      disableUpload: {
        status: false,
        message: ''
      },
      disableUpgrade: {
        status: false,
        message: ''
      },
      disableExtend: {
        status: false,
        message: ''
      },
      networkName: '',
      generalMessage: '',
      allowedUsers: ['seyedmehdinaji', 'parisazadkhor2', 'fomidvari', 'gh.mgh89', 'yavand', 'shaghz']
    }

    adminUsersResult.networkName = result.networkName
    adminUsersResult.generalMessage = result.generalMessage

    if (result.allowedUsers && result.allowedUsers.includes(currentUser)) result = adminUsersResult
    yield put(
      actions.setSettingState({
        disableCreateVm: result.disableCreateVm,
        disableUpload: result.disableUpload,
        isUpgradeDisabled: result.disableUpgrade,
        isExtendDisabled: result.disableExtend,
        networkName: result.networkName,
        generalMessage: result.generalMessage
      })
    )
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}

export function* getTransactions() {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield account.getTransactions()
    let editedArray = renderInfo(result)
    yield put(actions.setTransactions(editedArray))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* getProducts() {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield account.getProducts()
    yield put(actions.setProducts(result))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* getWallet(action: any) {
  try {
    let result = yield account.getWallet(action.payload)
    yield put(actions.setWallet(result.value))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
  }
}

export function* changePlan(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield put(actions.setLoadingInvoiceState(true))
    let result = yield account.changePlan(action.id, action.additionalInfo, action.applyNow)

    if (result == 200) {
      let result2 = yield account.getInvoices('cfs')
      let invoice =
        result2 &&
        result2.map((each: any) => {
          if (each.product.id == action.id) return each
        })
      yield put(actions.setInvoiceItem(invoice[0]))
      yield put(actions.setLoadingInvoiceState(false))
    }

    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* changeProfile(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield account.changeProfile(action.payload)
    yield put(actions.setUserInfo(result))
    yield put(actions.setMessage(t`Info is saved`))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* getInvoices(action: any) {
  let product
  if (window.location.pathname.includes('nwfm')) product = 'cfs'
  else if (window.location.pathname.includes('nwvms')) product = 'cws'

  try {
    yield put(actions.setLoadingState(true))
    let result = yield account.getInvoices(product)
    yield put(actions.setInvoices([]))
    yield put(actions.setInvoices(result))
    yield put(actions.setInvoicesFm(result))

    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* vmBillInvoices(action: any) {
  let product
  if (window.location.pathname.includes('nwfm')) product = 'cfs'
  else if (window.location.pathname.includes('nwvms')) product = 'cws'
  try {
    yield put(actions.setLoadingState(true))
    let result = yield account.vmBillInvoices(product)
    yield put(actions.setInvoices([]))
    let array
    if (product !== 'cfs')
      array = result.map((each: any) => {
        let id = each.id
        let vmBillName = each.vmBill.name
        each.invoice.mainId = id
        each.invoice.vmBillName = vmBillName
        return each.invoice
      })
    let tableData: any = []
    array &&
      array.map((each: any, index: number) => {
        tableData.push({
          index: +index + 1,
          id: each.mainId && each.mainId,
          refCode: each.refCode,
          productName: each.vmBillName,
          created_at: each.persianCreatedAt,
          payablePrice: formatPrice(each.payablePrice),
          paid: each.paid,
          rawData: each
        })
      })
    yield put(actions.setInvoicesVm(result))

    yield put(actions.setVmInvoices(result))
    yield put(actions.setInvoicesArray(tableData))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* hostInvoices(action: any) {
  let product

  try {
    yield put(actions.setLoadingState(true))
    let result = yield account.hostInvoices()
    yield put(actions.setInvoices([]))
    let array
    if (product !== 'cfs')
      array = result.map((each: any) => {
        let id = each.id
        let vmBillName = each.host.domain.domainName
        each.invoice.mainId = id
        each.invoice.vmBillName = vmBillName
        return each.invoice
      })
    let tableData: any = []
    array.map((each: any, index: number) => {
      tableData.push({
        index: +index + 1,
        id: each.mainId && each.mainId,
        refCode: each.refCode,
        productName: each.vmBillName,
        created_at: each.persianCreatedAt,
        payablePrice: formatPrice(each.payablePrice),
        paid: each.paid,
        rawData: each
      })
    })
    yield put(actions.setHostInvoices(result))
    yield put(actions.setInvoicesHost(array))
    yield put(actions.setInvoicesArray(tableData))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* domainInvoices(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    let result = yield account.domainInvoices()
    yield put(actions.setInvoices([]))
    let { array, tableData } = renderDomainInvoice(result)
    yield put(actions.setDomainInvoices(result))
    yield put(actions.setInvoicesDomain(array))
    yield put(actions.setInvoicesArray(tableData))
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* getVmBill() {
  try {
    yield put(actions.setInvoiceLoadingState(true))
    let result = yield account.vmBillInvoices()
    yield put(actions.setInvoices(result))
    yield put(actions.setInvoiceLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}

export function* payInvoice(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    yield account.payInvoice(action.payload.body, action.payload.id, action.payload.product)
    if (window.location.pathname.includes('nwvms')) {
      let result2 = yield account.vmBillInvoices('cws')
      yield put(actions.setInvoices([]))
      yield put(actions.setVmInvoices(result2))
    }
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
export function* getInvoice(action: any) {
  try {
    yield put(actions.setInvoiceLoadingState(true, action.index))
    let product = findProduct()
    let invoice = yield account.getInvoice(action.payload, product)
    let array = renderInvoice(invoice, product)
    yield put(actions.setInvoice(array, action.index))
    yield put(actions.setInvoiceLoadingState(false, action.index))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setInvoiceLoadingState(false, action.index))
  }
}

export function* addToBalance(action: any) {
  try {
    yield put(actions.setLoadingState(true))
    let expireDate = new Date(new Date().setTime(new Date().getTime() + 2 * 24 * 60 * 60 * 1000))
    document.cookie = `token="${localStorage.getItem('csrf_token')}";Domain=.persiangig.com;expires=${expireDate};path=/;SameSite=None; Secure`
    let result = yield account.addToBalance(action.payload.price / 1000)
    if (result) {
      window.location.replace(`/cli/payment/request/${result.id}?redirect=us`)
      // let result2 = yield account.doPay({ id: result.id })
      // yield put(actions.setRef({ ref: result2 }))
    } else {
      yield put(actions.setLoadingState(false))
    }
    yield put(actions.setLoadingState(false))
  } catch (err) {
    catchError.throwError(err)
    yield put(actions.setError(err.errors && err.errors[0] && err.errors[0].msg ? err.errors[0].msg : err))
    yield put(actions.setLoadingState(false))
  }
}
