import { actionTypes } from '../actions/types'

export interface PayloadInterface {
  data?: object[]
}

export const initialState: any = {
  editableForm: false,
  disableCreateVm: {},
  disableUpload: {},
  isUpgradeDisabled: {},
  networkName: '',
  generalMessage: {},
  isIdleDisabled: true,
  isExtendDisabled: {},
  products: [],
  monthly: true,
  submitInfo:[],
  info: [],
  cities: [''],
  billing: [],
  invoice: [],
  validationErr: '',
  invoiceItem: '',
  billingItem: '',
  ref: '',
  wallet: 0,
  cancleToken: '',
  billingVm: [],
  billingFm: [],
  billingHost: [],
  billingDomain: [],
  invoicesArray: [],
  profileInfo:[],
  ipCountry: '',
  email: '',
  expirationNotification: false,
  transactions: [],
  lockedAccount: false,
  notChangebleInfo:[],
  basicInfo:[],
  companyInfo:[]
}

const accountReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SETTING_STATE:
      return {
        ...state,
        disableCreateVm: action.payload.disableCreateVm,
        disableUpload: action.payload.disableUpload,
        isUpgradeDisabled: action.payload.isUpgradeDisabled,
        isExtendDisabled: action.payload.isExtendDisabled,
        networkName: action.payload.networkName,
        generalMessage: action.payload.generalMessage
      }
    case actionTypes.SET_FORM_STATE:
      return {
        ...state,
        editableForm: action.payload
      }
    case actionTypes.SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload
      }
    case actionTypes.SET_EXPIRATION_NOTIFICATION:
      return {
        ...state,
        expirationNotification: action.payload
      }
      case actionTypes.SET_SUBMIT_INFO:
        return {
          ...state,
          submitInfo: action.payload
        }
    case actionTypes.SET_IP_COUNTRY:
      return {
        ...state,
        ipCountry: action.payload
      }
    case actionTypes.SET_INVOICES_ARRAY:
      return {
        ...state,
        invoicesArray: action.payload
      }
    case actionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        info: action.info
      }
      case actionTypes.SET_USER_PROFILE:
        let {notChangebleInfo,basicInfo,profileInfo,companyInfo,submitInfo} =  action.info
        console.log()
        return {
          ...state,
          notChangebleInfo,
          basicInfo,
          profileInfo,
          submitInfo,
          companyInfo
        }
      
    case actionTypes.SET_CANCEL_TOKEN:
      return {
        ...state,
        cancelToken: action.payload
      }
    case actionTypes.SET_INVOICES:
      return {
        ...state,
        billing: action.payload
      }
    case actionTypes.SET_INVOICES_VM:
      return {
        ...state,
        billingVm: action.payload
      }
    case actionTypes.SET_INVOICES_HOST:
      return {
        ...state,
        billingHost: action.payload
      }
    case actionTypes.SET_INVOICES_DOMAIN:
      return {
        ...state,
        billingDomain: action.payload
      }
    case actionTypes.SET_INVOICES_FM:
      return {
        ...state,
        billingFm: action.payload
      }
    case actionTypes.SET_INVOICE_ITEM:
      return {
        ...state,
        invoiceItem: action.payload
      }

    case actionTypes.SET_INVOICE:
      let invoice = state.invoice
      invoice[action.index] = action.payload
      return {
        ...state,
        billingItem: action.payload,
        invoice: invoice
      }
    case actionTypes.REMOVE_INVOICE:
      return {
        ...state,
        billingItem: [],
        invoice: []
      }
    case actionTypes.SET_VALIDATION_ERR:
      return {
        ...state,
        validationErr: action.payload
      }
    case actionTypes.SET_WALLET:
      return {
        ...state,
        wallet: action.payload
      }
    case actionTypes.SET_REF:
      return {
        ...state,
        ref: action.payload.ref
      }

    case actionTypes.SET_CITIES:
      return {
        ...state,
        cities: action.payload
      }
    case actionTypes.SET_LOCKED_ACCOUNT:
      return {
        ...state,
        lockedAccount: action.payload
      }
    case actionTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.products
      }
    case actionTypes.SET_VM_INVOICE:
      return {
        ...state,
        vmInvoices: action.payload
      }
    case actionTypes.SET_HOST_INVOICE:
      return {
        ...state,
        hostInvoices: action.payload
      }
    case actionTypes.SET_DOMAIN_INVOICE:
      return {
        ...state,
        domainInvoices: action.payload
      }

    case actionTypes.SET_PRODUCT_TOGGLE:
      return {
        ...state,
        monthly: action.payload
      }
    default:
      return state
  }
}

export default accountReducer
