import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { Route, Switch, Link } from 'react-router-dom'

import BillingTable from './BillingTable'
import InvoiceContainer from './InvoiceContainer'
import PayModal from '../../../components/ui-elements/Modal/PayModal/PayModal'

import { Hr } from '../../../components/ui-elements/Hr/Hr'
import { formatPrice } from '../../../services/internal/utils/formatPrice'
import { Button } from '../../../components/ui-elements/Button/Button'
import { formatBytes } from '../../../services/internal/utils/formatBytes'

import { setSidebarState, removeInvoice, vmBillInvoices, getInvoices, setInvoiceItem } from '../../../services/internal/store/actions'

export interface Iprops {
  onClickOverlay: () => void
  open: boolean
  onItemClick?: any
  onDrop?: any
  sidebarOpen?: boolean
}

class Billing extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      tableDataVm: [],
      tableDataFm: [],
      tableDataHost: [],
      tableDataDomain: []
    }
  }
  componentDidMount() {
    this.props.setSidebarState(true)
    document.title = t`Billing`
    this.props.removeInvoice()
  }

  handleOnRowClick = (e: any) => {
    let item = this.props.billing.filter((obj: any) => {
      return obj.id === e
    })[0]
    this.props.setInvoiceItem(item)
  }

  pay = (e: any, item: any) => {
    e.stopPropagation()
    this.props.setInvoiceItem(item)
    this.setState({ showModal: true })
  }
  handleClose = () => {
    this.setState({ showModal: false, modalView: '' })
  }

  makeBillingArray = (array: any, product: any) => {
    let tableData: any = []
    array.map((each: any, index: number) => {
      tableData.push({
        index: +index + 1,
        id: product === 'fm' ? each.id && each.id : each && each.mainId && each.mainId,
        refCode: each.refCode,
        productName: product === 'fm' ? each.product.name + ' ' + formatBytes({ bytes: JSON.parse(each.product.jsonInfo).quota }) : each.vmBillName,
        created_at: each.persianCreatedAt,
        payablePrice: formatPrice(each.payablePrice),
        invoiceStatus: each.invoiceStatus
          ? this.renderStatusButton(each.invoiceStatus, each.id, each, each.paid)
          : this.renderStatusButton(each.paid ? 'PAID' : 'DRAFT', each.id, each, each.paid)
      })
    })
    return tableData
  }
  
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { invoiceItem } = nextProps

    if (invoiceItem) {
      this.setState({ itemPrice: invoiceItem.payablePrice, currentInvoiceItem: invoiceItem })
      if (invoiceItem.payablePrice < this.props.userInfo.balance) this.setState({ payment: 'done' })
    }
    if (nextProps.billingVm && nextProps.billingVm.length > 0) {
      this.setState({ tableDataVm: this.makeBillingArray(nextProps.billingVm, 'vm'), billing: nextProps.billing })
    }
    if (nextProps.billingFm && nextProps.billingFm.length > 0) {
      this.setState({ tableDataFm: this.makeBillingArray(nextProps.billingFm, 'fm'), billing: nextProps.billing })
    }
    if (nextProps.billingHost && nextProps.billingHost.length > 0) {
      this.setState({ tableDataHost: this.makeBillingArray(nextProps.billingHost, 'host'), billing: nextProps.billing })
    }
    if (nextProps.billingDomain && nextProps.billingDomain.length > 0) {
      this.setState({ tableDataDomain: this.makeBillingArray(nextProps.billingDomain, 'domain'), billing: nextProps.billing })
    }
    if (nextProps.vmInvoices && nextProps.vmInvoices.length > 0) {
      let array = nextProps.vmInvoices.map((each: any) => {
        let id = each.id
        let vmBillName = each.vmBill && each.vmBill.name
        if (each.invoice) {
          each.invoice.mainId = id
          each.invoice.vmBillName = vmBillName
        }
        return each.invoice
      })
      this.setState({ tableDataVm: this.makeBillingArray(array, 'vm') })
    }
  }
  renderStatusButton = (status: string, id?: any, array?: any, paid?: boolean) => {
    let button
    switch (status) {
      case 'DRAFT':
        button = (
          <Button onClick={this.pay} isArray={true} name={array} className={['pg-btnMd', 'pg-btnPrimary', 'pg-btnCircle', 'pg-rounded-full']}>
            {t`Pay`}
          </Button>
        )
        break
      case 'PAID':
        button = <Button className={['pg-btnMd', 'pg-btnSuccess pg-btnSuccessOutline', 'pg-btnCircle', 'pg-rounded-full']}>{t`Paid`}</Button>
        break
      case 'CANCEL':
        button = <Button className={['pg-btnMd', 'pg-btnDanger pg-btnDangerOutline', 'pg-btnCircle', 'pg-rounded-full']}>{t`Canceled`}</Button>
        break
    }
    if (paid) button = <Button className={['pg-btnMd', 'pg-btnSuccess pg-btnSuccessOutline', 'pg-btnCircle', 'pg-rounded-full']}>{t`Paid`}</Button>
    return button
  }

  componentWillUnmount() {
    this.props.setSidebarState(false)
  }

  render() {
    let tabs = [
      { link: '/nwfm', label: t`File Storage` },
      { link: '/nwvms', label: t`Virtual Server` },
      { link: '/hosting', label: t`Hosting` },
      { link: '/domain', label: t`Domain` }
    ]
    return (
      <>
        <div className={'pg-flex'}>
          {tabs.map((tab: any) => (
            <div className={`pg-p-3 ${!window.location.pathname.includes(tab.link) ? 'pg-text-gray-600' : 'pg-border-b-2 pg-border-blue-400'}`}>
              <Link to={`/nwbilling${tab.link}`}>{tab.label}</Link>
            </div>
          ))}
        </div>
        <Hr />
        <Switch>
          <Route exact path={`/nwbilling/nwvms/:id`} render={() => <InvoiceContainer />} />
          <Route path={`/nwbilling/nwvms`} render={props => <BillingTable open={this.props.open} tableData={this.state.tableDataVm} {...props} />} />
          <Route path={`/nwbilling/nwfm`} render={props => <BillingTable open={this.props.open} tableData={this.state.tableDataFm} {...props} />} />
          <Route
            path={`/nwbilling/hosting`}
            render={props => <BillingTable open={this.props.open} tableData={this.state.tableDataHost} {...props} />}
          />
          <Route
            path={`/nwbilling/domain`}
            render={props => <BillingTable open={this.props.open} tableData={this.state.tableDataDomain} {...props} />}
          />
          
        </Switch>
        {this.state.showModal &&  <PayModal showModal={this.state.showModal} handleCFClose={this.handleClose} payment={this.state.payment == 'done'} />}
      </>
    )
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSidebarState: (value: any) => dispatch(setSidebarState(value)),
    removeInvoice: () => dispatch(removeInvoice()),
    getVMBilling: () => dispatch(vmBillInvoices()),
    getFMBilling: () => dispatch(getInvoices()),
    setInvoiceItem: (value: any) => dispatch(setInvoiceItem(value))
  }
}
const mapStateToProps = (state: any) => ({
  billing: state.account.billing,
  billingVm: state.account.billingVm,
  billingFm: state.account.billingFm,
  billingHost: state.account.billingHost,
  billingDomain: state.account.billingDomain,
  vmInvoices: state.account.vmInvoices
})
export default connect(mapStateToProps, mapDispatchToProps)(Billing)
