import { StorageInterface } from '../storage/storage'
import { ConfigInterface } from '../../internal/config/config'
interface InputInterface {
  url: string
  headers?: object
  body?: object | string
  cancelToken?: object
  justData?: any
  config?: any
  setUploadProgress?: any
}

interface IBaseInput {
  method?: string
  justData?: any
  setUploadProgress?: any
  config?: any
  url: string
  headers?: object
  body?: object | string
  cancelToken?: object | null
}

export interface RestInterface {
  get(input: InputInterface): Promise<object>
  post(input: InputInterface): Promise<object>
  put(input: InputInterface): Promise<object>
  delete(input: InputInterface): Promise<object>
}
class Rest implements RestInterface {
  private _config: any
  private _http: any
  private _storage: StorageInterface
  private _headers: any
  constructor(config: ConfigInterface, storage: StorageInterface) {
    this._config = config
    this._storage = storage
    // window.document.cookie = `token="${localStorage.getItem('csrf_token')}"`
    let expireDate = new Date(new Date().setTime(new Date().getTime() + 2 * 24 * 60 * 60 * 1000))
    document.cookie = `token="${localStorage.getItem('csrf_token')}";Domain=.persiangig.com;expires=${expireDate};path=/;SameSite=None; Secure`
    this._headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // token: this._storage.getItem('token')
      // cookie: `token="${localStorage.getItem('token')}"`,
      token: localStorage.getItem('csrf_token')
    }

    this._http = this._config.get('httpClient').create({
      baseURL: this._config.get('baseUrl'),
      headers: this._headers,
      timeout: this._config.get('fetchTimeout')
    })
  }

  private async _base({ method = 'GET', url, headers = {}, body, cancelToken, config, setUploadProgress, justData }: IBaseInput) {
    try {
      let expireDate = new Date(new Date().setTime(new Date().getTime() + 2 * 24 * 60 * 60 * 1000))
      document.cookie = `token="${localStorage.getItem('csrf_token')}";Domain=.persiangig.com;expires=${expireDate};path=/;SameSite=None; Secure`
      if (headers) this._headers = { ...this._headers, ...headers }
      // //console.log(`%c[HEADERS]:`, 'font-weight: bold; color: green;', this._headers)
      // //console.log(`%c[${method}]: ${url}`, 'font-weight: bold; color: #3e3e3e;')
      // //console.log(document.cookie)
      if (this._headers.token == null) {
        this._headers.token = localStorage.getItem('csrf_token')
        this._headers.Cookie = window.document.cookie
      }
      if (this._headers.cookie == null) {
        this._headers.token = localStorage.getItem('csrf_token')
        this._headers.Cookie = `token=${localStorage.getItem('csrf_token')}`
      }
      // //console.log(this._headers)
      const httpInput = { method, url, headers: this._headers }
      if (body) (httpInput as any).data = body
      if (cancelToken) (httpInput as any).cancelToken = cancelToken
      let progress
      if (config) {
        this._http = this._config.get('httpClient').create({
          baseURL: this._config.get('baseUrl'),
          headers: this._headers,
          timeout: this._config.get('fetchTimeout'),
          setUploadProgress
        })
      }

      const { data, status } = await this._http(httpInput)

      let result
      if (data) {
        if (progress) {
          result = [data, progress]
        } else result = data
      } else {
        result = status
      }
      if (justData) result = data
      return result
    } catch (err) {
      let { response } = err
      if (response && response.data) {
        let data = response.data
        if (data.errors && data.errors[0].code === 403) {
          localStorage.setItem('prevPath', window.location.pathname)
          // alert(window.location.pathname)
          window.location.replace('/nwlogin')
        }
        throw data.length > 500 && response.status ? `${response.status} ${response.statusText} ` : data
      }
      if (!err.statusCode) throw 'Network Error'
      throw err
    }
  }

  async get({ url, headers, cancelToken, justData }: InputInterface) {
    // //console.log(headers)
    try {
      return await this._base({ method: 'GET', url, headers, cancelToken, justData })
    } catch (error) {
      throw error
    }
  }

  async post({ url, headers, body, config, setUploadProgress }: InputInterface) {
    try {
      return await this._base({ method: 'POST', url, headers, body, config, setUploadProgress })
    } catch (error) {
      throw error
    }
  }

  async put({ url, headers, body }: InputInterface) {
    try {
      return await this._base({ method: 'PUT', url, headers, body })
    } catch (error) {
      throw error
    }
  }

  async delete({ url, headers }: InputInterface) {
    try {
      return await this._base({ method: 'DELETE', url, headers })
    } catch (error) {
      throw error
    }
  }
}

export default Rest
